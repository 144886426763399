import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./adminDashActionTypes";

export const setOverview = (value) => ({
  type: TYPES.OVERVIEW_ALL,
  value,
});

export const setFirebaseErrors = (value) => ({
  type: TYPES.FIREBASE_ERRORS,
  value,
});

export const setUser = (value) => ({
  type: TYPES.GET_USER,
  value,
});

export const setAuditEvents = (value) => ({
  type: TYPES.GET_AUDIT_EVENTS,
  value,
});

export const setPlan = (value) => ({
  type: TYPES.GET_PLAN,
  value,
});

export const getRealTime = (value) => ({
  type: TYPES.GET_REALTIME,
  value,
});

export const userBusy = (value) => ({
  type: TYPES.APP_USER_BUSY,
  value,
});

export const planBusy = (value) => ({
  type: TYPES.APP_PLAN_BUSY,
  value,
});

export function getAdminScopes(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/scopes`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAdminOverview(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/overview`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setOverview(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getFireBaseErrors(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/audit?searchPath=/errors/${params.driverId}`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setFirebaseErrors(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAllUsers(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/accounts/all/user`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setUser(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getRtDrivers(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.REALTIME_URL");
    // const accountId = _get(getState(), "authData.profile.user.userId");

    params.accountId = "*"

    return axios({
      url: `${BASE_URL}/realtime`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(getRealTime(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAuditEvents(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/audit?searchPath=/errors`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setAuditEvents(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function deleteAuditEvents(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/audit`,
      method: "put",
      params: { searchPath: params.searchPath },
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setAuditEvents(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createUser(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    // const accountId = _get(getState(), "authData.profile.user.userId");

    dispatch(userBusy(true));

    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/user`,
      method: "post",
      data: JSON.stringify(params),
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { message };
      })
      .then((response) => {
        dispatch(userBusy(false));
        return response;
      });
  };
}

export function updateUser(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    // const accountId = _get(getState(), "authData.profile.user.userId");

    dispatch(userBusy(true));

    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/user/${params.id}`,
      method: "put",
      data: JSON.stringify(params),
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { message };
      })
      .then((response) => {
        dispatch(userBusy(false));
        return response;
      });
  };
}

export function updatePlan(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    // const accountId = _get(getState(), "authData.profile.user.userId");

    dispatch(planBusy(true));

    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/plan`,
      method: "put",
      data: JSON.stringify(params),
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { message };
      })
      .then((response) => {
        dispatch(planBusy(false));
        return response;
      });
  };
}

export function getPlan(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.userId");

    return axios({
      url: `${BASE_URL}/admin/accounts/${accountId}/plan`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(getPlan(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function syncRedis(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.REALTIME_URL");
    // const accountId = _get(getState(), "authData.profile.user.userId");

    return axios({
      url: `${BASE_URL}/sync`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(getRealTime(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function cleanRedis(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.REALTIME_URL");
    // const accountId = _get(getState(), "authData.profile.user.userId");

    return axios({
      url: `${BASE_URL}/sync/cleanup`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(getRealTime(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
