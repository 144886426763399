import * as TYPES from "../app/appActionTypes";

const defaultState = {
    appBusy: false,
    timezone: "",
    preCheck: true,
    accountBusy: true,
    accountUpdateBusy: true,
    accountSettings: {},
    appConfig: {}
};

export default function app(state = defaultState, action) {
    switch (action.type) {
        case TYPES.APP_BUSY:
            return {
                ...state,
                appBusy: action.value
            };
        case TYPES.PRE_CHECK:
            return {
                ...state,
                preCheck: action.value
            };
        case TYPES.TIMEZONE:
            return {
                ...state,
                timezone: action.value
            };
        case TYPES.ACCOUNT_UPDATE_BUSY:
            return {
                ...state,
                accountUpdateBusy: action.value
            };
        case TYPES.ACCOUNT_PROPERTIES:
            return {
                ...state,
                accountSettings: action.value
            };
        case TYPES.APP_CONFIG:
            return {
                ...state,
                appConfig: action.value
            };
        default:
            return state;
    }
}
