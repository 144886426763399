import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  TrafficLayer,
} from "@react-google-maps/api";
import { withRouter } from "react-router-dom";
import Button from "@mui/material/Button";

const camIconStyle = {
  verticalAlign: "bottom",
  fontSize: "18px !important",
  marginRight: 0,
  width: "1rem",
  height: "1rem",
  cursor: "pointer",
};

function ReactMap(props) {
  let center = {
    lat: 38.90254051813797,
    lng: -77.00683325564484,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: props.apiKey,
  });
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: "calc(100vh - 80px)",
            display: "block",
            flexDirection: "column",
            width: "100%",
          }}
          zoom={12}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <TrafficLayer />
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
}
export default ReactMap;
