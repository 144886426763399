import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import Helmet from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MainNavigator from "./Navigator/MainNavigator";
import MainRoutes from "./Navigator/MainRoutes";
import { signOut, getProfile } from "./actions/auth/authActions";
import Header from "./containers/Header";
import { getAccountAuth } from "./actions/account/accountAdminActions";
import * as cookie from "react-cookie";
import { HTML_HEAD, APP_STYLE_ID } from "./config/index";



const cookies = new cookie.Cookies();

const drawerWidth = 200;
const minDrawerWidth = 50;

const getIsMobile = () => window.innerWidth >= 768;

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {},
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: "white",
    width: "100%",
  },
};

const AppSecure = (props) => {
  const classes = {};
  // const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [show, setShow] = useState(getIsMobile());

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserIconClick = () => {
    props.signOut();
  };

  const handleAppAccount = async () => {
    const response = await props.getAccountAuth();

    cookies.set("token", response.token, {
      path: "/",
      maxAge: 43200,
    });

    await props.getProfile();

    setTimeout(() => {
      props.history.push(`/dashboard/accounts`);
      window.location.reload();
    }, 100);
  };

  const { needAutorization, accountBaseUrl } = props;
  const adminKey = props.adminKey;

  if (needAutorization) {
    props.history.push("/");
  }

  // const drawerFixWidth = show ? drawerWidth : minDrawerWidth;
  // const routesWidth = window.innerWidth - drawerFixWidth;

  useEffect(() => {
    const onResize = () => {
      setShow(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <Router>
      <div id={APP_STYLE_ID} className={classes.root}>
        <Helmet>
          <title>{HTML_HEAD.title}</title>
          <link rel="shortcut icon" href={HTML_HEAD.favicon} />
          <meta name="description" content={HTML_HEAD.content} />
          <meta name="author" content={HTML_HEAD.author} />
          <meta name="keyword" content={HTML_HEAD.keyword} />
        </Helmet>
        <CssBaseline />
        <div className={classes.appContent}>
          <Header
            adminKey={adminKey}
            handleAppAccount={handleAppAccount}
            onDrawerToggle={handleDrawerToggle}
            handleUserIconClick={handleUserIconClick}
          />
          <div className="d-flex">
            <MainNavigator
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              show={true}
            />
            <nav
              className={classes.drawer}
              style={{ width: show ? drawerWidth : minDrawerWidth }}
            >
              <MainNavigator
                show={show}
                setShow={() => {
                  setShow(!show);
                }}
                PaperProps={{
                  style: { width: show ? drawerWidth : minDrawerWidth },
                }}
              />
            </nav>
            <MainRoutes accountBaseUrl={accountBaseUrl} />
          </div>
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    isDriver: _get(state, "authData.isDriver", false),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    adminKey: _get(state, "authData.profile.adminId"),
    needAutorization: _get(state, "authData.needAutorization", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      getAccountAuth,
      getProfile,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AppSecure)
);
