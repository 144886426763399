export const LABELS = {
  saveMessage: "Guardado con éxito.",
  errorMessage: "Inténtalo después de un tiempo.",
  deleteLabel: "Eliminar infracciones",
  deleteMsg: "¿Está seguro de que desea eliminar todas las infracciones?",
  language: "Idioma",
  noDriverFound:'No se encontró ningún controlador',

  selectLanguage:"Seleccionar idioma",
  saving:"Ahorro...",
  save:"Ahorrar",
  close:"Cerca",

};
