const defaultState = {
    userBusy: false,
    count: 0,
    users: [],
    selectedEquipment: {}
};

export default function user(state = defaultState, action) {
    switch (action.type) {
        case "USER_COUNT":
            return {
                ...state,
                count: action.value
            };

        case "USERS":
            return {
                ...state,
                users: action.value
            };
        case "SELCTED_USER":
            return {
                ...state,
                selectedUser: action.value
            };
        case "APP_USER_BUSY":
            return {
                ...state,
                userBusy: action.value
            };
        default:
            return state;
    }
}
