import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  formatDate,
  formatTime,
  formatDateTimeSec,
  getStartOfDay,
  getEndOfDay,
  formatDateTime,
} from "../../actions/momentActions";
import {
  setSelectedLog,
  getUnidentifiedCount,
  getUnidentified,
} from "../../actions/logActions";
import { deleteAdminLogs } from "../../actions/account/accountAdminActions";
import CircularProgress from "@mui/material/CircularProgress";
import UnidentifiedDrivingTable from "./UnidentifiedDrivingTable";
import { startDay, currTime } from "../../utils/momentHelpers";
import ReassignDriving from "../ReassignDriving";
import SearchBar from "../../components/SearchBar";

class UnidentifiedDriving extends React.Component {
  state = {
    limit: 10,
    page: 0,
    open: false,
    start: startDay,
    end: currTime,
    driverId: "",
    vehicleId: "",
  };

  async componentDidMount() {
    await this.props.getUnidentifiedCount();
    await this.loadUnidentified();
  }

  loadUnidentified = async () => {
    this.setState({ loading: true });
    const { limit, page, vehicleId, start, end } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    await this.props.getUnidentified({
      limit,
      skip,
      vehicleId,
      start: start ? new Date(this.props.getStartOfDay(start)) : null,
      end: end ? new Date(this.props.getEndOfDay(end)) : null,
    });
    this.setState({ loading: false });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.loadUnidentified);
  };

  handleClose = (e, open = false) => {
    this.props.setSelectedLog({});
    // this.loadUnidentified();
    this.setState({ open: false });
  };

  handleAssignDriver = (e, selectedLog, open = true) => {
    this.props.setSelectedLog(selectedLog);
    this.setState({ open: true });
  };

  onSearch = ({ vehicleId, start, end }) => {
    this.setState({ vehicleId, start, end }, this.loadUnidentified);
  };

  render() {
    const { open = false, loading } = this.state;

    return (
      <div>
        {open && (
          <ReassignDriving
            open={open}
            editType={"UNIDENTIFIED"}
            handleClose={this.handleClose}
          />
        )}
        <SearchBar
          options={{
            isEqp: true,
            isStartDate: true,
            isEndDate: true,
            isTime: false,
            isTimeperiod: false,
          }}
          loading={loading}
          onSearch={this.onSearch}
        />
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        <UnidentifiedDrivingTable
          handleChange={this.handleChange}
          handleAssignDriver={this.handleAssignDriver}
          count={this.props.count}
          items={this.props.unidentifiedLogs}
          formatDate={this.props.formatDate}
          setSelectedLog={this.props.setSelectedLog}
          formatTime={this.props.formatTime}
          limit={this.state.limit}
          page={this.state.page}
          isMarkerShown={true}
          adminKey={this.props.adminKey}
          isQa={this.props.isQa}
          deleteAdminLogs={this.props.deleteAdminLogs}
          loadUnidentified={this.loadUnidentified}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unidentifiedLogs: _get(state, "logData.unidentifiedLogs", []),
    count: _get(state, "logData.unidentifiedLogsCount", 0),
    pagination: _get(state, "locationData.pagination"),
    adminKey: _get(state, "authData.profile.adminId"),
    isQa: _get(state, "appData.isQa"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatTime,
      formatDateTimeSec,
      setSelectedLog,
      getUnidentifiedCount,
      getUnidentified,
      getStartOfDay,
      getEndOfDay,
      formatDateTime,
      deleteAdminLogs,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnidentifiedDriving);
