import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import user from "./user";
import routes from "./routes";
import driver from "./driver";
import account from "./account";
import carrier from "./carrier";
import terminal from "./terminal";
import equipment from "./equipment";
import trailers from "./trailer";
import dvir from "./dvir";
import log from "./log";
import location from "./location";
import documentData from "./document";
import notification from "./notification";
import violation from "./violation";
import fmcsa from "./fmcsa";
import overview from "./adminDash";
import liveShare from "./liveShare";
import checkout from "./checkout";
import reseller from "./reseller";
import apiKey from "./apiKey";
import support from "./support";
import maintenance from "./maintenance";
import adminSupport from "./adminSupport";
import whatsapp from "./adminWhatsapp";
import { reducer as form } from "redux-form";

export default combineReducers({
    accountData: account,
    appData: app,
    logData: log,
    authData: auth,
    checkoutData: checkout,
    documentData,
    userData: user,
    dvirData: dvir,
    driverData: driver,
    carrierData: carrier,
    terminalData: terminal,
    equipmentData: equipment,
    locationData: location,
    notificationData: notification,
    fmcsaData: fmcsa,
    violationData: violation,
    liveShareData: liveShare,
    routesData: routes,
    adminOverview: overview,
    apiData: apiKey,
    trailerData: trailers,
    resellerData: reseller,
    supportData: support,
    adminSupportData: adminSupport,
    maintenanceData: maintenance,
    whatsappData: whatsapp,
    form
});
