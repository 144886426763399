export const LABELS={
   addCarrier :"AJOUTER UN TRANSPORTEUR",
   editCarrier:"MODIFIER LE TRANSPORTEUR",
   edit_Terminal:"MODIFIER LE TERMINAL",
   add_Terminal:"AJOUTER UN TERMINAL",
   addAccount:"AJOUTER UN COMPTE",

   editEquipment :"MODIFIER L'ÉQUIPEMENT",
   addEquipment:"AJOUTER UN ÉQUIPEMENT",

   editTrailer:"MODIFIER LA BANDE-ANNONCE",
   addTrailer:"AJOUTER UNE BANDE-ANNONCE",

   editReasons:"MODIFIER LES RAISONS",

   addUser:"AJOUTER UN UTILISATEUR",
   editUser:"MODIFIER L'UTILISATEUR",
   driver_details:"DÉTAILS DU PILOTE",
   update:"MISE À JOUR",
   addApi_key:"AJOUTER UNE CLÉ API",

   logingUsers:"Connexion des utilisateurs",
   supportCentre:"Centre de soutien",


   name:"Nom",
   dot:"DOT",
   timezone:"Fuseau horaire",
   country:"Pays",
   address_line1:"Adresse Ligne 1",
   suit_bldg:"Suit.,bldg",
   city:"Ville",
   state:"État",
   zip:"le code postal",
   contactName:"Nom du contact",
   email:"E-mail",
   phone:"Téléphone",
   role:"Rôle",
   dispatchName:"Nom de l'expéditeur",
   note:"NOTE :",
   info:"Les informations d'expédition ne sont requises que si vous souhaitez envoyer l'expédition à l'appareil ELD sous forme de notifications push.",
   save:"SAUVEGARDER",
   saving:"Économie...",
   cancel:"ANNULER",
   close:"FERMER",
   roles:"Les rôles",
   access:"Accéder",
   cargo:"Cargaison",
   hos:"HOS",
   dutyLogs:"JOURNAUX DE SERVICE",
   contact:"Contact",
   required:"Requis",
   billing: "Facturation",


   accountName:"Nom du compte",
   contactEmail:"Email du contact",
   contactPhone:"Numéro du contact",
   contactRole:"Rôle de contact",
   stripe:"Bande",
   lastActive:"Dernier actif",


  driverId:"ID du conducteur",
  firstName:"Prénom",
  lastName:"Nom de famille",
  mobile:"Mobile",
  password:"Mot de passe",
  licCountry:"Pays de licence",
  licState:"État de la licence",
  licNumber:"Numéro de licence",
  cycleUsa:"Cycle USA",
  cycleCA:"Cycle CA",
  startTime:"Heure de début",
  receiverMail:"E-mail du destinataire",
  personal:"Personnel",
  yardMoves:"Mouvements de cour",
  allowEdit:"Autoriser la modification",
  tracking:"Suivi",
  exempt:"Exempt",
  exemptDriver:"Conducteur exonéré",
  exemptAnnotation:"Annotation exemptée",
  assignedVehicle:"Véhicule attribué",
  currentVehicle:"Véhicule actuel",
  currentTrailer:"Bande-annonce actuelle",
  enterMobileNumber:"Entrez le numéro de portable",
  appHome_View:"Vue d'accueil de l'application",
  firebase:"Base de feu",
  apiKey:"Clé API",
  scopes:"Portées",
  message:"Message",
  sendNow:"Envoyer maintenant",
  loading:"Chargement...",
  sendVia_whatsapp:"Envoyer via WhatsApp",
  send_sms:"ENVOYER UN SMS",
  speed:"Vitesse",



  prefix:"Préfixe",
  state_mileage_report:"Rapport de kilométrage d'État",
  pdf:"PDF",
  details:"DÉTAILS",
  carrierName:"Nom du transporteur",
  vehicleName:"Nom du véhicule",
  miles:"Milles",
  mileage_miles:"Kilométrage (miles)",
  quarter:"Quart",
  report:"Rapport",
  select_vehicle:"Sélectionnez un véhicule",
  odometer:"Odomètre",
  superAdmin:"Super administrateur",
  supportAdmin:"Administrateur d'assistance",


   No_vehicle_is_assignedTO_deiver:" Aucun véhicule n'est attribué au chauffeur",
   please_set_current_vehicle:"Veuillez définir le véhicule actuel",



  
   endTime:"Heure de fin",
   duration:"Durée",
   recordStatus:"Statut de l'enregistrement",
   recordOrigin:"Origine de l'enregistrement",
   location:"Emplacement",
   assign:"Attribuer",
   driver:"Conducteur",


   diagostic$Malfunction:"Diagnostic/Dysfonctionnement",
   eventCode:"Code événement",
   eventTime:"Heure de l'évènement",





   status:"Statut",
   terminal:"Terminal",
   address:"Adresse",
   title:"Titre",
   body:"Corps",
   time:"Temps",
   user:"Utilisateur",
   allUser:"Tous les utilisateurs",
   sendNotification:"Envoyer une notification",
   send:"Envoyer",
   syncAll:"TOUT SYNCHRONISER",
   inspector:"Inspecteur",
   condition:"Condition",
   start_end:"Début Fin",
   view:"VOIR",
   date:"Date",
   _note:"Note",
   _details:"Détails",
   _view:"Voir",
   settings:"Paramètres",
   select:"Sélectionner",
   coordinates:"Coordonnés",
   event:"Événement",
   table:"TABLEAU",
   map:"CARTE",
   trips:"Voyages",
   delete:"SUPPRIMER",
   edit:"Modifier",
   



   driver_vehicle_inspection_report:"RAPPORT D'INSPECTION DU VÉHICULE DU CONDUCTEUR (DVIR)",
   AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS:"COMME EXIGÉ PAR LE D.O.T. RÈGLEMENT FÉDÉRAL SUR LA SÉCURITÉ DES TRANSPORTEURS AUTOMOBILES",
   dvirNumber:"Numéro DVIR : -",
  start_inspection:"Commencer l'inspection",
  endInspection:"Fin de l'inspection",
  inspection_type:"Type d'inspection",
  inspection_Area:"Zones d'inspection",
  I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements:"Je déclare que cet actif a été inspecté conformément aux exigences applicables.",
  driver_signature:"Signature du conducteur",



  
   downloading:"Téléchargement...",
   not_able_to_download:"Impossible de télécharger",
   missingFile:"Fichier manquant",



   history:"HISTOIRE",
   send_to_fmcsa:"ENVOYER À LA FMCSA",
   email_to_fmcsa:"COURRIEL À LA FMCSA",
   download:"TÉLÉCHARGER",
   SelectDriver:"Sélectionnez le pilote",
   fromDate:"Partir de la date",
   toDate:"À ce jour",
   comments:"commentaires",
   fmcsa_data_transfer:"Transfert de données FMCSA (étapes pour transférer des données)",
   selectDateRange:"Sélectionnez une plage de dates",
   enterComment:"Entrez un commentaire",
   sendDataTo_fmcsa:"Cliquez sur \"Envoyer à FMCSA\" pour transférer les données vers FMCSA",




    showall:"Afficher tout",
    vehicle:"VÉHICULE",
    trailer:"BANDE-ANNONCE",
    search:"Recherche...",
    add:"AJOUTER",
    liveshare:"Partage en direct",
    create_ifta:"CRÉER L'IFTA",
    no_data_found:"Aucune donnée disponible!",
    no_recordFound:"Aucun enregistrement n'a été trouvé",


    regulationMode:"Mode de régulation",
    plate:"Plaque",
    eld:"ELD",
    created_edited:"Créé/Modifié",
    created:"Créé",
    edited:"Édité",


    rowsperpage: "Lignes par page",


//   <------ADD equipment Form------->

    vin:"Vin",
    make:"Faire",
    model:"Modèle",
    year:"Année",
    fuel:"Carburant",
    type:"Taper",
    licPlate:"Plaque Licence",
    dvirForm:"Formulaire DVIR",
    gps_source:"Source GPS",
    devicetype:"Type d'appareil",
    devicename:"Nom de l'appareil",
    macAddrerss:"Adresse Mac",
    eld_serial:"Série DCE",
    eld_firmware:"Micrologiciel ELD",
    eld_BleVersion:"ELD BleVersion",
    connectionType:"Type de connexion",
    dashcam:"Dash Cam",
    odometer_offset:"Décalage du compteur kilométrique",
    active:"Active",
    codes:"Codes",
    ifta:"Ifta",
    vbusConnection:"Connexion VBUS requise pour la conduite",
    carrier:"Transporteur",
    dvir :"Dvir",
    licExpiry:"Expiration de la licence",
    adminScope:"PORTÉES D'ADMINISTRATION",
    assetType:"Type d'actif",
    dueby:"Due par",
    notes:"Remarques",
    


    dashcameraId:"Identifiant de la caméra embarquée",
    reseller:"Revendeur",
    updateFrequency:"Fréquence de mise à jour",
    bucket:"Seau",
    theme:"Thème",
    kioskpin:"Kioskpin",
    hereAppId:"Ici, identifiant de l'application",
    hereApiKey:"Ici la clé API",
    updateLocation:"Mettre à jour l'emplacement",
    debug:"Déboguer",
    allLogs:"Tous les journaux",
    editLogs:"Modifier les journaux",
    documents:"Documents",
    notification:"Notifications",
    addDvir:"Ajouter Dvir",
    appSettings:"Paramètres de l'application",
    appAll_Log:"Tous les journaux de l'application",
    appAllLogsFmcsa:"Application Tous les journaux Fmcsa",
    estimatedWaitTime:"Temps d'attente estimé",
    minutes:"minute(s)",
    maintenance:"Entretien",
    updateDvir:"Mettre à jour le Dvir",
    vehicletype:"Type de véhicule",
    form:"Formulaire",
    _vehicle:"Véhicule",
    StartDate:"Date de début",
    EndDate:"Date de fin",
    _trailer:"Bande-annonce",
    equipmentconditions:"Conditions de l'équipement",
    date_is_required:"La date est requise",






   noteinfo:"REMARQUE : Une fois que vous avez ajouté un nouveau véhicule, votre carte enregistrée sera automatiquement débitée.",
  ifta_info_note: "L'IFTA n'est disponible que pour les camions actifs.",
  ifta_info:"Si vous souhaitez désactiver ou supprimer un camion, veuillez télécharger le kilométrage de l'état avant de désactiver l'action.",
  saved_Successfully:"Enregistré avec succès",
  updateSuccessfully:"Mis à jour avec succés",
  sendSuccessfully:"Le SMS est envoyé avec succès",
  successMessage_carrier:"Le transporteur est enregistré avec succès",
  successMessage: "La bande-annonce est enregistrée avec succès",
  successMessage_Equipment: "L'équipement est enregistré avec succès",
  please_try_aftersometime:"S'il vous plaît, essayez après un certain temps.",
  successMessage_user:"L'utilisateur a été ajouté avec succès",
  successMessage_Api_Key:"La clé API a été ajoutée avec succès",
  response:"Quelque chose s'est mal passé, veuillez réessayer plus tard.",
  errorMessage:"Quelque chose s'est mal passé, veuillez réessayer plus tard.",
  editReasonUpdated:"Les motifs de modification ont été mis à jour avec succès.",
  logs_are_successfully_submitted:"Les journaux sont soumis avec succès.",
  user_is_updated_successfully:"L'utilisateur est mis à jour avec succès.",
  driver_is_cleaned_successfully:"Le pilote est nettoyé avec succès.",
  account_is_updated_successfully:"Le compte est mis à jour avec succès",
  Note_only_requiredforSetNewPassword:"Remarque : obligatoire uniquement si vous souhaitez définir un nouveau mot de passe",
  driver_is_required:"Un pilote est requis.",
  from_date_is_required:"La date de début est requise.",
  to_date_is_required:"À ce jour est requis.",
  comment_is_required:"Un commentaire est requis.",
  comment_is_invalid:"Le commentaire n'est pas valide.",

  are_you_sure_WantTo_delete:"Etes-vous sûr de vouloir supprimer dvir",
  errorMassage:"Quelque chose s'est mal passé, veuillez réessayer plus tard.",
  deletedsucessfully:"Supprimé avec succès.",
  deleting:"Suppression...",
  deleteViolation:"Supprimer les violations",
  are_you_sure_wantTo_delete_all_violation:"Êtes-vous sûr de vouloir supprimer toutes les violations",






}
