import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import _get from "lodash/get";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MultiStatusDetailTable from "./MultiStatusDetailTable";
import { formatDateTimeToUtc, getDate } from "../../../actions/momentActions";
import Error from "../../Error";
import Success from "../../Success";
import { createMultiEditRequest } from "../../../actions/logActions";
import { getPayload } from "../AddDailyStatusModal/helpers";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.dutyPage;


const styles = (theme) => ({
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    // padding: theme.spacing(1),
    minHeight: 200,
    overflow: "scroll",
  },
});

class MultipleStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logs: this.props.allLogs,
    };
  }
  multi = (params = {}) => {
    params.isEdited = true;
    const { logs } = this.state;
    const index = logs.findIndex((e) => e.cid === params.cid);
    logs[index] = params;
    this.setState({ logs });
  };

  onSubmit = () => {
    const { logs = [] } = this.state;
    const { selectedDate, selectedDriver } = this.props;
    const driver = selectedDriver.driver || {};
    const timezone = this.props.timezone;

    this.setState({ loading: true, successMessage: "", errorMessage: "" });

    for (var i = 0; i < logs.length; i++) {
      const evDate = logs[i].eventTime;
      if (!moment(evDate).isValid()) {
        this.setState({ loading: false, errorMessage: "Selected date is not valid" });
        return;
      }
    }

    let count = 0;
    const list = logs.filter((e) => e.isEdited);
    if (list.length > 0) {
      const error = list.filter(
        (e) =>
          !e.eventTime ||
          e.odometer === "" ||
          parseInt(e.odometer) > 9999999 ||
          e.location === "" ||
          e.eventCode === "" ||
          e.eventCode === "-" ||
          e.state === "" ||
          e.state === "-"
      );

      if (error && error.length > 0) {
        this.setState({
          errorMessage: "Fix field errors to save",
          loading: false,
        });
        return;
      } else {
        list.forEach(async (item, index) => {
          const payload = getPayload(
            driver,
            item,
            item,
            selectedDate,
            timezone,
            this.props.formatDateTimeToUtc
          );
          await this.props.createMultiEditRequest(payload);
          count++;
          if (count === list.length) {
            this.setState({
              successMessage: LABELS.successMessage,
              errorMessage: "",
              loading: false,
            });
            this.props.onDone();
          }
        });
      }
    } else {
      this.setState({
        errorMessage: LABELS.errorMessage,
        successMessage: "",
        loading: false,
      });
      this.props.onDone();
    }
  };

  render() {
    const { classes } = this.props;
    const { loading, successMessage, errorMessage, logs } = this.state;
    const list = logs.filter((e) => e.isEdited);
    const allLogs = logs.filter((e) => !e.locked);

    return (
      <div style={{ textAlign: "center" }}>
        <Dialog
          fullScreen
          className={classes.wrapper}
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" onClose={this.props.close}>
            <Typography style={{ minWidth: "900px" }}>
              {LABELS.editMultipleStatus}
            </Typography>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={this.props.close}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <MultiStatusDetailTable
              selectedDate={this.props.selectedDate}
              searchLogs={this.props.searchLogs}
              // isAllLog={this.props.isAllLog}
              allLogs={allLogs}
              // handleLogEdit={this.props.handleLogEdit}
              dailyDiary={this.props.dailyDiary}
              searchLogStatus={() =>
                this.props.searchLogs(this.props.selectedDate)
              }
              formatDateTimeToUtc={this.props.formatDateTimeToUtc}
              getDate={this.props.getDate}
              adminAccess={this.props.adminAccess}
              adminKey={this.props.adminKey}
              selectedLog={this.props.selectedLog}
              formatTime={this.props.formatTime}
              setSelectedLog={this.props.setSelectedLog}
              formatRawDate={this.props.formatRawDate}
              deleteAdminLogs={this.props.deleteAdminLogs}
              updateAdminLogs={this.props.updateAdminLogs}
              driverCalcus={this.props.driverCalcus}
              timezone={this.props.timezone}
              isDebug={this.props.isDebug}
              adminScope={this.props.adminScope}
              formatDateTimeUtc={this.props.formatDateTimeUtc}
              multi={this.multi}
              createMultiEditRequest={this.props.createMultiEditRequest}
              startOfDay={this.props.startOfDay}
            />

            {successMessage && <Success message={successMessage} />}
            {errorMessage && <Error message={errorMessage} />}
            <DialogActions className={classes.DialogActions}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={this.onSubmit}
                disabled={loading || list.length === 0}
              >
                {loading ? LABELS.saving : LABELS.save}
              </Button>
              <Button variant="contained" onClick={this.props.close}>
                {LABELS.close}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    timezone: _get(state, "appData.timezone", ""),
    driverDiary: _get(state, "logData.driverDiary", []),
    driverId: _get(state, "driverData.selectedDriver.driver.id", {}),
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createMultiEditRequest,
      formatDateTimeToUtc,
      getDate,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MultipleStatusModal))
);
