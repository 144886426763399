import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    formatDate,
    formatTime,
    formatDateTimeSec,
    getStartOfDay,
    getEndOfDay,
    formatDateTime
} from "../../actions/momentActions";
import {
    getViolationsCount,
    getViolations
} from "../../actions/violationActions";
import { updateDriverDaily } from "../../actions/logActions"
import ViolationsTable from "./ViolationsTable";
import { startDay, currTime } from "../../utils/momentHelpers";
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from "../../components/SearchBar";

class Violations extends React.Component {
    state = {
        limit: 10,
        page: 0,
        loading: false,
        start: startDay,
        end: currTime,
        driverId: "",
        vehicleId: ""
    };

    async componentDidMount() {
        await this.getViolations();
        await this.getViolationsCount();
    }

    getViolationsCount() {
        const { driverId, start, end } = this.state;
        this.props.getViolationsCount({ driverId, start, end });
    }

    async getViolations() {
        this.setState({ loading: true });
        const { limit, page, driverId, start, end } = this.state;
        await this.props.getViolations({
            limit,
            page,
            driverId,
            start: start ? new Date(this.props.getStartOfDay(start)) : null,
            end: end ? new Date(this.props.getEndOfDay(end)) : null
        });
        this.setState({ loading: false });

    }

    handleChange = ({ limit, page }) => {
        this.setState({ limit, page }, this.getViolations);
    };

    onSearch = ({ driverId, start, end }) => {
        this.setState({ driverId, start, end }, () => {
            this.getViolationsCount();
            this.getViolations();
        });
    };

    render() {
        const { loading } = this.state;
        return (
            <div>
                <SearchBar
                    options={{
                        isDriver: true,
                        isStartDate: true,
                        isEndDate: true,
                        isTime: true,
                        isDownload: false,
                        isTimeperiod: true,
                        dl: true,

                    }}
                    loading={loading}
                    onSearch={this.onSearch}
                    onDownload={() => { }}
                />
                {loading && (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                )}
                <ViolationsTable
                    count={this.props.count}
                    items={this.props.violations}
                    formatDate={this.props.formatDate}
                    formatTime={this.props.formatTime}
                    limit={this.state.limit}
                    page={this.state.page}
                    handleChange={this.handleChange}
                    updateDriverDaily={this.props.updateDriverDaily}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        violations: _get(state, "violationData.violations", []),
        count: _get(state, "violationData.count", 0),
        pagination: _get(state, "violationData.pagination")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            formatDate,
            formatTime,
            formatDateTimeSec,
            getViolationsCount,
            getViolations,
            getStartOfDay,
            getEndOfDay,
            formatDateTime,
            updateDriverDaily
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Violations);
