export function formatChats(chats) {
  let all = {};
  chats = chats || [];
  for (let i = 0; i < chats.length; i++) {
    const item = chats[i];

    if (item && item.status === "ACTIVE") {
      const user = item.from || {};
      const accountId = item.account ? item.account.id : item.chatId;
      const accountName = item.account && item.account.name;
      const userName = `${user.name}(${user.wid})`;

      all[accountId] = all[accountId] || {};
      all[accountId].account = all[accountId].account || item.account;
      all[accountId].name = all[accountId].name || accountName || userName;
      all[accountId].items = all[accountId].items || [];
      all[accountId].items.push(item);
    }
  }

  return Object.keys(all).map((item) => {
    return {
      account: all[item].account,
      name: all[item].name,
      items: all[item].items,
    };
  });
}
