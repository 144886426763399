import React from "react";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cleanRedis } from "../../actions/adminDashActions";
import Alert from "@mui/material/Alert";
import {LABELS as _LABELS} from "../../language";

const LABELS= _LABELS.resellerForm;


const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

export class ConfirmActionModal extends React.Component {
  state = {
    loading: false,
    message: "",
  };

  handleClean = async () => {
    const { accountId, driverId, vehicle } = this.props.driver;
    try {
      this.setState({ loading: true, message: "" });
      await this.props.cleanRedis({
        accountId,
        driverId,
        vehicleId: vehicle && vehicle.id,
      });
      this.setState({
        loading: false,
        message: LABELS.driver_is_cleaned_successfully,
      });
      setTimeout(() => this.props.handleClose(), 1000);
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    const loading = this.state.loading;
    const isSmallScreen = window.innerWidth < 500;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xs"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{`${LABELS.cleanDriver}`}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} >
            {this.state.message && (
              <Grid item xs={12} sm={12} md={12}>
                <Alert severity="success">{this.state.message}</Alert>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} className={classes.AlignCenter}>
              <Button
                size="small"
                onClick={this.handleClean}
                variant="contained"
                color="primary"
              >
                {/* {loading ? "Cleaning..." : "CLEAN NOW"} */}
                {`${loading ? LABELS.cleaning: LABELS.cleanNow}`}

              </Button>
              <br />
              <Button
                size="small"
                onClick={this.props.handleClose}
                color="primary"
              >
                {LABELS.close}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ cleanRedis }, dispatch);

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmActionModal))
);
