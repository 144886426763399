import axios from "./axios";
import _get from "lodash/get";

export function getLogPostUrl(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        const driverId = _get(getState(), "driverData.selectedDriver.userId", {});

        return axios({
            url: `${BASE_URL}/${accountId}/drivers/${driverId}/image/upload`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data");
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}


export function getLogGetUrl(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        const driverId = _get(getState(), "driverData.selectedDriver.userId", "");

        return axios({
            url: `${BASE_URL}/${accountId}/drivers/${driverId}/image`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data");
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}
