const defaultState = {
    invoices: []
};

export default function emailer(state = defaultState, action) {
    switch (action.type) {
        case 'INVOICES':
            return {
                ...state,
                invoices: action.value
            };
        default:
            return state;
    }
}
