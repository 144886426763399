import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { RedocStandalone } from "redoc";
import { LOGO_URL, LOGIN_ADS, APP_STYLE_ID } from "../../config/index";

function ApiSwagger() {
  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img alt="Logo" src={LOGO_URL} style={{ maxHeight: 40 }} />
          </Toolbar>
        </Container>
      </AppBar>
      <RedocStandalone
        specUrl={`${process.env.REACT_APP_OPEN_BASE_URL}/api-reference/swagger`}
        options={{
          nativeScrollbars: true,
          theme: { colors: { primary: { main: "#dd5522" } } },
        }}
      />
    </>
  );
}
export default ApiSwagger;
