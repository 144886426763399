import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./liveShareActionTypes";

export const setLiveShares = value => ({
    type: TYPES.LIVE_SHARES,
    value
});

export const setLiveSharesCount = value => ({
    type: TYPES.LIVE_SHARES_COUNT,
    value
});

export const liveShareBusy = value => ({
    type: TYPES.LIVE_SHARES_BUSY,
    value
});

export function getLiveSharesCount(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        try {
            const response = await axios({
                url: `${BASE_URL}/manager/${accountId}/liveShare/count`,
                method: "get",
                params
            });
            const _response = _get(response, "data.count", 0);

            dispatch(setLiveSharesCount(_response));
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function getLiveShares(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        try {
            const response = await axios({
                url: `${BASE_URL}/manager/${accountId}/liveShare`,
                method: "get",
                params
            });
            const _response = _get(response, "data", {});

            dispatch(setLiveShares(_response));

            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function createLiveShares(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        try {
            const response = await axios({
                url: `${BASE_URL}/manager/${accountId}/liveShare`,
                method: "post",
                data: params
            });

            const _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function updateLiveShares(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;
        try {
            const response = await axios({
                url: `${BASE_URL}/manager/${accountId}/liveShare/${
                    params._id
                }`,
                method: "put",
                data: params
            });
            const _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function getLiveSharesById(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;
        try {
            const response = await axios({
                url: `${BASE_URL}/manager/${accountId}/liveShare/${
                    params._id
                }`,
                method: "put",
                data: params
            });
            const _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function deleteLiveShares(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;
        try {
            const response = await axios({
                url: `${BASE_URL}/manager/${accountId}/liveShare/${
                    params._id
                }`,
                method: "put",
                data: params
            });
            const _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}
