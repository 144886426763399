import _get from "lodash/get";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
// import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import LanguageDialog from "./LanguageDialog";

const useStyles = makeStyles({});

export default function DetailCard(props) {
  const LABELS = props.LABELS;
  const classes = useStyles();
  const [isModal, setIsModal] = useState(false);
  const profile =
    useSelector((state) => _get(state, "authData.profile.user", {})) || {};

  const account =
    useSelector((state) => _get(state, "authData.profile.account", {})) || {};
  const roles =
    useSelector((state) => _get(state, "authData.profile.roles", [])) || [];

  const _language = profile.language;
  let language = "";
  switch (_language) {
    case "en":
      language = "English";
      break;
    case "es":
      language = "Español";
      break;
    case "fr":
      language = "Français";
      break;
    default:
  }

  function onClose() {
    setIsModal(false);
  }

  return (
    <>
      <LanguageDialog open={isModal} onClose={onClose} />
      <Card variant="outlined" style={{ minHeight: 425 }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h5"
            style={{ marginTop: 15 }}
          >
            {LABELS.profileInfo}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            component="p"
            style={{ marginTop: 25 }}
          >
            <span className={classes.title}>{LABELS.name}: </span>
            {`${profile.firstName} ${profile.lastName}`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <span className={classes.title}>{LABELS.email}: </span>
            {`${profile.email}`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <span className={classes.title}>{LABELS.status}: </span>
            {`${profile.status}`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <span className={classes.title}>{LABELS.language}: </span>
            {`${language}`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <span className={classes.title}>{LABELS.uuid}: </span>
            {`${profile.uuid}`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <span className={classes.title}>{LABELS.roles}: </span>
            {`${roles.toString()}`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <span className={classes.title}>{LABELS.account}: </span>
            {`${account.name}`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <span className={classes.title}>{LABELS.accountId}: </span>
            {`${profile.accountId}`}
          </Typography>
          <CardActions>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setIsModal(true)}
            >
              {LABELS.edit}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
}
