export const LABELS = {
  accounts: "Cuentas",
  drivers: "Conductores",
  equipments: "Equipos",

  allCompany: "Toda la empresa",
  company: "Compañía",
  dutyStatus: "Estado de servicio",
  connection: "Conexión",
  offDuty: "Fuera de servicio",
  onDuty: "De servicio",
  connected: "Conectado",
  disconnected: "Desconectado",
  driving: "Conduciendo",
  sleeping: "Durmiendo",
  driverInfo: "Información del conductor",
  name: "Nombre",
  status: "Estado",
  violations: "Violaciones",
  date: "Fecha",
  eLDConnection: "Conexión",
  total: "Total",
  formsSignatures: "Formularios y firmas",
  overdues: "Sobre cuotas",
  missingstripe: "Raya faltante",
  plan: "Plan",
  time_period:"Periodo de tiempo",
  today:"Hoy",
  yesterday:"Ayer",
  thisWeek:"Esta semana",
  thisMonth:"Este mes",
  sleeper:"Durmiente",
  remark:"Observación",
  ondutyNd:"OnDutyND",
  break:"Romper",
  OnDutyND:"En servicioND",
  callback:"Llamar de vuelta",


  count: "Contar",
  search: "Buscar",
  add: "Agregar",
  contact: "Contacto",
  payment: "Pago",
  created: "Creado",
  actions: "Comportamiento",
  edit: "Editar",
  edited:"Editado",
  details: "Detalles",
  leads: "Dirige",
  added:"Agregada",
  saving:"Ahorro...",
  settings:"Ajustes",

  account: "Cuenta",
  driver: "Conductor",
  clear: "Claro",
  new: "Nuevo",
  info: "Información",
  caller: "Llamador",
  detail: "Detalle",
  other: "Otro",
  clean:"LIMPIA",
  delete:"Borrar",
  support:"Apoyo",
  assigned:"Asignado",

  provider: "Proveedor",
  carrier: "Transportador",
  shipdate: "Fecha de envío",
  tracking: "Seguimiento",
  addedby: "Añadido por",
  logIn: "Acceso",
  deactivate: "Desactivar",
  activate: "Activar",
  cancel:"Cancelar",
  confirm:"Confirmar",
  pleaseConfirm:"Por favor confirme si desea ",
  enabled: "Activado",
  disabled: "Desactivado",
  annual: "Anual",
  monthly: "Mensual",
  role:"Role",
  terminal:"Terminal",
  dispatch:"Despacho",
  address:"DIRECCIÓN",
  link:"Enlace",
  expiry:"Expiración",
  start_expiry_date:"Fecha de inicio y caducidad",
  end_expiry_date:"Fecha de vencimiento final",


  commingSoon: "Muy pronto",

  startDate: "Fecha de inicio",
  endDate: "Fecha final",
  logs: "Registros",
  viewLogs: "Ver los registros",
  rowsperpage: "Filas por página",

  email: "Correo electrónico",
  error: "Error",
  time: "Tiempo",
  phone:"Teléfono",
  reason:"Razón",
  App:"Aplicación",
  Device:"Dispositivo",

  searchbyemailorname: "Buscar por correo electrónico o nombre",
  supportUsers: "Usuarios de soporte",
  norecordsarefound: "No se encuentran registros",
  selectVehicle:"Seleccionar vehículo",

  syncRealTime: "SINCRONIZACIÓN EN TIEMPO REAL",
  syncingRealTime:"SINCRONIZACIÓN EN TIEMPO REAL",

  reseller: "Revendedores",
  billing: "Facturación",
  notes: "Notas",


  sync: "Sincronizar",
  syncing:"Sincronizando...",
  activeManagers: "Administradores activos",
  inactiveManagers: "Administradores inactivos",
  activeDrivers: "Conductores activos",
  inactiveDrivers: "Controladores inactivos",
  allowedVehicles: "Vehículos permitidos",
  activeVehicles: "Vehículos activos",
  inactiveVehicles: "Vehículos inactivos",
  planInfo: "Información del plan",
  apiAccess: "Acceso a las API",
  dues: "Deudas",
  noduesfound: "No se encontraron cuotas",
  iftaAccess: "Acceso Ifta",
  addUser: "Gerentes",



  standard:"Estándar",
  premium:"De primera calidad",
  enterprise:"Empresa",



  editplan:"EDITAR PLAN",
  allowedVehicle:"Vehículo permitido",
  currentVehicle:"Vehículo actual",
  plan:"Plan",
  Name:"Nombre",
  ifta:"IFTA",
  api:"API",
  truck:"Camión",
  note:'Nota',
  addDue:"AÑADIR VENCIMIENTO",
  save:"AHORRAR",
  close:"CERCA",
  addUsers:"AGREGAR USUARIO",
  editUser:'EDITAR USUARIO',
  edit_User:"editar usuario",
  managers:"Gerentes",

  invoices:"Facturas",

  callqueues:"Colas de llamadas",
  callInQueue:"llamadas en cola.",
  loading:"Cargando...",
  refresh:"Actualizar",
  view:"Vista",
  signOut:"Desconectar",
  allEquipments:"Todos los equipos",
  allStates:"Todos los Estados",
  AllAccounts:"Todas las cuentas",
  notification:"Notificaciones",
  CustomerSupportPackage:"Paquete de atención al cliente",
  queues:"Colas",
  all:"Todo",

  chat:"Charlar",
  unread:"No leído",
  nounreadchats:"No hay chats no leídos",
  view_all_chats:"Ver todos los chats",

  shippingSaveSuccessfully:"El envío se guarda correctamente.",
  planIs_updated_successfully:"El plan se actualizó exitosamente",
  errorMasage_tryafterSometime:"Algo salió mal, inténtalo después de un tiempo.",
};
