export const LABELS = {
   addCarrier: "कैरियर जोड़ें",
   editCarrier: "कैरियर संपादित करें",
   edit_Terminal: "टर्मिनल संपादित करें",
   add_Terminal: "टर्मिनल जोड़ें",
   addAccount: "खाता जोड़ें",
 
   editEquipment: "उपकरण संपादित करें",
   addEquipment: "उपकरण जोड़ें",
 
   editTrailer: "ट्रेलर संपादित करें",
   addTrailer: "ट्रेलर जोड़ें",
 
   editReasons: "कारण संपादित करें",
 
   addUser: "उपयोगकर्ता जोड़ें",
   editUser: "उपयोगकर्ता संपादित करें",
   driver_details: "ड्राइवर विवरण",
   update: "सुधार करें",
   addApi_key: "API कुंजी जोड़ें",
 
   logingUsers: "लॉगिन उपयोगकर्ता",
   supportCentre: "समर्थन केंद्र",
 
 
   name: "नाम",
   dot: "डॉट",
   timezone: "समय क्षेत्र",
   country: "देश",
   address_line1: "पता पंक्ति 1",
   suit_bldg: "सूट., बिल्डिंग",
   city: "शहर",
   state: "राज्य",
   zip: "ज़िप",
   contactName: "संपर्क नाम",
   email: "ईमेल",
   phone: "फोन",
   role: "भूमिका",
   dispatchName: "डिस्पैच नाम",
   note: "नोट:",
   info: " डिस्पैच जानकारी केवल तब आवश्यक है, अगर आप ELD डिवाइस को पुश सूचनाओं के रूप में डिस्पैच करना चाहते हैं।",
   save: "सहेजें",
   saving: "सहेजा जा रहा है...",
   cancel: "रद्द करें",
   close: "बंद करें",
   roles: "भूमिकाएँ",
   access: "पहुँच",
   cargo: "कार्गो",
   hos: "HOS",
   dutyLogs: "कर्तव्य लॉग",
   contact: "संपर्क",
   required: "आवश्यक",
 
   billing: "बिलिंग",
 
   accountName: "खाता नाम",
   contactEmail: "संपर्क ईमेल",
   contactPhone: "संपर्क फोन",
   contactRole: "संपर्क भूमिका",
   stripe: "स्ट्राइप",
   lastActive: "अंतिम सक्रिय",
 
   driverId: "ड्राइवर आईडी",
   firstName: "पहला नाम",
   lastName: "उपनाम",
   mobile: "मोबाइल",
   password: "पासवर्ड",
   licCountry: "लाइसेंस देश",
   licState: "लाइसेंस राज्य",
   licNumber: "लाइसेंस नंबर",
   cycleUsa: "साइकिल यूएसए",
   cycleCA: "साइकिल CA",
   startTime: "प्रारंभ समय",
   receiverMail: "रिसीवर ईमेल",
   personal: "निजी",
   yardMoves: "यार्ड मूव्स",
   allowEdit: "संपादन अनुमति",
   tracking: "ट्रैकिंग",
   exempt: "मुक्त",
   exemptDriver: "मुक्त ड्राइवर",
   exemptAnnotation: "मुक्त टिप्पणी",
   assignedVehicle: "निर्धारित वाहन",
   currentVehicle: "वर्तमान वाहन",
   currentTrailer: "वर्तमान ट्रेलर",
   enterMobileNumber: "मोबाइल नंबर दर्ज करें",
   appHome_View: "ऐप होम दृश्य",
   firebase: "फ़ायरबेस",
   apiKey: "API कुंजी",
   scopes: "स्कोप",
   message: "संदेश",
   sendNow: "अब भेजें",
   loading: "लोड हो रहा है...",
   sendVia_whatsapp: "व्हाट्सएप के माध्यम से भेजें",
   send_sms: "एसएमएस भेजें",
   speed: "गति",
 
   prefix: "प्रिफ़िक्स",
   state_mileage_report: "राज्य माइलेज रिपोर्ट",
   pdf: "पीडीएफ",
   details: "विवरण",
   carrierName: "कैरियर नाम",
   vehicleName: "वाहन नाम",
   miles: "मील",
   mileage_miles: "माइलेज (मील)",
   quarter: "तिमाही",
   report: "रिपोर्ट",
   select_vehicle: "वाहन चुनें",
   odometer: "ओडोमीटर",
   superAdmin: "सुपर व्यवस्थापक",
   supportAdmin: "समर्थन व्यवस्थापक",
 
   No_vehicle_is_assignedTO_deiver: "किसी ड्राइवर को कोई वाहन नहीं निर्धारित किया गया है",
   please_set_current_vehicle: "कृपया वर्तमान वाहन सेट करें",
 
   endTime: "समाप्ति समय",
   duration: "अवधि",
   recordStatus: "रिकॉर्ड स्थिति",
   recordOrigin: "रिकॉर्ड मूल",
   location: "स्थान",
   assign: "निर्धारित",
   driver: "ड्राइवर",
 
   diagostic$Malfunction: "डायगोस्टिक / खराबी",
   eventCode: "घटना कोड",
   eventTime: "घटना समय",
 
   status: "स्थिति",
   terminal: "टर्मिनल",
   address: "पता",
   title: "शीर्षक",
   body: "शरीर",
   time: "समय",
   user: "उपयोगकर्ता",
   allUser: "सभी उपयोगकर्ता",
   sendNotification: "सूचना भेजें",
   send: "भेजें",
   syncAll: "सिंक सब",
   inspector: "निरीक्षक",
   condition: "शर्त",
   start_end: "प्रारंभ / समाप्त",
   view: "देखें",
   date: "तारीख",
   _note: "नोट",
   _details: "विवरण",
   _view: "देखें",
   settings: "सेटिंग्स",
   select: "चुनें",
   coordinates: "निर्देशांक",
   event: "घटना",
   table: "तालिका",
   map: "नक्शा",
   trips: "यात्राएँ",
   delete: "हटाएं",
   edit: "संपादित करें",
 
   driver_vehicle_inspection_report: "ड्राइवर के वाहन की जांच रिपोर्ट (DVIR)",
   AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS:
     "डीओटी फेडरल मोटर कैरियर सुरक्षा विनियमन द्वारा आवश्यक",
   dvirNumber: "DVIR नंबर:-",
   start_inspection: "जांच शुरू करें",
   endInspection: "जांच समाप्त करें ",
   inspection_type: "जांच प्रकार",
   inspection_Area: "जांच क्षेत्र",
   I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements:
     "मैं घोषणा करता हूँ कि इस संपत्ति की जांच संबंधी आवश्यकताओं के अनुसार की गई है।",
   driver_signature: "ड्राइवर हस्ताक्षर",
 
   download: "डाउनलोड",
   downloading: "डाउनलोड हो रहा है...",
   not_able_to_download: "डाउनलोड नहीं हो सका",
   missingFile: "फ़ाइल गायब",
 
   history: "इतिहास",
   send_to_fmcsa: "FMCSA को भेजें",
   email_to_fmcsa: "FMCSA को ईमेल करें",
   SelectDriver: "ड्राइवर का चयन करें",
   fromDate: "तारीख से",
   toDate: "तारीख तक",
   comments: "टिप्पणियाँ",
   fmcsa_data_transfer: "FMCSA डेटा स्थानांतरण (डेटा स्थानांतरण के लिए चरण)",
   selectDateRange: "तारीख सीमा चुनें",
   enterComment: "टिप्पणी दर्ज करें",
   sendDataTo_fmcsa: "FMCSA को डेटा स्थानांतरित करने के लिए \"FMCSA को भेजें\" पर क्लिक करें",
 
   showall: "सभी दिखाएं",
   vehicle: "वाहन",
   trailer: "ट्रेलर",
   search: "खोज...",
   add: "जोड़ें",
   liveshare: "लाइव शेयर",
   create_ifta: "आईएफटीए बनाएं",
   no_data_found: "कोई डेटा नहीं मिला!",
   no_recordFound: "कोई रिकॉर्ड नहीं मिला",
 
   regulationMode: "नियमन मोड",
   plate: "प्लेट",
   eld: "ELD",
   created_edited: "बनाया / संपादित किया गया",
 
   rowsperpage: "पंक्तियाँ प्रति पृष्ठ",
 
   //   <------ADD equipment Form------->
   vin: "विन",
   make: "मेक",
   model: "मॉडल",
   year: "वर्ष",
   fuel: "ईंधन",
   type: "प्रकार",
   licPlate: "लाइसेंस प्लेट",
   dvirForm: "DVIR फॉर्म",
   gps_source: "GPS स्रोत",
   devicetype: "डिवाइस प्रकार",
   devicename: "डिवाइस नाम",
   macAddrerss: "MAC पता",
   eld_serial: "ELD सीरियल",
   eld_firmware: "ELD फर्मवेयर",
   eld_BleVersion: "ELD BleVersion",
   connectionType: "कनेक्शन प्रकार",
   dashcam: "डैशकैम",
   odometer_offset: "ओडोमीटर ऑफसेट",
   active: "सक्रिय",
   codes: "कोड",
   ifta: "IFTA",
   vbusConnection: "ड्राइविंग के लिए वीबस कनेक्शन आवश्यक है",
   carrier: "कैरियर",
   dvir: "Dvir",
   licExpiry: "लाइसेंस समाप्ति",
   adminScope: "व्यवस्थापक दायरा",
   assetType:"संपदा प्रकार",
   dueby:"तक देय",
   notes:"टिप्पणियाँ",
   

 
   dashcameraId: "डैशकैम आईडी",
   reseller: "रीसेलर",
   updateFrequency: "अद्यतन अवधि",
   bucket: "बकेट",
   theme: "थीम",
   kioskpin: "कियोस्क पिन",
   hereAppId: "यहाँ ऐप आईडी",
   hereApiKey: "यहाँ एपीआई कुंजी",
   updateLocation: "स्थान अपडेट करें",
   debug: "डीबग",
   allLogs: "सभी लॉग",
   editLogs: "लॉग संपादित करें",
   documents: "दस्तावेज़",
   notification: "अधिसूचना",
   addDvir: "DVIR जोड़ें",
   appSettings: "ऐप सेटिंग्स",
   appAll_Log: "ऐप सभी लॉग",
   appAllLogsFmcsa: "ऐप सभी लॉग FMCSA",
   estimatedWaitTime: "अनुमानित प्रतीक्षा समय",
   minutes: "मिनट(टियां)",
   maintenance:"रखरखाव",
   updateDvir:"Dvir को सुधारें",
   vehicletype:"वाहन का प्रकार",
   form:"प्रकार",
   _vehicle:"वाहन",
   StartDate:"आरंभ करने की तिथि",
   EndDate:"अंतिम तिथि",
   _trailer:"ट्रेलर",
   equipmentconditions:"उपकरण की स्थिति",
   date_is_required:"दिनांक आवश्यक है",
 
   noteinfo: "नोट: एक नए वाहन जोड़ने के बाद, आपके फाइल पर स्वचालित रूप से कार्ड चार्ज किया जाएगा।",
   ifta_info_note: "IFTA केवल सक्रिय ट्रक के लिए उपलब्ध है।",
   ifta_info: "यदि आप ट्रक को अक्षम या हटाना चाहते हैं तो कृपया अक्षम कार्रवाई से पहले राज्य मील को डाउनलोड करें।",
   saved_Successfully: "सफलतापूर्वक सहेजा गया",
   updateSuccessfully: "सफलतापूर्वक अपडेट किया गया",
   sendSuccessfully: "Sms सफलतापूर्वक भेजा गया",
   successMessage_carrier: "कैरियर सफलतापूर्वक सहेजा गया",
   successMessage: "ट्रेलर सफलतापूर्वक सहेजा गया",
   successMessage_Equipment: "उपकरण सफलतापूर्वक सहेजा गया",
   please_try_aftersometime: "कृपया कुछ समय बाद प्रयास करें।",
   successMessage_user: "उपयोगकर्ता सफलतापूर्वक जोड़ा गया",
   successMessage_Api_Key: "एपीआई कुंजी सफलतापूर्वक जोड़ी गई",
   response: "कुछ गलत हुआ, कृपया समय के बाद प्रयास करें।",
   errorMessage: "कुछ गलत हुआ, कृपया समय के बाद प्रयास करें।",
   editReasonUpdated: "संशोधन कारण सफलतापूर्वक अपडेट किए गए हैं।",
   logs_are_successfully_submitted: "लॉग सफलतापूर्वक प्रस्तुत किए गए हैं।",
   user_is_updated_successfully: "उपयोगकर्ता सफलतापूर्वक अपडेट किया गया है।",
   driver_is_cleaned_successfully: "ड्राइवर को सफलतापूर्वक साफ किया गया है।",
   account_is_updated_successfully: "खाता सफलतापूर्वक अपडेट किया गया है",
   Note_only_requiredforSetNewPassword: "नोट: केवल आवश्यक है, यदि आप नया पासवर्ड सेट करना चाहते हैं",
   driver_is_required: "ड्राइवर आवश्यक है।",
   from_date_is_required: "तिथि से आवश्यक है।",
   to_date_is_required: "तिथि तक आवश्यक है। ",
   comment_is_required: "टिप्पणी आवश्यक है।",
   comment_is_invalid: "टिप्पणी अमान्य है।",
 
   are_you_sure_WantTo_delete: "क्या आपको सुनिश्चित है, कि आप DVIR को हटाना चाहते हैं",
   errorMassage: "कुछ गलत हुआ, कृपया कुछ समय बाद प्रयास करें।",
   deletedsucessfully: "सफलतापूर्वक हटाया गया।",
   deleting: "हटा रहा है...",
   deleteViolation:"उल्लंघन हटाएं",
   are_you_sure_wantTo_delete_all_violation:"क्या आप निश्चित हैं, आप सभी उल्लंघन हटाना चाहते हैं?",
 };
 