import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./terminalActionTypes";

export const setTerminals = value => ({
    type: TYPES.TERMINALS,
    value
});

export const selectedTerminal = value => ({
    type: TYPES.SELCTED_TERMINAL,
    value
});

export const appTerminalBusy = value => ({
    type: TYPES.APP_TERMINAL_BUSY,
    value
});

export function getTerminals(params = {}, accId) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        let accountId = "";

        if (accId) {
            accountId = accId
        }
        else {
            accountId = _get(getState(), "authData.profile.user.accountId");
        }

        dispatch(appTerminalBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/terminals`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setTerminals(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appTerminalBusy(false));
                return response;
            });
    };
}

export function createTerminal(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        dispatch(appTerminalBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/terminals`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appTerminalBusy(false));
                return response;
            });
    };
}

export function updateTerminal(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        dispatch(appTerminalBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/terminals/${params.id}`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appTerminalBusy(false));
                return response;
            });
    };
}
