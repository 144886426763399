// import VIN from "vehicle-identification-number";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;
const validate = values => {
    const errors = {};
    let requiredFields = [
        "name",
    ];

    if(values.name && values.name<1){
        errors.name="Name must be at least 1 characters"
    }

    if (values.assetType === "TRAILER") {
        requiredFields = ["name"];
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${LABELS.required}`;
        } else if (
            field === "name" &&
            values[field] &&
            values[field].length > 10
        ) {
            errors.name = "Too long";
        }
    });
    // if (values.vin && values.assetType !== "TRAILER") {
    //     try {
    //         VIN.parse(values.vin);
    //     } catch (e) {
    //         errors.vin = `VIN is not valid.`;
    //     }
    // }
    return errors;
};

export default validate;
