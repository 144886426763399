export const LABELS = {
  map: "Map",
  table: "Table",
  search: "Search",
  name: "Name",
  status: "Status",
  location: "Location",
  vehicle: "Vehicle",
  break: "Break",
  drive: "Drive",
  shift: "Shift",
  cycle: "Cycle",
  violations: "Violations",
  eld: "ELD",
  update: "Update",
  app: "App",
};
