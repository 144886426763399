import _get from "lodash/get";
import React, { useState, useEffect } from "react"
import { Formik, Form } from "formik";
// import { validate, defaultValues } from "./validate";
import Grid from '@mui/material/Grid';
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles';
import { getAllUsers } from "../../actions/adminDashActions";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.supportForm;
const LABEL=_LABELS.adminPage;
const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: 25,
    textAlign: "center",
  },
});

export function SearchAppBar(props) {
  const [users, setUsers] = useState([]);
  // const [accounts, setAccounts] = useState([]);
  // const classes = props.classes;
  const constants = props.constants;
  const { errorMessage, successMessage, loading } = {};

  let initialValues = {};

  function validate() {}

  function handleSubmit(values) {
    props.onSearch(values);
  }

  async function _getDrivers(accountId) {
    try {
      const _users = await props.getAllUsers({
        searchSupport: "ON",
        skip: 0,
        limit: 500,
      });
      setUsers(_users);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    _getDrivers();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ padding: "20px 10px" }}>
            <Grid container spacing={3}>
              <SelectBox
                md={3}
                selValue={"id"}
                selLabel={"firstName"}
                selLabelTwo={"lastName"}
                selLabelThree={"status"}
                name="assignedId"
                label={LABELS.assignedto}
                items={users}
              />
              <SelectBox
                md={3}
                name="status"
                label={LABELS.status}
                items={constants.TODO_STATUS}
              />
              <SelectBox
                md={3}
                name="priority"
                label={LABELS.priority}
                items={constants.TODO_PRORIRY}
              />
              <Grid item  sm xs={12} md={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {/* {loading ? "Saving..." : "Search"} */}
                  {`${loading ? LABELS.saving: LABELS.search}`}
                </Button>{" "}
                <Button
                  variant="contained"
                  onClick={() => {
                    setFieldValue("assignedId", "");
                    setFieldValue("status", "");
                    setFieldValue("priority", "");
                    props.onSearch({});
                  }}
                >
                  {LABEL.clear}
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.handleAddClick}
                >
                  {LABEL.new}
                </Button>{" "}
              </Grid>
            </Grid>

            {successMessage && <Success message={successMessage} />}
            {errorMessage && <Error message={errorMessage} />}
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    users: _get(state, "driverData.users", []),
    equipments: _get(state, "equipmentData.equipments", []),
    accounts: _get(state, "accountData.accounts", []),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllUsers,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchAppBar)
);
