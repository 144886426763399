import VIN from "vehicle-identification-number";

const validate = values => {
    const errors = {};
    const requiredFields = ["name", "dot","line1", "city", "state", "zip", "country"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${field} is required`;
        }
    });
    if (values.name && values.name.trim().length < 4) {
        errors.name = "Name must be at least 4 characters long";
    } else if (values.name && values.name.trim().length > 120) {
        errors.name = "Name cannot exceed 120 characters";
    }
    if(values.dot && values.dot.trim().length<1){
        errors.dot="DoT number must be at least 1 charecter long"
    }else if(values.dot && values.dot.trim().length>9){
        errors.dot = "Name cannot exceed 9 characters";
    }
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    } else if (values.vin) {
        try {
            VIN.parse(values.vin);
        } catch (e) {
            errors.vin = `VIN is not valid.`;
        }
    }
    return errors;
};

export default validate;
