import React from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import StatusChart from "./StatusChart";
import DutyStatus from "../../../components/DutyStatus";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

export class ConfirmActionModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {}

  render() {
    const { item, driver, classes, isViolation } = this.props;

    const isSmallScreen = window.innerWidth < 500;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>
            {driver.firstName} {driver.lastName}
          </Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ marginBottom: "15px", textAlign: "center" }}
            >
              {this.props.remaining.exception === "3" ? (
                <span className={classes.agriExm}>AGRICULTURE </span>
              ) : (
                <>
                  {" "}
                  {item.eventCode ? (
                    <DutyStatus
                      status={item}
                      eventType={item.eventType}
                      eventCode={item.eventCode}
                      annotation={item.annotation}
                      eventSubType={item.eventSubType}
                      isError={isViolation}
                    />
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.AlignCenter}>
              <StatusChart {...this.props} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} className={classes.AlignBtn}>
              <br />
              <Button
                onClick={this.props.handleClose}
                color="primary"
                autoFocus
              >
                CLOSE
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmActionModal);
