import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import NotFoundTableRow from "../NotFoundTableRow";
import Chip from "@mui/material/Chip";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.supportForm;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1.5),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
  button: {
    marginTop: 2,
    minWidth: 100,
  },
  buttonLink: {
    textDecoration: "none",
    color: "white",
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, item = {} } = this.props;
    const driver = item.driver || {};
    // const cbInfo = item.cbInfo || {};
    // const assignedTo = item.assignedTo || {};

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          {driver.name && (
            <div className={classes.capitalize}>
              {driver.name}
              <div>{`Ph: ${driver.phone}`}</div>
            </div>
          )}
        </TableCell>
        <TableCell>
          <Chip size="small" variant="outlined" label={item.status} />
        </TableCell>
        <TableCell>
          <div>{item.reason}</div>
          <div>{`Created: ${this.props.formatDateTime(item.created)}`}</div>
        </TableCell>
      </TableRow>
    );
  }
}

export class AccountTable extends React.Component {
  state = { open: false, selectedAccount: null, order: "asc" };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleDetails = async (selectedAccount) => {
    this.setState({ loading: true });
    this.setState({ open: true });
    this.props.selectedAccount(selectedAccount);
    const accountId = selectedAccount._id;
    await this.props.getAccountDetails({ accountId });
    this.setState({ loading: false });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.selectedAccount(null);
  };

  render() {
    const { eld, classes, items = [], count = 1000 } = this.props;
    const { page, limit } = this.props;

    // const { open, loading } = this.state;
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table
            size="small"
            aria-label="Support Call Table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell>{"Driver"}</TableCell>
                <TableCell>{"Status"}</TableCell>
                <TableCell>{LABELS.detail}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(items) &&
                items.map((item, i) => (
                  <ReduxTableRow
                    item={item}
                    key={i}
                    eld={eld}
                    classes={classes}
                    handleDetails={this.handleDetails}
                    handleEdit={this.props.handleEdit}
                    formatDate={this.props.formatDate}
                    formatDateTime={this.props.formatDateTime}
                    handleAccountDetails={this.props.handleAccountDetails}
                  />
                ))}
            </TableBody>
          </Table>
          <NotFoundTableRow items={items} />
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            labelRowsPerPage={LABELS.rowsperpage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

AccountTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountTable);
