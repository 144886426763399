export const LABELS = {
  accounts: "खाते",
  drivers: "ड्राइवर",
  equipments: "उपकरण",

  allCompany: "सभी कंपनियां",
  company: "कंपनी",
  dutyStatus: "कर्तव्य स्थिति",
  connection: "कनेक्शन",
  connected: "कनेक्टेड",
  disconnected: "डिस्कनेक्टेड",
  offDuty: "ऑफ ड्यूटी",
  onDuty: "ऑन ड्यूटी",
  driving: "ड्राइविंग",
  sleeping: "सोना",
  driverInfo: "ड्राइवर जानकारी",
  name: "नाम",
  status: "स्थिति",
  violations: "उल्लंघन",
  date: "तारीख",
  eLDConnection: "ईएलडी कनेक्शन",
  total: "कुल",
  formsSignatures: "फॉर्म और हस्ताक्षर",
  time_period: "समय अवधि",
  today: "आज",
  yesterday: "कल",
  thisWeek: "इस सप्ताह",
  thisMonth: "इस महीने",
  sleeper: "स्लीपर",
  remark: "टिप्पणी",
  break: "विराम",
  OnDutyND: "OnDutyND",
  callback: "कॉलबैक",

  count: "गिनती",
  search: "खोज",
  add: "जोड़ें",
  contact: "संपर्क",
  payment: "भुगतान",
  created: "बनाया गया",
  actions: "क्रियाएँ",
  edit: "संपादित करें",
  edited: "संपादित किया गया",
  details: "विवरण",
  leads: "लीड्स",
  added: "जोड़ा गया",
  saving: "सहेजना...",
  settings: "सेटिंग्स",

  account: "खाता",
  driver: "ड्राइवर",
  clear: "साफ़ करें",
  new: "नया",
  info: "जानकारी",
  caller: "कॉलर",
  detail: "विवरण",
  other: "अन्य",
  clean: "साफ़",
  delete: "हटाएँ",
  support: "समर्थन",
  assigned: "सौंपा गया",

  provider: "प्रदाता",
  carrier: "वाहक",
  shipdate: "शिप तारीख",
  tracking: "ट्रैकिंग",
  addedby: "द्वारा जोड़ा गया",
  role: "भूमिका",
  terminal: "टर्मिनल",
  dispatch: "प्रेषण",
  address: "पता",
  link: "लिंक",
  expiry: "समाप्ति",
  start_expiry_date: "प्रारंभ समाप्ति तिथि",
  end_expiry_date: "अंत समाप्ति तिथि",

  commingSoon: "जल्द आ रहा है",

  startDate: "प्रारंभ तिथि",
  endDate: "अंत तिथि",
  logs: "लॉग्स",
  viewLogs: "लॉग्स देखें",
  rowsperpage: "प्रति पृष्ठ पंक्तियाँ",
  logIn: "लॉग इन",

  email: "ईमेल",
  error: "त्रुटि",
  time: "समय",
  phone: "फ़ोन",
  reason: "कारण",
  App: "ऐप",
  Device: "डिवाइस",

  searchbyemailorname: "ईमेल या नाम से खोजें",
  supportUsers: "समर्थन उपयोगकर्ता",
  norecordsarefound: "कोई रिकॉर्ड नहीं मिला",
  selectVehicle: "वाहन चुनें",

  syncRealTime: "रीयलटाइम सिंक",
  syncingRealTime: "रीयलटाइम सिंक कर रहा है",

  reseller: "पुनर्विक्रेता",
  billing: "बिलिंग",
  overdues: "अतिदेय",
  missingstripe: "लापता पट्टी",
  notes: "नोट्स",

  annual: "वार्षिक",
  monthly: "मासिक",
  enabled: "सक्षम",
  disabled: "अक्षम",
  sync: "सिंक",
  syncing: "सिंक कर रहा है...",
  activate: "सक्रिय करें",
  cancel: "रद्द करें",
  confirm: "पुष्टि करें",
  pleaseConfirm: "कृपया पुष्टि करें, यदि आप चाहते हैं",

  deactivate: "निष्क्रिय करें",
  activeManagers: "सक्रिय प्रबंधक",
  inactiveManagers: "निष्क्रिय प्रबंधक",
  activeDrivers: "सक्रिय ड्राइवर",
  inactiveDrivers: "निष्क्रिय ड्राइवर",
  allowedVehicles: "अनुमत वाहन",
  activeVehicles: "सक्रिय वाहन",
  inactiveVehicles: "निष्क्रिय वाहन",
  plan: "योजना",
  planInfo: "योजना जानकारी",
  apiAccess: "एपीआई एक्सेस:",
  dues: "बकाया",
  noduesfound: "कोई बकाया नहीं मिला",
  iftaAccess: "इफ्ता एक्सेस",
  addUser: "प्रबंधक",

  standard: "मानक",
  premium: "प्रीमियम",
  enterprise: "उद्यम",

  editplan: "योजना संपादित करें",
  allowedVehicle: "अनुमत वाहन",
  currentVehicle: "वर्तमान वाहन",
  Name: "नाम",
  ifta: "इफ्ता",
  api: "एपीआई",
  truck: "ट्रक",
  note: "नोट",
  addDue: "बकाया जोड़ें",
  save: "सहेजें",
  close: "बंद करें",
  addUsers: "उपयोगकर्ता जोड़ें",
  editUser: "उपयोगकर्ता संपादित करें",
  edit_User: "उपयोगकर्ता संपादित करें",
  managers: "प्रबंधक",

  callqueues: "कॉल कतारें",
  callInQueue: "कतार में कॉल्स",
  loading: "लोड हो रहा है...",
  refresh: "ताज़ा करें",
  view: "देखें",
  signOut: "साइन आउट",
  AllAccounts: "सभी खाते",
  notification:"सूचनाएं",
  CustomerSupportPackage: "ग्राहक समर्थन पैकेज",
  queues: "कतारें",
  all: "सभी",

  invoices: "चालान",

  allEquipments: "सभी उपकरण",
  allStates: "सभी राज्य",


  chat:"बात करना",
  unread:"अपठित",
  nounreadchats:"कोई अपठित चैट नहीं",
  view_all_chats:"सभी चैट देखें",


  shippingSaveSuccessfully: "शिपिंग सफलतापूर्वक सहेजा गया",
  planIs_updated_successfully: "योजना सफलतापूर्वक अपडेट की गई",
  errorMasage_tryafterSometime: "कुछ गलत हो गया, कृपया कुछ समय बाद पुनः प्रयास करें।",
};
