
export const LABELS={
    addAccount:"ДОБАВИТЬ АККАУНТ",
  

    name:"Имя",
    status:"Положение дел",
    ein:"Ein",
    type:"Типe",
    email:"Электронная почта",
    phone:"Телефон",
    role:"Роль",
    billing_name:"Имя для выставления счета",
    billing_email:"Электронная почта для выставления счетов",
    billing_phone:"Платежный телефон",
    billing_role:"Роль выставления счетов",
    stripe:"Полоса",
    note:"Примечание",
    detail:"Деталь",
    contact:"Контакт",
    billing:"Биллинг",
    loging_users:"Вход пользователей",

    saving:"Сохранение...",
    save:"СОХРАНЯТЬ",
    close:"ЗАКРЫВАТЬ",


    cleanDriver:"Вы хотите удалить драйвер из списка?",
    cleanNow:"ЧИСТЫЙ ТЕПЕРЬ",
    cleaning:"Очистка...",



    // <-------for Support user----->

    addSupportUser:"Добавить пользователя службы поддержки",
  note: "Примечание: – Это только для добавления нового пользователя службы поддержки. Пользователь службы поддержки будет иметь доступ ко всей информации о вашей учетной записи и операторе связи.",
  firstName:"Имя",
  lastName:"Фамилия",
  mobile:"мобильный",
  password:"Пароль",
  reseller:"Реселлер",
  superAdmin:"Суперадминистратор",




 

}