import React from "react";
import PropTypes from "prop-types";
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import AccountDetail from "./AccountDetail";

const styles = theme => ({
    paper: {
        // maxWidth: 936,
        margin: 15,
        overflow: "hidden"
    },
    searchBar: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    searchInput: {
        fontSize: theme.typography.fontSize
    },
    block: {
        display: "block"
    },
    addUser: {
        marginRight: theme.spacing(1)
    },
    contentWrapper: {
        margin: "40px 16px",
        marginTop: "15px"
    }
});

function Detail(props) {
    const { classes } = props;
    return (
        <Paper className={classes.paper}>

            <div className={classes.contentWrapper}>
                <AccountDetail />
            </div>
        </Paper>
    );
}

Detail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Detail);
