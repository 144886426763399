const defaultState = {
    count: 0,
    violationBusy: false,
    violations: [],
    selectedViolation: {}
};

export default function violation(state = defaultState, action) {
    switch (action.type) {
        case "VIOLATIONS_COUNT":
            return {
                ...state,
                count: action.value
            };

        case "VIOLATIONS":
            return {
                ...state,
                violations: action.value
            };
        case "SELECTED_VIOLATION":
            return {
                ...state,
                selectedViolation: action.value
            };
        case "APP_VIOLATION_BUSY":
            return {
                ...state,
                violationBusy: action.value
            };
        default:
            return state;
    }
}
