export const LABELS={
    leads:"LEAD",

    channel:"Channel",
    status:"Status",
    dot:"DOT",
    name:"Name",
    adress_line:"Address line",
    address_city:"Address city",
    address_state:"Address state",
    address_zip:"Address zip",
    contact_name:"Contact name",
    contact_email:"Contact email",
    contact_phone:" Contact phone",
    callback:"Callback",
    description:"Description",

    assignedto:"Assigned to",
    assignee_email:"Assignee email",

    saving:"Saving",
    save:"SAVE",
    close:"CLOSE",



    lead_is_saved_successfully:"Lead is saved successfully",
    something_went_wrong_please_try_after_some_time:"Something went wrong, please try after some time.",




}
