import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from "@mui/icons-material/Edit";
// import PersonIcon from "@mui/icons-material/Person";
// import PhoneIcon from "@mui/icons-material/LocalPhone";
// import EmailIcon from "@mui/icons-material/Email";
import NotFoundTableRow from "../../NotFoundTableRow";
import {LABELS as _LABELS} from "../../../language";
import { Label } from "@mui/icons-material";

const LABELS=_LABELS.settingPage;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, isViewOnly, item = {} } = this.props;
    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          <div
            className={`${classes.dataLargeRow} ${classes.capitalize}`}
            onClick={(e) => this.props.handleEdit(item)}
          >
            <strong>{item.name}</strong>
          </div>
        </TableCell>
        <TableCell>{item.active ? "ACTIVE" : "IN ACTIVE"}</TableCell>
        <TableCell>{item.timezone}</TableCell>
        <TableCell>
          <div className={classes.dataLargeRow}>
            {item.line1 && (
              <div className={classes.capitalize}>{item.line1}</div>
            )}
            {item.line1 && (
              <div className={classes.capitalize}>
                {item.city}, {item.state}-{item.zip}
              </div>
            )}
          </div>
        </TableCell>
        {/*<TableCell>
                    <div className={classes.dataSmallRow}>
                        {item.created && (
                            <div>
                                Created: {this.props.formatDate(item.created)}
                            </div>
                        )}
                        {item.edited && (
                            <div>
                                Edited: {this.props.formatDate(item.edited)}
                            </div>
                        )}
                    </div>
                </TableCell>*/}
        {!isViewOnly && (
          <TableCell>
            <EditIcon
              className={classes.EditIcon}
              onClick={(e) => this.props.handleEdit(item)}
            />
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export class TerminalTable extends React.Component {
  render() {
    const { eld, classes, items = [], handleEdit, isViewOnly } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.name}</TableCell>
              <TableCell>{LABELS.status}</TableCell>
              <TableCell>{LABELS.terminal}</TableCell>
              <TableCell>{LABELS.address}</TableCell>
              {/*<TableCell>Created/Edited</TableCell>*/}
              {!isViewOnly && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  eld={eld}
                  classes={classes}
                  handleEdit={handleEdit}
                  formatDate={this.props.formatDate}
                  isViewOnly={isViewOnly}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
      </Paper>
    );
  }
}

TerminalTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TerminalTable);
