import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./userActionTypes";

export const setUserCount = value => ({
    type: TYPES.USER_COUNT,
    value
});

export const setUsers = value => ({
    type: TYPES.USERS,
    value
});

export const selectedUser = value => ({
    type: TYPES.SELCTED_USER,
    value
});

export const userBusy = value => ({
    type: TYPES.APP_USER_BUSY,
    value
});

export function getUserCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(userBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/users/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setUserCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(userBusy(false));
                return response;
            });
    };
}

export function getUsers(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(userBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/users`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setUsers(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(userBusy(false));
                return response;
            });
    };
}

export function createUser(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(userBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/users`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                dispatch(userBusy(false));
                return response;
            });
    };
}

export function updateUser(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(userBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/users/${params.userId}`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(userBusy(false));
                return response;
            });
    };
}

export function getScopes(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/scopes`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function updateUserScope(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        return axios({
            url: `${BASE_URL}/manager/${accountId}/users/${
                params.userId
            }/scope`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function sendSms(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/sms`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                return response;
            });
    };
}
