export const LABELS = {
  viewLogs: "View Logs",
  date: "Date",
  detailsMulti: "Multi",
  certified: "Certified",
  uncertified: "Uncertified",
  detail: "Detail",
  violation: "Violation",
  driving: "Driving",
  offDuty: "OffDuty",
  onDutyND: "OnDutyND",
  sleeper: "Sleeper",

  deleteLabel: "Delete Violations",
  deleteMsg: "Are you sure, you want to delete all violations",
};
