export const LABELS = {
  refresh: "Refresh",
  recap: "Recap",
  date: "Date",
  newTab: "New Tab",
  download: "Download",
  Audit: "Audit",
  AuditEvent:"Audit Events",
  openInNewTab:"Open In New Tab",
  ReassignMultipleStatus: "Reassign Multiple Status",
  AddMultipleStatus: "Add Multiple Status",
  EditMultipleStatus: "Edit Multiple Status",
  EditMultipleStatusAdmin: "Edit Multiple Status(Admin)",
  AddStatus: "Add Status",
  SendMessage: "Send Message",
  SendNotification: "Send Notification",
  AddShippingOrTrailer: "Add Shipping Or Trailer",
  AddStatusAdmin: "Add Status(Admin)",
  ReassignMultipleStatusAdmin: "Reassign Multiple Status(Admin)",

  driver: "Driver",
  vehicle: "Vehicle",
  trailer: "Trailer",
  email: "Email",
  startTime: "Start Time",
  carrier: "Carrier",
  terminal: "Home Terminal",
  shipping: "Shipping",
  app: "App",
  cycle: "Cycle",
  coDriver: "Co Driver",
  distance: "Distance",
  engineHr: "EngineHr",
  device: "Device",
  allLogs: "All Logs",
  state:"State",
  action:"Action",
  odometerMi:"Odometer(Mi)",




  editAdmin: "Edit Admin",
  DeactivateAdmin: "Deactivate Admin",
  DuplicateAdmin: "Duplicate Admin",

  time: "Time",
  status: "Status",
  Location: "Location",
  Odometer: "Odometer",
  EngineHours: "Engine Hours",
  Origin: "Origin",
  Annotation: "Annotation",
  days: "Days",
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",
  odometer: "Odometer",
  subtract: "Sub",
  add: "Add",
  edit: "Edit",
  deactivate: "Deactivate",
  reason:"Reason",
  details:"Details",
  notes:"Notes",
  selectDriver:"Select Driver",
  required:"Required",
  note:"Note",
  find:"FIND",
  log:"Log",
  admin:"Admin",
  adminEvents:"Admin events",
  dvir:"Dvir",


  DATE:"DATE",
  START_TIME:"START TIME",
  CYCLE:"CYCLE",
  DRIVER:"DRIVER",
  CARRIER:"CARRIER",
  CO_DRIVERS:"CO DRIVERS",
  VEHICLES:"VEHICLES",
  HOMETERMINAL:"HOME TERMINAL",
  DISTANCE:"DISTANCE",
  TRAILERS:"TRAILERS",
  SHIPPING_DOCS:"SHIPPING DOCS",
  ENGINE_HOURS:"ENGINE HOURS",
  EMAIL:"EMAIL",





  eventTime:"Event Time",
  CoordCode:"Coord Code",
  latitude:"Latitude",
  longitude:"Longitude",
  reasonForEdit:"Reason For Edit",
  recordOrigin:"Record Origin",
  recordStatus:"Record Status",
  eventType:"Event Type",
  subType:"Sub Type",
  sequenceId:"Sequence Id",
  shippingDoc:"Shipping Doc",
  accumulatedHours:"Accumulated Hours",
  accumulatedMiles:"Accumulated Miles",




  manageDrivers:"Manage Drivers",
  save:"SAVE",
  allDrivers:"All Drivers",
  saving:"Saving...",
  close:"CLOSE",
  cancel:"CANCEL",
  assignDriver:"Assign Driver",

  editMultipleStatus:"EDIT MULTIPLE STATUS",
  addMultipleLogs:"ADD MULTIPLE LOGS",

  drActionReqMsg: "Driver action required",
  drConfirmReqMsg:
    "Please confirm with driver to accept or reject the request.",
  automaticDrMsg: "Automatic drive can not be modified.",
  successMessage: "Saved successfully",
  errorMessage: "Something went wrong, please try after sometime.",
  automaticDriveTimeError:
    "Check event time. You can't add status after automatic drive.",

    editsuccessMessage:"Edit request is send successfully",

    No_logingFound_for:"No login found for",
    For_selected_date:"for selected date.",
    noDutyStatusFound:"No duty status found.",
    errorMasage_selectCo_driverTOReassignLogs:"Select co-driver to reassign logs",

   updateTrailerMassage_shippingDocs_application:"Updated trailer or shipping document will only be applicable to future status not to any past status."

};
