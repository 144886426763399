export const LABELS = {
  DASHCAMERA: {
    header: "Integrated Video is here.",
    header2:
      "Osrit Dashcam helps you improve driver behavior and mitigate risks to your business by providing visual context of harsh driving events.",
    header3: "To know more, please visit at",
    header3Link: "www.truckdashcamera.com",
  },
  OSRIT: {
    header: "Cloud Trucking Software | TMS.",
    header2:
      "Osrit Cloud Software helps you easily dispatch FTL or LTL loads, customers, brokers, drivers, payroll, invoicing, etc from one place !",
    header3: "To know more, please visit at",
    header3Link: "www.osrit.com",
  },
  SIGN_IN_FORM: {
    email: "Email",
    sendEmail:"Send Email",
    password: "Password",
    logInBtn: "Log In",
    logInLoading: "Loading...",
    forgetPwd: "Forget Password",
    required: "Required",
    inValidEmail: "Invalid email address",
    invalidPassword: 'Invalid password',
    passwordNoMatch: "Passwords do not match",
    invalidEmailPwd: "Invalid email or password",

  },
   
  close:"Close",
  please_check_yourEmail_for_password_reset_instruction:"Please check your email for password reset instructions.",
  please_try_after_sometime:"Please try after sometime.",
};
