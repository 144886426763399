const defaultState = {
    logBusy: false,
    addBusy: false,
    editBusy: false,
    dailyDiaryBusy: false,
    deleteBusy: false,
    logs: [],
    driverCalcus: [],
    driverLogs: [],
    unidentifiedLog: {},
    unidentifiedLogs: [],
    unidentifiedLogsCount: 0,
    malfunctionLogs: [],
    malfunctionLogsCount: 0,
    selectedLog: {},
    driverDiary: [],
    driverDiaryCount: 0,

    logEditsCount: 0,
    logEdits: []
};

export default function log(state = defaultState, action) {
    switch (action.type) {
        case "LOGS":
            return {
                ...state,
                logs: action.value
            };
        case "DRIVER_LOGS":
            return {
                ...state,
                driverLogs: action.value
            };
        case "DRIVER_CALCUS":
            return {
                ...state,
                driverCalcus: action.value
            };
        case "DRIVER_LAST_LOGS":
            return {
                ...state,
                driverLastLogs: action.value
            };
        case "DRIVER_NEXT_LOGS":
            return {
                ...state,
                driverNextLogs: action.value
            };
        case "DRIVER_DAILY_DIARY_COUNT":
            return {
                ...state,
                driverDiaryCount: action.value
            };
        case "DRIVER_DAILY_DIARY":
            return {
                ...state,
                driverDiary: action.value
            };
        case "LOGS_DAILY_BUSY":
            return {
                ...state,
                dailyDiaryBusy: action.value
            };
        case "LOGS_BUSY":
            return {
                ...state,
                logBusy: action.value
            };
        case "LOGS_DELETE_BUSY":
            return {
                ...state,
                deleteBusy: action.value
            };
        case "LOGS_ADD_BUSY":
            return {
                ...state,
                addBusy: action.value
            };
        case "LOGS_EDIT_BUSY":
            return {
                ...state,
                editBusy: action.value
            };
        case "SELECTED_LOG":
            return {
                ...state,
                selectedLog: action.value
            };
        case "LOG_UNIDENTIFIED":
            return {
                ...state,
                unidentifiedLog: action.value
            };
        case "LOGS_UNIDENTIFIED":
            return {
                ...state,
                unidentifiedLogs: action.value
            };
        case "LOGS_UNIDENTIFIED_COUNT":
            return {
                ...state,
                unidentifiedLogsCount: action.value
            };
        case "LOGS_MALFUNCTION":
            return {
                ...state,
                malfunctionLogs: action.value
            };
        case "LOGS_MALFUNCTION_COUNT":
            return {
                ...state,
                malfunctionLogsCount: action.value
            };
        case "LOG_EDITS":
            return {
                ...state,
                logEdits: action.value
            };
        case "LOG_EDITS_COUNT":
            return {
                ...state,
                logEditsCount: action.value
            };

        default:
            return state;
    }
}
