import React, { useState} from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import moment from "moment-timezone";
// import MomentUtils from "@date-io/moment";
// import InputLabel from "@mui/material/InputLabel";
// import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import SearchIcon from "@mui/icons-material/Search";
// import ClearIcon from "@mui/icons-material/Clear";
// import CircularProgress from "@mui/material/CircularProgress";
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

// const IFTA_YEARS = [];
const styles = (theme) => ({});

const SearchBar = (props) => {
  let { companies = [] } = props;

  const [companyId, setCompanyId] = useState("");
  const [dutyStatus, setDutyStatus] = useState("");
  const [connection, setConnection] = useState("");

  const onSearch = (e) => {
    e.preventDefault();

    setTimeout(() => {
      props.onSearch({ companyId, dutyStatus, connection });
    }, 1000);
  };

  const dutyStatusArray = [
    {
      id: "REMARK",
      name: "Remark",
    },
    {
      id: "ONDUTY_ND",
      name: "OnDutyND",
    },
    {
      id: "DRIVING",
      name: "Driving",
    },
    {
      id: "SLEEPER",
      name: "Sleeper",
    },
    {
      id: "OFFDUTY",
      name: "OffDuty",
    },
    {
      id: "BREAK",
      name: "Break",
    },
  ];

  const connectionArray = [
    {
      id: true,
      name: "Connected",
    },
    {
      id: false,
      name: "DisConnected",
    },
  ];

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <FormControl size="small" fullWidth>
            <Select
              displayEmpty
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value);
                props.onSearch({
                  companyId: e.target.value,
                  dutyStatus,
                  connection,
                });
              }}
              inputProps={{
                name: "companyId",
                id: "companyId",
              }}
            >
              <MenuItem value={""}>{LABELS.allCompany}</MenuItem>
              {companies.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item._id}
                  style={{ textTransform: "capitalize" }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl size="small" fullWidth>
            <Select
              displayEmpty
              value={dutyStatus}
              onChange={(e) => {
                setDutyStatus(e.target.value);
                props.onSearch({
                  companyId,
                  dutyStatus: e.target.value,
                  connection,
                });
              }}
              inputProps={{
                name: "dutyStatus",
                id: "dutyStatus",
              }}
            >
              <MenuItem value={""}>{LABELS.dutyStatus}</MenuItem>
              {dutyStatusArray.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item.id}
                  style={{ textTransform: "capitalize" }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl size="small" fullWidth>
            <Select
              displayEmpty
              value={connection}
              onChange={(e) => {
                setConnection(e.target.value);
                props.onSearch({
                  companyId,
                  dutyStatus,
                  connection: e.target.value,
                });
              }}
              inputProps={{
                name: "connection",
                id: "connection",
              }}
            >
              <MenuItem value={""}>{LABELS.connection}</MenuItem>
              {connectionArray.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item.id}
                  style={{ textTransform: "capitalize" }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    equipments: _get(state, "equipmentData.equipments", []),
    accounts: _get(state, "accountData.accounts", []),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);
