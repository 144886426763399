export const LABELS={
  addCarrier :"ADD CARRIER",
  editCarrier:"EDIT CARRIER",
  edit_Terminal:"EDIT TERMINAL",
  add_Terminal:"ADD TERMINAL",
  addAccount:"ADD ACCOUNT",

  editEquipment :"EDIT EQUIPMENT",
  addEquipment:"ADD EQUIPMENT",

  editTrailer:"EDIT TRAILER",
  addTrailer:"ADD TRAILER",

  editReasons:"EDIT REASONS",

  addUser:"ADD USER",
  editUser:"EDIT USER",
  driver_details:"DRIVER DETAILS",
  update:"UPDATE",
  addApi_key:"ADD API KEY",

  logingUsers:"Login Users",
  supportCentre:"Support Center",


  name:"Name",
  dot:"DOT",
  timezone:"Timezone",
  country:"Country",
  address_line1:"Address line 1",
  suit_bldg:"Suit.,bldg",
  city:"City",
  state:"State",
  zip:"Zip",
  contactName:"Contact Name",
  email:"Email",
  phone:"Phone",
  role:"Role",
  dispatchName:"Dispatch Name",
  note:"NOTE :",
  info:" Dispatch info is only required, if you want send dispatch to ELD device as push notifications.",
  save:"SAVE",
  saving:"Saving...",
  cancel:"CANCEL",
  close:"CLOSE",
  roles:"Roles",
  access:"Access",
  cargo:"Cargo",
  hos:"HOS",
  dutyLogs:"DUTY LOGS",
  contact:"Contact",
  required:"Required",
  billing: "Billing",


  accountName:"Account Name",
  contactEmail:"Contact email",
  contactPhone:"Contact phone",
  contactRole:"Contact role",
  stripe:"Stripe",
  lastActive:"Last active",


 driverId:"Driver ID",
 firstName:"First Name",
 lastName:"Last Name",
 mobile:"Mobile",
 password:"Password",
 licCountry:"Lic Country",
 licState:"Lic State",
 licNumber:"Lic Number",
 cycleUsa:"Cycle USA",
 cycleCA:"Cycle CA",
 startTime:"Start time",
 receiverMail:"Receiver Email",
 personal:"Personal",
 yardMoves:"Yard Moves",
 allowEdit:"Allow Edit",
 tracking:"Tracking",
 exempt:"Exempt",
 exemptDriver:"Exempt Driver",
 exemptAnnotation:"Exempt Annotation",
 assignedVehicle:"Assigned Vehicle",
 currentVehicle:"Current Vehicle",
 currentTrailer:"Current Trailer",
 enterMobileNumber:"Enter mobile number",
 appHome_View:"App Home View",
 firebase:"Firebase",
 apiKey:"Api Key",
 scopes:"Scopes",
 message:"Message",
 sendNow:"Send Now",
 loading:"Loading...",
 sendVia_whatsapp:"Send via whatsapp",
 send_sms:"SEND SMS",
 speed:"Speed",



 prefix:"Prefix",
 state_mileage_report:"State Mileage Report",
 pdf:"PDF",
 details:"DETAILS",
 carrierName:"Carrier Name",
 vehicleName:"Vehicle Name",
 miles:"Miles",
 mileage_miles:"Mileage(Miles)",
 quarter:"Quarter",
 report:"Report",
 select_vehicle:"Select vehicle",
 odometer:"Odometer",
 superAdmin:"Super Admin",
 supportAdmin:"Support Admin",


  No_vehicle_is_assignedTO_deiver:" No vehicle is assigned to driver",
  please_set_current_vehicle:"Please set current vehicle",



 
  endTime:"End time",
  duration:"Duration",
  recordStatus:"Record Status",
  recordOrigin:"Record Origin",
  location:"Location",
  assign:"Assign",
  driver:"Driver",


  diagostic$Malfunction:"Diagnostic/Malfunction",
  eventCode:"Event code",
  eventTime:"Event time",





  status:"Status",
  terminal:"Terminal",
  address:"Address",
  title:"Title",
  body:"Body",
  time:"Time",
  user:"User",
  allUser:"All Users",
  sendNotification:"Send Notification",
  send:"Send",
  syncAll:"SYNC ALL",
  inspector:"Inspector",
  condition:"Condition",
  start_end:"Start/End",
  view:"VIEW",
  date:"Date",
  _note:"Note",
  _details:"Details",
  _view:"View",
  settings:"Settings",
  select:"Select",
  coordinates:"Coordinates",
  event:"Event",
  table:"TABLE",
  map:"MAP",
  trips:"Trips",
  delete:"DELETE",
  edit:"Edit",
  



  driver_vehicle_inspection_report:" DRIVER'S VEHICLE INSPECTION REPORT (DVIR)",
  AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS:"AS REQUIRED BY THE D.O.T. FEDERAL MOTOR CARRIER SAFETY REGULATIONS",
  dvirNumber:"DVIR Number:-",
 start_inspection:"Start Inspection",
 endInspection:"End Inspection ",
 inspection_type:"Inspection Type",
 inspection_Area:"Inspection Areas",
 I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements:"I declare that this asset has been inspected in accordance with the applicable requirements.",
 driver_signature:"Driver Signature",



  download:"Download",
  downloading:"Downloading...",
  not_able_to_download:"Not able to download",
  missingFile:"Missing file",



  history:"HISTORY",
  send_to_fmcsa:"SEND TO FMCSA",
  email_to_fmcsa:"EMAIL TO FMCSA",
  download:"DOWNLOAD",
  SelectDriver:"Select Driver",
  fromDate:"From Date",
  toDate:"To Date",
  comments:"Comments",
  fmcsa_data_transfer:"FMCSA Data Transfer (Steps to transfer data)",
  selectDateRange:"Select Date range",
  enterComment:"Enter comment",
  sendDataTo_fmcsa:"Click on \"Send To FMCSA\" to transfer data to FMCSA",




   showall:"Show all",
   vehicle:"VEHICLE",
   trailer:"TRAILER",
   search:"Search...",
   add:"ADD",
   liveshare:"Live Share",
   create_ifta:"CREATE IFTA",
   no_data_found:" No data found!",
   no_recordFound:"No records are found",


   regulationMode:"Regulation Mode",
   plate:"Plate",
   eld:"ELD",
   created_edited:"Created/Edited",
   created:"Created",
   edited:"Edited",


   rowsperpage: "Rows per page",


//   <------ADD equipment Form------->

   vin:"Vin",
   make:"Make",
   model:"Model",
   year:"Year",
   fuel:"Fuel",
   type:"Type",
   licPlate:"Lic Plate",
   dvirForm:"DVIR Form",
   gps_source:"GPS Source",
   devicetype:"Device Type",
   devicename:"Device Name",
   macAddrerss:"MAC Address",
   eld_serial:"ELD Serial",
   eld_firmware:"ELD Firmware",
   eld_BleVersion:"ELD BleVersion",
   connectionType:"Connection Type",
   dashcam:"Dashcam",
   odometer_offset:"Odometer offset",
   active:"Active",
   codes:"Codes",
   ifta:"Ifta",
   vbusConnection:"VBUS connection required for driving",
   carrier:"Carrier",
   dvir :"Dvir",
   licExpiry:"Lic Expiry",
   adminScope:"ADMIN SCOPES",
   assetType:"Asset type",
   dueby:"Due by",
   notes:"Notes",
   




   dashcameraId:"Dashcamera Id",
   reseller:"Reseller",
   updateFrequency:"Update Frequency",
   bucket:"Bucket",
   theme:"Theme",
   kioskpin:"Kioskpin",
   hereAppId:"Here App Id",
   hereApiKey:"Here Api Key",
   updateLocation:"Update Location",
   debug:"Debug",
   allLogs:"All Logs",
   editLogs:"EditLogs",
   documents:"Documents",
   notification:"Notifications",
   addDvir:"Add Dvir",
   appSettings:"App settings",
   appAll_Log:"App All Logs",
   appAllLogsFmcsa:"App All Logs Fmcsa",
   estimatedWaitTime:"Estimated wait time",
   minutes:"minute(s)",
   maintenance:"Maintenance",
   updateDvir:"Update Dvir",
   vehicletype:"Vehicle type",
   form:"Form",
   _vehicle:"Vehicle",
   StartDate:"Start Date",
   EndDate:"End Date",
   _trailer:"Trailer",
   equipmentconditions:"Equipment conditions",
   date_is_required:"Date is required",






  noteinfo:"NOTE: Once you add new vehicle, your card on file will be automatically charged.",
 ifta_info_note: "IFTA is only available for active trucks.",
 ifta_info:"If you want to disable or delete truck please download state mileage before disable action.",
 saved_Successfully:"Saved successfully",
 updateSuccessfully:"Updated successfully",
 sendSuccessfully:"Sms is send successfully",
 successMessage_carrier:"Carrier is saved successfully",
 successMessage: "Trailer is saved successfully",
 successMessage_Equipment: "Equipment is saved successfully",
 please_try_aftersometime:"Please try after sometime.",
 successMessage_user:"User is added successfully",
 successMessage_Api_Key:"Api Key is added successfully",
 response:"Something went wrong, please try after time.",
 errorMessage:"Something went wrong, please try after time.",
 editReasonUpdated:"Edit reasons are updated successfully.",
 logs_are_successfully_submitted:"Logs are successfully submitted.",
 user_is_updated_successfully:"User is updated successfully.",
 driver_is_cleaned_successfully:"Driver is cleaned sucessfully.",
 account_is_updated_successfully:"Account is updated successfully",
 Note_only_requiredforSetNewPassword:"Note: Only required, if you want to set new password",
 driver_is_required:"Driver is required.",
 from_date_is_required:"From date is required.",
 to_date_is_required:"To date is required. ",
 comment_is_required:"Comment is required.",
 comment_is_invalid:"Comment is invalid. ",

 are_you_sure_WantTo_delete:"Are you sure, you want to delete dvir",
 errorMassage:"Something went wrong, please try sometime later.",
 deletedsucessfully:"Deleted sucessfully.",
 deleting:"Deleting...",
 deleteViolation:"Delete Violations",
 are_you_sure_wantTo_delete_all_violation:"Are you sure, you want to delete all violations",







}
