// import { settings } from "firebase/analytics";

export const LABELS = {
  accounts: "ਖਾਤੇ",
  drivers: "ਡਰਾਈਵਰ",
  equipments: "ਉਪਕਰਨ",

  allCompany: "ਸਾਰੀ ਕੰਪਨੀ",
  company: "ਕੰਪਨੀ",
  dutyStatus: "ਡਿਊਟੀ ਸਥਿਤੀ",
  connection: "ਕਨੈਕਸ਼ਨ",
  connected: "ਜੁੜਿਆ",
  disconnected: "ਡਿਸਕਨੈਕਟ ਕੀਤਾ",
  offDuty: "ਆਫ ਡਿਊਟੀ",
  onDuty: "ਕੰਮ ਤੇ",
  driving: "ਗੱਡੀ ਚਲਾਉਣਾ",
  sleeping: "ਸੁੱਤੇ ਹੋਏ",
  driverInfo: "ਡਰਾਈਵਰ ਜਾਣਕਾਰੀ",
  name: "ਨਾਮ",
  status: "ਸਥਿਤੀ",
  violations: "ਉਲੰਘਣਾਵਾਂ",
  date: "ਤਾਰੀਖ਼",
  eLDConnection: "ELD ਕਨੈਕਸ਼ਨ",
  total: "ਕੁੱਲ",
  formsSignatures: "ਫਾਰਮ ਅਤੇ ਦਸਤਖਤ",
  time_period:"ਸਮਾਂ ਮਿਆਦ",
  today:"ਅੱਜ",
  yesterday:"ਕੱਲ੍ਹ",
  thisWeek:"ਇਸ ਹਫ਼ਤੇ",
  thisMonth:"ਇਸ ਮਹੀਨੇ",
  sleeper:"ਸਲੀਪਰ",
  remark:"ਟਿੱਪਣੀ",
  break:"ਤੋੜ",
  OnDutyND:"OnDutyND",
  callback:"ਵਾਪਸ ਫੋਨ ਮਲਾਓ",







  count: "ਗਿਣਤੀ",
  search: "ਖੋਜ",
  add: "ਸ਼ਾਮਲ ਕਰੋ",
  contact: "ਸੰਪਰਕ ਕਰੋ",
  payment: "ਭੁਗਤਾਨ",
  created: "ਬਣਾਇਆ",
  actions: "ਕਾਰਵਾਈਆਂ",
  edit: "ਸੰਪਾਦਿਤ ਕਰੋ",
  edited:"ਸੰਪਾਦਿਤ ਕੀਤਾ",
  details: "ਵੇਰਵੇ",
  leads: "ਅਗਵਾਈ ਕਰਦਾ ਹੈ",
  added:"ਜੋੜਿਆ ਗਿਆ",
  saving:"ਰੱਖਿਅਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  settings:"ਸੈਟਿੰਗਾਂ",

  account: "ਖਾਤਾ",
  driver: "ਡਰਾਈਵਰ",
  clear: "ਸਾਫ਼",
  new: "ਨਵਾਂ",
  info: "ਜਾਣਕਾਰੀ",
  caller: "ਕਾਲ ਕਰਨ ਵਾਲਾ",
  detail: "ਵੇਰਵੇ",
  other: "ਹੋਰ",
  clean:"ਸਾਫ਼",
  delete:"ਮਿਟਾਓ",
  support:"ਸਪੋਰਟ",
  assigned:"ਨਿਰਧਾਰਤ",

  provider: "ਦੇਣ ਵਾਲੇ",
  carrier: "ਕੈਰੀਅਰ",
  shipdate: "ਜਹਾਜ਼ ਦੀ ਮਿਤੀ",
  tracking: "ਟਰੈਕਿੰਗ",
  addedby: "ਦੁਆਰਾ ਜੋੜਿਆ ਗਿਆ",
  role:"ਭੂਮਿਕਾ",
  terminal:"ਅਖੀਰੀ ਸਟੇਸ਼ਨ",
  dispatch:"ਡਿਸਪੈਚ",
  address:"ਪਤਾ",
  link:"ਲਿੰਕ",
  expiry:"ਮਿਆਦ ਪੁੱਗਣ",
  start_expiry_date:"ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਸ਼ੁਰੂ ਕਰੋ",
  end_expiry_date:"ਸਮਾਪਤੀ ਦੀ ਮਿਤੀ",

  commingSoon: "ਆਨ ਵਾਲੀ",

  startDate: "ਤਾਰੀਖ ਸ਼ੁਰੂ",
  endDate: "ਸਮਾਪਤੀ ਮਿਤੀ",
  logs: "ਲਾਗ",
  viewLogs: "ਲੌਗ ਦੇਖੋ",
  rowsperpage: "ਪ੍ਰਤੀ ਪੰਨਾ ਕਤਾਰਾਂ",
  logIn: "ਲਾਗਿਨ",

  email: "ਈ - ਮੇਲ",
  error: "ਗਲਤੀ",
  time: "ਸਮਾਂ", 
  phone:"ਫ਼ੋਨ",
  reason:"ਕਾਰਨ",
  App:"ਐਪ",
  Device:"ਡਿਵਾਈਸ",


  searchbyemailorname: "ਈਮੇਲ ਜਾਂ ਨਾਮ ਦੁਆਰਾ ਖੋਜ ਕਰੋ",
  supportUsers: "ਉਪਭੋਗਤਾਵਾਂ ਦਾ ਸਮਰਥਨ ਕਰੋ",
  norecordsarefound: "ਕੋਈ ਰਿਕਾਰਡ ਨਹੀਂ ਮਿਲਿਆ",
  selectVehicle:"ਵਾਹਨ ਦੀ ਚੋਣ ਕਰੋ",

  syncRealTime: "ਰੀਅਲਟਾਈਮ ਸਿੰਕ ਕਰੋ",
  syncingRealTime:"ਰੀਅਲਟਾਈਮ ਸਿੰਕ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ",

  reseller: "ਵਿਕਰੇਤਾ",
  billing: "ਬਿਲਿੰਗ",
  overdues: "ਵੱਧ ਬਕਾਇਆ",
  missingstripe: "ਗੁੰਮ ਹੋਈ ਪੱਟੀ",
  notes: "ਨੋਟਸ",

  annual: "ਸਾਲਾਨਾ",
  monthly: "ਮਹੀਨਾਵਾਰ",
  enabled: "ਸਮਰਥਿਤ",
  disabled: "ਅਯੋਗ",
  sync: "ਸਿੰਕ",
  syncing:"ਸਿੰਕ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  activate: "ਸਰਗਰਮ ਕਰੋ",
  cancel: "ਰੱਦ ਕਰੋ",
  confirm: "ਪੁਸ਼ਟੀ ਕਰੋ",
  pleaseConfirm: "ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀ ਕਰੋ, ਜੇਕਰ ਤੁਸੀਂ ਚਾਹੁੰਦੇ ਹੋ ",

  deactivate: "ਅਕਿਰਿਆਸ਼ੀਲ ਕਰੋ",
  activeManagers: "ਸਰਗਰਮ ਪ੍ਰਬੰਧਕ",
  inactiveManagers: "ਅਕਿਰਿਆਸ਼ੀਲ ਪ੍ਰਬੰਧਕ",
  activeDrivers: "ਸਰਗਰਮ ਡਰਾਈਵਰ",
  inactiveDrivers: "ਅਕਿਰਿਆਸ਼ੀਲ ਡਰਾਈਵਰ",
  allowedVehicles: "ਮਨਜ਼ੂਰਸ਼ੁਦਾ ਵਾਹਨ",
  activeVehicles: "ਸਰਗਰਮ ਵਾਹਨ",
  inactiveVehicles: "ਅਕਿਰਿਆਸ਼ੀਲ ਵਾਹਨ",
  plan: "ਯੋਜਨਾ",
  planInfo: "ਯੋਜਨਾ ਦੀ ਜਾਣਕਾਰੀ",
  apiAccess: "ਏਪੀਆਈ ਪਹੁੰਚ:",
  dues: "ਬਕਾਇਆ",
  noduesfound: "ਕੋਈ ਬਕਾਇਆ ਨਹੀਂ ਮਿਲਿਆ",
  iftaAccess: "ਇਫਟਾ ਐਕਸੈਸ",
  addUser: "ਪ੍ਰਬੰਧਕ",



 standard:"ਮਿਆਰੀ",
 premium:"ਪ੍ਰੀਮੀਅਮ",
 enterprise:"ਐਂਟਰਪ੍ਰਾਈਜ਼",


  editplan:"ਯੋਜਨਾ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  allowedVehicle:"ਮਨਜ਼ੂਰਸ਼ੁਦਾ ਵਾਹਨ",
  currentVehicle:"ਮੌਜੂਦਾ ਵਾਹਨ",
  Name:"ਨਾਮ",
  ifta:"IFTA",
  api:"API",
  truck:"ਟਰੱਕ",
  note:'ਨੋਟ ਕਰੋ',
  addDue:"ਬਕਾਇਆ ਸ਼ਾਮਲ ਕਰੋ",
  save:"ਸੇਵ ਕਰੋ",
  close:"ਬੰਦ ਕਰੋ",
  addUsers:"ਵਰਤੋਂਕਾਰ ਸ਼ਾਮਲ ਕਰੋ",
  editUser:'ਵਰਤੋਂਕਾਰ ਦਾ ਸੰਪਾਦਨ ਕਰੋ',
  edit_User:"ਉਪਭੋਗਤਾ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  managers:"ਪ੍ਰਬੰਧਕ",

  callqueues:"ਕਾਲ ਕਤਾਰਾਂ",
  callInQueue:"ਕਤਾਰ ਵਿੱਚ ਕਾਲ.",
  loading:"ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  refresh:"ਤਾਜ਼ਾ ਕਰੋ",
  view:"ਦੇਖੋ",
  signOut:"ਸਾਇਨ ਆਉਟ",
  AllAccounts:"ਸਾਰੇ ਖਾਤੇ",
  notification:"ਸੂਚਨਾਵਾਂ",
  CustomerSupportPackage:"ਗਾਹਕ ਸਹਾਇਤਾ ਪੈਕੇਜ",
  queues:"ਕਤਾਰਾਂ",
  all:"ਸਾਰੇ",

  invoices:"ਚਲਾਨ",

  allEquipments:"ਸਾਰੇ ਉਪਕਰਣ",
  allStates:"ਸਾਰੇ ਰਾਜ",


  chat:"ਚੈਟ",
  unread:"ਨਾ ਪੜ੍ਹਿਆ",
  nounreadchats:"ਕੋਈ ਅਣਪੜ੍ਹੀਆਂ ਚੈਟ ਨਹੀਂ",
  view_all_chats:"ਸਾਰੀਆਂ ਚੈਟਾਂ ਦੇਖੋ",


  shippingSaveSuccessfully:"ਸ਼ਿਪਿੰਗ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ",
  planIs_updated_successfully:"ਯੋਜਨਾ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤੀ ਗਈ ਹੈ",
  errorMasage_tryafterSometime:"ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",


};
