import React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Field } from "redux-form";
import Select from "@mui/material/Select";

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderSelectField = ({
  input,
  label,
  name,
  disabled = false,
  meta: { touched, error },
  children,
  ...custom
}) => {
  // console.log('name ::::: ', name);
  // console.log('label ::::: ', label);
  return (
    <FormControl
      fullWidth
      size="small"
      variant="standard"
      error={touched && !!error}
      style={{ width: "100%" }}
    >
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        native
        {...input}
        {...custom}
        inputProps={{
          name,
          id: name,
        }}
      >
        {children}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  );
};

export default function ReduxInput({
  onClick,
  xs = 12,
  sm = 12,
  name,
  label,
  keyName = "id",
  valueName = "name",
  valueTwoName,
  valueThreeName,
  valueFourName,
  items = [],
  type = "text",
  placeholder,
  disabled = false,
  loading = false,
  onChange = () => {},
}) {
  const _label = label ? label : name;
  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      style={{
        textAlign: "center",
        paddingRight: 5,
        paddingLeft: 5,
        paddingTop: 15,
      }}
    >
      <Field
        style={{ textAlign: "center" }}
        name={name}
        disabled={disabled}
        component={renderSelectField}
        label={_label}
        onChange={onChange}
      >
        <option value={""}>{_label}</option>
        {items &&
          items.map((e, i) => {
            const displayLabel = valueFourName
              ? `${e[valueName]} ${e[valueTwoName]} (${e[valueThreeName]}) (${e[valueFourName]})`
              : valueThreeName
              ? `${e[valueName]} ${e[valueTwoName]} (${e[valueThreeName]})`
              : valueTwoName
              ? `${e[valueName]} ${e[valueTwoName]}`
              : e[valueName];
            return (
              <option
                key={i}
                value={e[keyName]}
                style={{ textTransform: "capitalize" }}
              >
                {displayLabel}
              </option>
            );
          })}
      </Field>
    </Grid>
  );
}
