export const LABELS = {
  viewLogs: "Просмотр журналов",
  date: "Дата",
  detailsMulti: "Мульти",
  certified: "Проверенный",
  uncertified: "Несертифицированный",
  detail: "Деталь",
  violation: "Нарушение",
  driving: "Вождение",
  offDuty: "Вне службы",
  onDutyND: "OnDutyND",
  sleeper: "Спальное место",

  deleteLabel: "Удалить нарушения",
  deleteMsg: "Вы уверены, что хотите удалить все нарушения?",
};
