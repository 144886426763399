
export const LABELS={
    addAccount:"ADD ACCOUNT",
  

    name:"Name",
    status:"Status",
    ein:"Ein",
    type:"Type",
    email:"Email",
    phone:"Phone",
    role:"Role",
    billing_name:"Billing Name",
    billing_email:"Billing Email",
    billing_phone:"Billing Phone",
    billing_role:"Billing Role",
    stripe:"Stripe",
    note:"Note",
    detail:"Detail",
    contact:"Contact",
    billing:"Billing",
    loging_users:"Login Users",

    saving:"Saving...",
    save:"SAVE",
    close:"CLOSE",


    cleanDriver:"Do you want to clean driver from list",
    cleanNow:"CLEAN NOW",
    cleaning:"Cleaning...",



    // <-------for Support user----->

    addSupportUser:"Add Support User",
  note: "Note:-  This is only for adding new support user. Support user will have access to all your account and carrier details.",
  firstName:"First Name",
  lastName:"Last Name",
  mobile:"Mobile",
  password:"Password",
  reseller:"Reseller",
  superAdmin:"Super Admin",




 

}