import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

export default function LongMenu({
  selectedItem,
  prevLog,
  prevLog2,
  selectedLabel,
  labels = [],
  index = "",
  handleMenuClick = () => {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose(item, selectedItem, index) {
    setAnchorEl(null);
    handleMenuClick(null, item, selectedItem, index, prevLog, prevLog2);
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200,
          },
        }}
      >
        {labels.map((label) => (
          <MenuItem
            key={label}
            selected={label === selectedLabel}
            onClick={() => handleClose(label, selectedItem, index)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
