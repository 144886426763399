import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { getDriverById } from "../../actions/driverActions";
import {
  getDriverDailyCount,
  getDriverDaily,
  updateDriverDaily,
  getAllCalcus,
} from "../../actions/logActions";
import {
  formatDate,
  getDailyLogsDays,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { getDriverLogs } from "../../actions/logActions";
import CircularProgress from "@mui/material/CircularProgress";
import DailyDiaryTable from "./DailyDiaryTable";
import { LABELS } from "../../language";

const ALL_LABELS = LABELS.diaryPage;

export class DailyDiary extends React.Component {
  state = {
    limit: 10,
    page: 0,
  };

  async componentDidMount() {
    await this.getDriverDailyCount();
    await this.getDriverDaily();
  }

  handleViewLogs = (date) => {
    const { accountBaseUrl } = this.props;
    const driverId = _get(this, "props.match.params.driverId");
    this.props.history.push(
      `${accountBaseUrl}/status/detail/${driverId}?selectedDate=${date}`
    );
  };

  handleViewMultiLogs = (date) => {
    const { accountBaseUrl } = this.props;
    const driverId = _get(this, "props.match.params.driverId");
    this.props.history.push(
      `${accountBaseUrl}/status/multi/${driverId}?startDate=${date}&endDate=${date}`
    );
  };

  getDriverDailyCount = async () => {
    this.setState({ loading: true });
    const driverId = _get(this, "props.match.params.driverId");
    await this.props.getDriverDailyCount({ driverId });
    this.setState({ loading: false });
  };

  getDriverDaily = async () => {
    const driverId = _get(this, "props.match.params.driverId");
    this.setState({ loading: true });
    const { limit, page } = this.state;
    const skip = parseInt(page) * parseInt(limit);

    await this.props.getDriverById({ driverId });
    await this.props.getDriverDaily({ driverId, limit, skip });
    this.setState({ loading: false });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getDriverDaily);
  };

  render() {
    const { driverDiary, selectedDriver } = this.props;
    const { loading } = this.state;
    return (
      <Grid container spacing={0} style={{ padding: 10 }}>
        <Grid item xs={12} sm={12}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {selectedDriver && (
                <div style={{ padding: 10, textTransform: "capitalize" }}>
                  {selectedDriver.firstName} {selectedDriver.lastName}
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ float: "right" }}
                    onClick={(e) => this.handleViewLogs(new Date())}
                  >
                    {ALL_LABELS.viewLogs}
                  </Button>
                </div>
              )}

              <DailyDiaryTable
                items={driverDiary}
                selectedDriver={selectedDriver}
                driverId={selectedDriver.driver && selectedDriver.driver.id}
                handleChange={this.handleChange}
                handleViewLogs={this.handleViewLogs}
                handleViewMultiLogs={this.handleViewMultiLogs}
                formatDate={this.props.formatDate}
                limit={this.state.limit}
                page={this.state.page}
                count={this.props.count}
                timezone={this.props.timezone}
                updateDriverDaily={this.props.updateDriverDaily}
                isUnity={this.props.isUnity}
                isQa={this.props.isQa}
                adminKey={this.props.adminKey}
                accountId={this.props.accountId}
                LABELS={ALL_LABELS}
                getDailyLogsDays={this.props.getDailyLogsDays}
                getDriverLogs={this.props.getDriverLogs}
                getAllCalcus={this.props.getAllCalcus}
                formatDateTimeToUtc={this.props.formatDateTimeToUtc}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");

  return {
    timezone: _get(state, "appData.timezone"),
    driverDiary: _get(state, "logData.driverDiary", []),
    count: _get(state, "logData.driverDiaryCount", 0),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    selectedDriver: _get(state, "driverData.selectedDriver", {}),
    isUnity: _get(state, "appData.isUnity", false),
    isQa: _get(state, "appData.isQa", false),
    adminKey: adminKey,
    accountId: _get(state, "authData.accountId"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDriverDailyCount,
      getDriverById,
      getDriverDaily,
      formatDate,
      updateDriverDaily,
      getDailyLogsDays,
      getDriverLogs,
      getAllCalcus,
      formatDateTimeToUtc,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailyDiary)
);
