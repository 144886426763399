const validate = (values) => {
  const errors = {};

  if (!values.dueBy) {
    errors.dueBy = "Required";
  }
  if (!values.assetId) {
    errors.assetId = "Required";
  }
  if (!values.assetType) {
    errors.assetType = "Required";
  }

  return errors;
};

export default validate;
