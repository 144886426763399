import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog(props) {
  const handleClose = () => {
    props.onClose(false);
  };

  const label = props.label || "Please Confirm";
  const agreeLabel = props.agreeLabel || "Agree";
  const disAgreeLabel = props.disAgreeLabel || "Disagree";

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{label}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(false)} color="secondary">
          {disAgreeLabel}
        </Button>
        <Button onClick={() => props.onClose(true)} color="primary" autoFocus>
          {agreeLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
