function getKmFromString(text) {
  const textKm = text && text.split(`km`)[0].split('class="length">')[1];
  // console.log("textKm ===== ", textKm);
  return textKm ? parseInt(textKm) : 0;
}

export function getMeterToMi(meters, prefix = 0.83) {
  const _val = (meters * 0.00062137).toFixed();

  // FIXME:
  const val = (_val * prefix).toFixed();
  return val;
}

export function parseStateMileage(data) {
  let stateObj = {};
  for (let i = 0; i < data.length; i++) {
    const _data = (data[i] && data[i].route) || [];

    for (let j = 0; j < _data.length; j++) {
      const __data = _data[j];
      stateObj[__data.country] = stateObj[__data.country] || {};

      const _baseTime = stateObj[__data.country].baseTime || 0;
      const _baseTime1 = __data.baseTime;
      const baseTime = _baseTime + _baseTime1;

      const _distance = stateObj[__data.country].distance || 0;
      const _distanceKm = stateObj[__data.country].distanceKm || 0;
      const _distance1 = __data.distance;
      const _distance1Km = getKmFromString(__data.text);
      const distance = _distance + _distance1;
      const distanceKm = _distanceKm + _distance1Km;

      const _trafficTime = stateObj[__data.country].trafficTime || 0;
      const _trafficTime1 = __data.trafficTime;
      const trafficTime = _trafficTime + _trafficTime1;

      const _tollRoadDistance = stateObj[__data.country].tollRoadDistance || 0;
      const _tollRoadDistance1 = __data.tollRoadDistance || 0;
      const tollRoadDistance = _tollRoadDistance + _tollRoadDistance1;

      // const _travelTime = stateObj[__data.country].travelTime || 0;
      // const _travelTime1 = __data.travelTime;
      // const travelTime = _travelTime + _travelTime1;

      const _text = stateObj[__data.country].text || "";
      const _text1 = __data.text;
      const text = _text + " _ " + _text1;

      const _country = stateObj[__data.country].country || "";
      const _country1 = __data.country;
      const countryAll = _country + " _ " + _country1;

      stateObj[__data.country] = {
        baseTime,
        distance,
        distanceKm,
        tollRoadDistance,
        trafficTime,
        countryAll,
        country: __data.country,
        text,
      };
    }
  }

  const _objArray = Object.keys(stateObj);
  const objArray = _objArray.map((item) => stateObj[item]);
  let totalMiles = 0;
  let totalKm = 0;
  objArray.forEach((item) => {
    totalMiles = totalMiles + parseInt(item.distance);
    totalKm = totalKm + parseInt(item.distanceKm);
  });
  return objArray;
}

export function getDiffInKm(lat1, lon1, lat2, lon2) {
  // Convert degrees to radians
  const toRadians = (degrees) => degrees * (Math.PI / 180);

  const R = 6371; // Radius of the Earth in kilometers. Use 3958.8 for miles

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in kilometers
  return distance;
}

// // Example Usage:
// const lat1 = 37.7749; // Latitude of San Francisco
// const lon1 = -122.4194; // Longitude of San Francisco
// const lat2 = 34.0522; // Latitude of Los Angeles
// const lon2 = -118.2437; // Longitude of Los Angeles
//
// const distance = getDiffInKm(lat1, lon1, lat2, lon2);
// console.log(`Distance: ${distance} km`);
