import _get from "lodash/get";
import { React, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import {
  Marker,
  TrafficLayer,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import VideocamIcon from "@mui/icons-material/Videocam";
import Button from "@mui/material/Button";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { gMapApiKey } from "../../utils/constants";
import { formatPaths, formatStops, startLatLng, carImage } from "./helpers";

const camIconStyle = {
  verticalAlign: "bottom",
  fontSize: "18px !important",
  marginRight: 0,
  width: "0.2rem",
  height: "0.2rem",
  cursor: "pointer",
};

function getIntials(item) {
  const driver = item.driver || {};
  const fName = driver.firstName
    ? driver.firstName.slice(0, 1).toUpperCase()
    : "";
  const lName = driver.lastName
    ? driver.lastName.slice(0, 1).toUpperCase()
    : "";

  return fName + lName;
}

function RenderMarker(props) {
  const item = props.item || {};
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.dId) {
      if (props.dId === item.uqId) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  });

  const address = _get(props, "item.data.address.line1");
  const _time = _get(props, "item.data.time");
  const time = `${props.formatDate(_time)} ${props.formatTime(_time)}`;

  return (
    <Marker
      position={{ lat: props.lat, lng: props.lng }}
      prerender={false}
      labelAnchor={new window.google.maps.Point(0, 0)}
      labelStyle={{
        backgroundColor: "grey",
        color: "white",
        fontSize: "10px",
        padding: "1px",
      }}
      onClick={() => {
        if (props.dId) {
          props.onInfoWindowClose(item.driverId);
        }
        setIsOpen(true);
        props.onMarkerSelect(item);
      }}
      label={`${props.index + 1}`}
    >
      {isOpen ? (
        <InfoWindow
          onCloseClick={() => {
            setIsOpen(false);
            props.onInfoWindowClose();
            props.onMarkerReset();
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                textAlign: "center",
                padding: "5px 2px 0px 2px",
                marginTop: "0px",
                fontSize: "12px",
                color: "#333333",
                textTransform: "uppercase",
                cursor: "default",
                width: 200,
              }}
            >
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 600 }}>
                {`${item?.id}`}
              </p>
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 400 }}>
                {`${address}`}
              </p>
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 200 }}>
                {`${time}`}
              </p>
            </div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
}

function ReactMap(props) {
  let defaultCenter = {
    lat: 34.06441730765348,
    lng: -118.27857936594327,
  };
  const locations = props.locations;
  const centerLocation = locations && locations[0];
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gMapApiKey,
  });
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(19);
  const [center, setCenter] = useState(defaultCenter);
  const [dcOpen, setDcOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const onLoad = useCallback((map) => {
    const _center = {
      lat: parseFloat(centerLocation.coordinates[0]),
      lng: parseFloat(centerLocation.coordinates[1]),
    };
    const bounds = new window.google.maps.LatLngBounds(_center);
    locations &&
      locations.forEach((item) => {
        bounds.extend({
          lat: parseFloat(item.coordinates[0]),
          lng: parseFloat(item.coordinates[1]),
        });
      });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (centerLocation && centerLocation.coordinates) {
      const _lat = parseFloat(centerLocation.coordinates[0]);
      const _lng = parseFloat(centerLocation.coordinates[1]);
      setCenter({ lat: _lat, lng: _lng });
    }
  }, [centerLocation]);

  function handleDcClick(_selected) {
    setDcOpen(true);
    setSelectedItem(_selected);
  }

  let paths = formatPaths(locations);
  let stops = formatStops(locations);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: "calc(100vh - 175px)",
            display: "block",
            flexDirection: "column",
            width: "100%",
          }}
          zoom={6}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{ streetViewControl: false, zoomControl: true }}
        >
          <Polyline
            path={paths}
            options={{
              strokeColor: "#0088FF",
              strokeWeight: 6,
              strokeOpacity: 0.6,
              defaultVisible: true,
            }}
          />
          {stops.data &&
            stops.data.map((stop, idx) => (
              <RenderMarker
                key={`id-${idx}`}
                lat={stop.lat}
                lng={stop.lng}
                driver={{}}
                index={idx}
                item={stop}
                formatDate={props.formatDate}
                formatTime={props.formatTime}
                onMarkerSelect={() => {}}
              />
            ))}
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
}
export default ReactMap;
