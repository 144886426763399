export const LABELS = {
  profileInfo: "ਪ੍ਰੋਫਾਈਲ ਜਾਣਕਾਰੀ",
  accountInfo: "ਖਾਤਾ ਜਾਣਕਾਰੀ",
  name: "ਨਾਮ",
  email: "ਈ - ਮੇਲ",
  status: "ਸਥਿਤੀ",
  language: "ਭਾਸ਼ਾe",
  uuid: "UUID",
  roles: "ਭੂਮਿਕਾਵਾਂ",
  account: "ਖਾਤਾ",
  accountId: "ਖਾਤਾ ID",
  save: "ਸੇਵ ਕਰੋ",
  edit: "ਸੰਪਾਦਿਤ ਕਰੋ",
  phone: "ਫ਼ੋਨ",
  carrier: "ਕੈਰੀਅਰ",
  terminal: "ਅਖੀਰੀ ਸਟੇਸ਼ਨ",
  required: "ਲੋੜੀਂਦਾ ਹੈ",
};
