import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDate } from "../../../actions/momentActions";
import { getDriverById } from "../../../actions/driverActions";
import { updateUser } from "../../../actions/userActions";
import { getEquipments } from "../../../actions/equipmentActions";
import VehicleAssignment from "./Forms/VehicleAssignment";

const styles = theme => ({
    addButtonWrapper: {
        width: "99%",
        textAlign: "right",
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100px",
        textAlign: "left"
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    },
    paper: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        // textAlign: "center",
        color: theme.palette.text.secondary
    },
    textCenter: {
        textAlign: "center"
    },
    wrapper: {
        fontSize: 14,
        color: "black",
        fontWeight: 300
    },
    label: {
        minWidth: "100px",
        textAlign: "left",
        display: "inline-block"
    }
});

const useStyles = makeStyles(styles);

const RenderItem = props => {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12}>
            <span className={classes.label}>{props.label}</span>
            {props.value}
        </Grid>
    );
};

const RenderProfile = props => {
    const user = props.user || {};
    const driver = user.driver || {};
    const carrier = user.carrier || {};
    const terminal = user.terminal || {};

    return (
        <Grid container spacing={2} style={{ padding: 10 }}>
            <RenderItem
                label={"Name:"}
                value={`${user.firstName} ${user.lastName}`}
            />
            <RenderItem label={"Email:"} value={`${user.email}`} />
            <RenderItem label={"Mobile:"} value={`${user.mobile}`} />
            <RenderItem
                label={"License:"}
                value={`${driver.licenseState} ${driver.licenseNumber}`}
            />
            <RenderItem label={"Carrier:"} value={`${carrier.name}`} />
            <RenderItem label={"Terminal:"} value={`${terminal.name}`} />
        </Grid>
    );
};

export class User extends React.Component {
    state = {
        user: {},
        equipments: [],
        searching: false
    };

    async componentDidMount() {
        await this.getDriverById();
        await this.getEquipments();
    }

    getDriverById = async () => {
        this.setState({ searching: true });

        const driverId = _get(this, "props.match.params.driverId");
        const user = await this.props.getDriverById({
            driverId,
            include: ["equipments"]
        });
        this.setState({ searching: false, user });
    };

    handleOpen = () => { };

    handleClose = async () => { };

    getEquipments = async () => {
        const equipments = await this.props.getEquipments({
            skip: 0,
            limit: 250,
            assetType: "VEHICLE"
        });
        this.setState({ equipments });
    };

    handleChange = ({ limit, page, order }) => {
        // this.setState({ limit, page, order, query: "" }, this.getUsers);
    };

    onSearch = query => {
        // const { page, limit, order } = this.state;
        // this.setState({ limit, page, order, query }, this.getUsers);
    };

    render() {
        const { classes } = this.props;
        const { searching, user } = this.state;

        if (!user || !user.userId) {
            return null;
        }

        const _equipments = this.state.equipments || [];
        const selIds = (user.driver && user.driver.equipments) || [];

        let selectedEqp = [];

        if (_equipments && _equipments.length > 0) {
            selectedEqp =
                selIds.map(item =>
                    _equipments.find(_item => _item.id === item)
                ) || [];
        } else {
            return null;
        }

        return (
            <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={2} style={{ padding: 10 }}>
                    <Grid item xs={12} sm={12} className={classes.textCenter}>
                        {searching && <CircularProgress />}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RenderProfile user={user} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <VehicleAssignment
                            equipments={this.state.equipments}
                            selected={selectedEqp}
                            driver={user.driver}
                            onChange={params => {
                                console.log("params :: ", params);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                        <Button variant="contained" color="primary">
                            SAVE
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        userType: "ALL",
        count: _get(state, "userData.count", 0),
        users: _get(state, "userData.users", []),
        userBusy: _get(state, "userData.userBusy", false)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateUser,
            formatDate,
            getDriverById,
            getEquipments
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(User)
);
