import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { updateLanguage } from "../../../actions/auth/authActions";
import Grid from "@mui/material/Grid";
import Error from "../../Error";
import Success from "../../Success";
import { Formik, Form } from "formik";
import SelectBox from "../../../components/SelectBox";
import { LABELS, LANGS, selectedLang, updateLang } from "../../../language";

const ALL_LABELS = LABELS.messages;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class LanguageDialog extends React.Component {
  state = {
    email: "",
    successMessage: "",
    errorMessage: "",
  };

  componentDidMount() {}

  saveRequest = async (params) => {
    this.setState({ loading: true });
    const response = await this.props.updateLanguage(params);

    if (response._id) {
      this.setState({
        loading: false,
        successMessage: ALL_LABELS.saveMessage,
      });
      setTimeout(() => {
        this.props.onClose();
        updateLang(params.language);
      }, 1000);
    } else {
      this.setState({
        loading: false,
        errorMessage: ALL_LABELS.errorMessage,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { errorMessage, successMessage, loading } = this.state;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h2 style={{ textAlign: "center", padding: 0 }}>{`${ALL_LABELS.selectLanguage}`}</h2>
        <DialogTitle id="alert-dialog-title" onClose={this.props.onClose}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Formik
            initialValues={{ language: selectedLang }}
            validate={() => {}}
            onSubmit={this.saveRequest}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <SelectBox
                      md={12}
                      name="language"
                      label={ALL_LABELS.language}
                      items={LANGS}
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Error top={20} message={errorMessage} />
                    <Success top={20} message={successMessage} />
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      marginTop: 45,
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      style={{
                        minWidth: 250,
                      }}
                      size="small"
                    >
                      {loading ? `${ALL_LABELS.saving}` : `${ALL_LABELS.save}`}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      marginTop: 15,
                      textAlign: "center",
                    }}
                  >
                    <Button onClick={this.props.onClose}>{`${ALL_LABELS.close}`}</Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLanguage,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageDialog))
);
