import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { updateUserAccount } from "../../actions/account/accountAdminActions";
import Grid from "@mui/material/Grid";
import Error from "../Error";
import Success from "../Success";
import { Formik, Form } from "formik";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getAllowedScopes } from "./helpers";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function RenderCheckBox(props) {
  const [checked, setChecked] = useState(props.checked);

  function handleChange(event) {
    const _checked = event.target.checked;
    setChecked(_checked);
    props.handleChange(_checked, props.data);
  }

  let label = props.name;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name="checkedA"
          color="primary"
        />
      }
      label={label}
    />
  );
}

function RenderItem(props) {
  const scopes = props.scopes || [];
  const items = props.items || {};

  let view = items.find((item) => item.name.includes("VIEW_"));
  let add = items.find((item) => item.name.includes("ADD_"));
  let edit = items.find((item) => item.name.includes("EDIT_"));
  let admin = items.find((item) => item.name.includes("ADMIN_"));

  let viewChecked = view ? scopes.includes(view._id) : false;
  let addChecked = add ? scopes.includes(add._id) : false;
  let editChecked = edit ? scopes.includes(edit._id) : false;
  let adminChecked = admin ? scopes.includes(admin._id) : false;

  if (editChecked) {
    viewChecked = true;
    addChecked = true;
  }
  if (addChecked) {
    viewChecked = true;
  }

  return (
    <React.Fragment>
      {admin && (
        <RenderCheckBox
          name={"Admin"}
          data={admin}
          checked={adminChecked}
          handleChange={props.handleChange}
        />
      )}

      {edit && (
        <RenderCheckBox
          name={"Edit"}
          data={edit}
          checked={editChecked}
          handleChange={props.handleChange}
        />
      )}
      {add && (
        <RenderCheckBox
          name={"Add"}
          data={add}
          checked={addChecked}
          handleChange={props.handleChange}
        />
      )}
      {view && (
        <RenderCheckBox
          name={"View"}
          data={view}
          checked={viewChecked}
          handleChange={props.handleChange}
        />
      )}
    </React.Fragment>
  );
}

function RenderListItem(props) {
  const data = props.data || {};
  const items = data.data || [];
  // const selected = props.scopes || {};
  const _scopes = props.scopes || [];
  const scopes = _scopes.map((item) => item._id);

  let label = data.name;
  if (label === "SP") {
    label = "SUPPORT PORTAL (ADMIN)";
  } else if (label === "DS") {
    label = "DUTY STATUS(ADMIN)";
  } else if (label === "RS") {
    label = "RE SELLER(ADMIN)";
  } else if (label === "PAYMENT") {
    label = "PAYMENT(ADMIN)";
  } else if (label === "RA") {
    label = "REASSIGN(ADMIN)";
  } else if (label === "DSMT") {
    label = "DUTY STATUS PRO(ADMIN)";
  }

  return (
    <Grid item xs={12} md={6}>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginTop: 15, fontWeight: 600 }}>
          {label}
        </Grid>

        <FormGroup row style={{ marginLeft: 25 }}>
          <RenderItem
            name={data.name}
            scopes={scopes}
            items={items}
            handleChange={props.handleChange}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export class RoleForm extends React.Component {
  constructor(props) {
    super();

    const scopes = _get(props, "selected.scopes", []);
    this.state = {
      email: "",
      successMessage: "",
      errorMessage: "",
      scopes,
    };
  }

  componentDidMount() {}

  updateScope = async (values) => {
    const user = this.props.selected || {};
    const scopes = this.state.scopes || [];

    this.setState({
      loading: true,
      errorMessage: "",
      successMessage: "",
    });

    const response = await this.props.updateUserAccount({
      id: user.id,
      scopes: scopes.map((item) => item._id),
    });

    if (response && response.uuid) {
      this.setState({
        successMessage: LABELS.saved_Successfully,
        loading: false,
      });

      setTimeout(() => this.props.handleClose(), 1000);
    } else {
      this.setState({
        loading: false,
        errorMessage: LABELS.errorMessage,
      });
    }
  };

  handleChange = (isCheck, params) => {
    const scopes = this.state.scopes || [];

    if (isCheck) {
      scopes.push(params);
      this.setState({ scopes });
    } else {
      const _scopes = scopes.filter((item) => item._id !== params._id);
      this.setState({ scopes: _scopes });
    }
  };

  render() {
    const allScopes = this.props.allScopes || [];
    // const isBluehorse = this.props.isBluehorse;
    // const isEldBooks = this.props.isEldBooks;
    const { errorMessage, successMessage, loading } = this.state;

    return (
      <Formik
        initialValues={{}}
        validate={() => {}}
        onSubmit={this.updateScope}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const adminScopes = ["SP", "DS", "RS", "PAYMENT", "RA", "DSMT"];

          return (
            <Form style={{ padding: "20px 10px" }}>
              <Grid container spacing={2}>
                {allScopes.map((item) => {
                  const isAdmin = !adminScopes.includes(item.name);

                  return isAdmin ? (
                    <RenderListItem
                      key={item.name}
                      data={item}
                      scopes={this.state.scopes}
                      handleChange={this.handleChange}
                    />
                  ) : null;
                })}
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 45,
                    textAlign: "center",
                  }}
                >
                  <h1>{LABELS.adminScope}</h1>
                </Grid>

                {allScopes.map((item) => {
                  const isAdmin = adminScopes.includes(item.name);

                  if (getAllowedScopes().includes(item.name)) {
                    return null;
                  }

                  return isAdmin ? (
                    <RenderListItem
                      key={item.name}
                      data={item}
                      scopes={this.state.scopes}
                      handleChange={this.handleChange}
                    />
                  ) : null;
                })}
              </Grid>
              <Grid container spacing={2}>
                <Error top={20} message={errorMessage} />
                <Success top={20} message={successMessage} />
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                style={{
                  marginTop: 45,
                  textAlign: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  style={{
                    minWidth: 250,
                  }}
                >
                  {loading ? LABELS.loading : LABELS.save}
                </Button>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  marginTop: 15,
                  textAlign: "center",
                }}
              >
                <Button onClick={this.props.handleClose}>{LABELS.close}</Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
    isBluehorse: _get(state, "appData.isBluehorse", false),
    isEldBooks: _get(state, "appData.isEldBooks", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateUserAccount }, dispatch);

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleForm))
);
