import { React, useState, useEffect } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { getRtDrivers } from "../../actions/adminDashActions";
import { formatRtDriver } from "./helpers";
import { formatDate } from "../../actions/momentActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import DriverViolations from "./DriverViolations";
import DriverTable from "./DriverTable";
import DriverRecap from "./DriverRecap";
import SearchBar from "./SearchBar";


const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Content(props) {
  // const { classes } = props;
  const [loading, setLoading] = useState(false);
  const [orgDrivers, setOrgDrivers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [drivers, setDrivers] = useState([]);

  async function _getRtDrivers() {
    try {
      setLoading(true);
      const accounts = await props.getAccounts({ limit: 1000 });
      const response = await props.getRtDrivers({});
      const _drivers = formatRtDriver(response, accounts);

      setAccounts(accounts);
      setDrivers(_drivers);
      setOrgDrivers(response);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    _getRtDrivers();
  }, []);

  function onSearch(params) {
    let drs = [...orgDrivers];

    if (params.companyId) {
      drs = drs.filter((item) => item.accountId === params.companyId);
    }
    if (params.dutyStatus) {
      drs = drs.filter((item) => item.eventCode === params.dutyStatus);
    }
    if (params.connection !== "") {
      drs = drs.filter((item) => item.connected === params.connection);
    }

    const drObj = formatRtDriver(drs, accounts);
    setDrivers(drObj);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <SearchBar companies={accounts} onSearch={onSearch} />
      </Grid>
      <Grid item xs={12} md={12}>
        <DriverRecap data={drivers} />
      </Grid>
      <Grid item xs={12} md={10}>
        <DriverTable data={drivers} formatDate={props.formatDate} />
      </Grid>
      <Grid item xs={12} md={2}>
        <DriverViolations data={drivers} />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: PropTypes.object.isRequired,
    adminScope: _get(state, "authData.profile.scopes"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRtDrivers,
      formatDate,
      getAccounts,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Content)
);
