import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { reduxForm } from "redux-form";
import { getCarriers } from "../../../actions/carrierActions";
import { getTerminals } from "../../../actions/terminalActions";
import { createUser, } from "../../../actions/adminDashActions";
import { getEquipments } from "../../../actions/equipmentActions";
import { getTrailers } from "../../../actions/trailerActions";
import validate from "./validate";
import Grid from '@mui/material/Grid';
import ReduxButton from "../../../components/ReduxButton";
import ReduxInput from "../../../components/ReduxInput";
import ReduxSelect from "../../../components/ReduxSelect";
// import ReduxCheckBox from "../../components/ReduxCheckBox";
import Error from "../../Error";
import Success from "../../Success";
import { ALL_OPTIONS, ALL_STATUS_OPTIONS } from "../../../constants";
import VehicleAssignment from "../../Setting/User/Forms/VehicleAssignment";
import { LABELS as _LABELS } from "../../../language";

const LABELS=_LABELS.settingPage;

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    DriverDetail: {
        marginTop: 20
    }
});

export class AddEquipmentForm extends React.Component {
    constructor(props) {
        super()
        this.state = {
            error: {},
            vehicles: [],
            trailers: [],
            accountId: _get(props, "match.params.accountId")
        };
    }
    async componentDidMount() {
        const { accountId } = this.state
        this.props.getCarriers({}, accountId);
        this.props.getTerminals({}, accountId);
        this.getEquipments(accountId);
        this.getTrailers(accountId);

        this.setState({ errorMessage: "", successMessage: "" });
        if (!this.props.isEdit) {
            this.props.change("carrierId", this.props.carrierId);
            this.props.change("terminalId", this.props.terminalId);
        }
    }

    getEquipments = async (accId) => {
        const eqps = await this.props.getEquipments({ skip: 0, limit: 250, active: true }, accId);
        if (eqps && Array.isArray(eqps)) {
            const vehicles = eqps.filter(item => item.assetType === "VEHICLE");
            // const trailers = eqps.filter(item => item.assetType === "TRAILER");
            this.setState({
                vehicles
                // , trailers
            });
        }
    };

    getTrailers = async (accId) => {
        const eqps = await this.props.getTrailers({ skip: 0, limit: 250, active: true }, accId);
        if (eqps && Array.isArray(eqps)) {
            // const vehicles = eqps.filter(item => item.assetType === "VEHICLE");
            // const trailers = eqps.filter(item => item.assetType === "TRAILER");
            this.setState({
                trailers: eqps
                // , trailers
            });
        }
    };

    save = async params => {
        this.setState({ errorMessage: "", successMessage: "" });

        let driver;
        const {
            status,
            alias,
            country,
            number,
            state,
            cargo,
            regulation,
            cycleUsa,
            cycleCa,
            // autoStatus,
            // fullAddress,
            allowEdit,
            tracking,
            cycleCountry,
            exemptAnnotation,
            exemptDriver,
            personal,
            yardMoves,
            startTime,
            carrierId,
            terminalId,
            email,
            password,
            firstName,
            lastName,
            userStatus,
            mobile,
            role,
            driverId,
            receiverEmail
        } = params;

        let payload = {
            terminalId,
            carrierId,
            email,
            password,
            firstName,
            lastName,
            status: userStatus,
            mobile,
            role
        };
        payload.accountId = this.state.accountId;

        if (params.role === "DRIVER") {
            const _driver = this.props.driver || {};
            const vehicleId = this.state.vehicleId || _driver.vehicleId;
            const trailerId = this.state.trailerId || _driver.trailerId;
            const equipments = this.state.equipments || _driver.equipments;

            if (!vehicleId || !equipments || equipments.length === 0) {
                this.setState({
                    error: {
                        vehicle: !vehicleId,
                        equipments: !equipments || equipments.length === 0
                    }
                });
                return;
            } else {
                this.setState({ error: {} });
            }

            driver = {
                licenseCountry: country,
                licenseNumber: number,
                licenseState: state,
                alias,
                status,
                cargo,
                regulation,
                cycleUsa,
                cycleCa,
                cycleCountry,
                exemptAnnotation,
                exemptDriver,
                personal,
                yardMoves,
                allowEdit,
                tracking,
                startTime,
                carrierId,
                vehicleId,
                trailerId,
                terminalId,
                receiverEmail,
                equipments
            };

            payload.driver = driver;
        }
        let response;

        this.setState({ saving: true });
        if (this.props.isEdit) {
            let editPayload = {
                email,
                firstName,
                lastName,
                mobile,
                status: userStatus,
                userId: params.id
            };

            if (params.role === "DRIVER") {
                editPayload.driver = driver;
                editPayload.driver.driverId = driverId;
            }
            // if (password) {
            //     editPayload.password = password;
            // }

            // response = await this.props.updateUser(editPayload);
        } else {
            response = await this.props.createUser(payload);
        }
        this.setState({ saving: false });

        if (response && response.uuid) {
            this.setState({ successMessage: LABELS.successMessage_user});
            setTimeout(() => {
                this.props.handleSave();
            }, 1000);
        } else if (response && response.status === 400) {
            this.setState({ errorMessage: response.message });
        }
        else if (response && response.message) {
            this.setState({ errorMessage: response.message });
        } else {
            if (typeof response !== String) {
                response = LABELS.response;
            }
            this.setState({ errorMessage: response });
        }
    };

    onRoleChange = e => {
        const userRole = _get(e, "currentTarget.value");

        if (userRole === "DRIVER") {
            this.props.change("country", "US");
            this.props.change("cargo", "PROPERTY");
            this.props.change("regulation", "ELD");
            this.props.change("cycleUsa", "US70hr8days");
            this.props.change("cycleCa", "Can70hr7daysSouth");
            this.props.change("cycleCountry", "US");
            this.props.change("startTime", "12 AM");
            this.props.change("status", "ACTIVE");
            // this.props.change("autoStatus", "ON");
            // this.props.change("fullAddress", "OFF");
            this.props.change("allowEdit", "OFF");
            this.props.change("tracking", "ON");
            this.props.change("personal", "OFF");
            this.props.change("yardMoves", "OFF");
            this.props.change("userStatus", "ACTIVE");
            this.props.change("exemptAnnotation", "");
            this.props.change("exemptDriver", "OFF");
        }
    };

    render() {
        const { classes, handleSubmit, constants, userValues } = this.props;
        const { isEdit } = this.props;
        const { carriers, terminals } = this.props;
        const { saving, vehicles, trailers, error } = this.state;
        const { errorMessage, successMessage } = this.state;


        const isSmallScreen = window.innerWidth < 400;
        const isDriver = userValues.role === "DRIVER";
        const pwdHelp = isEdit
            ? LABELS.Note_only_requiredforSetNewPassword
            : "";

        const selDriver = isEdit ? _get(this, "props.selected.driver", {}) : {};
        const selIds = isEdit
            ? _get(this, "props.selected.driver.equipments", [])
            : [];
        let selectedEqp = [];

        if (vehicles && vehicles.length > 0) {
            selectedEqp =
                selIds.map(item => vehicles.find(_item => _item.id === item)) ||
                [];
        } else {
            // return null;
        }

        userValues.country = "US"
        // userValues.status = "ACTIVE"
        // userValues.role = "MANAGER"

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"lg"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.handleClose}
                >
                    <Typography>
                        <strong>{isEdit ? LABELS.editUser :LABELS.addUsers}</strong>
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={isDriver ? 3 : 4}>
                                <ReduxSelect
                                    name="role"
                                    label="Role"
                                    disabled={isEdit}
                                    onChange={this.onRoleChange}
                                    items={constants.USER_ROLES}
                                />

                            </Grid>
                            {isDriver && (
                                <Grid item xs={12} sm={12} md={3}>
                                    <ReduxInput
                                        name="alias"
                                        label={LABELS.driverId}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={isDriver ? 3 : 4}>
                                <ReduxInput
                                    name="firstName"
                                    label={LABELS.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={isDriver ? 3 : 4}>
                                <ReduxInput name="lastName" label={LABELS.lastName}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <ReduxSelect
                                    name="userStatus"
                                    label={LABELS.status}
                                    disabled={!isEdit}
                                    items={ALL_STATUS_OPTIONS}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <ReduxInput name="mobile" label={LABELS.mobile}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <ReduxInput name="email" label={LABELS.email} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <ReduxInput
                                    name="password"
                                    label={LABELS.password}
                                    helpText={pwdHelp}
                                />
                            </Grid>

                            {isDriver && (
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        className={classes.DriverDetail}
                                    >
                                        <strong>{LABELS.driver_details}</strong>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="country"
                                            label={LABELS.licCountry}
                                            items={constants.COUNTRIES}
                                        />
                                    </Grid>
                                    {userValues.country === "CA" ? (
                                        <Grid item xs={12} sm={12} md={3}>
                                            <ReduxSelect
                                                name="state"
                                                label={LABELS.state}
                                                items={constants.CA_STATES}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sm={12} md={3}>
                                            <ReduxSelect
                                                name="state"
                                                label={LABELS.state}
                                                items={constants.US_STATES}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxInput
                                            name="number"
                                            label={LABELS.licNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="cargo"
                                            label={LABELS.cargo}
                                            items={constants.DRIVER_CARGO}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            disabled={true}
                                            name="regulation"
                                            label={LABELS.regulationMode}
                                            items={constants.REGULATION}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="cycleUsa"
                                            label={LABELS.cycleUsa}
                                            items={constants.DRIVER_CYCLE_USA}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="cycleCa"
                                            label={LABELS.cycleCA}
                                            disabled={true}
                                            items={constants.DRIVER_CYCLE_CA}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="startTime"
                                            label={LABELS.startTime}
                                            keyName={"name"}
                                            valueName={"name"}
                                            items={constants.DRIVER_START_TIME}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="cycleCountry"
                                            label={LABELS.country}
                                            items={constants.COUNTRIES}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="terminalId"
                                            keyName={"id"}
                                            valueName={"name"}
                                            label={LABELS.terminal}
                                            items={terminals}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="carrierId"
                                            keyName={"id"}
                                            valueName={"name"}
                                            label={LABELS.carrier}
                                            items={carriers}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="status"
                                            label={LABELS.status}
                                            items={ALL_STATUS_OPTIONS}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <ReduxInput
                                            name="receiverEmail"
                                            label={LABELS.receiverMail}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <ReduxSelect
                                            name="exemptDriver"
                                            label={LABELS.exemptDriver}
                                            items={ALL_OPTIONS}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <ReduxInput
                                            name="exemptAnnotation"
                                            label={LABELS.exemptAnnotation}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={2}>
                                        <ReduxSelect
                                            name="personal"
                                            label={LABELS.personal}
                                            items={ALL_OPTIONS}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <ReduxSelect
                                            name="yardMoves"
                                            label={LABELS.yardMoves}
                                            items={ALL_OPTIONS}
                                        />
                                    </Grid>

                                    {/*<Grid item xs={12} sm={12} md={3}>
                                        <ReduxSelect
                                            name="autoStatus"
                                            label="Auto Status"
                                            items={ALL_OPTIONS}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <ReduxSelect
                                            name="fullAddress"
                                            label="Full Address"
                                            items={ALL_OPTIONS}
                                        />
                                    </Grid>*/}
                                    <Grid item xs={12} sm={12} md={2}>
                                        <ReduxSelect
                                            name="allowEdit"
                                            label={LABELS.allowEdit}
                                            items={ALL_OPTIONS}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <ReduxSelect
                                            name="tracking"
                                            label={LABELS.tracking}
                                            items={ALL_OPTIONS}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <VehicleAssignment
                                            error={error}
                                            equipments={vehicles}
                                            selected={selectedEqp}
                                            trailers={trailers}
                                            driver={selDriver}
                                            onChange={params =>
                                                this.setState(params)
                                            }
                                        />
                                    </Grid>

                                </React.Fragment>
                            )}

                            <Error top={20} message={errorMessage} />
                            <Success top={20} message={successMessage} />
                        </Grid>
                        <DialogActions className={classes.DialogActions}>
                            <ReduxButton
                                width
                                disabled={saving || !(!successMessage)}
                                onClick={handleSubmit(this.save)}
                                marginTop={25}
                                label={saving ? LABELS.saving : LABELS.save}
                            />
                            <Button
                                onClick={this.props.handleClose}
                                color="primary"
                                autoFocus
                            >
                                {LABELS.cancel}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        constants: _get(state, "appData.constants", {}),
        userValues: _get(state, "form.userForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        terminals: _get(state, "terminalData.terminals", []),
        // vehicles: _get(state, "equipmentData.equipments", []),
        // trailers: _get(state, "equipmentData.trailers", []),
        errorMessage: _get(state, "authData.errorMessage", ""),
        carrierId: _get(state, "authData.profile.carrier.id"),
        terminalId: _get(state, "authData.profile.terminal.id")
    };
};

AddEquipmentForm = reduxForm({
    form: "userForm",
    validate
})(AddEquipmentForm);

AddEquipmentForm = connect(state => {
    const user = _get(state, "userData.selectedUser", {});
    const driver = user.driver || {};
    return {
        driver,
        initialValues: {
            isEdit: !!user.uuid,
            status: driver.status,
            country: driver.licenseCountry,
            number: driver.licenseNumber,
            state: driver.licenseState,
            alias: driver.alias,
            cargo: driver.cargo,
            regulation: driver.regulation || "ELD",
            cycleUsa: driver.cycleUsa,
            cycleCa: driver.cycleCa,

            // autoStatus: driver.autoStatus,
            // fullAddress: driver.fullAddress,
            allowEdit: driver.allowEdit,
            tracking: driver.tracking,
            personal: driver.personal,
            yardMoves: driver.yardMoves,

            cycleCountry: driver.cycleCountry,
            exemptAnnotation: driver.exemptAnnotation,
            exemptDriver: driver.exemptDriver,
            startTime: driver.startTime,
            carrierId: driver.carrierId,
            vehicleId: driver.vehicleId,
            trailerId: driver.trailerId,
            terminalId: driver.terminalId,
            driverId: driver.driverId,
            receiverEmail: driver.receiverEmail,

            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            role: user.role,
            userStatus: user.status,
            id: user.id
        }
    };
})(AddEquipmentForm);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCarriers,
            getEquipments,
            getTrailers,
            getTerminals,
            createUser,
            //
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddEquipmentForm)
    )
);
