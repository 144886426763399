import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import EditIcon from "@mui/icons-material/Edit";
// import SmsIcon from "@mui/icons-material/Sms";
import IconButton from '@mui/material/IconButton';
import CopyIcon from "@mui/icons-material/FileCopy";
import NotFoundTableRow from "../../NotFoundTableRow";
import { sorting } from "../../../utils/sortingHelpers";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableRowTitle: {
        display: "inline-block",
        width: 50
    },
    tableRow: {
        height: 30
    },
    tableCell: {
        // fontSize: 10
    },
    capitalize: {
        textTransform: "capitalize"
    },
    dl: {
        fontSize: 10,
        fontWeight: 500
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    access: {
        maxWidth: 150
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;

        return (
            <TableRow hover className={classes.tableRow}>
                <TableCell
                    className={`${classes.tableCell} ${classes.capitalize}`}
                >
                    {/* <Button
                        color="primary"
                    // disabled={isInfo}
                    // onClick={() => this.props.viewDetails(driver)}
                    >
                    </Button> */}
                    {item.name}

                </TableCell>

                <TableCell className={classes.tableCell}>
                    {item.active ? "ACTIVE" : "INACTIVE"}
                </TableCell>


                <TableCell className={`${classes.tableCell} ${classes.access}`}>
                    <div>
                        {item.scopes}
                    </div>
                </TableCell>

                {/* <TableCell className={classes.tableCell}>{this.props.formatDateTime(item.expiry)}</TableCell> */}

                <TableCell className={classes.tableCell}>
                    <div>{`${item.apiKey}`}
                        <IconButton
                            aria-label="copy"
                            onClick={e => this.props.copyToClip(e, item.apiKey)}
                        >
                            <CopyIcon color="primary" />
                        </IconButton></div>
                </TableCell>

                <TableCell className={classes.tableCell}>
                    {/* <SmsIcon
                        className={classes.EditIcon}
                        onClick={() => this.props.handleSms(item)}
                    />{" "} */}
                    <EditIcon
                        className={classes.EditIcon}
                        onClick={() => this.props.handleEdit(item)}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };



    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ limit, page });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ limit, page });
    };

    handleSort = field => {
        const { page, limit } = this.props.pagination;
        const order = this.state.order === "desc" ? "asc" : "desc";

        this.setState({ order });
        this.props.handleChange({ page, limit, order: `${field} ${order}` });
    };

    copyToClip = (e, url) => {
        let textField = document.createElement("textarea");
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };

    render() {
        const { classes, items = [] } = this.props;
        const { page, limit } = this.props;
        const itemArray = sorting(items, "name");

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{LABELS.name}</TableCell>
                            <TableCell>{LABELS.status}</TableCell>
                            <TableCell>{LABELS.access}</TableCell>
                            {/* <TableCell>Expiry</TableCell> */}
                            <TableCell>{LABELS.apiKey}</TableCell>


                            {/*<TableCell>Created/Edited</TableCell>*/}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(itemArray) &&
                            itemArray.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    handleSms={this.props.handleSms}
                                    handleEdit={this.props.handleEdit}
                                    formatDateTime={this.props.formatDateTime}
                                    copyToClip={this.copyToClip}
                                // viewDetails={this.props.viewDetails}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={itemArray.length}
                    // disabled={itemArray.length !== 0 && itemArray.length < limit}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    labelRowsPerPage={LABELS.rowsperpage}
                />
            </Paper>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);
