
export const LABELS={
    addAccount:"AJOUTER UN COMPTE",
  

    name:"Nom",
    status:"Statut",
    ein:"Ein",
    type:"Taper",
    email:"E-mail",
    phone:"Téléphone",
    role:"Rôle",
    billing_name:"Nom de facturation",
    billing_email:"E-mail de facturation",
    billing_phone:"Téléphone de facturation",
    billing_role:"Rôle de facturation",
    stripe:"Bande",
    note:"Note",
    detail:"Détail",
    contact:"Contact",
    billing:"Facturation",
    loging_users:"Connexion des utilisateurs",

    saving:"Économie...",
    save:"SAUVEGARDER",
    close:"FERMER",


    cleanDriver:"Voulez-vous nettoyer le pilote de la liste",
    cleanNow:"NETTOIE MAINTENANT",
    cleaning:"Nettoyage...",



    // <-------for Support user----->

    addSupportUser:"Ajouter un utilisateur d'assistance",
  note: "Remarque : - Ceci concerne uniquement l'ajout d'un nouvel utilisateur de support. L'utilisateur du support aura accès à tous les détails de votre compte et de votre opérateur.",
  firstName:"Prénom",
  lastName:"Nom de famille",
  mobile:"Mobile",
  password:"Mot de passe",
  reseller:"Revendeur",
  superAdmin:"Super administrateur",




 

}