const defaultState = {
    locationBusy: false,
    locations: [],
    count: 0,
    pagination: {
        page: 0,
        limit: 10
    }
};

export default function carrier(state = defaultState, action) {
    switch (action.type) {
        case "LOCATIONS":
            return {
                ...state,
                locations: action.value
            };
        case "LOCATIONS_COUNT":
            return {
                ...state,
                count: action.value
            };
        case "LOCATION_BUSY":
            return {
                ...state,
                locationBusy: action.value
            };
        case "LOCATION_PAGINATION":
            return {
                ...state,
                pagination: action.value
            };
        default:
            return state;
    }
}
