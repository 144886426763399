import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./notificationsActionTypes";

export const setNotifications = value => ({
    type: TYPES.NOTIFICATIONS,
    value
});

export const setNotificationCount = value => ({
    type: TYPES.NOTIFICATIONS_COUNT,
    value
});

export const notificationBusy = value => ({
    type: TYPES.NOTIFICATIONS_BUSY,
    value
});

export const setNotificationsSetup = value => ({
    type: TYPES.NOTIFICATIONS_SETUP,
    value
});

export const setNotificationsSetupCount = value => ({
    type: TYPES.NOTIFICATIONS_SETUP_COUNT,
    value
});

export function getNotificationCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/notifications/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setNotificationCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getNotifications(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(notificationBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/notifications`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.notifications", []);
                dispatch(setNotifications(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(notificationBusy(false));
                return response;
            });
    };
}

export function sendNotification(data) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/notifications`,
            method: "post",
            data
        })
            .then(response => {
                const _response = _get(response, "data");
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            });
    };
}

export function getNotificationsSetup(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.REALTIME_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/api/v1/manager/${accountId}/notifications/setup`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", []);
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function createNotificationsSetup(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.REALTIME_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/api/v1/manager/${accountId}/notifications/setup`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function updateNotificationsSetup(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.REALTIME_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/api/v1/manager/${accountId}/notifications/setup`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}
