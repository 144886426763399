import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  addDay,
  subtractDay,
  formatRawDate,
  isToday,
} from "../../actions/momentActions";
import DatePicker, { CalendarContainer } from "react-datepicker";
import Button from "@mui/material/Button";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom-styles.css";
// const isSmartelds = process.env.REACT_APP_CLIENT_APP === "Smartelds";
// const isUnity = process.env.REACT_APP_CLIENT_APP === "Unity";
// const isEldBooks = process.env.REACT_APP_CLIENT_APP === "Eldbooks";

const styles = (theme) => ({
  dateInput: {
    backgroundColor: "transparent",
    border: 0,
    fontSize: 16,
    width: 100,
    textAlign: "center",
    color: "white",
    fontWeight: 600,
  },
});

export class DateWithButton extends React.Component {
  state = {
    selectedDate: new Date(),
  };
  constructor(props) {
    super(props);

    const selectedDate = props.selectedDate
      ? new Date(props.selectedDate)
      : new Date();
    this.state = {
      selectedDate,
    };
  }

  componentDidMount() {}

  handleChange = (selectedDate) => {
    this.setState({ selectedDate });
    this.props.onChange(selectedDate);
  };

  handlePrevDate = () => {
    const date = new Date(this.state.selectedDate);
    const _selectedDate = this.props.subtractDay(
      this.props.formatRawDate(date)
    );
    const selectedDate = new Date(_selectedDate);
    this.setState({ selectedDate });
    this.props.onChange(selectedDate);
  };

  handleNextDate = () => {
    const date = new Date(this.state.selectedDate);
    const _selectedDate = this.props.addDay(this.props.formatRawDate(date));
    const selectedDate = new Date(_selectedDate);
    this.setState({ selectedDate });
    this.props.onChange(selectedDate);
  };

  render() {
    const {
      classes,
      disabled,
      // prevDisabled = false,
      prevDays,
      isSmall = false,
    } = this.props;
    const { selectedDate } = this.state;
    const isNextDisabled = disabled || this.props.isToday(selectedDate);
    // const isPrevDisabled = disabled || prevDisabled;

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
      <IconButton
        onClick={onClick}
        ref={ref}
        variant="contained"
        aria-label="Previous"
        color="primary"
      >
        <CalendarMonthIcon />
      </IconButton>
    ));

    const MyContainer = ({ className, children }) => {
      return (
        <div style={{ marginLeft: 120 }}>
          <CalendarContainer className={className}>
            <div>{children}</div>
          </CalendarContainer>
        </div>
      );
    };

    return isSmall ? (
      <div>
        <IconButton
          onClick={this.handlePrevDate}
          variant="contained"
          aria-label="Previous"
          color="primary"
        >
          <NavigateBefore />
        </IconButton>
        <DatePicker
          selected={selectedDate}
          onChange={this.handleChange}
          maxDate={new Date()}
          minDate={prevDays}
          disabled={disabled}
          customInput={<CustomInput />}
          calendarContainer={MyContainer}
        />
        <IconButton
          onClick={this.handleNextDate}
          aria-label="Next"
          color="primary"
          disabled={isNextDisabled }
        >
          
          <NavigateNext />
        </IconButton>
      </div>
    ) : (
      <Button component="label" variant="contained" disabled={disabled}>
        <NavigateBefore
          onClick={this.handlePrevDate}
          style={{ color: "white" }}
        />
        <div>
          <DatePicker
            className={classes.dateInput}
            selected={selectedDate}
            onChange={this.handleChange}
            maxDate={new Date()}
            minDate={prevDays}
            disabled={disabled}
            calendarContainer={MyContainer}
          />
        </div>
        <NavigateNext
          onClick={this.handleNextDate}
          style={{ color: "white" }}
          disabled={isNextDisabled}
        />
      </Button>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ formatRawDate, addDay, subtractDay, isToday }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DateWithButton)
);
