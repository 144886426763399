import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./actions/reducers";
import thunk from "redux-thunk";
import queryString from "query-string";
import loggerMiddleware from "./middlewares/loggerMiddleware";
// import logger from 'redux-logger'
import { getProfile } from "./actions/auth/authActions";
import * as constants from "./constants";
import { getConfig } from "./actions/app/appActions";
import _package from "../package.json";
import { selApp, andriodUrl, iosUrl } from "./clientConfig";
import {
  isSmartelds,
  isUnity,
  isEldBooks,
  isBluehorse,
  isVulcansols,
  isRadical,
  isApex,
  isRoadwise,
} from "./clientConfig";
const moment = require("moment-timezone");

moment.createFromInputFallback = function (config) {
  config._d = new Date(config._i);
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let token;
try {
  const values = queryString.parse(window.location.search);
  token = values.token;
} catch (e) {}

let appVersion = 1;
try {
  const _versionFix = process.env.REACT_APP_APP_VERSION || 0;
  const versionFix = parseInt(_versionFix) || 0;
  const versionPackage = parseFloat(_package.version) || 0;
  const _appVersion = versionPackage - versionFix;
  appVersion = Math.round(_appVersion * 100) / 100;
} catch (e) {}

const isQa = process.env.REACT_APP_IS_QA === "true";
const initialState = {
  appData: {
    preCheck: false,
    timezone: moment.tz.guess(),
    defaultTimezone: moment.tz.guess(),
    BASE_URL: process.env.REACT_APP_CORE_API,
    STATUS_BASE_URL: process.env.REACT_APP_STATUS_BASE_URL,
    REALTIME_URL: process.env.REACT_APP_REALTIME_BASE_URL,
    LIVE_SHARE_URL: process.env.REACT_APP_LIVE_SHARE_URL,
    CO_URL: process.env.REACT_APP_CO_URL,
    OPEN_URL: process.env.REACT_APP_OPEN_BASE_URL,
    WA_URL: process.env.REACT_APP_WA_BASE_URL,
    S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
    constants,
    isQa,
    IFTA_KEY: process.env.REACT_APP_IFTA_API_KEY,
    clientApp: process.env.REACT_APP_CLIENT_APP,
    isSmartelds: isSmartelds,
    isUnity: isUnity,
    isEldBooks: isEldBooks,
    isBluehorse: isBluehorse,
    isVulcansols: isVulcansols,
    isRadical: isRadical,
    isApex: isApex,
    isRoadwise: isRoadwise,
    selApp: selApp,
    andriodAppUrl: andriodUrl,
    iosAppUrl: iosUrl,
    appVersion: appVersion,
  },
};

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk, loggerMiddleware))
);

if (!token) {
  store.dispatch(getConfig());
  store.dispatch(getProfile());
}

export {
  isSmartelds,
  isUnity,
  isEldBooks,
  isBluehorse,
  isVulcansols,
  isRadical,
  selApp,
  isApex,
  isRoadwise,
};
export default store;
