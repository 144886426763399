import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";
import Divider from '@mui/material/Divider';
import classNames from "classnames";
import ListItem from '@mui/material/ListItem';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

class MainNavigatorLinks extends React.Component {
  onNavClick = (url) => {
    this.props.history.push(url);
    this.props.onClose();
  };

  render() {
    const { isSmartelds, isBluehorse, isUnity, isEldBooks, isVulcansols, isApex } = this.props;
    const { show, index } = this.props;
    const {
      appRoutes = [],
      classes,
      isManager,
      isPartner,
      isManagerSor,
      isDriver,
      isAdmin,
      isSuperAdmin,
      isAdminSup,
      isAdminRes,
      settings,
    } = this.props;
    const currentRoute = _get(this, "props.location.pathname");
    const driverId = _get(this, "props.driverId");
    const accountId = _get(this, "props.accountId");

    let navClassName = "eldbooks-nav-btn";
    let itemStyle = {
      minHeight: 36,
      marginLeft: 5,
      paddingTop: 6,
      paddingBottom: 10
    };

    if (isBluehorse) {
      itemStyle = {
        minHeight: 36,
        border: "1px solid grey",
        margin: 0,
        borderRadius: 0,
      };
      navClassName = "bluehorse-eld-nav-btn";
    }
    if (isUnity) {
      navClassName = "unity-eld-nav-btn";
    }
    if (isEldBooks) {
      navClassName = "eldbooks-eld-nav-btn";
    }

    return (
      <div key={index}>
        {appRoutes.map(({ id, children, url }) => {
          if (accountId) {
            url = `${url}/${accountId}`;
          }
          const _children = children.filter((_child) => {
            if (isManagerSor) {
              if (_child.manager) {
                return true;
              }
            } else if (isSuperAdmin) {
              if (_child.adminSupportPlan) {
                return isSmartelds || isBluehorse || isEldBooks  || isVulcansols;
              }
              if (_child.superAdmin) {
                if (isSuperAdmin) {
                  if (_child.adminSmEld || _child.adminEbEld || _child.adminBhEld) {
                    return (isSmartelds || isBluehorse || isEldBooks) && isSuperAdmin;
                  } else {
                    return isSuperAdmin;
                  }
                }
              } else {
                return false;
              }
            } else if (isAdmin) {
              if (_child.adminSupportPlan) {
                return isSmartelds || isBluehorse || isEldBooks || isVulcansols;
              }
              if (_child.admin) {
                return isAdmin;
              } else {
                return false;
              }
            } else {
              if (_child.driver) {
                return isDriver;
              }
              if (_child.manager) {
                return isManager;
              }
              if (_child.partner) {
                return isPartner;
              }
              if (_child.adminSupport) {
                return isAdminSup;
              }
              if (_child.adminReseller) {
                return isAdminRes;
              }
            }
            return false;
          });

          return (
            <React.Fragment key={id}>
              {/* <ListItem className={classes.categoryHeader}> */}
              {/*<ListItemText classes={{ primary: classes.categoryHeaderPrimary, }}>{id}</ListItemText>*/}
              {/* </ListItem> */}
              {_children.map(
                ({
                  label: childLabel,
                  id: childId,
                  url: childUrl,
                  icon,
                  active,
                  admin,
                  adminSupport,
                  manager,
                  driver,
                }) => {
                  let urlFinal =
                    childUrl === "/overview"
                      ? "/dashboard/overview"
                      : childUrl === "/fmcsa"
                      ? "/dashboard/fmcsa"
                      : childUrl === "/accounts"
                      ? "/dashboard/accounts"
                      : childUrl === "/payments"
                      ? "/dashboard/payments"
                      : childUrl === "/admin"
                      ? "/dashboard/admin"
                      : childUrl === "/admin-audit"
                      ? "/dashboard/admin-audit"
                      : childUrl === "/admin-todo"
                      ? "/dashboard/admin-todo"
                      : childUrl === "/admin-users"
                      ? "/dashboard/admin-users"
                      : childUrl === "/admin-settings"
                      ? "/dashboard/admin-settings"
                      : childUrl === "/admin-reseller"
                      ? "/dashboard/admin-reseller"
                      : childUrl === "/leads"
                      ? "/dashboard/leads"
                      : childUrl === "/support"
                      ? "/dashboard/support"
                      : childUrl === "/whatsapp"
                      ? "/dashboard/whatsapp"
                      : childUrl === "/support/queue"
                      ? "/dashboard/support/queue"
                      : childUrl === "/shipping"
                      ? "/dashboard/shipping"
                      : `${url}${childUrl}`;

                  // driverId
                  urlFinal = urlFinal.replace(":driverId", driverId);

                  if (
                    childId === "EditLogs" &&
                    settings &&
                    !settings.editLogs
                  ) {
                    return null;
                  }
                  if (
                    childId === "Tracking" &&
                    settings &&
                    !settings.isTracking
                  ) {
                    return null;
                  }
                  if (
                    (childId === "StateMileage" ||
                      childId === "StateMileageBeta") &&
                    settings &&
                    !settings.isIfta
                  ) {
                    return null;
                  }
                  if (childId === "Document" && settings && !settings.isDoc) {
                    return null;
                  }
                  if (
                    childId === "Notifications" &&
                    settings &&
                    !settings.isNotif
                  ) {
                    return null;
                  }
                  if (
                    childId === "Liveshare" &&
                    settings &&
                    !settings.isShare
                  ) {
                    return null;
                  }

                  if (isBluehorse) {
                    childLabel = childLabel.toUpperCase();
                  }

                  return (
                    <ListItem
                      button
                      dense
                      key={childId}
                      className={classNames(
                        classes.item,
                        classes.itemActionable,
                        currentRoute === urlFinal && classes.itemActiveItem,
                        currentRoute === urlFinal && show && "activeItem"
                      )}
                      url={urlFinal}
                      onClick={() => this.onNavClick(urlFinal)}
                      style={itemStyle}
                    >
                      {!isBluehorse && (
                        <ListItemIcon
                          style={{
                            marginRight: 10,
                            fontSize: 24,
                            minWidth: "auto",
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      >
                        {show ? childLabel : ""}
                      </ListItemText>
                    </ListItem>
                  );
                }
              )}
              <Divider className={classes.divider} />
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

MainNavigatorLinks.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = (state) => ({
  isSuperAdmin: _get(state, "authData.isSuperAdmin"),
  isAdmin: _get(state, "authData.isAdmin"),
  isPartner: _get(state, "authData.isPartner"),
  isDriver: _get(state, "authData.isDriver"),
  isManager: _get(state, "authData.isManager"),
  isManagerSor: _get(state, "authData.isManagerSor"),
  isAdminSup: _get(state, "authData.isAdminSup"),
  isAdminRes: _get(state, "authData.isAdminRes"),
  driverId: _get(state, "authData.driverId"),
  accountId: _get(state, "authData.accountId"),
  settings: _get(state, "authData.profile.accountProperties.settings"),
  isSmartelds: _get(state, "appData.isSmartelds", false),
  isUnity: _get(state, "appData.isUnity", false),
  isEldBooks: _get(state, "appData.isEldBooks", false),
  isBluehorse: _get(state, "appData.isBluehorse", false),
  isVulcansols: _get(state, "appData.isVulcansols", false),
  isApex: _get(state, "appData.isApex", false),
});

export default withRouter(connect(mapStateToProps, {})(MainNavigatorLinks));
