export const LABELS = {
  viewLogs: "ਲੌਗ ਦੇਖੋ",
  date: "ਤਾਰੀਖ਼",
  detailsMulti: "ਬਹੁ",
  certified: "ਪ੍ਰਮਾਣਿਤ",
  uncertified: "ਗੈਰ-ਪ੍ਰਮਾਣਿਤ",
  detail: "ਵੇਰਵੇ",
  violation: "ਉਲੰਘਣਾ",
  driving: "ਗੱਡੀ ਚਲਾਉਣਾ",
  offDuty: "ਆਫਡਿਊਟੀ",
  onDutyND: "OnDutyND",
  sleeper: "ਸਲੀਪਰ",

  deleteLabel: "ਉਲੰਘਣਾਵਾਂ ਨੂੰ ਮਿਟਾਓ",
  deleteMsg: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਸਾਰੀਆਂ ਉਲੰਘਣਾਵਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",
};
