import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getDriverSignById } from "../../actions/driverActions";
import {
  formatDateTime,
  formatDate,
  formatTime,
} from "../../actions/momentActions";
import { pdfOptions } from "../../helpers/helpers";
import { LABELS as _LABELS } from "../../language";
import EditDvirDialog from "./EditDvirDialog";
import { findDvirById } from "../../actions/dvirActions";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  table: { border: "1px solid #ccc", marginBottom: 25 },
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class DvirDialog extends React.Component {
  state = {
    email: "",
    carrier: null,
    successMessage: "",
    errorMessage: "",
    loading: false,
  };

  componentDidMount() {
    this.loadSign();
    this.findDvirById();
  }

  findDvirById = async (e) => {
    const id = _get(this, "props.match.params.dvirId");
    const _id = _get(this, "props.selected._id");
    const user = _get(this, "props.profile.user");
    let params = { id: id || _id };
    if (user && user.driverId) {
      params.driverId = user.driverId;
    }
    try {
      const response = await this.props.findDvirById(params);
      if (response._id) {
        this.setState({ selected: response }, () => this.loadSign());
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  loadSign = async (e) => {
    const driverId = _get(this, "props.selected.driverId");
    try {
      const response = await this.props.getDriverSignById({ driverId });
      if (response.carrier) {
        const signature = _get(response, "sign.data");
        this.setState({ carrier: response.carrier, signature });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  createPdf = async () => {
    try {
      this.setState({ loading: true });
      const vehicleName = _get(this, "props.selected.vehicleName");
      const insType = _get(this, "props.selected.inspectionType");
      const _startTime = _get(this, "props.selected.startTime");
      const startTime = this.props.formatDateTime(_startTime);
      const fileName = `dvir-${vehicleName}-${insType}-${startTime}.${"pdf"}`.replace(
        / /g,
        ""
      );

      const element = document.getElementById("dvirPdf");

      pdfOptions.filename = fileName;
      pdfOptions.margin = [0.3, 0.1];
      await html2pdf().set(pdfOptions).from(element).toPdf().save();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const timezone = this.props.timezone;
    const signature = this.state.signature;
    const carrier = this.state.carrier || {};
    const selected = this.props.selected || {};
    const areas = selected.areas || [];
    const templateId = selected.templateId || "Tractor";
    const vehicleType = templateId.toUpperCase().includes("TRACTOR")
      ? "Tractor"
      : "Trailer";
    const isEdit = this.state.isEdit;
    const notDriver = _get(this, "props.profile.roles[0]") !== "DRIVER";

    return (
      <Dialog
        fullScreen
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.onClose}>
          <Grid container spacing={2}>
            {isEdit && selected && (
              <EditDvirDialog
                open={isEdit}
                selected={selected}
                handleClose={() => {
                  this.setState({ isEdit: false });
                }}
                afterSave={() => {
                  this.setState({ isEdit: false }, (_id) =>
                    this.findDvirById(_id)
                  );
                  window.location.reload();
                }}
              />
            )}
            <Grid item xs={6} md={6}>
              {"DVIR"}
            </Grid>
            <Grid item xs={6} md={6}>
              <div style={{ textAlign: "right", padding: 0 }}>
                <ButtonGroup
                  size="small"
                  variant="outlined"
                  aria-label="Dvir action buttons"
                >
                  <Button disabled={loading} onClick={this.createPdf}>
                    {loading ? LABELS.downloading : LABELS.download}
                  </Button>

                  {notDriver && (
                    <Button
                      onClick={() => this.props.onDeleteConfirm(selected)}
                      color="error"
                    >
                      {`${LABELS.delete}`}
                    </Button>
                  )}
                  {notDriver && (
                    <Button
                      onClick={() => {
                        this.setState({ isEdit: true });
                      }}
                    >
                      {`${LABELS.edit}`}
                    </Button>
                  )}
                  <Button onClick={this.props.onClose}>{LABELS.close}</Button>
                </ButtonGroup>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <div style={{ maxWidth: 700, margin: "auto" }} id={"dvirPdf"}>
            <h2 style={{ textAlign: "center" }}>{carrier.name}</h2>
            <h3 style={{ textAlign: "center" }}>
              {LABELS.driver_vehicle_inspection_report}
            </h3>
            <h5 style={{ textAlign: "center" }}>
              {
                LABELS.AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS
              }
            </h5>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {LABELS.dvirNumber} {`${selected.dvirSerial}`}
                    </TableCell>
                    <TableCell>
                      {`${LABELS.location}:-`} {`${selected.location}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.carrier}:-`} {carrier.name}
                    </TableCell>
                    <TableCell>
                      Date:-{" "}
                      {`${this.props.formatDate(selected.startTime, timezone)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.dot}:-`} {carrier.dot}
                    </TableCell>
                    <TableCell>
                      {`${LABELS.odometer}`} {`${selected.odometer}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.driver}:-`} {`${selected.driverName}`}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {`${vehicleType}`}:- {`${selected.vehicleName}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.start_inspection} :-`}{" "}
                      {`${this.props.formatTime(selected.startTime, timezone)}`}
                    </TableCell>
                    <TableCell>
                      {`${LABELS.endInspection} :-`}{" "}
                      {`${this.props.formatTime(selected.endTime, timezone)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {`${LABELS.inspection_type} :-`}{" "}
                      {`${selected.inspectionType}`}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {`${LABELS.inspector}`} {`${selected.inspectorType}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{LABELS.inspection_Area}</TableCell>
                    <TableCell>{LABELS.status}</TableCell>
                    <TableCell style={{ maxWidth: 100 }}>
                      {LABELS.note}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {areas.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.value === "1" ? "PASS" : "FAIL"}
                      </TableCell>
                      <TableCell style={{ maxWidth: 100 }}>
                        {row.note}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ textAlign: "center" }}>
              <h5>
                {
                  LABELS.I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements
                }
              </h5>
              <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                  <img
                    src={`data:image/jpeg;base64,${signature}`}
                    style={{ width: 120 }}
                    alt="Signature"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h5>{LABELS.driver_signature}</h5>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: _get(state, "authData.profile"),
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
    timezone: _get(state, "authData.profile.account.carrier.timezone"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDriverSignById,
      formatDateTime,
      formatTime,
      formatDate,
      findDvirById,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DvirDialog))
);
