const defaultState = {
  resellers: [],
};

export default function reseller(state = defaultState, action) {
  switch (action.type) {
    case "RESELLERS":
      return {
        ...state,
        resellers: action.value,
      };

    default:
      return state;
  }
}
