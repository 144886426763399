import React, { Component } from "react";
import { withStyles } from '@mui/styles';
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import { withRouter } from "react-router-dom";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MultiStatusAdminDetailTable from "./ReMultiStatusAdminDetailTable";
import {
  formatDateTimeToUtc,
  getDate,
  getStartOfDay,
} from "../../../actions/momentActions";
import Error from "../../Error";
import Success from "../../Success";
import { getDrivers } from "../../../actions/driverActions";
import { updateAdminLogs } from "../../../actions/account/accountAdminActions";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.dutyPage;

const styles = (theme) => ({
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    padding: theme.spacing(1),
    minHeight: 200,
    overflow: "scroll",
  },
});

class MultipleStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logs: this.props.allLogs,
      selDriver: null,
      selAssignLog: false,
      errorMessage: "",
      successMessage: "",
    };
  }

  componentDidMount() {
    this.props.getDrivers({ skip: 0, limit: 250 });
  }

  getCoDrivers = () => {
    const dailyDiary = this.props.dailyDiary || [];
    let drivers = [];
    for (let i = 0; i < dailyDiary.length; i++) {
      const _d = dailyDiary[i].coDriver || [];
      drivers = [...drivers, ..._d];
    }
    return drivers;
  };

  multi = (params) => {
    let { logs } = this.state;
    if (params) {
      const index = logs.findIndex((e) => e.cid === params.cid);
      logs[index] = params;
    }
    this.setState({ logs, errorMessage: "" });
  };

  onSubmit = async () => {
    const logs = this.state.logs || [];
    const selDriver = this.state.selDriver;
    const drivers = this.props.drivers || [];

    this.setState({ loading: true, successMessage: "", errorMessage: "" });
    let count = 0;

    const list = logs.filter((e) => e.isEdited === true);

    if (list.length > 0) {
      if (!selDriver || selDriver === "-") {
        this.setState({
          errorMessage: LABELS.errorMasage_selectCo_driverTOReassignLogs,
          loading: false,
        });
        return;
      } else {
        const driver = drivers.find((dr) => dr.id === selDriver) || {};
        const arrayOfPromises = list.map((item, idx) => {
          const vehicle = item.vehicle || {};

          return this._saveLogs(
            {
              id: item.id || item._id,
              accountId: item.accountId,
              action: "REASSIGN",
              driver: {
                email: driver.email,
                firstName: driver.firstName,
                id: driver.id,
                lastName: driver.lastName,
              },
              vehicle: {
                id: vehicle.id,
                vin: vehicle.vin,
                name: vehicle.name,
              },
            },
            idx
          );
        });
        const response = await Promise.all(arrayOfPromises);

        console.log("response ::: ", response);
        this.setState({
          successMessage: LABELS.successMessage,
          loading: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        loading: false,
      });
      this.props.onDone();
    }
  };

  _saveLogs = (log, idx) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const response = await this.props.updateAdminLogs(log);
          resolve(response);
        } catch (e) {
          resolve(null);
        }
      }, idx * 100);
    });
  };

  render() {
    const { classes } = this.props;
    const { loading, successMessage, errorMessage, logs } = this.state;
    const list = logs.filter((e) => e.isEdited === true);
    const allLogs = logs.filter((e) => !e.locked);
    const drivers = this.getCoDrivers();
    return (
      <div style={{ textAlign: "center" }}>
        <Dialog
          maxWidth={"xl"}
          fullScreen={true}
          fullWidth={true}
          className={classes.wrapper}
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" onClose={this.props.close}>
            <Typography style={{ minWidth: "800px" }}>
              {LABELS.ReassignMultipleStatusAdmin}
            </Typography>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={this.props.close}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <MultiStatusAdminDetailTable
              onDriverSelect={(id) => {
                this.setState({ selDriver: id });
              }}
              isAllLog={this.props.isAllLog}
              selectedDate={this.props.selectedDate}
              startOfDay={this.props.getStartOfDay}
              allLogs={allLogs}
              dailyDiary={this.props.dailyDiary}
              formatDateTimeToUtc={this.props.formatDateTimeToUtc}
              getDate={this.props.getDate}
              adminAccess={this.props.adminAccess}
              adminKey={this.props.adminKey}
              formatTime={this.props.formatTime}
              setSelectedLog={this.props.setSelectedLog}
              formatRawDate={this.props.formatRawDate}
              formatDateTimeUtc={this.props.formatDateTimeUtc}
              multi={this.multi}
              drivers={drivers}
            />

            {successMessage && <Success message={successMessage} />}
            {errorMessage && <Error message={errorMessage} />}
            <DialogActions className={classes.DialogActions}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={this.onSubmit}
                disabled={loading || list.length === 0}
              >
                {/* {loading ? "Saving..." : "SAVE"} */}
                {loading ? LABELS.saving :LABELS.save}
              </Button>
              <Button variant="contained" onClick={this.props.close}>
                {LABELS.close}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    driverDiary: _get(state, "logData.driverDiary", []),
    driverId: _get(state, "driverData.selectedDriver.driver.id", {}),
    drivers: _get(state, "driverData.drivers", []),
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAdminLogs,
      getStartOfDay,
      getDrivers,
      formatDateTimeToUtc,
      getDate,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MultipleStatusModal))
);
