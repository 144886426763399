// import _get from "lodash/get";
// import _set from "lodash/set";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.dutyPage;


const defaultValues = {
  accountId: "",
  accountName: "",
  driverId: "",
  category: "SUPPORT",
  product: "ELD",
  status: "OPEN",
  priority: "MEDIUM",
  driver: { name: "", email: "", phone: "" },
  cb: false,
  cbTm: null,
  callTm: new Date()
};

const validate = (values) => {
  let errors = {};
  const requiredFields = ["reason", "driverId"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.required;
    }
  });

  return errors;
};

export { validate, defaultValues };
