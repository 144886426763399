import _get from "lodash/get";
import { React, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Marker, TrafficLayer, InfoWindow } from "@react-google-maps/api";
import DutyStatus from "../../../components/DutyStatus";
import VideocamIcon from "@mui/icons-material/Videocam";
import Button from "@mui/material/Button";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const camIconStyle = {
  verticalAlign: "bottom",
  fontSize: "18px !important",
  marginRight: 0,
  width: "1rem",
  height: "1rem",
  cursor: "pointer",
};

function getIntials(item) {
  const driver = item.driver || {};
  const fName = driver.firstName
    ? driver.firstName.slice(0, 1).toUpperCase()
    : "";
  const lName = driver.lastName
    ? driver.lastName.slice(0, 1).toUpperCase()
    : "";

  return fName + lName;
}

function ReactMap(props) {
  const defaultCenter = {
    lat: 35.10321044921875,
    lng: -110.99175262451172,
  };
  // console.log("props Render______", props)
  const defaultZoom = 4;
  const drivers = props.drivers;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: props.apiKey,
  });
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(defaultZoom);
  const [center, setCenter] = useState(defaultCenter);
  const [mapKey, setMapKey] = useState(0);
  const [dcOpen, setDcOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [isInfoWindow, setIsInfoWindow] = useState(null);

  const speedKm = infoWindow?.telemetrics.speedKm;
  const speed = speedKm !== undefined && speedKm !== null ? (speedKm * 0.621371).toFixed(2) : (0).toFixed(2);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    let markers = [];
    let markerClusterer;

    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      markers = drivers.map((driver) => {
        const marker = new window.google.maps.Marker({
          position: {
            lat: parseFloat(driver.lat),
            lng: parseFloat(driver.lng),
          },
          icon: {
            path:
              driver.eventCode === "DRIVING"
                ? window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                : window.google.maps.SymbolPath.CIRCLE,
            fillColor:
              driver.eventCode === "DRIVING"
                ? "#1f9a07"
                : driver.eventCode === "SLEEPER"
                ? "#FF7E0E"
                : driver.eventCode === "ONDUTY_ND"
                ? "#0085EC"
                : "grey",
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: parseInt(driver.heading || "60"),
            scale: driver.eventCode === "DRIVING" ? 6 : 10,
          },
          label: {
            text: getIntials(driver),
            color: "black",
            fontSize: "10px",
            fontWeight: "bold",
          },
        });

        marker.addListener("click", () => {
          props.setDriverId(driver.uqId);
        });

        bounds.extend(marker.getPosition());
        return marker;
      });

      markerClusterer = new MarkerClusterer({
        markers,
        map,
        options: {
          minimumClusterSize: 10,
        },
      });

      map.fitBounds(bounds);
    }

    // Cleanup function to remove markers and event listeners
    return () => {
      if (markerClusterer) {
        // Clear all markers from the clusterer
        markerClusterer.clearMarkers();
      }
      // Remove each marker from the map
      markers.forEach((marker) => marker.setMap(null));
    };
  }, [drivers, map, props.dId]);

  const onUnmount = useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    const selectedDriver = drivers.find((item) => item.uqId === props.dId);

    if (selectedDriver) {
      // setCenter({ lat: selectedDriver.lat, lng: selectedDriver.lng });
      //
      // setZoom(12);

      if (map) {
        map.panTo({ lat: selectedDriver.lat, lng: selectedDriver.lng });
        smoothZoom(22); // Set your desired zoom level
      }

      // Open the InfoWindow for the selected driver
      setInfoWindow(selectedDriver);
    } else {
      smoothZoom(4);
      setInfoWindow(null);
      props.setDriverId();
      resetMap()
    }
  }, [props.dId, drivers]);

  const resetMap = () => {
    // Trigger a map re-render by changing the key
    // setMapKey(prevKey => prevKey + 1);
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      drivers.forEach((driver) => {
        bounds.extend(new window.google.maps.LatLng(driver.lat, driver.lng));
      });
      map.fitBounds(bounds);
    }
    setInfoWindow(null);
  };

  function handleDcClick(selected) {
    setDcOpen(true);
    setSelectedItem(selected);
  }

  const smoothZoom = (targetZoom) => {
    if (!map) {
      return null;
    }
    map.setZoom(targetZoom);
  };

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: "calc(100vh - 80px)",
            display: "block",
            flexDirection: "column",
            width: "100%",
          }}
          key={mapKey}
          zoom={zoom}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{}}
        >
          <TrafficLayer />
          {infoWindow && (
            <InfoWindow
              key={infoWindow?.driverId || "info-window"}
              position={{
                lat: parseFloat(infoWindow.lat),
                lng: parseFloat(infoWindow.lng),
              }}
              onCloseClick={() => {
                props.setDriverId();
              }}
              options={{ pixelOffset: new window.google.maps.Size(0, -10) }}
            >
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    padding: "5px 2px 0px 2px",
                    fontWeight: 450,
                    marginTop: "0px",
                    fontSize: "12px",
                    color: "#333333",
                    textTransform: "uppercase",
                    cursor: "default",
                  }}
                >
                  <p style={{ margin: "0", marginBottom: "1px" }}>
                    {`${infoWindow.driver?.firstName} ${infoWindow.driver?.lastName}`}
                  </p>
                  <p style={{ margin: "0", marginBottom: "1px" }}>
                    {`Truck# ${infoWindow?.vehicle.name}`}
                    {infoWindow.driver.dcId && (
                      <VideocamIcon
                        onClick={() => props.onDcClick(drivers)}
                        fontSize="small"
                        style={camIconStyle}
                      />
                    )}
                  </p>
                </div>

                <div style={{ cursor: "default", fontSize: "12px" }}>
                  {`${props.formatDate(
                    infoWindow?.eventTime || ""
                  )} @ ${props.formatTime(infoWindow?.eventTime || "")}`}
                </div>
                <div
                  style={{
                    padding: "5px",
                    cursor: "default",
                    fontSize: "12px",
                  }}
                >
                  {infoWindow?.location}
                </div>
                {infoWindow?.eventCode === "DRIVING" ? (
                  <div style={{ cursor: "default", fontSize: "12px" }}>
                    {speed} M/hr
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "5px",
                    marginLeft: "5px",
                  }}
                >
                  <Button
                    color="primary"
                    style={{
                      height: "25px",
                      width: 85,
                      padding: 3,
                      margin: "0 7px 0 0",
                      borderRadius: 5,
                      fontWeight: 500,
                      textDecoration: "none",
                      fontSize: "13px",
                      border: "1px solid #52514E",
                      color: "#52514E",
                    }}
                    onClick={() => {
                      const url = `${props.accountBaseUrl}/tracking?id=${infoWindow.driver?.id}`;
                      props.navigateToPage(url);
                    }}
                  >
                    ROUTE
                  </Button>

                  {infoWindow?.eventCode ? (
                    <DutyStatus
                      status={infoWindow}
                      eventType={infoWindow?.eventType}
                      eventCode={infoWindow?.eventCode}
                      annotation={infoWindow?.annotation}
                      eventSubType={infoWindow?.eventSubType}
                    />
                  ) : null}
                </div>
                <div
                  style={{
                    fontSize: 10,
                    textAlign: "center",
                    marginTop: 8,
                    maxWidth: 200,
                  }}
                />
                <div />
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : null}
    </>
  );
}

export default withRouter(ReactMap);
