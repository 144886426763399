import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.adminPage;

export const PLAN_OPTIONS = [
  { id: "monthly", name: LABELS.monthly },
  { id: "annually", name: LABELS.annual },
];

export const PLAN_NAME_OPTIONS = [
  { id: "STANDARD", name: LABELS.standard },
  { id: "PREMIUM", name: LABELS.premium },
  { id: "ENTERPRISE", name: LABELS.enterprise},
];
