import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./fmcsaActionTypes";

export const setFmcsa = value => ({
    type: TYPES.FMCSA,
    value
});

export const setFmcsaCount = value => ({
    type: TYPES.FMCSA_COUNT,
    value
});

export const fmcsaBusy = value => ({
    type: TYPES.FMCSA_BUSY,
    value
});

export const setFmcsaHistory = value => ({
    type: TYPES.FMCSAHISTORY,
    value
});

export const setFmcsaHistoryCount = value => ({
    type: TYPES.FMCSAHISTORY_COUNT,
    value
});

export function getFmcsaCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/fmcsa/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setFmcsaCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getFmcsa(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(fmcsaBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/fmcsa`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", []);
                dispatch(setFmcsa(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(fmcsaBusy(false));
                return response;
            });
    };
}

export function sendFmcsa(data) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(fmcsaBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/fmcsa`,
            method: "post",
            data
        })
            .then(response => {
                if (data.download) {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", data.fileName);
                    document.body.appendChild(link);
                    link.click();
                    return;
                } else {
                    const _response = _get(response, "data");
                    return _response;
                }
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(fmcsaBusy(false));
                return response;
            });
    };
}

export function getFmcsaHistoryCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        return axios({
            url: `${BASE_URL}/admin/fmcsa/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setFmcsaHistoryCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getFmcsaHistory(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        dispatch(fmcsaBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/fmcsa`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", []);
                dispatch(setFmcsaHistory(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(fmcsaBusy(false));
                return response;
            });
    };
}

export function getFmcsaHistoryCountAdmin(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        return axios({
            url: `${BASE_URL}/admin/fmcsa/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setFmcsaHistoryCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getFmcsaHistoryAdmin(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        // const accountId = _get(getState(), "authData.profile.user.accountId");
        dispatch(fmcsaBusy(true));

        return axios({
            url: `${BASE_URL}/admin/fmcsa`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", []);
                dispatch(setFmcsaHistory(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(fmcsaBusy(false));
                return response;
            });
    };
}
