import React from "react";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import ProfileCard from "./ProfileCard";
import AccountCard from "./AccountCard";
import { LABELS } from "../../../language";

const ALL_LABELS = LABELS.profilePage;

const styles = (theme) => ({});

export class Detail extends React.Component {
  render() {
    return (
      <Grid container spacing={2} style={{ padding: 10 }}>
        <Grid item xs={12} sm={12} md={6}>
          <ProfileCard LABELS={ALL_LABELS} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <AccountCard LABELS={ALL_LABELS} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Detail);
