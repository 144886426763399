import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import ImageIcon from "@mui/icons-material/Image";
import TextField from "@mui/material/TextField";
import { Button, Grid } from "@mui/material";
import UpdateUserForm from "./UpdateUserForm";
import { LABELS as _LABELS } from "../../language";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import StarIcon from "@mui/icons-material/Star";
import _get from "lodash/get";
const LABELS = _LABELS.adminPage;

function RenderItem(props) {
  const data = props.item || {};
  const items = data.items || [];
  const account = data.account || {};
  const selected = props.selected || {};
  const name = data.name || "";
  const phoneTo = data.phoneTo || "";

  let isSelected = !!items.find((item) => {
    return item._id === selected._id;
  });

  const isSe = account.provider === "SE";
  const isEb = account.provider === "EB";
  const isPremium = account.plan === "ENTERPRISE";

  const messages = items.map((item) => {
    let account = item.account || {};
    let _message = item.message;
    const phoneFrom = item.phoneFrom || "";
    let message = _message ? _message.slice(0, 30) : "";
    if (_message && _message.length > 30) {
      message = `${message}...`;
    }

    let readAt = item.readAt
      ? `${props.formatUsDate(item.readAt)} ${props.formatTime(item.readAt)}`
      : "";

    const _readBy = item.readBy || {};
    const readBy = _readBy.name ? `${_readBy.name}` : "";
    const created = item.created;
    const from = item.from || {};
    const isImage = item.type === "image";
    let datetime = props.formatUsDate(created);
    let createdTm = `${props.formatTime(created)}`;
    if (props.isToday(created)) {
      datetime = props.formatTime(created);
      createdTm = "";
      readAt = item.readAt ? `${props.formatTime(item.readAt)}` : "";
    }
    if (props.isYesterday(created)) {
      datetime = `Yesterday`;
      createdTm = `${props.formatTime(created)}`;
      readAt = item.readAt
        ? `${props.formatUsDate(item.readAt)} ${props.formatTime(item.readAt)}`
        : "";
    }

    const userName = account.alias || from.name || "";
    return {
      read: item.read,
      isImage,
      readBy,
      message,
      userName: userName ? ` ${userName}:- ` : "",
      userPhone: from.wid || "",
      readAt,
      datetime,
      data: item,
      phoneFrom,
    };
  });

  const providerStyles = {
    fontWeight: "800",
    float: "right",
    fontSize: 12,
  };

  return (
    <>
      <ListItem
        alignItems="flex-start"
        style={{
          backgroundColor: isSelected ? "#f0f0f0" : "",
          paddingLeft: 5,
          paddingTop: 5,
          paddingRight: 5,
        }}
      >
        <ListItemText
          primary={
            <>
              {name}
              {isPremium && (
                <StarIcon style={{ float: "right" }} fontSize="12" />
              )}
              {isSe && <span style={providerStyles}>{"SE"}</span>}
              {isEb && <span style={providerStyles}>{"EB"}</span>}
            </>
          }
          style={{ cursor: "pointer" }}
          secondary={
            <React.Fragment>
              {messages.map((item, idx) => {
                const data = item.data || {};
                const _isSel = data._id === selected._id;
                return (
                  <div key={`key-${idx}-${data._id}`}>
                    <Tooltip
                      key={`key-${idx}-${data._id}`}
                      title={
                        <div>
                          {item.read ? (
                            <>
                              <div>{`Read at: ${item.readAt}`}</div>
                              <div>{`Read by: ${item.readBy}`}</div>
                            </>
                          ) : (
                            "Unread"
                          )}
                          <div>Phone: {item.userPhone}</div>
                        </div>
                      }
                      placement="top"
                    >
                      <Typography
                        style={{
                          fontSize: 12,
                          color: item.read ? "" : "#009be5",
                        }}
                        onClick={() => props.handleClick(item.data)}
                      >
                        <ContentCopyIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(item.phoneFrom);
                          }}
                          color="primary"
                          fontSize="8"
                        />
                        <span
                          style={{
                            fontWeight: _isSel ? "800" : item.read ? "" : "800",
                          }}
                        >
                          {`${item.userName} `}
                          <>
                            {item.isImage ? (
                              <ImageIcon fontSize={"10"} />
                            ) : (
                              item.message
                            )}
                          </>{" "}
                        </span>

                        <span style={{ float: "right" }}>{item.datetime}</span>
                      </Typography>
                    </Tooltip>
                  </div>
                );
              })}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
}

export default function ChatUser(props) {
  const selected = props.selected || {};
  const items = props.items || [];
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("All");
  const [editItem, setEditItem] = useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredItems = items.filter((item) => {
    const name = item.name || "";
    const message = item.message || "";
    const matchesSearchQuery =
      name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      message.toLowerCase().includes(searchQuery.toLowerCase());

    if (filter === "Unread") {
      return matchesSearchQuery && !item.read;
    } else if (filter === "SE" || filter === "EB") {
      const provider = item.account && item.account.provider;
      return matchesSearchQuery && provider === filter;
    }

    return matchesSearchQuery;
  });

  return (
    <div>
      {editItem && (
        <UpdateUserForm
          isOpen={!!editItem}
          selected={editItem}
          constants={props.constants}
          updateChat={props.updateChat}
          handleClose={() => setEditItem(null)}
        />
      )}
      <Grid container style={{ width: "100%", paddingRight: 10 }}>
        <Grid item xs>
          <TextField
            sx={{ width: "100%" }}
            id="message-search"
            label={`${LABELS.search}`}
            size="small"
            variant="outlined"
            margin="normal"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{ width: "100%", height: "80vh", paddingRight: 10 }}
      >
        <Grid item xs={12} style={{ borderRight: "1px solid #e0e0e0" }}>
          <Grid item xs={12} style={{ padding: "4px" }}>
            <Chip
              label={`UnGroup`}
              onClick={() => props.handleGroup()}
              sx={{
                backgroundColor: "#cce4ff",
                color: "#0044cc",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`${LABELS.all}`}
              onClick={() => handleFilterChange("All")}
              sx={{
                backgroundColor: filter === "All" ? "#cce4ff" : "default",
                color: filter === "All" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`SE`}
              onClick={() => handleFilterChange("SE")}
              sx={{
                backgroundColor: filter === "SE" ? "#cce4ff" : "default",
                color: filter === "SE" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`EB`}
              onClick={() => handleFilterChange("EB")}
              sx={{
                backgroundColor: filter === "EB" ? "#cce4ff" : "default",
                color: filter === "EB" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
          </Grid>
          <Divider style={{ width: "98%" }} />
          {filteredItems && filteredItems.length === 0 && filter === "Unread" && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ padding: "10px", height: "100%", textAlign: "center" }}
            >
              <Typography variant="body1">{`${LABELS.nounreadchats}`}</Typography>
              <Button
                onClick={() => handleFilterChange("All")}
                style={{ marginTop: "10px" }}
              >
                {`${LABELS.view_all_chats}`}
              </Button>
            </Grid>
          )}
          <List
            sx={{
              width: "100%",
              // maxWidth: 360,
              maxHeight: props.maxHeight,
              overflow: "scroll",
              bgcolor: "background.paper",
            }}
          >
            {filteredItems.map((item, idx) => (
              <RenderItem
                key={idx}
                item={item}
                selected={selected}
                handleClick={props.handleClick}
                isToday={props.isToday}
                formatTime={props.formatTime}
                formatUsDate={props.formatUsDate}
                isYesterday={props.isYesterday}
                onEdit={(_item) => setEditItem(_item)}
              />
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
