export const LABELS = {
  refresh: "Actualizar",
  recap: "Resumen",
  // date: "Date",
  newTab: "Nueva pestaña",
  download: "Date",
  Audit: "Audit",
  AuditEvent:"Eventos de auditoría",
  openInNewTab:"Abrir en una pestaña nueva",
  ReassignMultipleStatus: "Reasignar estado múltiple",
  AddMultipleStatus: "Agregar varios estados",
  EditMultipleStatus: "Editar estados múltiples",
  EditMultipleStatusAdmin: "Editar estados múltiples (Administrador)",
  AddStatus: "Agregar estado",
  SendMessage: "Enviar mensaje",
  SendNotification: "Enviar notificación",
  AddShippingOrTrailer: "Agregar envío o remolque",
  AddStatusAdmin: "Agregar estado (administrador)",
  ReassignMultipleStatusAdmin: "Reasignar varios estados (Administrador)",

  date: "Fecha",
  driver: "Conductor",
  vehicle: "Vehículo",
  trailer: "Remolque",
  email: "Correo electrónico",
  startTime: "Hora de inicio",
  carrier: "Transportador",
  terminal: "Home Terminal",
  shipping: "Envío",
  app: "App",
  cycle: "Ciclo",
  coDriver: "copiloto",
  distance: "distancia",
  engineHr: "Horas del motor",
  device: "Dispositivo",
  allLogs: "Todos los registros",
  note:"Nota",
  find:"ENCONTRAR",
  action:"Acción",
  odometerMi:"Odómetro (Mi)",
  dvir:"Dvir",



  eventTime:"Hora del evento",
  CoordCode:"Código de coordinación",
  latitude:"Latitud",
  longitude:"Longitud",
  reasonForEdit:"Motivo de la edición",
  recordOrigin:"Origen del registro",
  recordStatus:"Estado de registro",
  eventType:"Tipo de evento",
  subType:"Subtipo",
  sequenceId:"ID de secuencia",
  shippingDoc:"Documento de envío",
  accumulatedHours:"Horas acumuladas",
  accumulatedMiles:"Millas acumuladas",



  
  editAdmin: "Editar administrador",
  DeactivateAdmin: "Desactivar administrador",
  DuplicateAdmin: "Administrador duplicado",

  time: "Tiempo",
  status: "Estado",
  Location: "Ubicación",
  Odometer: "Cuentakilómetros",
  EngineHours: "Horas de motor",
  Origin: "Origen",
  Annotation: "Anotación",
  days: "Días",
  hours: "Horas",
  minutes: "Minutos",
  seconds: "Segundos",
  odometer: "Cuentakilómetros",
  subtract: "Sustraer",
  add: "Agregar",
  edit: "Editar",
  deactivate: "Desactivar",
  reason:"Razón",
  details:"Detalles",
  notes:"Notas",
  selectDriver:"Seleccionar controlador",
  required:"Requerido",
  state:"Estado",
  log:"registro",
  admin:"Administrador",
  adminEvents:"Eventos de administración",
 

  DATE:"FECHA",
  START_TIME:"HORA DE INICIO",
  CYCLE:"CICLO",
  DRIVER:"CONDUCTOR",
  CARRIER:"TRANSPORTADOR",
  CO_DRIVERS:"CONDUCTORES CO",
  VEHICLES:"VEHICULOS",
  HOMETERMINAL:"TERMINAL DE CASA",
  DISTANCE:"DISTANCIA",
  TRAILERS:"REMOLQUES",
  SHIPPING_DOCS:"DOCUMENTOS DE ENVÍO",
  ENGINE_HOURS:"HORAS DE MOTOR",
  EMAIL:"CORREO ELECTRÓNICO",

  manageDrivers:"Administrar controladores",
  save:"AHORRAR",
  allDrivers:"Todos los conductores",
  saving:"Ahorro...",
  close:"CERCA",
  cancel:"CANCELAR",
  assignDriver:"Asignar controlador",

  editMultipleStatus:"EDITAR MÚLTIPLES ESTADOS",
  addMultipleLogs:"AÑADIR VARIOS REGISTROS",

  drActionReqMsg: "Se requiere acción del conductor",
  drConfirmReqMsg:
    "Confirme con el conductor para aceptar o rechazar la solicitud.",
  automaticDrMsg: "La conducción automática no se puede modificar.",
  successMessage: "Guardado exitosamente",
  errorMessage: "Algo salió mal, inténtalo más tarde.",
  automaticDriveTimeError:
    "Consulta la hora del evento. No puede agregar estado después de la conducción automática.",

    editsuccessMessage:"La solicitud de edición se envió correctamente",

    No_logingFound_for:"No se encontró ningún inicio de sesión para",
    noDutyStatusFound:"No se encontró ningún estado de servicio.",
    For_selected_date:"para la fecha seleccionada.",
    errorMasage_selectCo_driverTOReassignLogs:"Seleccione copiloto para reasignar registros",

    updateTrailerMassage_shippingDocs_application:"Updated trailer or shipping document will only be applicable to future status not to any past status."
};
