import React from "react";
import { useState } from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../components/ConfirmDialog";
import { LABELS as _LABELS } from "../../language";
import Grid from "@mui/material/Grid";
import ExploreIcon from '@mui/icons-material/Explore';
import TripDetailsMap from "./TripDetailsMap";


// import Button from '@mui/material/Button';
// import DialogActions from '@mui/material/DialogActions';
// import { Alert, AlertTitle } from "@mui/lab";
// import Badge from "@mui/material/Badge";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import StepContent from "@mui/material/StepContent";

// import TableContainer from "@mui/material/TableContainer";
// import TableHead from '@mui/material/TableHead';

const LABELS = _LABELS.settingPage;

const moment = require("moment-timezone");
moment.createFromInputFallback = function (config) {
  config._d = new Date(config._i);
};

function formatDate(date) {
  return moment(date).format("DD MMM");
}

function formatDateTime(date) {
  return moment(date).format("DD MMM @ h:mm a");
}

function kmToMi(km) {
  try {
    let speed = parseFloat(km) * 0.621371;

    return speed.toFixed(2);
  } catch (e) {
    return 0;
  }
}

const styles = (theme) => ({
  wrapper: {
    width: "100%",
  },
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapperButton: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  cententBox: {
    border: "1px solid #ccc",
    padding: 3,
    borderRadius: 10,
    textAlign: "center",
    margin: 20,
  },
});

const TripCard = ({ classes, trip }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <h4 onClick={() => setVisible(!visible)}>
          {`Trip: ${trip.name}`} ({" "}
          {formatDate(trip.items[trip.items.length - 1].date)} to{" "}
          {formatDate(trip.items[0].date)}) ({trip.items.length} Locations)
          <ExpandMoreIcon />
        </h4>
      </Grid>
      {visible && (
        <Grid item xs={12} sm={12} md={12}>
          <Grid container className={classes.addWrapper}>
            {trip.items.map((item, idx) => {
              return (
                <Grid item xs={2} sm={2} md={2}>
                  <div className={classes.cententBox}>
                    <span>{`${idx + 1}`}</span> <br />
                    {`${item.eventCode}`}
                    <br />
                    {`${item.city}, ${item.state}`}
                    <br />
                    {`${formatDateTime(item.date)}`}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const TripTable = ({ classes, trip, index, onDelete, deletedItems }) => {
  const [visible, setVisible] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);

  // Handler to open the map dialog
  const handleToggleMap = () => {
    setIsMapVisible(!isMapVisible);
    // setVisible(false)
  };

  const VisibleView=()=>{
    setVisible(!visible)
    // setIsMapVisible(false)

  }

  return (
    <React.Fragment key={`id=${index}`}>
      <Grid item xs={12} sm={12} md={12 } style={{
        display:"flex",
        alignItems:"center",
        gap:"10px",
        justifyItems:"center"
      }}>
        <h4 onClick={VisibleView}>
          {`Trip: ${trip.name}`} ({" "}
          {formatDate(trip.items[trip.items.length - 1].date)} to{" "}
          {formatDate(trip.items[0].date)}) ({trip.items.length} Locations)
          <ExpandMoreIcon />
        </h4>
        <div>
        <ExploreIcon style={{ fontSize: 30, marginTop: "10px", cursor: "pointer" }} onClick={handleToggleMap} />
        </div>
      </Grid>
      {visible && (
        <Grid item xs={12} sm={12} md={12}>
          <Grid container className={classes.addWrapper}>
            <Table size="small">
              <TableBody>
                {trip.items.map((item, idx) => {
                  if (deletedItems.includes(item._id)) {
                    return null;
                  }
                  const cords = item.coordinates || [];
                  const createdBy = item.createdBy || {};

                  return (
                    <TableRow key={`${idx + 1}`}>
                      <TableCell>
                        <span>{`${idx + 1}`}</span>
                      </TableCell>
                      <TableCell>
                        {`${item.eventCode}`}
                        <div>{`${item._id}`}</div>
                      </TableCell>
                      <TableCell>
                        {`${item.city}, ${item.state}`}
                        <div>{`${cords[0]}, ${cords[1]}`}</div>
                      </TableCell>
                      <TableCell>{`${kmToMi(item.odometer)} Mi(s)`}</TableCell>
                      <TableCell>
                      {`${formatDateTime(item.created)}`}
                      <div>{`${createdBy.email}`}</div>
                      </TableCell>
                      <TableCell>
                        <DeleteIcon
                          onClick={() => {
                            onDelete(item);
                            item.deleted = true;
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>

      )}
       {isMapVisible && (
        <Grid item xs={12}>
          <TripDetailsMap trip={trip} />
        </Grid>
      )}
    </React.Fragment>
  );
};

export class TripDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: "",
      successMessage: "",
      selEvent: {},
      trips: [],
      deletedItems: [],
      selected: null,
    };
  }

  componentDidMount() {
    // const statemiles = this.props.allStatus || [];
    // const trips = {};
    // let tripName = 1;
    // statemiles.forEach((item, idx) => {
    //   trips[tripName] = trips[tripName] || {};
    //   trips[tripName].name = tripName;
    //   trips[tripName].items = trips[tripName].items || [];
    //
    //   if (idx > 0) {
    //     const previous = moment(statemiles[idx - 1].date);
    //     const current = moment(item.date);
    //
    //     trips[tripName].items.push(item);
    //
    //     if (previous.diff(current, "hours") > 24) {
    //       tripName = tripName + 1;
    //     }
    //   }
    // });
    //
    // const tripKeys = Object.keys(trips);
    // const _trip = tripKeys.map((item) => trips[item]);
    // this.setState({ trips: _trip });
  }

  onDelete = (selected) => {
    this.setState({ isConfirm: true, selected });
  };

  deleteIftaLogs = async (isTrue) => {
    if (isTrue) {
      const deletedItems = this.state.deletedItems || [];
      const selected = this.state.selected || {};
      this.setState({ isConfirm: false, deletedItems });
      try {
        const response = await this.props.deleteIftaLogs({
          id: selected._id,
        });
        console.log("response == ", response);
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({ isConfirm: false });
    }
  };

  render() {
    const classes = this.props.classes;
    const trips = this.props.trips || [];
    const deletedItems = this.state.deletedItems || [];
    const isConfirm = this.state.isConfirm || false;
    // const isSmallScreen = window.innerWidth < 0;
    return (
      <Dialog
        fullScreen={true}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{`${LABELS.trips} ${trips.length}`}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <ConfirmDialog
            open={isConfirm}
            text={"Please confirm, you want to delete selected item."}
            onClose={(isTrue) => this.deleteIftaLogs(isTrue)}
          />
          <Grid container className={classes.addWrapper}>
            {trips &&
              trips.map((trip, idx) => {
                return (
                  <TripTable
                    key={`id=${idx}`}
                    trip={trip}
                    classes={classes}
                    index={idx}
                    onDelete={this.onDelete}
                    deletedItems={deletedItems}
                  />
                );
              })}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TripDetail);
