import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import EditIcon from "@mui/icons-material/Edit";
import SmsIcon from "@mui/icons-material/Sms";
import NotFoundTableRow from "../../NotFoundTableRow";
import { sorting } from "../../../utils/sortingHelpers";
import { Link } from "react-router-dom";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableRowTitle: {
    display: "inline-block",
    width: 50,
  },
  tableRow: {
    height: 30,
  },
  tableCell: {
    // fontSize: 10
  },
  capitalize: {
    textTransform: "capitalize",
  },
  dl: {
    fontSize: 10,
    fontWeight: 500,
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  access: {
    maxWidth: 150,
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, item = {}, accountBaseUrl, isViewOnly } = this.props;
    const driver = item.driver || {};

    return (
      <TableRow hover className={classes.tableRow}>
        <TableCell className={`${classes.tableCell} ${classes.capitalize}`}>
          {item.firstName} {item.lastName}
          <div className={classes.dl}>
            {driver.licenseState && (
              <div>
                {`Lic: `}
                {driver.licenseNumber}
                {` (${driver.licenseState})`}
              </div>
            )}
          </div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div>{`Email: ${item.email}`}</div>
          {item.mobile ? <div>{`Ph: ${item.mobile}`}</div> : null}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {item.status}
          <div>{item.role}</div>
        </TableCell>

        <TableCell className={`${classes.tableCell} ${classes.access}`}>
          {item.scopes && Array.isArray(item.scopes) && (
            <div>{item.scopes.map((_item) => ` ${_item.name}`).toString()}</div>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {/* {driver.regulation || "ELD"} */}
          <div>{driver.cargo}</div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {driver.driverId && item.role === "DRIVER" && (
            <Link to={`${accountBaseUrl}/status/diary/${driver.driverId}`}>
              {LABELS.dutyLogs}
            </Link>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <SmsIcon
            className={classes.EditIcon}
            onClick={() => this.props.handleSms(item)}
          />{" "}
          {!isViewOnly && (
            <EditIcon
              className={classes.EditIcon}
              onClick={() => this.props.handleEdit(item)}
            />
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export class ReduxTable extends React.Component {
  state = {
    order: "asc",
  };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ limit, page });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ limit, page });
  };

  handleSort = (field) => {
    const { page, limit } = this.props.pagination;
    const order = this.state.order === "desc" ? "asc" : "desc";

    this.setState({ order });
    this.props.handleChange({ page, limit, order: `${field} ${order}` });
  };

  render() {
    const { classes, items = [], accountBaseUrl } = this.props;
    const { page, limit, count, isViewOnly } = this.props;
    const itemArray = sorting(items, "firstName");

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.name}</TableCell>
              <TableCell>{LABELS.contact}</TableCell>
              <TableCell>{LABELS.roles}</TableCell>
              <TableCell>{LABELS.access}</TableCell>
              <TableCell>{LABELS.cargo}</TableCell>
              <TableCell>{LABELS.hos}</TableCell>
              {!isViewOnly && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(itemArray) &&
              itemArray.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  classes={classes}
                  handleSms={this.props.handleSms}
                  handleEdit={this.props.handleEdit}
                  formatDate={this.props.formatDate}
                  viewDetails={this.props.viewDetails}
                  accountBaseUrl={accountBaseUrl}
                  isViewOnly={isViewOnly}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          labelRowsPerPage={LABELS.rowsperpage}
        />
      </Paper>
    );
  }
}

ReduxTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReduxTable);
