import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import {
  createAdminShipping,
  updateAdminShipping,
} from "../../actions/adminSupportActions";
import {
  formatDateTimeUtc,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import Grid from "@mui/material/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import AdminAccountSearch from "../../components/AdminAccountSearch";
import Error from "../Error";
import Success from "../Success";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function UpdateUserForm(props) {
  async function handleSubmit(values) {
    // console.log("values = ", values);
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await props.updateChat(values);
      // console.log("response = ", response);
      if (response && response._id) {
        setLoading(false);
        setSuccessMessage("Saveed successfully");
        setTimeout(() => {
          props.handleClose();
        }, 1000);
      } else {
        setLoading(false);
        setErrorMessage("Not able to save, please try after some time");
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Not able to save, please try after some time");
    }
  }

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const classes = props.classes || [];
  const constants = props.constants || [];
  const selected = props.selected || {};
  const isSmallScreen = window.innerWidth < 400;
  const selAcc = selected.account || {};
  let initialValues = {
    _id: selected._id,
    status: selected.status,
    account: {
      id: selAcc.id,
      name: selAcc.name || "",
      alias: selAcc.alias || "",
      eld: selAcc.provider || "",
      plan: selAcc.plan || "STANDARD",
    },
  };

  let selectedAcc = { id: selAcc.id || "", name: selAcc.name || "" };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"lg"}
      className={classes.wrapper}
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography>{`Update`}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validate={() => {}}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <AdminAccountSearch
                    md={12}
                    selValue={"_id"}
                    selLabel={"name"}
                    name="accountId"
                    label={`Accounts`}
                    selected={selectedAcc}
                    onChange={(_sel) => {
                      if (_sel && _sel._id) {
                        const plan = _get(_sel, "plan.plan.name", "");
                        const uName = _get(selected, "from.name");
                        setFieldValue("account.id", _sel._id);
                        setFieldValue("account.provider", _sel.app);
                        setFieldValue("account.name", _sel.name);
                        setFieldValue("account.alias", `${uName}`);
                        setFieldValue(
                          "account.plan",
                          _get(_sel, "plan.plan.name", "STANDARD")
                        );
                      }
                    }}
                  />
                  <InputBox md={3} name="account.alias" label={`Alias`} />
                  <InputBox md={3} name="account.name" label={`Name`} />
                  <SelectBox
                    md={3}
                    name="account.plan"
                    label={`Plan`}
                    items={constants.PLAN_NAME_OPTIONS}
                  />
                  <SelectBox
                    md={3}
                    name="status"
                    label={`Status`}
                    items={constants.ALL_STATUS_OPTIONS}
                  />
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
                <DialogActions className={classes.DialogActions}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "SAVE"}
                  </Button>
                  <Button variant="contained" onClick={props.handleClose}>
                    {`Close`}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(UpdateUserForm);
