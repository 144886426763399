import React from "react";
import _get from "lodash/get";
import { Alert } from "@mui/lab";
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles'
import { EXCEPTIONS } from "../../constants";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        margin: "10px auto 15px"
    }
}));

export default function Exceptions(props) {
    const classes = useStyles();
    const exceptions = _get(props, "dailyDiary[0].exceptions", []);

    if (!exceptions || !Array.isArray(exceptions) || exceptions.length === 0) {
        return null;
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            {exceptions.map(item => {
                return (
                    <Grid item key={item._id} xs={4} md={4}>
                        <Alert
                            severity="info"
                            style={{ width: "100%", marginTop: 3 }}
                        >
                            {`Exception : ${EXCEPTIONS[item.id]} (${props.formatTime(item.time)})`}
                        </Alert>
                    </Grid>
                );
            })}
        </Grid>
    );
}
