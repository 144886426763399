import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
// import NavigateNext from "@mui/icons-material/NavigateNext";
// import NavigateBefore from "@mui/icons-material/NavigateBefore";
// import IconButton from "@mui/material/IconButton";
import {
  addDay,
  subtractDay,
  formatRawDate,
  isToday,
} from "../../actions/momentActions";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom-styles.css";

const styles = (theme) => ({
  wrapper: { width: "fit-content" },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "200px",
    textAlign: "left",
  },
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
    marginTop: 10,
  },
  dateWrapper: {
    backgroundColor: "#009be5",
    paddingTop: 1,
    display: "inline-block",
    borderRadius: 5,
  },
  dateWrapperDisabled: {
    backgroundColor: "#c5ccd0",
    paddingTop: 1,
    display: "inline-block",
    borderRadius: 5,
  },
  dateButton: {
    padding: "5px 0px",
  },
  colorWhite: {
    color: "white",
  },
  dateInput: {
    backgroundColor: "transparent",
    // border: 0,
    // fontSize: 14,
    width: 170,
    padding: 5,
    textAlign: "center",
    // color: "white",
    // fontWeight: 600,
    border: "1px solid #ccc",
    borderRadius: 5,
    marginTop: 10,
  },
  dateIconBtn: {
    padding: 0,
  },
  colorDisabled: {
    color: "#c5ccd0",
    // color: "#009be5"
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
});

function DateRangePicker(props) {
  const classes = props.classes;
  const _startDate = props.startDate ? new Date(props.startDate) : null;
  const _endDate = props.endDate ? new Date(props.endDate) : null;
  const [dateRange, setDateRange] = useState([_startDate, _endDate]);
  const [startDate, endDate] = dateRange;

  const dateMax = new Date();
  const theDayOfTheMonthOnNextWeek = dateMax.getDate() + 1;
  dateMax.setDate(theDayOfTheMonthOnNextWeek);

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ marginLeft: 120 }}>
        <CalendarContainer className={className}>
          <div>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <DatePicker
      id={props.id}
      name={props.name || props.id}
      className={classes.dateInput}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        if (update[0] && update[1]) {
          props.onChange(update);
        }
      }}
      maxDate={dateMax}
      calendarContainer={MyContainer}
    />
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ formatRawDate, addDay, subtractDay, isToday }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DateRangePicker)
);
