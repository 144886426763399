import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RECORD_ORIGIN } from "../../../constantsStatus";
import DutyStatus from "../../../components/DutyStatus";
// import { CircularProgress } from "@mui/material";
// import { validateEngHr, validateOdometer, validateLocation } from "../helpers";
import { LABELS as _LABELS } from "../../../language";

function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

const LABELS = _LABELS.dutyPage;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableHeaderCell: {
    textAlign: "left",
    padding: 10,
    minWidth: 55,
  },
  tableRowCell: {
    fontSize: 12,
    padding: 10,
  },
  cycleReset: {
    // textAlign: "left",
    // marginLeft: 55,
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textInactive: {
    textDecoration: "line-through",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  errorText: {
    color: "red",
  },
});

class ReduxTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  render() {
    const {
      classes,
      item = {},
      isAllLog = false,
      isCoDriver = false,
      timezone,
    } = this.props;

    const tableClass =
      item.recordStatus === "INACTIVE_CHANGE_REQUEST"
        ? ""
        : item.recordStatus === "ACTIVE"
        ? ""
        : `${classes.textInactive}`;

    const isCertify = item.eventType === "CERTIFICATION";
    const evtTime = item.eventTimeOrg || item.eventTime;
    const deleteTm = item.deleted;
    const newEventTime = item.newEventTime;
    const deleteBy = _get(item, "deletedBy.email", "");

    const eventTime = this.props.formatTime(evtTime, timezone);
    const eventDate = this.props.formatRawDate(evtTime, timezone);
    const deleteTime = this.props.formatTime(deleteTm, timezone);
    const deleteDate = this.props.formatRawDate(deleteTm, timezone);

    const newTime = newEventTime
      ? this.props.formatTime(newEventTime, timezone)
      : "";
    const newDate = newEventTime
      ? this.props.formatRawDate(newEventTime, timezone)
      : "";

    return (
      <TableRow hover className={`${classes.table} ${tableClass}`}>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>
            <strong>{`${item.action}`}</strong>
            <div>
              <small>{`${deleteDate} @ ${deleteTime}`}</small>
            </div>
            <small>{`By ${deleteBy}`}</small>
          </div>
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>
            <strong>{`${eventDate} ${eventTime}`}</strong>
          </div>
          {newEventTime && (
            <div style={{ fontSize: 10 }}>
              <strong>{`N: ${newDate} ${newTime}`}</strong>
            </div>
          )}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <DutyStatus
            status={item}
            tableClass={tableClass}
            eventType={item.eventType}
            eventCode={item.eventCode}
            annotation={item.annotation}
            eventSubType={item.eventSubType}
          />

          <div style={{ fontSize: 10 }}>{item.recordStatus}</div>
          {/*={item.recordStatus}={item.eventType} = {item.eventCode} ={" "}
                    {item.eventSubType}*/}
          {/*(isYard || isPersonal) && (
                        <small className={classes.upperCase}>
                            {item.eventType}
                        </small>
                    )*/}
          {/*<small>{item.eventType}</small>*/}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>{item.location}</div>
        </TableCell>

        <TableCell className={`${classes.tableRowCell}`}>
          {item.odometer && (
            <div className={classes.errorText}>
              {isCertify ? "" : pF(item.odometer).toFixed()}
            </div>
          )}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {item.engineHours}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {RECORD_ORIGIN[item.recordOrigin]}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>{item.annotation}</div>
        </TableCell>

        <TableCell className={`${classes.tableRowCell}`}>
          <>
            {item.sequenceId && <div>{`Sq Id: ${item.sequenceId}`}</div>}
            {item.shippingDoc && <div>{`Shipping: ${item.shippingDoc}`}</div>}
            {item.trailer && <div>{`Trailer: ${item.trailer}`}</div>}
          </>
        </TableCell>
      </TableRow>
    );
  }
}

export class StatusAuditTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // updateSelectedLogs
      allChecked: false,
      driverLogs: props.driverLogs,
    };
  }

  componentDidMount() {}

  isCoDriver = () => {
    const dailyDiary = this.props.dailyDiary || [];

    let isExist = false;

    for (let i = 0; i < dailyDiary.length; i++) {
      const coD = dailyDiary[i].coDriver || [];

      if (coD && Array.isArray(coD) && coD.length > 0) {
        isExist = true;
        break;
      }
    }

    return isExist;
  };

  render() {
    const {
      classes,
      dailyDiary,
      cycleStart,
      isDebug,
      adminKey,
      isAdminDs,
      isAllLog,
      isUnity,
      isFmcsaSend,
      isViewOnly,
    } = this.props;
    const driverLogs = this.state.driverLogs;
    const allChecked = this.state.allChecked;
    const { rDOpen, isConfirmOpen, selected, deleting } = this.state;
    const isCoDriver = this.isCoDriver();
    const role = _get(this, "props.profile[0]", "");
    const timezone = _get(this, "props.dailyDiary[0].timezone");

    return (
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.action}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.time}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.status}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Location}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.odometerMi}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.EngineHours}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Origin}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Annotation}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.details}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(driverLogs) &&
                driverLogs.map((item, i) => {
                  return (
                    <ReduxTableRow
                      item={item}
                      key={i}
                      index={i}
                      classes={classes}
                      cycleStart={cycleStart}
                      isCoDriver={isCoDriver}
                      isDebug={isDebug}
                      isAllLog={this.props.isAllLog}
                      isAdminDs={isAdminDs}
                      timezone={timezone}
                      isFmcsaSend={isFmcsaSend}
                      formatTime={this.props.formatTime}
                      searchLogStatus={this.props.searchLogStatus}
                      setSelectedLog={this.props.setSelectedLog}
                      handleReassignDrivingModal={
                        this.handleReassignDrivingModal
                      }
                      formatRawDate={this.props.formatRawDate}
                      deleteAdminLogs={(selected) => {
                        this.setState({
                          selected,
                          isConfirmOpen: true,
                        });
                      }}
                      handleLogEdit={this.props.handleLogEdit}
                      adminScope={this.props.adminScope}
                      role={role}
                      isQa={this.props.isQa}
                      adminKey={adminKey}
                      isUnity={isUnity}
                      handleCheckChange={this.handleCheckChange}
                      formatTimeSec={this.props.formatTimeSec}
                      isViewOnly={isViewOnly}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

StatusAuditTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatusAuditTable);
