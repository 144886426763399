import _get from "lodash/get";


export function getInitialValues(props) {
    const item = _get(props, "selected", {});
    if (item && item._id) {
        return {
            // status: item.status,
            name: item.name,
            // expiry: item.expiry,
            scopes: item.scopes,
            active: item.active
        };
    } else {
        return {
            name: "",
            expiry: props.addYears(new Date(), 1),
            scopes: "",
            active: true
        };
    }
}

export function getPayload(params) {
    let payload = {
        name: params.name,
        scopes: params.scopes,
        // active: params.active,
        expiry: params.expiry,
    };
    return payload;
}
