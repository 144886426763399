import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { getAllUsers } from "../../actions/adminDashActions";
import { getResellerById } from "../../actions/reseller/resellerActions";
import { formatDate } from "../../actions/momentActions";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {LABELS as _LABELS} from "../../language";
// import CircularProgress from '@mui/material/CircularProgress';
// import { formatDate } from "../../actions/momentActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const LABELS=_LABELS.settingPage;

const styles = (theme) => ({
  root: {
    margin: 15,
  },
  title: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  statusButton: {
    margin: "0 2vh 0 2vh",
    float: "right",
  },
});

function RenderItem(props) {

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={3}>
        {props.item}
      </Grid>
      <Grid item xs={8} md={9}>
        {props.value}
      </Grid>
    </Grid>
  );
}

export class ResellerDetail extends React.Component {
  state = {
    loading: true,
    isEditOpen: false,
    dataLoading: false,
    reseller: null,
    users: [],
  };

  async componentDidMount() {
    await this.getResellerById();
    await this.getAllUsers();
  }

  getAllUsers = async () => {
    try {
      const resellerId = this.props.match.params.resellerId;
      const users = await this.props.getAllUsers({
        resellerId,
        searchPartner: "ON",
      });
      if (users && Array.isArray(users)) {
        this.setState({ users });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getResellerById = async () => {
    const id = this.props.match.params.resellerId;
    const reseller = await this.props.getResellerById({ id });
    this.setState({ reseller, loading: false });
  };

  render() {
    const classes = this.props.classes;
    const users = this.state.users || [];
    const reseller = this.state.reseller || {};
    const contact = reseller.contact || {};
    const billing = reseller.billing || {};
    
    // return (
    //   <Card className={classes.root}>
    //     <CardActionArea>
    //       <CardContent className={classes.paper}>
    //         <Grid container spacing={2}>
    //           <Grid item xs={12} md="12" className={classes.title}>
    //             <Typography gutterBottom variant="h5" component="h2">
    //               {reseller.name}
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={12} md={6}>
    //             <Typography variant="h6" component="h4">
    //               {/* {"Detail"} */}
    //               {LABELS.detail}
    //             </Typography>
    //             <RenderItem item="Status" value={reseller.status} />
    //             <RenderItem item="Type" value={reseller.bussType} />
    //             <RenderItem item="Created" value={reseller.created} />
    //             <RenderItem item="Edited" value={reseller.edited} />
    //             <br />
    //             <Typography variant="h6" component="h4">
    //               {/* {"Contact"} */}
    //               {LABELS.contact}
    //             </Typography>
    //             <RenderItem item="Name" value={contact.name} />
    //             <RenderItem item="Phone" value={contact.phone} />
    //             <RenderItem item="Email" value={contact.email} />
    //             <RenderItem item="Role" value={contact.role} />
    //             <br />
    //             <Typography variant="h6" component="h4">
    //               {/* {"Billing"} */}
    //               {LABELS.billing}
    //             </Typography>
    //             <RenderItem item="Name" value={billing.name} />
    //             <RenderItem item="Phone" value={billing.phone} />
    //             <RenderItem item="Email" value={billing.email} />
    //             <RenderItem item="Role" value={billing.role} />
    //           </Grid>
    //           <Grid item xs={12} md={6}>
    //             <Typography variant="h6" component="h4">
    //               {/* {"Login Users"} */}
    //               {LABELS.loging_users}
    //             </Typography>
    //           </Grid>
    return (
      <Card className={classes.root}>
        <CardContent className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className={classes.title}>
              <Typography gutterBottom variant="h5" component="h2">
                {reseller.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h4">
                {LABELS._details}
              </Typography>
              <RenderItem item={LABELS.status} value={reseller.status} />
              <RenderItem item="EIN" value={reseller.ein} />
              <RenderItem
                item={LABELS.created}
                value={this.props.formatDate(reseller.created)}
              />
              <RenderItem
                item={LABELS.edited}
                value={this.props.formatDate(reseller.edited)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h4">
                {LABELS.contact}
              </Typography>
              <RenderItem item={LABELS.name} value={contact.name} />
              <RenderItem item={LABELS.phone} value={contact.phone} />
              <RenderItem item={LABELS.email} value={contact.email} />
              <RenderItem item={LABELS.role} value={contact.role} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h4">
                {LABELS.billing}
              </Typography>
              <RenderItem item= {LABELS.name} value={billing.name} />
              <RenderItem item= {LABELS.phone} value={billing.phone} />
              <RenderItem item={LABELS.email} value={billing.email} />
              <RenderItem item={LABELS.role} value={billing.role} />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: 25 }}>
              <Typography variant="h6" component="h4">
                {LABELS.logingUsers}
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{LABELS.name}</TableCell>
                      <TableCell>{LABELS.status}</TableCell>
                      <TableCell>{LABELS.access}</TableCell>
                      <TableCell>{LABELS.contact}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users.map((user) => (
                        <TableRow key={user.id}>
                          <TableCell component="th" scope="user">
                            {user.firstName} {user.lastName}
                          </TableCell>
                          <TableCell align="left">{user.status}</TableCell>
                          <TableCell align="left">
                            <div>{`Role: ${
                              user.roles && user.roles.map((item) => item.name)
                            }`}</div>
                            <div>{`Scope: ${
                              user.scopes &&
                              user.scopes.map((item) => item.name)
                            }`}</div>
                          </TableCell>
                          <TableCell align="left">
                            <div>{`Mobile: ${user.mobile}`}</div>
                            <div>{`Email: ${user.email}`}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getResellerById,
      getAllUsers,
      formatDate,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ResellerDetail))
);
