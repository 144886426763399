export const LABELS={
    leads:"ਲੀਡ",

    channel:"ਚੈਨਲ",
    status:"ਸਥਿਤੀ",
    dot:"ਡੀ.ਓ.ਟੀ",
    name:"ਨਾਮ",
    adress_line:"ਐਡਰੈੱਸ ਲਾਈਨ",
    address_city:"ਪਤਾ ਸ਼ਹਿਰ",
    address_state:"ਪਤਾ ਰਾਜ",
    address_zip:"ਪਤਾ ਜ਼ਿਪ",
    contact_name:"ਸੰਪਰਕ ਨਾਮ",
    contact_email:"ਸੰਪਰਕ ਈਮੇਲ",
    contact_phone:" ਸੰਪਰਕ ਫ਼ੋਨ",
    callback:"ਵਾਪਸ ਫੋਨ ਮਲਾਓ",
    description:"ਵਰਣਨ",

    assignedto:"ਨੂੰ ਦਿੱਤਾ",
    assignee_email:"ਜ਼ਿੰਮੇਦਾਰ ਈਮੇਲ",

    saving:"ਸੰਭਾਲ ਰਿਹਾ ਹੈ",
    save:"ਸੇਵ ਕਰੋ",
    close:"ਬੰਦ ਕਰੋ",



    lead_is_saved_successfully:"ਲੀਡ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ ਹੈ",
    something_went_wrong_please_try_after_some_time:"ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।.",




}
