const numeral = require("numeral");

export function formatNumber(value) {
  return numeral(value).format("0,0");
}

export function formatCurrencyRaw(value) {
  if (parseFloat(value)) {
    return numeral(value).format("00.00");
  }
  return numeral(0).format("00.00");
}

export function formatCurrency(value) {
  if (parseFloat(value)) {
    return numeral(value).format("$0,0.00");
  }
  return numeral(0).format("$0,0.00");
}

export function formatPhoneNo(phoneNumber) {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumber;
}

export function minToHrMin(totalMinutes) {
  const __hours = Math.floor(totalMinutes / 60);
  const __minutes = totalMinutes % 60;
  const _hours = `${Math.round(__hours)}`;
  const _minutes = `${Math.round(__minutes)}`;
  const hr = _hours.length === 1 ? `0${_hours}` : _hours;
  const min = _minutes.length === 1 ? `0${_minutes}` : _minutes;

  return { hr, min };
}
