import { Link } from "react-router-dom";
import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import offDutyTruck from "../../utils/icons/offDutyTruck.png";
import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
import NotFoundTableRow from "../NotFoundTableRow";
import DvirDialog from "./DvirDialog";
import EditDvirDialog from "./EditDvirDialog";
import ConfirmActionModal from "../ConfirmActionModal";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
});

class ReduxTableRow extends React.Component {
  handleDownload = (e, item) => {};

  render() {
    const { accountBaseUrl, adminKey, classes, item = {} } = this.props;
    const timezone = this.props.timezone;

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          <div className={classes.capitalize}>
            {item.inspectionType}
            <div>{item.templateId}</div>
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          {item.status}
          <div className={classes.capitalize}>{item.vehicleType}</div>
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>
            <div>
              <img
                style={{
                  width: 15,
                  top: 3,
                  position: "relative",
                  marginRight: "5px",
                }}
                alt={"Truck"}
                src={offDutyTruck}
              />
              <span className={classes.capitalize}>{item.vehicleName}</span>
            </div>
            {item.driverName ? (
              <div className={classes.capitalize}>{item.driverName}</div>
            ) : (
              <div className={classes.capitalize}>{item.inspectorType}</div>
            )}
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className={classes.dataMedRow}>
            <div>
              Status:{" "}
              <span className={classes.capitalize}>
                {item.equipmentCondition}
              </span>
            </div>
            <div>
              Odometer: <span>{item.odometer} Miles</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.dataMedRow}>
            {item.created && (
              <div>
                Start: {this.props.formatDateTime(item.startTime, timezone)}
              </div>
            )}
            {item.endTime && (
              <div>
                End: {this.props.formatDateTime(item.endTime, timezone)}
              </div>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>{item.location}</div>
        </TableCell>
        <TableCell>
          <Button
            className={classes.buttonLink}
            color="primary"
            onClick={() => this.props.onSelect(item)}
          >
            {`${LABELS.view}`}
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export class DvirTable extends React.Component {
  state = {
    order: "asc",
    limit: 10,
    page: 0,
    isView: false,
    isEdit: false,
    selected: null,
    deleting: false,
  };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  getAllDvir = () => {
    const { limit, page } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    this.props.getAllDvir({ limit, skip });
  };

  onDeleteConfirm = (data) => {
    this.setState({ selected: data, isConfirmOpen: true });
  };

  onDelete = async () => {
    const id = _get(this, "state.selected._id");

    this.setState({ deleting: true });
    try {
      const response = await this.props.deleteDvir({ id });

      if (response && response._id) {
        this.setState({ successMessageDelete: "Deleted sucessfully." });
        this.getAllDvir();
        setTimeout(() => {
          this.setState({
            isConfirmOpen: false,
            deleting: false,
            selected: null,
          });
        }, 1000);
      } else {
        this.setState({
          deleting: false,
          errorMessageDelete: `${LABELS.errorMassage}`,
        });
      }
    } catch (e) {
      this.setState({
        deleting: false,
        errorMessageDelete: `${LABELS.errorMassage}`,
      });
    }
  };

  render() {
    const { eld, classes, items = [], handleEdit, count = 100 } = this.props;

    const { limit, page } = this.props;
    const isView = this.state.isView;
    const isEdit = this.state.isEdit;
    const isConfirmOpen = this.state.isConfirmOpen;
    const selected = this.state.selected;
    const deleting = this.state.deleting;
    const errorMessageDelete = this.state.errorMessageDelete;
    const successMessageDelete = this.state.successMessageDelete;
    const timezone = this.props.timezone;

    return (
      <Paper className={classes.root}>
        {isView && selected && (
          <DvirDialog
            open={isView}
            selected={selected}
            onDeleteConfirm={this.onDeleteConfirm}
            onClose={() => {
              this.setState({ selected: null, isView: false });
            }}
          />
        )}
        {isConfirmOpen && selected && (
          <ConfirmActionModal
            open={isConfirmOpen}
            selected={selected}
            loading={deleting}
            errorMessage={errorMessageDelete}
            successMessage={successMessageDelete}
            handleClose={() =>
              this.setState({
                isConfirmOpen: false,
                deleting: false,
                selected: null,
              })
            }
            handleConfirm={this.onDelete}
            label={`${LABELS.delete}`}
            message={`${LABELS.are_you_sure_WantTo_delete}`}
          />
        )}
        {isEdit && selected && (
          <EditDvirDialog
            open={isEdit}
            selected={selected}
            onDeleteConfirm={this.onDeleteConfirm}
            handleClose={() => {
              this.setState({ selected: null, isEdit: false });
            }}
            afterSave={() => {
              this.setState({ selected: null, isEdit: false }, () =>
                this.getAllDvir()
              );
            }}
          />
        )}
        <h4>{"Dvirs"}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.type}</TableCell>
              <TableCell>{LABELS.status}</TableCell>
              <TableCell>{LABELS.inspector}</TableCell>
              <TableCell>{LABELS.condition}</TableCell>
              <TableCell>{LABELS.start_end}</TableCell>
              <TableCell>{LABELS.location}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  eld={eld}
                  classes={classes}
                  handleEdit={handleEdit}
                  timezone={timezone}
                  formatDateTime={this.props.formatDateTime}
                  adminKey={this.props.adminKey}
                  onSelect={(selected) => {
                    this.setState({ selected, isView: true });
                  }}
                  onEditSelect={(selected) => {
                    this.setState({ selected, isEdit: true });
                  }}
                  accountBaseUrl={this.props.accountBaseUrl}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          labelRowsPerPage={LABELS.rowsperpage}
        />
      </Paper>
    );
  }
}

DvirTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DvirTable);
