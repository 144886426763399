const defaultState = {
  maintenance: [],
  maintenanceCount: 0,
  warnings: [],
};

export default function maintenance(state = defaultState, action) {
  switch (action.type) {
    case "MAINTENANCE_COUNT":
      return {
        ...state,
        maintenanceCount: action.value,
      };
    case "MAINTENANCES":
      return {
        ...state,
        maintenance: action.value,
      };
    case "MAINTENANCE_WARNING":
      return {
        ...state,
        warnings: action.value,
      };
    default:
      return state;
  }
}
