export const LABELS = {
  saveMessage: "Saved sucessfully.",
  errorMessage: "Please try after sometime.",
  deleteLabel: "Delete Violations",
  deleteMsg: "Are you sure, you want to delete all violations",
  language: "Language",
  noDriverFound:'No driver found',

  selectLanguage:"Select Language",
  saving:"Saving...",
  save:"Save",
  close:"Close",


};
