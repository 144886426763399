import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Download from "../../components/Download";
import NotFoundTableRow from "../NotFoundTableRow";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.settingPage;

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    label: {
        display: "inline-block",
        minWidth: 65
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    }
});

class ReduxTableRow extends React.Component {
    state = {};

    render() {
        const { classes, item = {} } = this.props;

        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    <div className={classes.capitalize}>{item.type}</div>
                </TableCell>
                <TableCell>
                    {item.details && (
                        <div>
                            {item.details.manifest && (
                                <div className={classes.capitalize}>
                                    <span className={classes.label}>
                                        Manifest:
                                    </span>{" "}
                                    {item.details.manifest}
                                </div>
                            )}
                            {item.details.reference && (
                                <div className={classes.capitalize}>
                                    <span className={classes.label}>
                                        Reference:
                                    </span>{" "}
                                    {item.details.reference}
                                </div>
                            )}
                            {item.details.shipper && (
                                <div className={classes.capitalize}>
                                    <span className={classes.label}>
                                        Shipper:
                                    </span>{" "}
                                    {item.details.shipper}
                                </div>
                            )}
                            {item.details.gallons && (
                                <div className={classes.capitalize}>
                                    <span className={classes.label}>
                                        Gallons:
                                    </span>{" "}
                                    {item.details.gallons}
                                </div>
                            )}
                            {item.details.truckStop && (
                                <div className={classes.capitalize}>
                                    <span className={classes.label}>
                                        TruckStop:
                                    </span>{" "}
                                    {item.details.truckStop}
                                </div>
                            )}
                            {item.details.city && (
                                <div className={classes.capitalize}>
                                    <span className={classes.label}>
                                        Address:
                                    </span>{" "}
                                    {item.details.city} {item.details.state}
                                </div>
                            )}
                        </div>
                    )}
                </TableCell>
                <TableCell>
                    <div className={classes.dataLargeRow}>
                        {item.date && (
                            <div>{this.props.formatDateTime(item.date)}</div>
                        )}
                    </div>
                </TableCell>
                <TableCell>{item.note}</TableCell>
                <TableCell>
                    <Download media={item.media} />
                </TableCell>
            </TableRow>
        );
    }
}

export class DocumentTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
        // this.setState({ page }, this.getAllDocument);
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
        // this.setState({ limit }, this.getAllDocument);
    };


    render() {
        const {
            eld,
            classes,
            items = [],
            handleEdit,
            count = 100
        } = this.props;

        // const { limit = 10, page = 1 } = this.state;
        const { page, limit } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{LABELS.type}</TableCell>
                            <TableCell>{LABELS._details}</TableCell>
                            <TableCell>{LABELS.date}</TableCell>
                            <TableCell>{LABELS._note}</TableCell>
                            <TableCell>{LABELS._view}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    eld={eld}
                                    classes={classes}
                                    handleEdit={handleEdit}
                                    downloadImage={this.props.downloadImage}
                                    formatDateTime={this.props.formatDateTime}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    labelRowsPerPage={LABELS.rowsperpage}
                />
            </Paper>
        );
    }
}

DocumentTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DocumentTable);
