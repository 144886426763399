import axios from "./axios";
import _get from "lodash/get";

export function getImagePostUrl(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/imagePostUrl`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data");
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}


export function getImageGetUrl(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/imageGetUrl`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data");
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}
