import React from "react";
import { connect } from "react-redux";
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Error from "../Error";
import Success from "../Success";
import TextField from '@mui/material/TextField';
import { LABELS as _LABELS } from "../../language";

const LABELS=_LABELS.settingPage;

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 25,
    // overflow: "scroll"
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
    float: "right",
  },
  driverHeader: {
    textAlign: "center",
    marginLeft: 25,
    padding: 10,
    fontWeight: 600,
    marginBottom: "30px",
  },
  accountRow: {
    padding: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 10,
  },
  accountTitle: {
    display: "inline-block",
    minWidth: 150,
  },
});

export class SettingEditReasonDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editReasons: props.editReasons,
      errorMessage: "",
      successMessage: "",
      dcId: props.accountSettings.dcId,
      // gps: props.accountSettings.gps,
    };
  }

  componentDidMount() {}

  handleToggle = (e, item) => {
    const allItem = this.state.editReasons;
    const editReasons = allItem.map((_item) => {
      if (_item.en === item.en) {
        _item.active = e.target.checked;
      }
      return _item;
    });
    this.setState({ editReasons });
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  save = async (e, items) => {
    const response = await this.props.updateAccountProperties({
      accountSettingsId: this.props.accountSettingsId,
      editReasons: items,
      // gps: this.state.gps,
      dcId: this.state.dcId,
    });

    if (response._id) {
      this.setState({
        successMessage: LABELS.editReasonUpdated,
      });
    } else {
      this.setState({
        errorMessage: LABELS.response,
      });
    }
  };

  render() {
    const {  dcId } = this.state;
    const { classes, accountUpdateBusy } = this.props;
    const { editReasons, errorMessage, successMessage } = this.state;

    return (
      <Paper className={classes.paper}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                id="dcId"
                label={LABELS.dashcameraId}
                defaultValue={dcId}
                onChange={(e) => this.handleInputChange("dcId", e.target.value)}
              />
            </Grid>
            {/*<Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="gps"
                label="Gps Id"
                defaultValue={gps}
                onChange={(e) => this.handleInputChange("gps", e.target.value)}
              />
            </Grid>*/}
          </Grid>
        </form>
        <div className={classes.driverHeader}>{LABELS.editReasons}</div>
        <List className={classes.root}>
          <Grid>
            {Array.isArray(editReasons) &&
              editReasons.map((item, i) => (
                <ListItem key={i} role="listitem" button>
                  <ListItemIcon>
                    <Checkbox
                      onClick={(e) => this.handleToggle(e, item)}
                      checked={item.active}
                      tabIndex={-1}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.en} />
                </ListItem>
              ))}
          </Grid>
        </List>
        <Error top={20} message={errorMessage} />
        <Success top={20} message={successMessage} />
        <div className={classes.driverHeader}>
          <Button
            color="primary"
            disabled={accountUpdateBusy}
            variant="contained"
            className={classes.button}
            onClick={(e) => this.save(e, editReasons)}
          >
            {/* {accountUpdateBusy ? "Saving..." : "SAVE"} */}
            {accountUpdateBusy ?LABELS.saving :LABELS.save}
          </Button>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {})(SettingEditReasonDetail)
);
