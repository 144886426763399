export const LABELS = {
  profileInfo: "प्रोफ़ाइल जानकारी",
  accountInfo: "खाता जानकारी",
  name: "नाम",
  email: "ईमेल",
  status: "स्थिति",
  language: "भाषा",
  uuid: "यूयूआईडी",
  roles: "भूमिकाएँ",
  account: "खाता",
  accountId: "खाता आईडी",
  save: "सहेजें",
  edit: "संपादित करें",
  phone: "फोन",
  carrier: "वाहक",
  terminal: "टर्मिनल",
  required: "आवश्यक",
};

