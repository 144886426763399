const defaultState = {
    carrierBusy: false,
    carriers: [],
    selectedCarrier: {}
}

export default function carrier(state = defaultState, action) {
    switch (action.type) {
    case 'CARRIERS':
        return {
            ...state,
            carriers: action.value
        }
    case 'SELCTED_CARRIER':
        return {
            ...state,
            selectedCarrier: action.value
        }
    case 'APP_CARRIER_BUSY':
        return {
            ...state,
            carrierBusy: action.value
        }
    default:
        return state
    }
}
