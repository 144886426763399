import { name, website, companyName, email } from "../../clientConfig";

export function getTerms() {
  const items = [
    {
      title: "",
      content: `This Privacy Policy governs the collection, use, maintenance,
                and disclosure of information by ${companyName}. (“${name},”
                “we,” “our,” or “us”) from users (“you” or “your”) of our
                website, products, and services (“Services”). This Policy
                applies to the collection and processing of personal data in
                accordance with applicable laws and regulations, including the
                Data Protection Act.`,
    },
    {
      title: "",
      content: `Personal Information refers to any information that identifies, relates to, describes, or is capable of being associated with, directly or indirectly, an individual user.`,
    },
    {
      title: "",
      content: `Device Data includes information about your device, such as its unique device identifier, IP address, operating system, and browser type.`,
    },
    {
      title: "",
      content: `Internet Activity encompasses data related to your online activity, such as browsing history, interactions with our website, and internet protocol (IP) addresses.`,
    },
    {
      title: "",
      content: `Location Data refers to information about your device’s geographic location, such as GPS coordinates or Wi-Fi access points.`,
    },
    {
      title: "Severability",
      content: `Should any provision of this Privacy Policy be determined to be invalid, illegal, or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect. The invalid, illegal, or unenforceable provision shall be modified to the extent necessary to render it valid, legal, and enforceable while preserving its intent to the maximum extent permitted by law.`,
    },
    {
      title: "Governing Law",
      content: `This Privacy Policy and any dispute or claim arising out of or in connection with it (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law provisions. Any legal action or proceeding relating to this Privacy Policy shall be instituted exclusively in the federal or state courts located in the County of Alameda, California. Each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding.`,
    },
    {
      title: "Entire Agreement",
      content: `This Privacy Policy constitutes the entire agreement between you and ${name} regarding the collection, use, and disclosure of personal information pertaining to the use of our application and website. Any prior agreements, understandings, communications, representations, warranties, or commitments, whether oral or written, between you and ${name} regarding such matters are superseded by this Privacy Policy.`,
    },
    {
      title: "Modification of Terms",
      content: `${companyName} reserves the right to modify, amend, or update this Privacy Policy at any time without prior notice. Any changes to the policy will be effective immediately upon posting on this page. It is your responsibility to review this Privacy Policy periodically to stay informed of updates. Your continued use of our Android application and website after any modifications to the Privacy Policy constitutes acceptance of those changes.`,
    },
    {
      title: "Disclaimer of Liability",
      content: `${companyName}, its affiliates, officers, directors, employees, agents, and licensors shall not be liable to you or any third party for any damages of any kind arising out of or relating to the use of our application or website, including but not limited to any indirect, incidental, consequential, special, or punitive damages, or damages for loss of profits, revenue, data, or use, incurred by you or any third party, whether in an action in contract or tort, arising from your access to, or use of, our application or website.`,
    },
    {
      title: "Language Interpretation",
      content: `This Privacy Policy may be translated into multiple languages for the convenience of our users. In the event of any conflict or discrepancy between the English version and a translated version, the English language version shall prevail and be controlling to the fullest extent permitted by law.`,
    },
    {
      title: "Information Collection",
      content: `This section outlines the various types of information collected from users of the ${name} application (“App”), the methods employed for collection, such as website forms, cookies, and analytics tools, and elucidates the purposes for which such information is gathered. It serves to inform users comprehensively about the nature of data being collected and the rationale behind its collection.`,
    },
    {
      title: "Types of Information Collected",
      content: `${companyName} (“Company” or “We”) collects diverse categories of information from users (“you” or “your”) through multiple channels, including but not limited to the App’s website forms, cookies, and analytics tools. This encompasses personal data such as names, contact details, Commercial Driving License (CDL) numbers, along with non-identifiable information and internet usage details.`,
    },
    {
      title: "Methods of Collection",
      content: `Information is collected through various means, including user inputs in website forms, automated data capture via cookies, and analytical tools tracking user interactions with the App. These methods are utilized to ensure the efficiency and functionality of the services provided by ${companyName}`,
    },
    {
      title: "Purposes of Collection:",
      content: `The data collected is employed for several purposes, including but not limited to enhancing user experience, providing personalized services, and optimizing the performance of the App. Additionally, data may be utilized for analytical insights, improving service offerings, and complying with legal obligations`,
    },
    {
      title: "",
      content: `If you have any questions or concerns about this Privacy Policy, please contact us at ${email}.`,
    },
  ];
  return items;
}
