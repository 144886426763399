import axios from "axios";
// import axiosCo from "../axios";
import _get from "lodash/get";
import * as TYPES from "./coActionTypes";

export function setInvoices(value) {
  return { type: TYPES.INVOICES, value };
}

export function getDueInvoices() {
  return async (dispatch, getState) => {
    let customer = _get(getState(), "authData.profile.account.stripeBuss");
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    try {
      if (customer) {
        customer = customer.replace("SE:", "");
      }
      const params = { customer };
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/invoices`,
        method: "get",
        params: params,
      });
      let _response = _get(response, "data.data", []);
      dispatch(setInvoices(_response));
      return _response;
    } catch (error) {
      return null;
    }
  };
}

export function getDueInvoicesAcc() {
  return async (dispatch, getState) => {
    const customer = _get(getState(), "appData.appConfig.stripeCusId");
    // const providerId = _get(getState(), "authData.accountId");
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    try {
      const params = { customer };

      const response = await axios({
        url: `${BASE_URL}/admin/bill/account`,
        method: "get",
        params: params,
      });
      let _response = _get(response, "data.data", []);
      return _response;
    } catch (error) {
      return null;
    }
  };
}
