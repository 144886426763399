export const LABELS = {
  saveMessage: "सफलतापूर्वक सहेजा गया।",
  errorMessage: "कृपया कुछ समय बाद पुनः प्रयास करें।",
  deleteLabel: "उल्लंघन हटाएँ",
  deleteMsg: "क्या आप वाकई सभी उल्लंघनों को हटाना चाहते हैं?",
  language: "भाषा",
  noDriverFound: "कोई ड्राइवर नहीं मिला",


  selectLanguage:"भाषा चुने",
  saving:"सहेजा जा रहा है...",
  save:"जमा करना",
  close:"बंद करना",
  
};

