import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  getFmcsaHistoryCountAdmin,
  getFmcsaHistoryAdmin,
} from "../../actions/fmcsaActions";
import { formatDateTime, formatDate } from "../../actions/momentActions";
import NotFoundTableRow from "../NotFoundTableRow";
import SearchBar from "../../components/SearchBarAdmin";
import Button from "@mui/material/Button";
import {LABELS as _LABELS} from '../../language';

const LABELS=_LABELS.adminPage;

const styles = (theme) => ({});

function RenderLogs({ submissionId, logs, error }) {
  // Declare a new state variable, which we'll call "count"
  const [isVisible, setVisibility] = useState(false);
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setVisibility(!isVisible)}
        aria-labelledby="simple-dialog-title"
        open={isVisible}
      >
        <DialogTitle id="log-details">Detailed Report</DialogTitle>

        <DialogContent>
          {submissionId && <h3>Submission Id: {submissionId}</h3>}
          {error && <h3>Errors: {error}</h3>}
          {logs &&
            logs.map((_item, j) => (
              <DialogContentText key={j}>{_item}</DialogContentText>
            ))}
        </DialogContent>
      </Dialog>
      <Button
        variant="outlined"
        size="small"
        onClick={() => setVisibility(!isVisible)}
      >
        {LABELS.viewLogs}
      </Button>
    </div>
  );
}

class ReduxTableRow extends React.Component {
  state = {};

  render() {
    const { classes, item = {} } = this.props;
    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          {item.driver && (
            <div className={classes.capitalize}>
              {item.driver.firstName} {item.driver.lastName}
            </div>
          )}
          <div>{item.eldFilename}</div>
        </TableCell>
        <TableCell>
          {item.status}
          <div>{item.channel}</div>
        </TableCell>

        <TableCell>
          <div>From: {this.props.formatDate(item.fromDate)}</div>
          <div>To: {this.props.formatDate(item.toDate)}</div>
        </TableCell>
        <TableCell>
          <div className={classes.dataLargeRow}>
            {item.created && (
              <div>{this.props.formatDateTime(item.created)}</div>
            )}
          </div>
        </TableCell>
        <TableCell>
          <RenderLogs
            logs={item.logs}
            submissionId={item.submissionId}
            error={item.error}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export class FmcsaAdmin extends React.Component {
  state = {
    limit: 10,
    page: 0,
  };

  componentDidMount() {
    this.getAllFmcsa();
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, this.getAllFmcsa);
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    this.setState({ limit }, this.getAllFmcsa);
  };

  getAllFmcsa = async () => {
    const { companyId, limit, page, start, end } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    let payload = { accountId: companyId, skip, limit };

    if (start && end) {
      payload.to = end;
      payload.from = start;
    }
    this.setState({ loading: true });
    await this.props.getFmcsaHistoryAdmin(payload);
    await this.props.getFmcsaHistoryCountAdmin(payload);
    this.setState({ loading: false });
  };

  handleSearchChange = ({ companyId, driverId, start, end }) => {
    this.setState({ companyId, driverId, start, end }, this.getAllFmcsa);
  };

  render() {
    const {
      classes,
      formatDate,
      formatDateTime,
      fmcsas = [],
      count = 0,
    } = this.props;

    const { limit = 10, page = 1, loading } = this.state;

    return (
      <>
        {/* <Paper className={classes.root}> */}
        <SearchBar
          options={{
            isComp: true,
            isEqp: false,
            isDriver: false,
            isStartDate: true,
            isEndDate: true,
            isTime: true,
          }}
          onSearch={this.handleSearchChange}
        />
        <Grid container spacing={0} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12}>
            {LABELS.count} {count}
          </Grid>
        </Grid>
        {loading ? (
          <Grid>
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          </Grid>
        ) : (
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{LABELS.driver}</TableCell>
                  <TableCell>{LABELS.status}</TableCell>
                  <TableCell>{LABELS.details}</TableCell>
                  <TableCell>{LABELS.date}</TableCell>
                  <TableCell>{LABELS.logs}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(fmcsas) &&
                  fmcsas.map((item, i) => (
                    <ReduxTableRow
                      item={item}
                      key={i}
                      formatDate={formatDate}
                      formatDateTime={formatDateTime}
                      classes={classes}
                    />
                  ))}
              </TableBody>
            </Table>
            <NotFoundTableRow items={fmcsas} />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              labelRowsPerPage={LABELS.rowsperpage}
            />
          </Paper>
        )}
      </>
    );
  }
}

FmcsaAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    fmcsaBusy: _get(state, "fmcsaData.fmcsaBusy", false),
    count: _get(state, "fmcsaData.historyCount", 0),
    fmcsas: _get(state, "fmcsaData.fmcsasHistory", []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatDateTime,
      getFmcsaHistoryCountAdmin,
      getFmcsaHistoryAdmin,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FmcsaAdmin)
);
