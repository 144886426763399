const defaultState = {
    fmcsas: [],
    count: 0,
    fmcsaBusy: false,
    fmcsasHistory: [],
    historyCount: 0
};

export default function fmcsa(state = defaultState, action) {
    switch (action.type) {
        case "FMCSA":
            return {
                ...state,
                fmcsas: action.value
            };
        case "FMCSA_COUNT":
            return {
                ...state,
                count: action.value
            };
        case "FMCSA_BUSY":
            return {
                ...state,
                fmcsaBusy: action.value
            };
        case "FMCSAHISTORY":
            return {
                ...state,
                fmcsasHistory: action.value
            };
        case "FMCSAHISTORY_COUNT":
            return {
                ...state,
                historyCount: action.value
            };

        default:
            return state;
    }
}
