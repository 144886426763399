import React from "react";
import { withStyles } from '@mui/styles';
import { EVENT_CODES } from "../../constantsStatus";

const styles = theme => ({
    wrapper: {
        display: "inline-block",
        textAlign: "left",
        fontSize: "12px"
    },
    status: {
        textTransform: "uppercase",
        flexBasis: "25%",
        padding: "1px",
        backgroundColor: "#707070",
        textAlign: "center",
        color: "white",
        minWidth: "84px",
        display: "inline-block",
        borderRadius: "4px",
        fontWeight: 500,
        border: "none",
    },
    small: {
        padding: 0,
        fontSize: "12px",
        width: 60
    },
    labelStatusdefault: {
        color: "#777",
        border: "1px solid #777"
    },
    labelStatusbreak: {
        color: "#1e88e5",
        border: "1px solid #1e88e5"
    },
    labelStatusremark: {
        color: "grey",
        border: "1px solid grey"
    },
    labelStatus_OFFDUTY: {
        backgroundColor: "grey"
    },
    labelStatus_SLEEPER: {
        backgroundColor: "#FF7E0E"
    },
    labelStatus_DRIVING: {
        backgroundColor: "#1f9a07"
    },
    labelStatus_ONDUTY_ND: {
        backgroundColor: "#0085EC"
    },
    labelStatusRemark: {
        color: "#29b6f6",
        // border: "1px solid #29b6f6"
    },
    labelStatuswaitingAtSite: {
        color: "#ff7043",
        // border: "1px solid #ff7043"
    },
    labelError: {
        backgroundColor: "red",
    }
});

function DutyStatus(props) {
    const classes = props.classes;
    const isError = props.isError;
    const status = props.status || {};
    const size = props.size || "medium";
    const tableClass = props.tableClass;
    const eventCode = props.eventCode || "";
    const classKey = `labelStatus_${eventCode}`;
    let classNamesStatus = `${classes.status} ${classes[classKey]
        } ${tableClass} ${classes[size]}`;
    let eventLabel = EVENT_CODES[props.eventCode];

    if (props.eventType === "LOGIN_LOGOUT") {
        eventLabel = props.eventSubType;
        classNamesStatus = `${classes.status}`;
    } else if (props.eventType === "INTERMEDIATE_LOG") {
        eventLabel = props.eventSubType;
        classNamesStatus = `${classes.status}`;
    } else if (props.eventType === "POWERUP_SHUTDOWN") {
        eventLabel = props.eventSubType;
        classNamesStatus = `${classes.status}`;
    } else if (props.eventType === "CERTIFICATION") {
        eventLabel = props.eventSubType;
        classNamesStatus = `${classes.status}`;
    } else if (props.eventType === "MALFUNCTION_DIAGNOSTIC") {
        eventLabel = status.diagnostic ? "Diagnostic" : "Malfunction";

        if (!status.diagnostic && status.diagnosticCode) {
            eventLabel = "Clear Diag.";
        }
        if (!status.malfunction && status.malfunctionCode) {
            eventLabel = "Clear Malf.";
        }

        classNamesStatus = `${classes.status}`;
    }

    if (isError) {
        classNamesStatus = `${classNamesStatus} ${classes.labelError}`;
    }

    const key = props.eventSubType === "EXP:AGIS" ? "AS" : props.eventSubType === "EXP:AGIE" ? "AE" :
        props.eventType === "YARD_MOVES"
            ? " YM"
            : props.eventType === "PERSONAL_USE"
                ? " PC"
                : "";

    return (
        <span className={`${classes.wrapper} ${classes[size]}`}>
            <span className={classNamesStatus}>{`${eventLabel}`}</span>
            <strong>{`${key}`}</strong>
        </span>
    );
}
export default withStyles(styles)(DutyStatus);
