import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from '@mui/material/CircularProgress';
import {
    formatDate,
    formatTime,
    formatDateTimeSec,
    getStartOfDay,
    getEndOfDay,
    formatDateTime,

} from "../../actions/momentActions";
import { startDay, currTime } from "../../utils/momentHelpers";
import { getMalfunction, getMalfunctionCount } from "../../actions/logActions";
import { getCarriers } from "../../actions/carrierActions";
import SearchBar from "../../components/SearchBar";
import MalfunctionsTable from "./MalfunctionsTable";
// import SearchBar from "../../components/SearchBar";

class Malfunctions extends React.Component {
    state = {
        limit: 10,
        page: 0,
        loading: false,
        start: startDay,
        end: currTime,
        driverId: "",
        vehicleId: ""
    };

    async componentDidMount() {
        await this.props.getMalfunctionCount();
        await this.getMalfunction();
        await this.props.getCarriers();
    }

    getMalfunction = async () => {
        this.setState({ loading: true });
        const { limit, page, driverId, vehicleId, start, end } = this.state;
        const skip = parseInt(page) * parseInt(limit);
        await this.props.getMalfunction({
            limit,
            skip,
            driverId,
            vehicleId,
            start: start ? new Date(this.props.getStartOfDay(start)) : null,
            end: end ? new Date(this.props.getEndOfDay(end)) : null
        });
        this.setState({ loading: false });
    };

    handleChange = ({ limit, page }) => {
        this.setState({ limit, page }, this.getMalfunction);
    };

    handleSearchChange = ({ driverId, vehicleId, start, end }) => {
        this.setState({ driverId, vehicleId, start, end }, this.getMalfunction);
    };

    render() {
        return (
            <div>
                <SearchBar
                    options={{
                        isEqp: true,
                        isDriver: true,
                        isStartDate: true,
                        isEndDate: true,
                        isTime: true,
                        isTimeperiod: true,
                        dl: true,
                    }}
                    loading={this.state.loading}
                    onSearch={this.handleSearchChange}
                />
                {this.state.loading && (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                )}
                <MalfunctionsTable
                    count={this.props.count}
                    items={this.props.malfunctionLogs}
                    formatDate={this.props.formatDate}
                    formatTime={this.props.formatTime}
                    limit={this.state.limit}
                    page={this.state.page}
                    adminKey={this.props.adminKey}
                    isQa={this.props.isQa}
                    handleChange={this.handleChange}
                    carriers={this.props.carriers}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        malfunctionLogs: _get(state, "logData.malfunctionLogs", []),
        count: _get(state, "logData.malfunctionLogsCount", 0),
        pagination: _get(state, "locationData.pagination"),
        adminKey: _get(state, "authData.profile.adminId"),
        isQa: _get(state, "appData.isQa"),
        carriers: _get(state, "carrierData.carriers", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            formatDate,
            formatTime,
            formatDateTimeSec,
            getMalfunction,
            getMalfunctionCount,
            getStartOfDay,
            getEndOfDay,
            formatDateTime,
            getCarriers,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Malfunctions);
