import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import {
  createAdminShipping,
  updateAdminShipping,
} from "../../actions/adminSupportActions";
import { formatDateTimeUtc, formatDateTimeToUtc } from "../../actions/momentActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import { validate, defaultValues } from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../components/InputBox";
import InputDateBox from "../../components/InputDateBox";
import SelectBox from "../../components/SelectBox";
import AdminAccountSearch from "../../components/AdminAccountSearch";
import Error from "../Error";
import Success from "../Success";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/lab/Autocomplete";
import {LABELS as _LABELS} from "../../language"

const LABELS = _LABELS.adminPage;
const LABELS_Shipping = _LABELS.supportForm;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddResellerForm extends React.Component {
  state = {
    accounts: [],
    drivers: [],
  };

  componentDidMount() {
    this.getAccounts();
    // const selected = this.props.selected;
  }

  getAccounts = async () => {
    const accounts = await this.props.getAccounts({});
    this.setState({ accounts: accounts || [] });
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response;

    values.shipDate = this.props.formatDateTimeToUtc(values.shipDate);
    if (values._id) {
      response = await this.props.updateAdminShipping(values);
    } else {
      response = await this.props.createAdminShipping(values);
    }

    if (response && response._id) {
      this.setState({
        successMessage: LABELS.shippingSaveSuccessfully,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = LABELS.errorMasage_tryafterSometime;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const accounts = this.state.accounts || [];
    // const drivers = this.state.drivers || [];
    const user = this.props.user || {};
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;
    const assignedTo = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phone: user.mobile,
    };
    let initialValues = {
      ...defaultValues,
      assignedTo,
      ...selected,
    };

    let selectedAcc;
    if (selected && selected.accountName && selected.accountId) {
      selectedAcc = { id: selected.accountId, name: selected.accountName };
    }
    if (selected && selected.shipDate) {
      initialValues.shipDate = this.props.formatDateTimeUtc(selected.shipDate);
    }

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={this.props.isAdd}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{`${LABELS_Shipping.addShipping}`}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <AdminAccountSearch
                      md={3}
                      selValue={"_id"}
                      selLabel={"name"}
                      name="accountId"
                      label={`${LABELS.accounts}`}
                      items={accounts}
                      selected={selectedAcc}
                      onChange={(e) => {
                        if (e && e._id) {
                          setFieldValue("accountId", e._id);
                          setFieldValue("accountName", e.name);
                        }
                      }}
                    />
                    <SelectBox
                      md={3}
                      name="status"
                      label={`${LABELS.status}`}
                      items={constants.SHIPPING_STATUS}
                    />
                    <SelectBox
                      md={3}
                      name="provider"
                      label={`${LABELS.provider}`}
                      items={constants.SHIPPING_PROVIDER}
                    />
                    <InputDateBox
                      md={3}
                      name="shipDate"
                      label={`${LABELS.shipdate}`}
                      type="datetime-local"
                    />
                    <InputBox md={12} name="trackingUrl" label={`${LABELS_Shipping.trackingUrl}`} />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {`${loading ? LABELS_Shipping.saving : LABELS_Shipping.save}`}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {`${LABELS_Shipping.closed}`}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, "authData.profile.user", {}),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccounts,
      createAdminShipping,
      updateAdminShipping,
      formatDateTimeUtc,
      formatDateTimeToUtc
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddResellerForm))
);
