import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import MapListItem from "./MapListItem";
import ArrowRight from "@mui/icons-material/ArrowRightOutlined";
import ArrowLeft from "@mui/icons-material/ArrowLeftOutlined";
import {
  getDcImages,
  getDcDetail,
} from "../../../actions/dashcam/dashcamActions";
import { getDtcs } from "../../../actions/equipmentActions";
import { formatDate, formatTime } from "../../../actions/momentActions";

import ChartModal from "../StatusChart/ChartModal";
import CleanModal from "../../AdminUser/CleanModal";
import DashCamModal from "./DashCamModal";
import VehicleDetailModal from "./VehicleDetailModal";

function LLMap(props) {
  const [openChart, setOpenChart] = useState(false);
  const [openClean, setOpenClean] = useState(false);
  const [openDc, setOpenDc] = useState(false);
  const [openVehDetail, setOpenVehDetail] = useState(false);
  const [selDriver, setSelDriver] = useState();
  const [selDetail, setSelDetail] = useState();

  const { show, setShow } = props;
  const drivers = props.drivers || [];

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {openChart && selDetail ? (
        <ChartModal
          open={openChart}
          handleClose={() => {
            setOpenChart(false);
          }}
          item={selDetail}
          driver={selDetail.driver}
          coDriver={_get(selDetail, "coDriver")}
          isViolation={!!(selDetail.remaining && selDetail.remaining.violation)}
          remaining={selDetail.remaining}
        />
      ) : null}
      {openClean ? (
        <CleanModal
          open={openClean}
          handleClose={() => {
            setSelDriver(null);
            setOpenClean(false);
            props.getRealTime();
          }}
          driver={selDriver}
        />
      ) : null}
      {openVehDetail ? (
        <VehicleDetailModal
          open={openVehDetail}
          handleClose={() => {
            setOpenVehDetail(false);
            setSelDetail(null);
          }}
          data={selDetail}
          getDtcs={props.getDtcs}
        />
      ) : null}
      {openDc ? (
        <DashCamModal
          open={openDc}
          handleClose={() => {
            setOpenDc(false);
            setSelDetail(null);
          }}
          data={selDetail}
          getDcImages={props.getDcImages}
          getDcDetail={props.getDcDetail}
          formatDate={props.formatDate}
          formatTime={props.formatTime}
        />
      ) : null}
      <div
        style={{
          height: "100%",
          maxHeight: "94vh",
          overflowY: "scroll",
          paddingTop: "8px",
        }}
      >
        {Array.isArray(drivers) &&
          drivers.map((item, i) => {
            const itemdriver = item.driver || {};
            const status = item.eventCode || "";
            const location = item.location || "";
            const time = item.lastContact
              ? item.lastContact
              : item.eventTime
              ? item.eventTime
              : null;
            const date = props.formatDate(time);
            const currtime = props.formatTime(time);
            const speed = (
              _get(item, "telemetrics.speedKm", 0) * 0.621371
            ).toFixed(2);
            const rpm = _get(item, "telemetrics.rpm", 0);
            const faultCodes = _get(item, "telemetrics.faultCodes", "") || "";

            let fuel = _get(item, "telemetrics.fuel", "0") || "0";
            fuel = parseInt((parseInt(fuel) / 247) * 100);
            if (fuel > 100) {
              fuel = 100;
            }
            const coolant = _get(item, "telemetrics.coolant", "") || "0";

            const gear = _get(item, "telemetrics.gear", "") || "0";

            const odometer = _get(item, "telemetrics.odometerKm", "") || "0";

            const odometerMi = parseFloat((odometer * 0.621371).toFixed(2));

            let engineHours = _get(item, "telemetrics.engineHours", "") || "0";
            engineHours = parseFloat(engineHours);
            if (!isNaN(engineHours)) {
              engineHours = parseFloat(engineHours.toFixed(1));
            } else {
              engineHours = 0; // or any other default value if engineHours is not a number
            }

            return (
              <MapListItem
                show={show}
                key={i}
                item={item}
                status={status}
                date={date}
                time={currtime}
                driver={itemdriver}
                vehicle={item.vehicle}
                address={item.address}
                location={location}
                speed={speed}
                gear={gear}
                odometerMi={odometerMi}
                coolant={coolant}
                dId={props.dId}
                popUpOpen={props.popupOpen}
                rpm={rpm}
                faultCodes={faultCodes}
                fuel={fuel}
                engineHours={engineHours}
                handleClick={props.handleClick}
                accountBaseUrl={props.accountBaseUrl}
                adminKey={props.adminKey}
                getRealTime={props.getRealTime}
                getDcImages={props.getDcImages}
                getDcDetail={props.getDcDetail}
                formatDate={props.formatDate}
                formatTime={props.formatTime}
                getDtcs={props.getDtcs}
                setOpenChart={setOpenChart}
                setOpenClean={setOpenClean}
                setOpenDc={setOpenDc}
                setOpenVehDetail={setOpenVehDetail}
                setSelDetail={setSelDetail}
                setSelDriver={setSelDriver}
              />
            );
          })}
      </div>

      <div
        style={{
          display: "none",
          backgroundColor: "aliceblue",
          position: "absolute",
          bottom: "0px",
          width: "100%",
          height: "42px",
          textAlign: "right",
        }}
        onClick={() => {
          setShow(!show);
        }}
      >
        <div>
          {!show ? (
            <ArrowRight style={{ fontSize: 42 }} />
          ) : (
            <ArrowLeft style={{ fontSize: 42 }} />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  adminKey: _get(state, "authData.profile.adminId"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatTime,
      getDcImages,
      getDcDetail,
      getDtcs
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LLMap));
