import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import Autocomplete from "@mui/lab/Autocomplete";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { Paper } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import {LABELS as _LABELS} from "../../../../language";


const LABELS=_LABELS.settingPage;

const useStyles = makeStyles(theme => ({
    root: {
        width: 500,
        "& > * + *": {
            marginTop: theme.spacing(3)
        }
    },
    center: {
        textAlign: "center"
    },
    formControl: {
        width: "100%"
    }
}));

export default function VehicleAssignment(props) {
    const driver = props.driver || {};
    const trailers = props.trailers || [];
    const [assigned, setAssigned] = useState(props.selected || []);
    const [vehicleId, setVehicleId] = useState(driver.vehicleId || "");
    const [trailerId, setTrailerId] = useState(driver.trailerId || "");

    const error = props.error || {};
    const errorVehicle = error.vehicle || error.vehicleId;
    const errorEquipments = error.equipments;

    const classes = useStyles();

    const CustomPaper = props => {
        return (
            <Paper
                {...props}
                style={{
                    zIndex: 1305,
                    position: "absolute",
                    bottom: 6,
                    width: 250
                }}
            />
        );
    };

    return (
        <Grid container spacing={1} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={12} md={6} style={{ position: "relative" }}>
                <Autocomplete
                    multiple
                    size="small"
                    id="assigned-vehicles"
                    freeSolo
                    options={props.equipments}
                    getOptionLabel={option => {
                        return option && option.name;
                    }}
                    PopperComponent={CustomPaper}
                    // disableCloseOnSelect
                    defaultValue={assigned}
                    noOptionsText=" "
                    renderInput={params => (
                        <TextField
                            {...params}
                            style={{ paddingTop: 3 }}
                            label={LABELS.assignedVehicle}
                            placeholder="Select"
                            variant="standard"
                        />
                    )}
                    value={assigned}
                    onChange={(e, values) => {
                        setAssigned(values);
                        const ids = values
                            ? values.map(item => {
                                  return item && item.id;
                              })
                            : [];

                        if (!ids.includes(vehicleId)) {
                            setVehicleId("");
                            props.onChange({
                                equipments: ids,
                                vehicleId: "",
                                trailerId
                            });
                        } else {
                            props.onChange({
                                equipments: ids,
                                vehicleId,
                                trailerId
                            });
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <FormControl className={classes.formControl} variant="standard">
                    <InputLabel id="vehicleId">
                        {LABELS.currentVehicle}
                    </InputLabel>
                    <Select
                        labelId="vehicleId"
                        id="vehicleId"
                        value={vehicleId}
                        disabled={!assigned || assigned.length === 0}

                        onChange={e => {
                            const vId = e.target.value;
                            setVehicleId(vId);
                            const ids = assigned.map(item => item.id) || [];
                            props.onChange({
                                equipments: ids,
                                vehicleId: vId,
                                trailerId
                            });
                        }}
                    >
                        {assigned &&
                            assigned.map(item => {
                                if (!item) {
                                    return null;
                                }
                                return (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <FormControl className={classes.formControl}  variant="standard">
                    <InputLabel id="trailerId">
                        {LABELS.currentTrailer}
                    </InputLabel>
                    <Select
                        labelId="trailerId"
                        id="trailerId"
                        value={trailerId}
                        disabled={trailers.length === 0}
                        onChange={e => {
                            const tId = e.target.value;
                            setTrailerId(tId);
                            const ids = assigned.map(item => item.id) || [];
                            props.onChange({
                                equipments: ids,
                                vehicleId,
                                trailerId: tId
                            });
                        }}
                    >
                        {trailers.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {(errorVehicle || errorEquipments) && (
                <Grid item xs={12} sm={12} md={12}>
                    {errorEquipments && (
                        <Alert severity="error" style={{ width: "100%" }}>
                            {/* No vehicle is assigned to driver{" "} */}
                            {`${LABELS. No_vehicle_is_assignedTO_deiver}{" "}`}
                        </Alert>
                    )}
                    {errorVehicle && (
                        <Alert severity="error" style={{ marginTop: 2 }}>
                            {/* Please set current vehicle */}
                            {LABELS.please_set_current_vehicle}
                        </Alert>
                    )}
                </Grid>
            )}
        </Grid>
    );
}
