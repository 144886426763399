import React from "react";
import { Grid } from "@mui/material";
import { Alert, AlertTitle } from "@mui/lab";
import "./styles.css";

function TimeCircle({ per, color, time, type, backColor }) {
    return (
        <div className="time-circle svg">
            <h2>{time}</h2>
            <h3>{type}</h3>
            <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <title>{type}</title>
                    <circle
                        id="circle-back"
                        strokeDasharray={440}
                        r="69.85699"
                        cy="81"
                        cx="81"
                        strokeWidth="8"
                        stroke={backColor}
                        fill="none"
                    />
                    <circle
                        id="circle"
                        strokeDashoffset={(per * 440) / 100}
                        strokeDasharray={440}
                        r="69.85699"
                        cy="81"
                        cx="81"
                        strokeWidth="8"
                        stroke={color}
                        fill="none"
                    />
                </g>
            </svg>
        </div>
    );
}
export default function statusChart(props) {
    function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }

    const item = props.item || {};
    const remaining = props.remaining || {};
    const exception = props.exception || {};
    const violations = remaining.violation
        ? remaining.violation.split(",")
        : [];
    const isOnDuty =
        item.eventCode === "DRIVING" || item.eventCode === "ONDUTY_ND";
    let expMessage = "";

    const break_ = 100 - convertH2M(remaining.break) / 4.8;
    const drive_ = 100 - convertH2M(remaining.drive) / 6.6;
    const shift_ = 100 - convertH2M(remaining.onDuty) / 8.4;
    const cycle_ = 100 - convertH2M(remaining.cycle) / 42.0;

    if (exception === "1") {
        expMessage = "Adverse weather condition";
        // drive_ = 100 - convertH2M(remaining.drive) / 7.8;
        // shift_ = 100 - convertH2M(remaining.onDuty) / 9.6;
    } else if (exception === "2") {
        expMessage = "16 hours shift once a week";
        // shift_ = 100 - convertH2M(remaining.onDuty) / 9.6;
    } else if (exception === "3") {
        expMessage = "Agriculture Exemption";
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-evenly " }}
            >
                <TimeCircle
                    per={break_}
                    color={
                        remaining.break !== "00:00" &&
                            item.eventCode === "DRIVING"
                            ? "#1f9a07"
                            : "#474747"
                    }
                    time={remaining.break}
                    type={"BREAK"}
                    backColor={
                        remaining.break === "00:00" && isOnDuty ? "red" : "grey"
                    }
                />
                <TimeCircle
                    per={drive_}
                    color={"#1f9a07"}
                    time={remaining.drive}
                    type={"DRIVE"}
                    backColor={
                        remaining.drive === "00:00" && isOnDuty ? "red" : "grey"
                    }
                />
                <TimeCircle
                    per={shift_}
                    color={"#00F"}
                    time={remaining.onDuty}
                    type={"SHIFT"}
                    backColor={
                        remaining.onDuty === "00:00" && isOnDuty
                            ? "red"
                            : "grey"
                    }
                />
                <TimeCircle
                    per={cycle_}
                    color={"#F8BA00"}
                    time={remaining.cycle}
                    type={"CYCLE"}
                    backColor={
                        remaining.cycle === "00:00" && isOnDuty ? "red" : "grey"
                    }
                />
            </Grid>

            {(remaining.splitDrive || remaining.splitOnDuty) && (
                <Grid container>
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                        <p style={{ fontWeight: "700", marginTop: "0px" }}>
                            {" "}
                            SPLIT DRIVE : {remaining.splitDrive}
                        </p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{ fontWeight: "700", marginTop: "0px" }}>
                            {" "}
                            SPLIT SHIFT : {remaining.splitOnDuty}
                        </p>
                    </Grid>
                    <Grid item xs={3} />
                </Grid>
            )}

            {exception && expMessage && (
                <Grid
                    container
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <Grid item xs={6}>
                        <Alert
                            severity="info"
                            style={{
                                margin: "5px",
                                marginTop: "10px",
                                textAlign: "center"
                            }}
                        >
                            <AlertTitle>{expMessage}</AlertTitle>
                        </Alert>
                    </Grid>
                </Grid>
            )}

            <Grid container>
                {/* <Grid style={{ display: "flex" }}> */}
                {violations.map(e => {
                    let message = "";
                    if (e === "-14") {
                        return ""
                    } else if (e === "30") {
                        message = "30 Minute Break Required";
                    } else if (e === "11") {
                        message = "Driving beyond the 11 Hours of Driving limit";
                    } else if (e === "13") {
                        message = "Driving beyond the 13 Hours of Drive limit";
                    } else if (e === "14") {
                        message = "Driving beyond the 14 Hours of Shift limit";
                    } else if (e === "16") {
                        message = "Driving beyond the 16 Hours of Shift limit";
                    } else if (e === "18") {
                        message = "Driving beyond the 18 Hours of Shift limit";
                    }  else if (e === "12") {
                        message = "Driving beyond the 12 Hours of Drive limit";
                    }
                    else {
                        message = `Driving beyond the ${e} Hours of Cycle Limit`
                    }
                    return (
                        <Grid item xs={6}>
                            {message && <Alert
                                severity="error"
                                style={{ margin: "5px", marginTop: "10px" }}
                            >
                                <AlertTitle>{message}</AlertTitle>
                            </Alert>}
                        </Grid>
                    );
                })}
            </Grid>
            {/* </Grid> */}
        </Grid>
    );
}
