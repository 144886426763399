import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import ImageIcon from "@mui/icons-material/Image";
import TextField from "@mui/material/TextField";
import { Button, Grid } from "@mui/material";
import UpdateUserForm from "./UpdateUserForm";
import { LABELS as _LABELS } from "../../language";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import StarIcon from "@mui/icons-material/Star";
const LABELS = _LABELS.adminPage;

function RenderItem(props) {
  const item = props.item || {};
  const account = item.account || {};
  const selected = props.selected || {};
  const name = (item.from && item.from.name) || "";
  const phoneTo = item.phoneTo || "";
  const phoneFrom = item.phoneFrom || "";
  const _message = item.message || "";
  const created = item.created;
  const isImage = item.type === "image";
  const isSe = account.provider === "SE";
  const isEb = account.provider === "EB";
  const isPremium = account.plan === "ENTERPRISE";

  let message = _message.slice(0, 50);

  if (_message.length > 50) {
    message = `${message}...`;
  }
  let createdTm = `${props.formatTime(created)}`;

  let datetime = props.formatUsDate(created);
  let readAt = item.readAt
    ? `${props.formatUsDate(item.readAt)} ${props.formatTime(item.readAt)}`
    : "";
  const _readBy = item.readBy || {};

  const readBy = _readBy.name ? `${_readBy.name}` : "";

  if (props.isToday(created)) {
    datetime = props.formatTime(created);
    createdTm = "";
    readAt = item.readAt ? `${props.formatTime(item.readAt)}` : "";
  }
  if (props.isYesterday(created)) {
    datetime = `Yesterday`;
    createdTm = `${props.formatTime(created)}`;
    readAt = item.readAt
      ? `${props.formatUsDate(item.readAt)} ${props.formatTime(item.readAt)}`
      : "";
  }

  const isSelected = item._id === selected._id;

  const alias = account.alias || name;
  const phone = account.name || phoneFrom;
  const providerStyles = {
    fontWeight: "800",
    float: "right",
  };

  return (
    <>
      <ListItem
        alignItems="flex-start"
        style={{
          backgroundColor: isSelected ? "#f0f0f0" : "",
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 5,
        }}
      >
        <Tooltip title={`Reciever ${phoneTo}`} placement="top">
          <ListItemAvatar
            size={"small"}
            style={{ cursor: "pointer" }}
            onClick={() => props.onEdit(item)}
          >
            <Avatar alt={name} src="/static/images/avatar/1.jpg" />
          </ListItemAvatar>
        </Tooltip>

        <ListItemText
          primary={
            <>
              {isPremium && <StarIcon fontSize="small" />}
              {alias}
            </>
          }
          onClick={() => props.handleClick(item)}
          style={{ cursor: "pointer" }}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "block" }}
                style={{ float: "right", marginTop: -23, fontSize: 12 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {`${datetime} `}

                <span
                  style={{ fontSize: 10, textAlign: "right", display: "block" }}
                >
                  {createdTm}
                </span>
              </Typography>
              {phoneFrom !== phone && (
                <Typography
                  sx={{ display: "block" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  <ContentCopyIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(phoneFrom);
                    }}
                    color="primary"
                    fontSize="8"
                  />
                  {` ${phoneFrom} `}
                </Typography>
              )}
              <Typography
                sx={{ display: "block" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <ContentCopyIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(phone);
                  }}
                  color="primary"
                  fontSize="8"
                />
                {` ${phone}`}
              </Typography>
              <Typography
                sx={{ display: "block" }}
                style={{ float: "right", marginTop: -45 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {item.read ? (
                  ""
                ) : (
                  <Chip color="primary" size="small" label="NEW" />
                )}
              </Typography>

              <Tooltip
                title={item.read ? `Read: ${readBy} ${readAt}` : "Unread"}
                placement="top"
              >
                <span>{isImage ? <ImageIcon /> : message}</span>
              </Tooltip>
              {isSe && <span style={providerStyles}>{"SE"}</span>}
              {isEb && <span style={providerStyles}>{"EB"}</span>}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export default function ChatUser(props) {
  const selected = props.selected || {};
  const items = props.items || [];
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("All");
  const [editItem, setEditItem] = useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredItems = items.filter((item) => {
    const name = (item.from && item.from.name) || "";
    const message = item.message || "";
    const matchesSearchQuery =
      name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      message.toLowerCase().includes(searchQuery.toLowerCase());

    if (filter === "Unread") {
      return matchesSearchQuery && !item.read;
    } else if (filter === "SE" || filter === "EB") {
      const provider = item.account && item.account.provider;
      return matchesSearchQuery && provider === filter;
    }

    return matchesSearchQuery;
  });

  return (
    <div>
      {editItem && (
        <UpdateUserForm
          isOpen={!!editItem}
          selected={editItem}
          constants={props.constants}
          updateChat={props.updateChat}
          handleClose={() => setEditItem(null)}
        />
      )}
      <Grid container style={{ width: "100%", paddingRight: 10 }}>
        <Grid item xs>
          <TextField
            sx={{ width: "100%" }}
            id="message-search"
            label={`${LABELS.search}`}
            size="small"
            variant="outlined"
            margin="normal"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{ width: "100%", height: "80vh", paddingRight: 10 }}
      >
        <Grid item xs={12} style={{ borderRight: "1px solid #e0e0e0" }}>
          <Grid item xs={12} style={{ padding: "4px" }}>
            <Chip
              label={`Group`}
              onClick={() => props.handleGroup()}
              sx={{
                backgroundColor: "#cce4ff",
                color: "#0044cc",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`${LABELS.all}`}
              onClick={() => handleFilterChange("All")}
              sx={{
                backgroundColor: filter === "All" ? "#cce4ff" : "default",
                color: filter === "All" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`${LABELS.unread}`}
              onClick={() => handleFilterChange("Unread")}
              sx={{
                backgroundColor: filter === "Unread" ? "#cce4ff" : "default",
                color: filter === "Unread" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`SE`}
              onClick={() => handleFilterChange("SE")}
              sx={{
                backgroundColor: filter === "SE" ? "#cce4ff" : "default",
                color: filter === "SE" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`EB`}
              onClick={() => handleFilterChange("EB")}
              sx={{
                backgroundColor: filter === "EB" ? "#cce4ff" : "default",
                color: filter === "EB" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
          </Grid>
          <Divider style={{ width: "98%" }} />
          {filteredItems && filteredItems.length === 0 && filter === "Unread" && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ padding: "10px", height: "100%", textAlign: "center" }}
            >
              <Typography variant="body1">{`${LABELS.nounreadchats}`}</Typography>
              <Button
                onClick={() => handleFilterChange("All")}
                style={{ marginTop: "10px" }}
              >
                {`${LABELS.view_all_chats}`}
              </Button>
            </Grid>
          )}
          <List
            sx={{
              width: "100%",
              // maxWidth: 360,
              maxHeight: props.maxHeight,
              overflow: "scroll",
              bgcolor: "background.paper",
            }}
          >
            {filteredItems.map((item) => (
              <RenderItem
                key={item._id}
                item={item}
                selected={selected}
                handleClick={props.handleClick}
                isToday={props.isToday}
                formatTime={props.formatTime}
                formatUsDate={props.formatUsDate}
                isYesterday={props.isYesterday}
                onEdit={(_item) => setEditItem(_item)}
              />
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
