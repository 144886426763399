export const LABELS = {
  addAccount: "खाता जोड़ें",

  name: "नाम",
  status: "स्थिति",
  ein: "ईईएन",
  type: "प्रकार",
  email: "ईमेल",
  phone: "फोन",
  role: "भूमिका",
  billing_name: "बिलिंग नाम",
  billing_email: "बिलिंग ईमेल",
  billing_phone: "बिलिंग फोन",
  billing_role: "बिलिंग भूमिका",
  stripe: "स्ट्राइप",
  note: "ध्यान दें",
  detail: "विवरण",
  contact: "संपर्क",
  billing: "बिलिंग",
  loging_users: "लॉगिन उपयोगकर्ता",

  saving: "सहेजा जा रहा है...",
  save: "सहेजें",
  close: "बंद करें",

  cleanDriver: "क्या आप ड्राइवर को सूची से साफ करना चाहते हैं",
  cleanNow: "अब साफ करें",
  cleaning: "सफाई हो रही है...",

  // <-------for Support user----->
  addSupportUser: "समर्थन उपयोगकर्ता जोड़ें",
  note: "ध्यान दें: यह केवल नए समर्थन उपयोगकर्ता जोड़ने के लिए है। समर्थन उपयोगकर्ता को आपके सभी खाता और वाहक विवरणों तक पहुँच होगी।",
  firstName: "पहला नाम",
  lastName: "उपनाम",
  mobile: "मोबाइल",
  password: "पासवर्ड",
  reseller: "पुनर्विक्रेता",
  superAdmin: "सुपर व्यवस्थापक",

};
