export const LABELS = {
  profileInfo: "Información de perfil",
  accountInfo: "Informacion de cuenta",
  name: "Nombre",
  email: "Correo electrónico",
  status: "Estado",
  language: "Idioma",
  uuid: "UUID",
  roles: "Acceso",
  account: "Cuenta",
  accountId: "ID de la cuenta",
  save: "Ahorrar",
  edit: "Editar",
  phone: "Teléfono",
  carrier: "Transportador",
  terminal: "Terminal",
  required: "Requerido",
};
