import React from "react";
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import ApiForm from "./ApiForm";
import {LABELS as _LABELS} from "../../../../language";

const LABELS=_LABELS.settingPage;

function a11yProps(index) {
    return {
        id: `accunts-tab-${index}`,
        "aria-controls": `accunts-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    wrapper: {},
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));

export default function EditAccount(props) {
    const isSmallScreen = window.innerWidth < 400;
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Dialog
            fullScreen={isSmallScreen}
            fullWidth={true}
            maxWidth={"md"}
            className={classes.wrapper}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.isEdit && props.isAdmin ? (
                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="secondary"
                        aria-label="accunts tabs"
                    >
                        <Tab label={LABELS._details}{...a11yProps(0)} />
                    </Tabs>
                ) : (
                    <React.Fragment>{"NOTIFICATIONS"}</React.Fragment>
                )}
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={props.handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {selectedTab === 0 && <ApiForm {...props} />}
            </DialogContent>
        </Dialog>
    );
}
