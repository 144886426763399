import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./configureStore";
import "./index.css";
import "./custom-styles.css";
import "./common.css";
import "./reseller-styles.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./styles/theme";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import H from "@here/maps-api-for-javascript";

// const sentryDns = process.env.REACT_APP_SENTRY_DNS;
const container = document.getElementById('root');
const root = createRoot(container);

// console.log(" **** SENTRY INIT **** ", process.env.NODE_ENV);

window.H = H;

// if (process.env.NODE_ENV === "production" && sentryDns) {
//     console.log(" **** SENTRY INIT **** ", process.env.NODE_ENV);
//
//     Sentry.init({
//         dsn:
//             "https://26597b76439e473cb73264f4fda0b9d0@o563436.ingest.sentry.io/5703406",
//         integrations: [new Integrations.BrowserTracing()],
//
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 1.0
//     });
// }

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
