import React from "react";
// import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDate, formatDateTime } from "../../actions/momentActions";
import LeadTable from "./LeadTable";
// import Button from "@mui/material/Button";
import SearchBar from "./SearchBar";
import { getLeadCount, getLead } from "../../actions/adminLeadActions";
import AddLeadForm from "./AddLeadForm";

const styles = (theme) => ({
  addWrapper: {
    padding: 10,
  },
  searchText: {
    display: "inline-block",
    marginTop: 10,
    marginLeft: 5,
  },
});

export class Lead extends React.Component {
  state = {
    isAdd: false,
    carrier: null,
    limit: 25,
    page: 0,
    leadCount: 0,
    leads: [],
    selected: {},
  };

  async componentDidMount() {
    await this.onReload();
  }

  onReload = async () => {
    await this.getLeadCount();
    await this.getLead();
  };

  getLeadCount = async () => {
    const response = await this.props.getLeadCount({});
    if (response && response.count) {
      this.setState({ leadCount: response.count });
    }
  };

  getLead = async (params) => {
    const { limit, page } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    const leads = await this.props.getLead({
      limit,
      skip,
      ...params,
    });
    this.setState({ leads, loading: false });
  };

  handleOpen = () => {
    this.setState({ isAdd: true });
  };

  handleClose = () => {
    this.onReload();
    this.setState({ isAdd: false, selected: null });
  };

  handleEdit = (selected) => {
    this.setState({ isAdd: true, selected });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getLead);
  };

  render() {
    const classes = this.props.classes;
    const { loading, isAdd, selected } = this.state;
    const leads = this.state.leads || [];

    return (
      <div>
        {isAdd && (
          <AddLeadForm
            isAdd={isAdd}
            handleClose={this.handleClose}
            selected={selected}
          />
        )}
        <Grid container className={classes.addWrapper}>
          <Grid item xs={12} sm={12} md={12}>
            <SearchBar
              handleAddClick={this.handleOpen}
              onSearch={this.getLead}
            />
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <LeadTable
              items={leads}
              count={this.state.leadCount}
              handleEdit={this.handleEdit}
              formatDate={this.props.formatDate}
              formatDateTime={this.props.formatDateTime}
              handleChange={this.handleChange}
              limit={this.state.limit}
              page={this.state.page}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatDateTime,
      getLeadCount,
      getLead,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Lead)
);
