import {createLogger} from 'redux-logger';

const loggerMiddleware = createLogger({
    errorTransformer: (error) => {
        console.log('loggerMiddleware e', error);
        return error;
    },
    collapsed: (getState, action, logEntry) => !logEntry.error,
    predicate: (getState, action) => false //isDev  Enable logger only for dev
})

export default loggerMiddleware;
