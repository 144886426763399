export const LABELS = {
  profileInfo: "Profile Info",
  accountInfo: "Account Info",
  name: "Name",
  email: "Email",
  status: "Status",
  language: "Language",
  uuid: "UUID",
  roles: "Roles",
  account: "Account",
  accountId: "Account Id",
  save: "Save",
  edit: "Edit",
  phone: "Phone",
  carrier: "Carrier",
  terminal: "Terminal",
  required: "Required",
};
