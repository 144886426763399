export const LABELS = {
  profileInfo: "Informations sur le profil",
  accountInfo: "Informations de compte",
  name: "Name",
  email: "E-mail",
  status: "Statut",
  language: "Langue",
  uuid: "UUID",
  roles: "Les rôles",
  account: "Compte",
  accountId: "Identifiant de compte",
  save: "Sauvegarder",
  edit: "Modifier",
  phone: "Téléphone",
  carrier: "Transporteur",
  terminal: "Terminal",
  required: "Requis",
};
