export const LABELS={
    addsupportcase:"समर्थन मामला जोड़ें",
    addShipping:"शिपिंग जोड़ें",
    addtodo:"TODO जोड़ें",
    
    account: "खाता",
    driver: "ड्राइवर",
    category:"श्रेणी",
    type:"प्रकार",
    product:"उत्पाद",

    priority:"प्राथमिकता",
    status:"स्थिति",
    time:"समय",
    assignedto:"को सौंपा गया",
    assignee_email:"सौंपने वाले का ईमेल",
    description:"विवरण",


    issue:"मुद्दा",
    detail:"विवरण",
    trackingUrl:"ट्रैकिंग URL",

    Solution:"समाधान",

    caller_name:"कॉलर का नाम",
    caller_email:"कॉलर का ईमेल",
    caller_phone:"कॉलर का फोन",


    callback:"कॉल बैक",
    callback_time:"कॉल बैक का समय",
    name:"नाम",
    email:"ईमेल",
    phone:"फोन",
    saving:"सहेजा जा रहा है...",
    save:"सहेजें",
    closed:"बंद",
    search:"खोजें",
    clear:"साफ़ करें",
    new:"नया",
    close:"बंद",
    update:"अद्यतन",
    add:"जोड़ना",



    Info:"जानकारी",
    Caller:"कॉलर",
    supportPlan:"समर्थन योजनाएँ",
    supportticket:"समर्थन टिकट",
    reason:"कारण",
    driverPhone:"ड्राइवर फोन",
    driveremail:"ड्राइवर ईमेल",
    addSupportTicket:"समर्थन टिकट जोड़ें",
    

    

    PleaseWaitLoading:"कृपया प्रतीक्षा करें, लोड हो रहा है....",
    successMessage: "समर्थन मामला सफलतापूर्वक सहेजा गया है",
    response :"कुछ गलत हो गया है, कृपया थोड़ी देर बाद पुन: प्रयास करें।",
    not_able_add_support_ticket:"समर्थन टिकट जोड़ने में सक्षम नहीं",
    errorMassageShow:"कुछ गलत हो गया है, कृपया थोड़ी देर बाद पुन: प्रयास करें।",

    rowsperpage: "प्रति पृष्ठ रो",

}
