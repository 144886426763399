export const LABELS = {
  refresh: "ताज़ा करें",
  recap: "संक्षिप्त",
  date: "तारीख",
  newTab: "नया टैब",
  download: "डाउनलोड",
  Audit: "लेखापरीक्षा",
  AuditEvent:"लेखापरीक्षा घटनाएँ",
  openInNewTab:"नए टैब में खोलें",
  ReassignMultipleStatus: "कई स्थिति पुनः असाइन करें",
  AddMultipleStatus: "कई स्थिति जोड़ें",
  EditMultipleStatus: "कई स्थिति संपादित करें",
  EditMultipleStatusAdmin: "कई स्थिति संपादित करें (प्रशासक)",
  AddStatus: "स्थिति जोड़ें",
  SendMessage: "संदेश भेजें",
  SendNotification: "सूचना भेजें",
  AddShippingOrTrailer: "शिपिंग या ट्रेलर जोड़ें",
  AddStatusAdmin: "स्थिति जोड़ें (प्रशासक)",
  ReassignMultipleStatusAdmin: "कई स्थिति पुनः असाइन करें (प्रशासक)",

 
  driver: "ड्राइवर",
  vehicle: "वाहन",
  trailer: "ट्रेलर",
  email: "ईमेल",
  startTime: "प्रारंभ समय",
  carrier: "वाहक",
  terminal: "होम टर्मिनल",
  shipping: "शिपिंग",
  app: "ऐप",
  cycle: "चक्र",
  coDriver: "सह-चालक",
  distance: "दूरी",
  engineHr: "इंजन घंटे",
  device: "डिवाइस",
  allLogs: "सभी लॉग्स",
  state: "राज्य",
  action: "क्रिया",
  odometerMi: "ओडोमीटर (मील)",

  editAdmin: "प्रशासक संपादित करें",
  DeactivateAdmin: "प्रशासक निष्क्रिय करें",
  DuplicateAdmin: "प्रशासक नकल करें",

  time: "समय",
  status: "स्थिति",
  Location: "स्थान",
  Odometer: "ओडोमीटर",
  EngineHours: "इंजन घंटे",
  Origin: "मूल",
  Annotation: "टिप्पणी",
  days: "दिन",
  hours: "घंटे",
  minutes: "मिनट",
  seconds: "सेकंड",
  odometer: "ओडोमीटर",
  subtract: "घटाएं",
  add: "जोड़ें",
  edit: "संपादित करें",
  deactivate: "निष्क्रिय करें",
  reason: "कारण",
  details: "विवरण",
  notes: "नोट्स",
  selectDriver: "ड्राइवर चुनें",
  required: "आवश्यक",
  note: "नोट",
  find: "खोजें",
  log: "लॉग",
  admin: "प्रशासक",
  adminEvents: "प्रशासक घटनाएँ",
  dvir: "डीवीआईआर",

  DATE: "तारीख",
  START_TIME: "प्रारंभ समय",
  CYCLE: "चक्र",
  DRIVER: "ड्राइवर",
  CARRIER: "वाहक",
  CO_DRIVERS: "सह-चालक",
  VEHICLES: "वाहन",
  HOMETERMINAL: "होम टर्मिनल",
  DISTANCE: "दूरी",
  TRAILERS: "ट्रेलर",
  SHIPPING_DOCS: "शिपिंग दस्तावेज़",
  ENGINE_HOURS: "इंजन घंटे",
  EMAIL: "ईमेल",

  eventTime: "घटना समय",
  CoordCode: "कोऑर्ड कोड",
  latitude: "अक्षांश",
  longitude: "देशांतर",
  reasonForEdit: "संपादन का कारण",
  recordOrigin: "रिकॉर्ड मूल",
  recordStatus: "रिकॉर्ड स्थिति",
  eventType: "घटना प्रकार",
  subType: "उप प्रकार",
  sequenceId: "क्रम संख्या",
  shippingDoc: "शिपिंग दस्तावेज़",
  accumulatedHours: "संचित घंटे",
  accumulatedMiles: "संचित मील",

  manageDrivers: "ड्राइवर प्रबंधित करें",
  save: "सहेजें",
  allDrivers: "सभी ड्राइवर",
  saving: "सहेजा जा रहा है...",
  close: "बंद करें",
  cancel: "रद्द करें",
  assignDriver: "ड्राइवर असाइन करें",

  editMultipleStatus: "कई स्थिति संपादित करें",
  addMultipleLogs: "कई लॉग जोड़ें",

  drActionReqMsg: "ड्राइवर कार्रवाई की आवश्यकता",
  drConfirmReqMsg: "कृपया ड्राइवर से अनुरोध स्वीकार या अस्वीकार करने की पुष्टि करें।",
  automaticDrMsg: "स्वचालित ड्राइव को संशोधित नहीं किया जा सकता।",
  successMessage: "सफलतापूर्वक सहेजा गया",
  errorMessage: "कुछ गलत हो गया, कृपया कुछ समय बाद पुनः प्रयास करें।",
  automaticDriveTimeError: "घटना समय की जाँच करें। आप स्वचालित ड्राइव के बाद स्थिति नहीं जोड़ सकते।",

  editsuccessMessage: "संपादन अनुरोध सफलतापूर्वक भेजा गया",

  No_logingFound_for: " कोई लॉगिन नहीं मिला",
  For_selected_date: "चयनित तारीख के लिए।",
  noDutyStatusFound: "कोई ड्यूटी स्थिति नहीं मिली।",
  errorMasage_selectCo_driverTOReassignLogs: "लॉग पुनः असाइन करने के लिए सह-ड्राइवर चुनें",

  updateTrailerMassage_shippingDocs_application: "अपडेट किए गए ट्रेलर या शिपिंग दस्तावेज़ केवल भविष्य की स्थिति के लिए लागू होंगे, किसी भी पिछले स्थिति के लिए नहीं।"
};
