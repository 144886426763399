const defaultState = {
    equipmentBusy: false,
    trailers: [],
    equipments: [],
    assetType: "VEHICLE",
    selectedEquipment: {},
    pagination: {
        page: 0,
        limit: 10
    }
};

export default function equipment(state = defaultState, action) {
    switch (action.type) {
        case "EQUIPMENTS":
            return {
                ...state,
                equipments: action.value
            };
        case "TRAILERS":
            return {
                ...state,
                trailers: action.value
            };
        case "EQUIPMENTS_COUNT":
            return {
                ...state,
                equipmentsCount: action.value
            };
        case "SELCTED_EQUIPMENT":
            return {
                ...state,
                selectedEquipment: action.value
            };
        case "APP_EQUIPMENT_BUSY":
            return {
                ...state,
                equipmentBusy: action.value
            };
        case "EQUIPMENTS_PAGINATION":
            return {
                ...state,
                pagination: action.value
            };
        case "EQUIPMENTS_ASSET_TYPE":
            return {
                ...state,
                assetType: action.value
            };
        default:
            return state;
    }
}
