import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import { withRouter } from "react-router-dom";
import { Alert } from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pdfOptions } from "../../helpers/helpers";
import { formatDate, formatTime } from "../../actions/momentActions";
import {
  getEqpLogs,
  getIftaLogs,
  deleteIftaLogs,
  emailIftaLogs,
  updateStateMileage,
} from "../../actions/stateMileageActions";
import { getProfile } from "../../actions/auth/authActions";
import CircularProgress from "@mui/material/CircularProgress";
import SearchBar from "../../components/SearchBar";
import MileTable from "./mileTable";
import IftaEmailModal from "./IftaEmailModal";
import { getMeterToMi, parseStateMileage, getDiffInKm } from "./helpers";
import { LABELS as _LABELS } from "../../language";
import { usStates } from "../../utils/constants";
const moment = require("moment-timezone");

const LABELS = _LABELS.settingPage;

function kmToMi(km) {
  try {
    let speed = parseFloat(km) * 0.621371;

    return speed.toFixed(2);
  } catch (e) {
    return 0;
  }
}

class StateMileage extends React.Component {
  state = {
    limit: 10,
    page: 0,
    loading: false,
    search: false,
    allRoutes: [],
    rawAllRoutes: [],
    prefix: 1,
    isIftaEmailOpen: false,
  };

  getEqpLogs = async () => {
    const { vehicleId, start, end, stateId } = this.state;
    if (!vehicleId) {
      this.setState({
        errorMessage: `${LABELS.select_vehicle}`,
        loading: false,
      });
    } else {
      try {
        this.setState({ loading: true, errorMessage: "", allRoutes: [] });

        const vehicle = this.props.equipments.filter(
          (item) => item.id === vehicleId
        );
        this.setState({ vehicle });
        const iftaLogs = await this.props.getIftaLogs({
          vehicleId: vehicleId,
          from: start,
          to: end,
          // vehicleId: "667f7e2a5b075b2ca2590394",
          // from: "2024-07-01T07:00:00.000Z",
          // to: "2024-08-01T06:59:59.000Z",
        });

        const _statemiles = iftaLogs.statemiles || [];
        const startLog = _statemiles[0];
        const lastLog = _statemiles[_statemiles.length - 1];
        const startOdometer = kmToMi(startLog.odometer);
        const lastOdometer = kmToMi(lastLog.odometer);
        const diffOdometer = (startOdometer - lastOdometer).toFixed();
        const allStatus = _statemiles.filter((item, idx) => {
          if (stateId && item.state !== stateId) {
            return false;
          }
          let prevLat = _statemiles[idx - 1]
            ? _statemiles[idx - 1].coordinates[0]
            : 0;
          let curLat = item.coordinates[0];
          // console.log('prevLat = ', prevLat);
          // console.log('curLat = ', curLat);
          return (
            Array.isArray(item.coordinates) &&
            item.coordinates.length === 2 &&
            item.coordinates[0] !== 0 &&
            item.coordinates[1] !== 0 &&
            prevLat !== curLat &&
            item.eventCode === "DRIVING"
          );
        });

        if (this.state.reportOn) {
          this.setState({
            allStatus,
            loading: false,
            errorMessage: "",
          });
          return;
        }
        this.setState({
          allStatus,
          diffOdometer,
        });

        if (allStatus && Array.isArray(allStatus) && allStatus.length > 1) {
          this.getStateMileage(allStatus);
        } else {
          this.setState({
            errorMessage: LABELS.no_recordFound,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    }
  };

  getWayPoints = (locations = []) => {
    try {
      const _first = locations[0];
      const _last = locations[locations.length - 1];
      const first = _first.coordinates;
      const last = _last.coordinates;
      let result = {
        origin: `${first[0]},${first[1]}`,
        destination: `${last[0]},${last[1]}`,
      };

      let idx = 0;
      for (let i = 0; i < locations.length; i++) {
        const _item = locations[i].coordinates;
        if (_item && _item[0]) {
          const keyName = `waypoint${idx}`;

          result[keyName] = `geo!${_item[0]},${_item[1]}`;

          idx++;
        }
      }
      return result;
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  makeTrips = async (statemiles) => {
    const chunkSize = 100;
    const trips = {};
    let tripName = 1;

    statemiles.forEach((item, idx) => {
      trips[tripName] = trips[tripName] || {};
      trips[tripName].name = tripName;
      trips[tripName].items = trips[tripName].items || [];

      if (idx > 0) {
        const previousCords = statemiles[idx - 1].coordinates;
        const currentCords = item.coordinates;
        const previous = moment(statemiles[idx - 1].date);
        const current = moment(item.date);
        const lat = currentCords && currentCords[0];
        const lng = currentCords && currentCords[1];

        let distance = 0;
        if (
          previousCords &&
          previousCords[0] &&
          currentCords &&
          currentCords[0]
        ) {
          distance = getDiffInKm(
            previousCords[0],
            previousCords[1],
            currentCords[0],
            currentCords[1]
          );
        }

        if (
          previous.diff(current, "hours") > 34 ||
          distance > 150 ||
          (trips[tripName] &&
            trips[tripName].items &&
            trips[tripName].items.length > chunkSize)
        ) {
          tripName = tripName + 1;
        }

        if (
          distance > 10 &&
          lat >= 7 &&
          lat <= 76 &&
          lng >= -169 &&
          lng <= -51
        ) {
          trips[tripName] = trips[tripName] || {};
          trips[tripName].items = trips[tripName].items || [];
          trips[tripName].items.push(item);
        }
      }
    });

    const tripKeys = Object.keys(trips);

    let _trips = [];
    for (var i = 0; i < tripKeys.length; i++) {
      const data = trips[tripKeys[i]];
      if (data && data.items && data.items.length > 1) {
        _trips.push(data);
      }
    }
    this.setState({ trips: _trips });
    return _trips;
  };

  getStateMileage = async (dutyLogs) => {
    try {
      const arrayOfPromises = [];
      const chunkSize = 100;

      const trips = await this.makeTrips(dutyLogs);

      for (let i = 0; i < trips.length; i++) {
        const dutyStatus = trips[i] || {};
        arrayOfPromises.push(this._getStateMileage(dutyStatus.items));
      }

      const response = await Promise.all(arrayOfPromises);

      const allRoutes = parseStateMileage(response);

      this.setState({
        allRoutes,
        rawAllRoutes: response,
        loading: false,
        errorMessage: "",
      });
    } catch (e) {
      console.log(" ************* END ERROR ************* ", e);
      this.setState({
        allRoutes: [],
        loading: false,
        errorMessage: e.errorMessage || "Not able to calculate state mileage",
      });
    }
  };

  _getStateMileage = (dutyLogs, cb) => {
    const { iftaApiKey } = this.props;
    return new Promise((resolve, reject) => {
      try {
        const platform = new window.H.service.Platform({
          apikey: iftaApiKey,
        });
        const allWayPoints = this.getWayPoints(dutyLogs);
        let routingParameters = {
          mode: "shortest;car",
          representation: "display",
          // routeattributes: "waypoints,summary,summaryByCountry,shape,legs",
          routeattributes: "wp,sm,sh,sc",
          maneuverattributes: "direction,action",
          alternatives: 0,
          // metricSystem: 'metric',
          language: "en-us",
          // excludeCountries:['TX'],
          ...allWayPoints,
        };
        const router = platform.getRoutingService();

        router.calculateRoute(
          routingParameters,
          (result) => {
            const response = (result.response && result.response.route) || [];
            const allRoutes = _get(response, "[0].summaryByCountry", []);
            resolve({ route: allRoutes, data: response });
          },
          (error) => {
            console.log(error);
            const _error = { errorMessage: "Calculate Ifta for few days" };
            resolve(_error);
          }
        );
      } catch (e) {
        console.log(e);
        resolve(e);
      }
    });
  };

  onSearch = ({
    vehicleId,
    vehicle,
    start,
    end,
    reportOn,
    prefix,
    stateId,
  }) => {
    this.setState(
      { vehicleId, vehicle, start, end, reportOn, prefix, stateId },
      this.getEqpLogs
    );
  };

  downloadReport = async () => {
    this.setState({ download: true });
    const fileName = `stateMileage.${"pdf"}`.replace(/ /g, "");
    window.scrollTo(0, 0);
    const element = document.getElementById("print_log");

    pdfOptions.filename = fileName;
    pdfOptions.margin = [0.3, 0.3, 0.3, 0.3];

    await html2pdf().set(pdfOptions).from(element).toPdf().save();
    this.setState({ download: false, saving: true });
  };

  emailReport = async (data, prefix) => {
    const iftaEmailData = data.map((item) => {
      const distance = getMeterToMi(item.distance, prefix);
      const stateObj = usStates.find((it) => item.country === it.name) || {};
      return {
        distance,
        state: stateObj.abbreviation,
        name: stateObj.name,
      };
    });

    this.setState({ isIftaEmailOpen: true, iftaEmailData });
  };

  onDetail = () => {
    const accountBaseUrl = this.props.accountBaseUrl;
    this.props.history.push(`${accountBaseUrl}/state-mileage/detail`);
  };

  tryOldIfta = () => {
    const { accountBaseUrl } = this.props;
    this.props.history.push(`${accountBaseUrl}/ifta`);
  };

  render() {
    const adminKey = this.props.adminKey;
    const rawAllRoutes = this.state.rawAllRoutes;
    const { loading, errorMessage } = this.state;
    const { iftaEmailData, isIftaEmailOpen = false } = this.state;

    return (
      <>
        {isIftaEmailOpen && (
          <IftaEmailModal
            open={isIftaEmailOpen}
            data={iftaEmailData}
            vehicle={{
              vehicleId: this.state.vehicleId,
              vehicle: this.state.vehicle,
              start: this.state.start,
              end: this.state.end,
              reportOn: this.state.reportOn,
            }}
            account={this.props.account}
            formatDate={this.props.formatDate}
            emailIftaLogs={this.props.emailIftaLogs}
            handleClose={() => this.setState({ isIftaEmailOpen: false })}
          />
        )}
        <SearchBar
          options={{
            isEqp: true,
            // isState: true,
            isStartDate: true,
            isEndDate: true,
            isTime: false,
            isTimeperiod: true,
            isReport: true,
            isPrefix: true,
            isDetail: true,
          }}
          loading={loading}
          onSearch={this.onSearch}
          stateMil={true}
          onDetail={this.onDetail}
        />

        {errorMessage && (
          <div style={{ margin: "20px" }}>
            <Alert severity="warning">
              {errorMessage}{" "}
              {adminKey && <button onClick={this.tryOldIfta}>IFTA</button>}
            </Alert>
          </div>
        )}
        {this.state.diffOdometer && (
          <div style={{ marginLeft: 25 }}>
            {LABELS.odometer}: {this.state.diffOdometer}
          </div>
        )}
        <>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <MileTable
              trips={this.state.trips}
              emailReport={this.emailReport}
              downloadReport={this.downloadReport}
              allRoutes={this.state.allRoutes}
              vehicle={this.state.vehicle}
              carrier={this.props.carrier}
              start={this.state.start}
              end={this.state.end}
              adminKey={this.props.adminKey}
              download={this.state.download}
              vehicleId={this.state.vehicleId}
              viewDetails={this.viewDetails}
              deleteIftaLogs={this.props.deleteIftaLogs}
              allStatus={this.state.allStatus}
              diffOdometer={this.state.diffOdometer}
              prefix={this.state.prefix}
              isState={true}
            />
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipments: _get(state, "equipmentData.equipments", []),
    adminKey: _get(state, "authData.profile.adminId"),
    carrier: _get(state, "authData.profile.account.carrier.name", ""),
    iftaApiKey: _get(state, "appData.IFTA_KEY", ""),
    account: _get(state, "authData.profile.account", {}),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIftaLogs,
      formatDate,
      formatTime,
      getEqpLogs,
      updateStateMileage,
      getProfile,
      emailIftaLogs,
      deleteIftaLogs,
      // getEquipments
    },
    dispatch
  );
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StateMileage)
);
