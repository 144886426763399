import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import { Formik, Form } from "formik";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputBox from "../../../components/InputBox";
import { Alert, AlertTitle } from "@mui/lab";
import { validate } from "./validate";
import Error from "../../Error";
import Success from "../../Success";
import { updateDriverDaily } from "../../../actions/logActions";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapperButton: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
});

export class AddDailyStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: "",
      successMessage: "",
      selEvent: {},
    };
  }

  componentDidMount() {
    const allEvents = this.props.allEvents || [];
    const selEvent =
      allEvents.find((item) => {
        const isActive =
          item.recordStatus === "ACTIVE" && item.eventSubType === "status";

        return (isActive && item.shippingDoc) || isActive;
      }) || {};

    this.setState({ selEvent });
  }

  handleSubmit = async (values) => {
    values.shippingUpdate = true;

    const diaryToday = this.props.diaryToday || {};

    const payload = {
      driverId: values.driverId,
      date: diaryToday.date,
      shippingDoc: values.shippingDoc,
    };

    const response = await this.props.updateDriverDaily(payload);

    if (response && response._id) {
      this.setState({
        successMessage: "Saved successfully.",
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 500);
    } else {
      this.setState({
        errorMessage: "Something went wrong, please try after sometime.",
        loading: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const selEvent = this.state.selEvent || {};
    const { errorMessage, successMessage, loading } = this.state;
    const isSmallScreen = window.innerWidth < 400;

    let initialValues = {
      statusId: selEvent.id,
      driverId: selEvent.driverId,
      shippingDoc: selEvent.shippingDoc || "",
    };

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{`Update`}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {selEvent && selEvent.id ? (
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={this.handleSubmit}
              enableReinitialize={true}
            >
              {({ values, handleSubmit, setFieldValue, handleChange }) => {
                return (
                  <Form style={{ padding: "20px 10px" }}>
                    <Grid container spacing={3}>
                      <InputBox name="shippingDoc" label="Shipping Doc" />
                    </Grid>
                    {successMessage && <Success message={successMessage} />}
                    {errorMessage && <Error message={errorMessage} />}
                    <DialogActions className={classes.DialogActions}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        {loading ? "Saving..." : "SAVE"}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={this.props.handleClose}
                      >
                        CLOSE
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Alert severity="warning">
              <AlertTitle>No duty status found.</AlertTitle>
            </Alert>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDriverDaily,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddDailyStatusModal)
);
