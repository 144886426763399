import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { signIn } from "../../../actions/auth/authActions";
import { getTimeZone } from "../../../actions/momentActions";
import { updatePlan } from "../../../actions/adminDashActions";
// import AddIcon from '@mui/icons-material/Add';
// import { validateEdit } from "./validate";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import TextField from "@mui/material/TextField";
import CheckBox from "../../../components/CheckBoxNew";
// import MomentUtils from "@date-io/moment";
// import {
//   KeyboardDateTimePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";
import { PLAN_OPTIONS, PLAN_NAME_OPTIONS } from "./formhelpers";
import {
  formatDateTimeUtc,
  formatDateTimeToUtc,
} from "../../../actions/momentActions";
import Error from "../../Error";
import Success from "../../Success";
import moment from "moment";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.adminPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 25,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class EditAccountForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      dues: props.userPlan?.dues || [],
      accountId: _get(props, "match.params.accountId"),
      planType: "monthly",
    };
  }

  async componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });
    const { dues, accountId } = this.state;
    const error = dues.filter((e) => !e.date || e.truck === "");
    const vehicles = values.vehicles || {};
    if (error && error.length > 0) {
      this.setState({ errorMessage: "Enter required fields ", loading: false });
      return;
    } else if (values.plan === "annually") {
      values.plan = {
        monthly: false,
        annual: true,
      };
    } else if (values.plan === "monthly") {
      values.plan = {
        monthly: true,
        annual: false,
      };
    }

    vehicles.allowed = vehicles.allowed || "0";
    vehicles.current = vehicles.current || "0";

    if (parseInt(vehicles.allowed) < parseInt(vehicles.current)) {
      this.setState({
        errorMessage: "Current vehicles must be less than allowed vehicles ",
        loading: false,
      });
      return;
    }

    if (values.planname) {
      values.plan = values.plan || {};
      values.plan.name = values.planname;
    }

    values.dues = dues.map((item) => {
      if (item.date) {
        item.date = this.props.formatDateTimeToUtc(item.date);
      }
      return item;
    });
    values.accountId = accountId;

    let response = await this.props.updatePlan(values);

    if (response && response._id) {
      this.setState({
        successMessage: "Plan is updated successfully",
        loading: false,
      });
      // this.props.getAccounts();
      setTimeout(() => {
        this.props.handleSave();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };
  handlePlanChange = (value) => {
    this.setState({ planType: value });
  };

  // Method to add a new due
  handleAddDue = () => {
    const { dues, planType } = this.state;
    dues.push({
      date: moment().add(planType === "monthly" ? 1 : 12, "M"), // Add 1 month for monthly plan, 1 year for annual plan
    });
    this.setState({ dues });
  };

  render() {
    const { classes, userPlan } = this.props;
    const {
      errorMessage,
      successMessage,
      loading,
      dues,
      planType,
    } = this.state;
    const isSmallScreen = window.innerWidth < 400;
    const getPlan = _get(userPlan, "plan", {});

    const plan = getPlan.monthly ? "monthly" : "annually";
    const planname = getPlan.name || "STANDARD";
    const initialValues = {
      planname,
      ...userPlan,
      plan,
    };

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleSave}>
          <Typography>{LABELS.editplan}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            // validate={validateEdit}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              initialValues,
              errors,
            }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <InputBox
                      md={2}
                      name="vehicles.allowed"
                      label={LABELS.allowedVehicle}
                      type="number"
                    />
                    <InputBox
                      md={2}
                      name="vehicles.current"
                      label={LABELS.currentVehicle}
                      type="number"
                    />

                    <SelectBox
                      md={2}
                      name="plan"
                      label="Plan"
                      items={PLAN_OPTIONS}
                      value={planType} // Pass the planType state as value
                      onChange={(e) => this.handlePlanChange(e.target.value)}
                    />

                    <SelectBox
                      md={2}
                      name="planname"
                      label={LABELS.name}
                      items={PLAN_NAME_OPTIONS}
                    />

                    <CheckBox md={2} name="ifta" label={"IFTA"} />
                    <CheckBox md={2} name="api" label={"API"} />
                    <Grid
                      container
                      spacing={4}
                      style={{
                        justifyContent: "space-around",
                        margin: "30px 0 5px 0",
                      }}
                    >
                      {dues.length !== 0 ? (
                        <>
                          <span>{LABELS.date}</span>
                          <span>{LABELS.truck}</span>
                          <span>{LABELS.note}</span>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleAddDue}
                          >
                            {LABELS.addDue}
                          </Button>
                        </>
                      ) : (
                        <Grid>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleAddDue}
                          >
                            {LABELS.addDue}
                          </Button>
                        </Grid>
                      )}
                    </Grid>

                    {dues.map((item, index) => {
                      const time = item.date
                        ? this.props.formatDateTimeUtc(item.date)
                        : moment();
                      return (
                        <Grid
                          container
                          spacing={4}
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginBottom: "20px",
                          }}
                        >
                          <div style={{ marginTop: "33px" }}>
                            <TextField
                              fullWidth
                              variant="standard"
                              size={"small"}
                              type={"datetime-local"}
                              className={classes.font}
                              style={{
                                borderBottom: "1px solid #7F7F7F",
                              }}
                              placeholder={LABELS.date}
                              onChange={(e) => {
                                item.date = e.target.value;
                                dues[index] = item;
                                this.setState({ dues });
                              }}
                              value={time}
                              InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                              }}
                            />
                          </div>
                          <div>
                            <TextField
                              fullWidth
                              variant="standard"
                              size={"small"}
                              className={classes.font}
                              style={{
                                borderBottom: "1px solid #7F7F7F",
                                marginTop: "32px",
                              }}
                              placeholder="Truck"
                              type="text"
                              onChange={(e) => {
                                item.truck = e.target.value;
                                dues[index] = item;
                                this.setState({ dues });
                              }}
                              // onBlur={() => {
                              //     this.props.multi(item)
                              // }}
                              value={this.state.truck || item.truck}
                              InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                              }}
                            />
                          </div>
                          <div>
                            <TextField
                              fullWidth
                              variant="standard"
                              size={"small"}
                              className={classes.font}
                              style={{
                                borderBottom: "1px solid #7F7F7F",
                                marginTop: "32px",
                              }}
                              placeholder="Note"
                              type="text"
                              onChange={(e) => {
                                item.note = e.target.value;
                                dues[index] = item;
                                this.setState({ dues });
                              }}
                              // onBlur={() => {
                              //     this.props.multi(item)
                              // }}
                              value={this.state.note || item.note}
                              InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                              }}
                            />
                          </div>
                          <IconButton
                            style={{ marginTop: "30px" }}
                            aria-label="Close"
                            // className={classes.closeButton}
                            onClick={(e) => {
                              e.preventDefault();
                              const due = dues.filter((ee) => item !== ee);
                              this.setState({ dues: due });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <div className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {loading ? LABELS.saving : LABELS.save}
                    </Button>{" "}
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signIn,
      updatePlan,
      getTimeZone,
      formatDateTimeUtc,
      formatDateTimeToUtc,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAccountForm))
);
