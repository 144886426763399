// import _get from "lodash/get";
// import _set from "lodash/set";

import { LABELS as _LABELS } from "../../language";
import { formatDateTimeUtc } from "../../utils/momentHelpers";

const LABELS = _LABELS.dutyPage;

const defaultValues = {
  accountId: "",
  accountName: "",
  driverId: "",
  category: "SUPPORT",
  callType: "INBOUND",
  product: "ELD",
  callStatus: "IN_PROGRESS",
  priority: "MEDIUM",
  title: "",
  detail: "",
  solution: "",
  caller: { name: "", email: "", phone: "" },
  cb: "NO",
  cbTm: formatDateTimeUtc(new Date()),
  callTm: formatDateTimeUtc(new Date()),
  cbInfo: { name: "", email: "", phone: "" },
  assignedTo: { name: "", email: "", phone: "" },
  notes: ''
};

const validate = (values) => {
  let errors = {};
  const requiredFields = ["title", "detail"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.required;
    }
  });

  return errors;
};

const validateQueue = (values) => {
  let errors = {};
  const requiredFields = ["accountId", "driverId", "reason"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.required;
    }
  });
  return errors;
};

export { validate, validateQueue, defaultValues };
