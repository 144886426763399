import _get from "lodash/get";
import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from '@mui/material/AppBar';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
// import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import {
  getAuditEvents,
  deleteAuditEvents,
} from "../../actions/adminDashActions";
import { formatDateTime } from "../../actions/momentActions";
import AuditTable from "./auditTable";

const styles = (theme) => ({
  paper: {
    // maxWidth: 936,
    margin: 15,
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Content(props) {
  const { classes } = props;
  const [events, setEvents] = useState([]);
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    getAllDayEvents();
    // eslint-disable-next-line
  }, []);

  async function getAllDayEvents() {
    setLoading(true);
    let response = await props.getAuditEvents();
    setEvents(response);
    setLoading(false);
  }

  async function onDelete(params) {
    setDeleting(true);
    // const searchPath = `/errors/${params.id}`;

    // let response = await props.deleteAuditEvents({ ...params, searchPath });
    getAllDayEvents();
    setDeleting(false);
  }

  return (
    <Paper className={classes.paper}>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {deleting && (
          <div style={{ textAlign: "center" }}>
            <h3>{"Deleting..."}</h3>
          </div>
        )}
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <AuditTable
              items={events}
              onDelete={onDelete}
              formatDateTime={props.formatDateTime}
              adminAccess={props.adminAccess}
              // count={this.state.accountCount}
              // handleEdit={this.handleEdit}
              // handleChange={handleChange}
              // limit={limit}
              // page={page}
            />
          </>
        )}
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    adminAccess: _get(state, 'authData.adminAccess'),
    classes: PropTypes.object.isRequired,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAuditEvents,
      deleteAuditEvents,
      formatDateTime,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Content)
);
