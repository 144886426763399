import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import NotFoundTableRow from "../NotFoundTableRow";
import Button from "@mui/material/Button";
// import Chip from "@mui/material/Chip";
import {LABELS as _LABELS} from "../../language"
const LABELS = _LABELS.adminPage;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1.5),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
  button: {
    marginTop: 2,
    minWidth: 100,
  },
  buttonLink: {
    textDecoration: "none",
    color: "white",
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, item = {} } = this.props;
    const carrier = item.carrier || {};
    // const account = item.account || {};
    // const createdBy = item.createdBy || {};
    const isCb = item.cb === "YES";

    return (
      <TableRow hover>
        <TableCell>
          <div>DOT# {`${item.dot}`}</div>
          <div className={classes.capitalize}>{carrier.name}</div>
          <div className={classes.capitalize}>{carrier.line}</div>
          <div
            className={classes.capitalize}
          >{`${carrier.city}, ${carrier.state}-${carrier.zip}`}</div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div>{`Status: ${item.status}`}</div>
          <div>{`${item.description}`}</div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div>{`Callback: ${this.props.formatDateTime(item.cbDate)}`}</div>
          <div>{`Created: ${this.props.formatDateTime(item.created)}`}</div>
          <div>{`Edited: ${this.props.formatDateTime(item.edited)}`}</div>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            color="primary"
            className={classes.button}
            onClick={(e) => this.props.handleEdit(item)}
          >
            {LABELS.edit}
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export class LeadTable extends React.Component {
  state = { open: false, selectedAccount: null, order: "asc" };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleDetails = async (selectedAccount) => {
    this.setState({ loading: true });
    this.setState({ open: true });
    this.props.selectedAccount(selectedAccount);
    const accountId = selectedAccount._id;
    await this.props.getAccountDetails({ accountId });
    this.setState({ loading: false });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.selectedAccount(null);
  };

  render() {
    const { eld, classes, items = [], count = 1000 } = this.props;
    const { page, limit } = this.props;

    // const { open, loading } = this.state;
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{LABELS.carrier}</TableCell>
                <TableCell>{LABELS.status}</TableCell>
                <TableCell>{LABELS.added}</TableCell>
                <TableCell>{LABELS.actions}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(items) &&
                items.map((item, i) => (
                  <ReduxTableRow
                    item={item}
                    key={i}
                    eld={eld}
                    classes={classes}
                    handleDetails={this.handleDetails}
                    handleEdit={this.props.handleEdit}
                    formatDate={this.props.formatDate}
                    formatDateTime={this.props.formatDateTime}
                    handleAccountDetails={this.props.handleAccountDetails}
                  />
                ))}
            </TableBody>
          </Table>
          <NotFoundTableRow items={items} />
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            labelRowsPerPage={LABELS.rowsperpage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

LeadTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadTable);
