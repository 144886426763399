import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
// import TextField from '@mui/material/TextField';
import AsyncSelect from "react-select/async";
import { getAccounts } from "../../actions/account/accountAdminActions";

function AccountSearch({
  onClick,
  xs = 12,
  sm = 12,
  md = 4,
  name,
  label,
  error,
  type = "text",
  placeholder,
  loading = false,
  onChange = () => {},
  ...rest
}) {
  const _label = label ? label : name;
  const defultValue = rest.selected || null;
  const [selected, setSelected] = useState(defultValue);

  const promiseOptions = (query: string) => {
    return new Promise(async (resolve) => {
      //
      const accountOrg = await rest.getAccounts({
        limit: 25,
        skip: 0,
        query,
      });
      resolve(accountOrg);
    });
  };

  const _getAccounts = async (query) => {
    const { limit = 25, page = 0 } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    const accountOrg = await this.props.getAccounts({
      limit,
      skip,
      query,
    });
    return accountOrg;
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} style={{ marginTop: 10.5 }}>
      <AsyncSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 0,
            border: 0,
            borderBottom: "1px solid #b3b3b3",
          }),
        }}
        placeholder={_label}
        cacheOptions
        isClearable
        loadOptions={promiseOptions}
        onChange={(_selected) => {
          setSelected(_selected);
          onChange(_selected);
        }}
        value={selected}
        filterOption={() => true}
        getOptionLabel={(option) => {
          return option.status
            ? `${option.name}(${option.status})`
            : `${option.name}`;
        }}
      />
      {error && <small style={{ color: "red" }}>{error}</small>}
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    carrierBusy: _get(state, "carrierData.carrierBusy", false),
    accounts: _get(state, "accountData.accounts", []),
    isSuperAdmin: _get(state, "authData.profile.user.adminAccess", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccounts,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccountSearch);
