const defaultState = {
  queueCount: 0,
};

export default function adminSupport(state = defaultState, action) {
  switch (action.type) {
    case "ADMIN_QUEUE_COUNT":
      return {
        ...state,
        queueCount: action.value,
      };

    default:
      return state;
  }
}
