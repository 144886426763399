export const LABELS = {
  saveMessage: "Enregistré avec succès.",
  errorMessage: "S'il vous plaît, essayez après un certain temps.",
  deleteLabel: "Supprimer les violations",
  deleteMsg: "Êtes-vous sûr de vouloir supprimer toutes les violations",
  language: "Langue",
  noDriverFound:'Aucun pilote trouvé',


  selectLanguage:"Sélectionnez la langue",
  saving:"Économie...",
  save:"Sauvegarder",
  close:"Fermer",
  
};
