import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import Badge from "@mui/material/Badge";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { getDriversRealTime } from "../../actions/driverActions";
import { formatDate, formatTime } from "../../actions/momentActions";
import NotFoundTableRow from "../NotFoundTableRow";
import DutyStatus from "../../components/DutyStatus";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  table: {
    // minWidth: 700,
    // maxWidth: 700,
  },
  small: {
    minWidth: 75,
  },
  medium: {
    minWidth: 200,
  },
  large: {
    minWidth: 300,
  },
  speed: {
    display: "inline-block",
    marginLeft: 10,
    fontWeight: 600,
  },
});

function RenderViolation(props) {
  const violation = props.violation;
  return (
    <>
      {violation ? (
        <Badge
          overlap="rectangular"
          badgeContent={`${violation}`}
          color="error"
        ></Badge>
      ) : null}
    </>
  );
}

function getAppName(name) {
  if (!name) {
    return "";
  }
  const nameArray = name.split(":");
  const first = nameArray && nameArray[0];
  let app = "";
  if (first === "I") {
    app = "Apple";
  } else if (first === "A") {
    app = "Android";
  }
  return app;
}

class ReduxTableRow extends React.Component {
  renderTelemetrics = (item) => {
    if (item.eventCode !== "DRIVING") {
      return null;
    }
    const _speed = _get(item, "telemetrics.speed", 0);
    const speed = _speed ? `Speed  ${parseInt(_speed)} M/hr(s)` : "";

    return (
      <div>
        <small>{speed}</small>
      </div>
    );
  };

  render() {
    const { classes, item = {} } = this.props;
    const driver = item.driver || {};
    const vehicle = item.vehicle || {};
    const connected = item.connected || false;
    const certPending = item.certPending || "";
    const version = item.version || "";
    const remaining = _get(item, "remaining", {});
    const violation = _get(item, "remaining.violation", "");
    const appName = getAppName(item.version);

    return (
      <TableRow
        hover
        onClick={() => this.props.handleClick(driver)}
        style={{ cursor: "pointer" }}
      >
        <TableCell className={classes.capitalize}>
          {driver.firstName} {driver.lastName}
        </TableCell>

        <TableCell>
          <DutyStatus
            status={item}
            eventType={item.eventType}
            eventCode={item.eventCode}
            annotation={item.annotation}
            eventSubType={item.eventSubType}
          />
        </TableCell>
        <TableCell>
          {this.renderTelemetrics(item)}
          {item.location}
        </TableCell>

        <TableCell>{vehicle.name ? vehicle.name : ""}</TableCell>
        <TableCell>{remaining.break}</TableCell>
        <TableCell>{remaining.drive}</TableCell>
        <TableCell>{remaining.onDuty}</TableCell>
        <TableCell>{remaining.cycle}</TableCell>
        <TableCell>
          <RenderViolation violation={remaining.violation} />
        </TableCell>
        <TableCell>{connected ? "Connected" : "Disconnected"}</TableCell>
        <TableCell>
          <div>
            {this.props.formatDate(item.lastContact)}
            {` @ `}
            {this.props.formatTime(item.lastContact)}
          </div>
        </TableCell>
        <TableCell>
          {appName === "Apple" && <AppleIcon fontSize="medium" />}
          {appName === "Android" && (
            <AndroidIcon fontSize="medium" style={{ color: "green" }} />
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export class CurrentLogsTable extends React.Component {
  state = {
    driversOrg: [],
  };

  handleClick = (driver) => {
    const { accountBaseUrl } = this.props;
    this.props.history.push(`${accountBaseUrl}/status/diary/${driver.id}`);
  };

  render() {
    const classes = this.props.classes;
    const LABELS = this.props.LABELS;
    const drivers = this.props.driverData || [];
    const driversOrg = this.props.driversOrg || [];
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.medium}>{LABELS.name}</TableCell>
                  <TableCell className={classes.small}>{LABELS.status}</TableCell>
                  <TableCell className={classes.medium}>{LABELS.location}</TableCell>
                  <TableCell className={classes.small}>{LABELS.vehicle}</TableCell>
                  <TableCell className={classes.small}>{LABELS.break}</TableCell>
                  <TableCell className={classes.small}>{LABELS.drive}</TableCell>
                  <TableCell className={classes.small}>{LABELS.shift}</TableCell>
                  <TableCell className={classes.small}>{LABELS.cycle}</TableCell>
                  <TableCell className={classes.small}>{LABELS.violations}</TableCell>
                  <TableCell className={classes.small}>{LABELS.eld}</TableCell>
                  <TableCell className={classes.large}>{LABELS.update}</TableCell>
                  <TableCell className={classes.small}>{LABELS.app}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(drivers) &&
                  drivers.map((item, i) => {
                    if (!item.driverId) {
                      return null;
                    }
                    return (
                      <ReduxTableRow
                        item={item}
                        key={i}
                        classes={classes}
                        handleClick={this.handleClick}
                        formatDate={this.props.formatDate}
                        formatTime={this.props.formatTime}
                      />
                    );
                  })}
              </TableBody>
            </Table>
            <NotFoundTableRow
              items={drivers.length === 0 ? drivers : driversOrg}
            />
          </Paper>
        </Grid>
        {/*<Grid item xs={12} sm={12} md={12}>
                    <LLMap
                        items={driverRealTime}
                        formatDate={this.props.formatDate}
                        formatTime={this.props.formatTime}
                    />
                </Grid>*/}
      </Grid>
    );
  }
}

CurrentLogsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "logData.logBusy", false),
    logs: _get(state, "logData.logs", []),
    driverRealTime: _get(state, "driverData.driverRealTime", []),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatTime,
      getDriversRealTime,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrentLogsTable))
);
