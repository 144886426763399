export const TELE_EVENTS = {
  // PER: "PER",
  // ENOF: "EN0",
  // ENON: "EN1",
  // PWOFF: "PW0",
  // PWON: "PW1",
  // IGOF: "IG0",
  // IGON: "IG1",
  // BLD: "BL0",
  // BLC: "BL1",
  // TRST: "TR1",
  // TRED: "TR0",
  // BUSC: "BU1",
  // BUSD: "BU0",
  // HAC: "ACC",
  // HBR: "BRK",
  // HCO: "COR",
  // PER: "PERIODIC",
  PWON: "Power ON",
  PWOFF: "Power OFF",
  IGON: "Ignition ON",
  IGOF: "Ignition OFF",
  ENON: "Engine ON",
  ENOF: "Engine OFF",
  TRST: "Trip START",
  TRED: "Trip STOP",
  PER: "Periodic",
  BLC: "Bluetooth Connected",
  BLD: "Bluetooth Disconnected",
  BUSC: "BUS Connected",
  BUSD: "BUS Disconnected",
  HAC: "Harsh Accelerating",
  HBR: "Harsh Braking",
  HCO: "Harsh Cornering/Swerving",
};
