export default function routes(state = {}, action) {
    switch (action.type) {
    case 'APP_ROUTES':
        return {
            ...state,
            routes: action.value
        }
    default:
        return state
    }
}
