function degreesToRadians(degrees) {
  const pi = Math.PI;
  return degrees * (pi / 180);
}

function radiansToDegrees(radians) {
  const pi = Math.PI;
  return (radians * 180.0) / pi;
}

function getBearingBetweenTwoPoints(lat, lng, _lat2, _lng2) {
  let lat1 = degreesToRadians(lat);
  let lon1 = degreesToRadians(lng);

  let lat2 = degreesToRadians(_lat2);
  let lon2 = degreesToRadians(_lng2);

  let dLon = lon2 - lon1;
  let y = Math.sin(dLon) * Math.cos(lat2);
  let x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  let radiansBearing = Math.atan2(y, x);

  let deg = radiansToDegrees(radiansBearing);
  if (deg >= 11.25 && deg < 33.75) {
    return "NNE";
  } else if (deg >= 33.75 && deg < 56.25) {
    return "NE";
  } else if (deg >= 56.25 && deg < 78.75) {
    return "ENE";
  } else if (deg >= 78.75 && deg < 101.25) {
    return "E";
  } else if (deg >= 101.25 && deg < 123.75) {
    return "ESE";
  } else if (deg >= 123.75 && deg < 146.25) {
    return "SE";
  } else if (deg >= 146.25 && deg < 168.75) {
    return "SSE";
  } else if (deg >= 168.75 && deg < 191.25) {
    return "S";
  } else if (deg >= 191.25 && deg < 213.75) {
    return "SSW";
  } else if (deg >= 213.75 && deg < 236.25) {
    return "SW";
  } else if (deg >= 236.25 && deg < 258.75) {
    return "WSW";
  } else if (deg >= 258.75 && deg < 281.25) {
    return "W";
  } else if (deg >= 281.25 && deg < 303.75) {
    return "WNW";
  } else if (deg >= 303.75 && deg < 326.25) {
    return "NW";
  } else {
    return "N";
  }
}

function getDistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return Math.round(dist);
  }
}

export function getAddress({ location, lat, lng, lat2, lng2 }) {
  const dir = getBearingBetweenTwoPoints(lat, lng, lat2, lng2);
  const _lat = parseFloat(lat);
  const _lng = parseFloat(lng);
  const _lat2 = parseFloat(lat2);
  const _lng2 = parseFloat(lng2);
  const distance = getDistance(_lat, lng, _lat2, lng2);
  const city = location && location.city;
  const state = location && location.state_abbr;
  const addressStr = `${distance} mi ${dir} ${city}, ${state}`;

  return addressStr;
}
