import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./resellerActionTypes";

export const setResellers = (value) => ({
  type: TYPES.RESELLERS,
  value,
});

export function getResellers(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");

      const response = await axios({
        url: `${BASE_URL}/admin/resellers`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", []);
      dispatch(setResellers(_response));
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}

export function createReseller(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");

      const response = await axios({
        url: `${BASE_URL}/admin/resellers`,
        method: "post",
        data: params,
      });
      const _response = _get(response, "data", []);
      dispatch(setResellers(_response));
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}

export function updateReseller(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");

      const response = await axios({
        url: `${BASE_URL}/admin/resellers`,
        method: "put",
        data: params,
      });
      const _response = _get(response, "data", []);
      dispatch(setResellers(_response));
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}

export function getResellerById(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const resellerId = params.id || params._id;
      const response = await axios({
        url: `${BASE_URL}/admin/resellers/${resellerId}`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", {});
      dispatch(setResellers(_response));
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}
