import _get from "lodash/get";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import { getUsers } from "../../actions/userActions";
import SupportPackage from "./SupportPackage";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.supportForm;

const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: 25,
    textAlign: "center",
  },
  button: {
    minWidth: 75,
  },
  buttonPlan: {
    minWidth: 150,
    fontWeight: 500,
  },
});

export function SearchAppBar(props) {
  const [drivers, setDrivers] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const classes = props.classes;
  const constants = props.constants;
  const { errorMessage, successMessage, loading } = {};

  let initialValues = {
    callStatus: "OPEN",
  };

  function validate() {}

  function handleSubmit(values) {
    props.onSearch(values);
  }

  async function _getDrivers(accountId) {
    try {
      const payload = {
        limit: 500,
        skip: 0,
        status: "ACTIVE",
      };

      const _drivers = await props.getUsers(payload);
      setDrivers(_drivers);
    } catch (e) {
      console.log(e);
    }
  }

  const [visible, setVisible] = useState(true);
  function forceRerender() {
    setVisible(false);
    setTimeout(function () {
      setVisible(true);
    }, 10);
  }

  useEffect(() => {
    _getDrivers();
  }, []);

  return (
    <>
      {isSupport && (
        <SupportPackage
          account={props.account}
          profile={props.profile}
          open={isSupport}
          plan={props.plan}
          onClose={() => setIsSupport(false)}
        />
      )}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <SelectBox
                  md={5}
                  selValue={"id"}
                  selLabel={"firstName"}
                  selLabelTwo={"lastName"}
                  selLabelThree={"status"}
                  name="driverId"
                  label={LABELS.driver}
                  items={drivers}
                />
                <SelectBox
                  md={3}
                  name="status"
                  label={LABELS.status}
                  items={constants.QUEUE_STATUS}
                />
                <Grid item sm xs={12} md={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.button}
                  >
                    {loading ? LABELS.saving : LABELS.search}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      setFieldValue("accountId", "");
                      setFieldValue("driverId", "");
                      setFieldValue("callStatus", "");
                      forceRerender();
                      props.onSearch({});
                    }}
                  >
                    {LABELS.clear}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.handleAddClick}
                    className={classes.button}
                  >
                    {`${LABELS.new}`}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsSupport(true)}
                    className={classes.buttonPlan}
                  >
                    {`${LABELS.supportPlan}`}
                  </Button>
                  {}
                </Grid>
              </Grid>

              {successMessage && <Success message={successMessage} />}
              {errorMessage && <Error message={errorMessage} />}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    equipments: _get(state, "equipmentData.equipments", []),
    constants: _get(state, "appData.constants", {}),
    plan: _get(state, "authData.profile.plan.plan", {}),
    profile: _get(state, "authData.profile", {}),
    account: _get(state, "authData.profile.account", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchAppBar)
);
