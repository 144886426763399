// import { data } from "./test";

export function formatRtDriver(data, accounts) {
  const offDuty = data.filter((item) => item.eventCode === "OFFDUTY");
  const onDuty = data.filter((item) => item.eventCode === "ONDUTY_ND");
  const driving = data.filter((item) => item.eventCode === "DRIVING");
  const sleeper = data.filter((item) => item.eventCode === "SLEEPER");
  const violations = data.filter((item) => {
    const remaining = item.remaining || {};
    return remaining.violation;
  });
  const certPending = data.filter((item) => {
    return item.certPending;
  });
  const connects = data.filter((item) => {
    return item.connected;
  });

  const drivers = data.map((item, idx) => {
    const driver = item.driver || {};
    const remaining = item.remaining || {};
    const violation = remaining.violation || "";
    const company = accounts.find((_item) => {
      return _item._id === item.accountId;
    });

    const payload = {
      id: `id-${idx + 1}`,
      firstName: driver.firstName,
      lastName: driver.lastName,
      violation,
      eventTime: item.eventTime,
      eventCode: item.eventCode,
      connected: item.connected,
      company: company ? company.name : "",
      companyId: company ? company._id : ''
    };
    return payload;
  });

  return {
    drivers: drivers,
    offDuty: offDuty.length,
    onDuty: onDuty.length,
    driving: driving.length,
    sleeper: sleeper.length,
    connected: connects.length,
    violations: violations.length,
    forms: certPending.length,
  };
}
