import _get from "lodash/get";
import * as TYPES from "../auth/authActionTypes";
import { isBluehorse, isEldBooks } from "../../configureStore";
const defaultState = {
  appBusy: false,
  preCheck: true,
  isAdmin: false,
  isDriver: false,
  isManager: false,
  termsBusy: false,
  needAutorization: false,
  accountBaseUrl: "",
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case "PROFILE":
      const roles = _get(action, "value.roles", []);
      const scopes = _get(action, "value.scopes", []);
      const adminId = _get(action, "value.adminId");
      const superAdminAccess = _get(action, "value.adminAccess");
      const superSupportAccess = _get(action, "value.adminSupport");
      const superPartnerAccess = _get(action, "value.adminReseller");
      const isAdmin = roles.includes("ADMIN");
      const isManager = roles.includes("MANAGER");
      const isPartner = roles.includes("PARTNER");
      const isManagerSor = (isAdmin && adminId) || (isPartner && adminId);
      const isDriver = roles.includes("DRIVER");
      const isSuperAdmin = superAdminAccess && isAdmin;
      // const isSupportAdmin = superAdminAccess && isAdmin;

      const accountId = `${_get(action, "value.user.accountId")}`;
      const driverId = `${_get(action, "value.user.driverId")}`;
      const adminAccess = _get(action, "value.user.adminAccess", false);
      const isViewOnly =
        roles.includes("MANAGER") && scopes.includes("VIEW_ALL");

      let isAdminDs = // Duty status status
        ((superAdminAccess || superSupportAccess) &&
          roles.includes("ADMIN") &&
          scopes.includes("ADMIN_DS_ALL")) ||
        false;

      let isAdminDsMul = // All duty status multi admin
        ((superAdminAccess || superSupportAccess) &&
          roles.includes("ADMIN") &&
          scopes.includes("ADMIN_DSMT_ALL")) ||
        false;

      let isAdminDre = // All reassign duty status admin
        ((superAdminAccess || superSupportAccess) &&
          roles.includes("ADMIN") &&
          scopes.includes("ADMIN_RA_SUPPORT")) ||
        false;

      let isAdminPay = // All payment admin
        ((superAdminAccess || superSupportAccess) &&
          roles.includes("ADMIN") &&
          scopes.includes("ADMIN_PAYMENT_SUPPORT")) ||
        false;

      let isAdminSup = // All payment admin
        ((superAdminAccess || superSupportAccess) &&
          roles.includes("ADMIN") &&
          scopes.includes("ADMIN_SP_ALL")) ||
        false;

      let isAdminRes = // All reseller admin
        (superAdminAccess &&
          roles.includes("ADMIN") &&
          scopes.includes("ADMIN_RS_ALL")) ||
        false;

      if (isEldBooks || isBluehorse) {
        isAdminDs = // Duty status status
          ((superAdminAccess || superSupportAccess || superPartnerAccess) &&
            (roles.includes("ADMIN") || roles.includes("PARTNER")) &&
            scopes.includes("ADMIN_DS_ALL")) ||
          false;

        isAdminDre = // All reassign duty status admin
          ((superAdminAccess || superSupportAccess || superPartnerAccess) &&
            (roles.includes("ADMIN") || roles.includes("PARTNER")) &&
            scopes.includes("ADMIN_RA_SUPPORT")) ||
          false;
      }

      return {
        ...state,
        profile: action.value,
        driverId,
        accountId,
        needAutorization: false,
        accountBaseUrl: `/dashboard/${accountId}`,

        isAdmin,
        isSuperAdmin,
        isPartner,
        isManager,
        isManagerSor,
        isDriver,
        adminAccess,
        isViewOnly,

        isAdminDs, // Duty status status
        isAdminDsMul, // All duty status multi admin
        isAdminDre, // All reassign duty status admin
        isAdminRes, // All reseller admin
        isAdminPay, // All payment admin
        isAdminSup, // All support admin
      };
    case "SIGNIN_ERROR":
      return {
        ...state,
        signInError: action.value,
      };
    case "NEED_AUTORIZATION":
      return {
        ...state,
        needAutorization: action.value,
      };
    case TYPES.TERMS_BUSY:
      return {
        ...state,
        termsBusy: action.value,
      };
    default:
      return state;
  }
}
