import React from "react";
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  formatDate,
  formatTime,
  formatDateTimeSec,
  getStartOfDay,
  getEndOfDay,
  formatDateTime,
} from "../../actions/momentActions";
import LiveShareTable from "./LiveShareTable";
import AddModal from "./AddModal";
import AddIcon from "@mui/icons-material/Add";
import {
  getLiveShares,
  getLiveSharesCount,
} from "../../actions/liveShareActions";
import { startDay, currentTime } from "../../utils/momentHelpers";
import SearchBar from "../../components/SearchBar";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.adminPage;

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
    marginTop: 5,
  },
  title: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
    marginTop: "20px",
  },
});

class LiveShare extends React.Component {
  state = {
    limit: 10,
    page: 0,
    count: 0,
    openAdd: false,
    loading: false,
    start: startDay,
    end: currentTime(30),
    driverId: "",
    vehicleId: "",
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getLiveSharesCount();
    await this.getLiveShares();
    this.setState({ loading: false });
  }

  getLiveSharesCount = async () => {
    this.setState({ loading: true });
    const { limit, page, driverId, vehicleId, start, end } = this.state;

    await this.props.getLiveSharesCount({
      status: ["ACTIVE", "IN_ACTIVE"],
      limit,
      page,
      driverId,
      vehicleId,
      start: start ? new Date(this.props.getStartOfDay(start)) : null,
      end: end ? new Date(this.props.getEndOfDay(end)) : null,
    });
    this.setState({ loading: false });
  };
  getLiveShares = async () => {
    this.setState({ loading: true });
    const { limit, page, driverId, vehicleId, start, end } = this.state;

    await this.props.getLiveShares({
      status: ["ACTIVE", "IN_ACTIVE"],
      limit,
      page,
      driverId,
      vehicleId,
      start: start ? new Date(this.props.getStartOfDay(start)) : null,
      end: end ? new Date(this.props.getEndOfDay(end)) : null,
    });
    this.setState({ loading: false });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, async () => {
      this.getLiveShares();
    });
  };

  handleClose = () => {
    this.setState({ openAdd: false, selected: null });
  };

  handleSearchChange = ({ driverId, vehicleId, start, end }) => {
    this.setState({ driverId, vehicleId, start, end }, async () => {
      await this.getLiveSharesCount();
      this.getLiveShares();
    });
  };

  onSelect = (selected) => {
    this.setState({ selected, openAdd: true });
  };

  render() {
    const { classes } = this.props;
    const { openAdd, selected, loading } = this.state;
    return (
      <div style={{ padding: 5 }}>
        {openAdd && (
          <AddModal
            open={openAdd}
            selected={selected}
            handleClose={this.handleClose}
            onDone={async () => {
              this.getLiveShares();
              // this.getLiveSharesCount();
              this.handleClose();
            }}
          />
        )}
        <AppBar position="static" color="inherit">
          <SearchBar
            options={{
              isEqp: true,
              isDriver: true,
              isStartDate: true,
              isEndDate: true,
              isTime: true,
              isTimeperiod: true,
              startDate: this.state.start,
              startDate: this.state.end,
            }}
            startLabel={LABELS.start_expiry_date}
            endLabel={LABELS.end_expiry_date}
            loading={loading}
            onSearch={this.handleSearchChange}
          />
          {/* {loading && (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>
                    )} */}
          <Toolbar>
            <Typography className={classes.title} />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                this.setState({ openAdd: true });
              }}
            >
              {LABELS.add}
            </Button>
          </Toolbar>
        </AppBar>

        {loading ? (
          <div className={classes.grow}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Paper className={classes.root}>
              <LiveShareTable
                count={this.props.count}
                items={this.props.liveShares}
                formatDate={this.props.formatDate}
                formatTime={this.props.formatTime}
                limit={this.state.limit}
                page={this.state.page}
                onSelect={this.onSelect}
                shareUrl={this.props.shareUrl}
                handleChange={this.handleChange}
              />
            </Paper>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    liveShares: _get(state, "liveShareData.liveShares", []),
    count: _get(state, "liveShareData.count", 0),
    shareUrl: _get(state, "appData.LIVE_SHARE_URL"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatTime,
      formatDateTimeSec,
      getLiveShares,
      getLiveSharesCount,
      getStartOfDay,
      getEndOfDay,
      formatDateTime,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LiveShare)
);
