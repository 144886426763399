import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { sendSms } from "../../../../actions/userActions";
import Grid from "@mui/material/Grid";
import Error from "../../../Error";
import Success from "../../../Success";
import { validateSms } from "./validate";
import { Formik, Form } from "formik";
import InputBox from "../../../../components/InputBox";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class TermsDialog extends React.Component {
  state = {
    email: "",
    successMessage: "",
    errorMessage: "",
  };

  componentDidMount() {}

  onResetRequest = async ({ to, message }) => {
    this.setState({ loading: true });

    const params = { to: to.toString(), message };
    const response = await this.props.sendSms(params);

    if (response && response.direction === "outbound-api") {
      this.setState({
        loading: false,
        successMessage: "Sms is send successfully",
      });
      setTimeout(() => this.props.handleClose(), 5000);
    } else if (response.status && response.status === "ERROR") {
      this.setState({
        loading: false,
        errorMessage: response.message,
      });
    } else {
      this.setState({
        loading: false,
        errorMessage: "Please try after sometime.",
      });
    }
  };

  render() {
    const { classes, selected } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const andriodUrl = this.props.andriodAppUrl;
    const message = `${andriodUrl} Driver Login ${selected.email}`;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h2 style={{ textAlign: "center", padding: 0 }}>{"SEND SMS"}</h2>
        <DialogTitle id="alert-dialog-title" onClose={this.props.onClose}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Formik
            initialValues={{ to: selected.mobile, message }}
            validate={validateSms}
            onSubmit={this.onResetRequest}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <InputBox
                      md={12}
                      name="to"
                      type="number"
                      label="Mobile Number"
                    />
                    <InputBox
                      md={12}
                      type="textarea"
                      name="message"
                      label="Message"
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Error top={20} message={errorMessage} />
                    <Success top={20} message={successMessage} />
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      marginTop: 45,
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      style={{
                        minWidth: 250,
                      }}
                    >
                      {loading ? "Loading..." : "Send Now"}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      marginTop: 15,
                      textAlign: "center",
                    }}
                  >
                    <Button onClick={this.props.handleClose}>{"Close"}</Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    iosAppUrl: _get(state, "appData.iosAppUrl"),
    andriodAppUrl: _get(state, "appData.andriodAppUrl"),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ sendSms }, dispatch);

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsDialog))
);
