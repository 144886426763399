export const pdfOptions = {
    enableLinks: true,
    margin: [0.5, 0.5, 0.5, 0.5],
    image: { type: "jpeg", quality: 0.90 },
    html2canvas: {
        scale: 1.2,
        dpi: 162,
        letterRendering: true,
        useCORS: true
    },
    jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait",
        compressPDF: true
    }
};
