export const LABELS = {
  viewLogs: "लॉग्स देखें",
  date: "तारीख",
  detailsMulti: "मल्टी",
  certified: "प्रमाणित",
  uncertified: "अप्रमाणित",
  detail: "विवरण",
  violation: "उल्लंघन",
  driving: "ड्राइविंग",
  offDuty: "ऑफ ड्यूटी",
  onDutyND: "OnDutyND",
  sleeper: "स्लीपर",

  deleteLabel: "उल्लंघन हटाएँ",
  deleteMsg: "क्या आप वाकई सभी उल्लंघनों को हटाना चाहते हैं?",
};
