const defaultState = {
    trailerBusy: false,
    trailers: [],
    // trailerss: [],
    // assetType: "VEHICLE",
    selectedtrailers: {},
    pagination: {
        page: 0,
        limit: 10
    }
};

export default function trailers(state = defaultState, action) {
    switch (action.type) {
        case "TRAILERS":
            return {
                ...state,
                trailers: action.value
            };
        case "APP_TRAILER_BUSY":
            return {
                ...state,
                trailerBusy: action.value
            };
        case "TRAILERS_COUNT":
            return {
                ...state,
                trailersCount: action.value
            };
        case "TRAILERS_PAGINATION":
            return {
                ...state,
                pagination: action.value
            };

        default:
            return state;
    }
}
