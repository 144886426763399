import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.dutyPage;

const defaultValues = {
  status: "",
  provider: "",
  shipDate: "null",
  trackingUrl: "",
};

const validate = (values) => {
  let errors = {};
  const requiredFields = ["accountId", "status"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.dutyPage;
    }
  });

  return errors;
};

export { validate, defaultValues };
