export const LABELS={
  addCarrier :"ਕੈਰੀਅਰ ਸ਼ਾਮਲ ਕਰੋ",
  editCarrier:"ਕੈਰੀਅਰ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  edit_Terminal:"ਟਰਮੀਨਲ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  add_Terminal:"ਟਰਮੀਨਲ ਸ਼ਾਮਲ ਕਰੋ",
  addAccount:"ਖਾਤਾ ਸ਼ਾਮਲ ਕਰੋ",

  editEquipment :"ਉਪਕਰਨ ਸੰਪਾਦਿਤ ਕਰੋ",
  addEquipment:"ਉਪਕਰਨ ਸ਼ਾਮਲ ਕਰੋ",

  editTrailer:"ਟ੍ਰੇਲਰ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  addTrailer:"ਟ੍ਰੇਲਰ ਸ਼ਾਮਲ ਕਰੋ",

  editReasons:"ਕਾਰਨਾਂ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",

  addUser:"ਵਰਤੋਂਕਾਰ ਸ਼ਾਮਲ ਕਰੋ",
  editUser:"ਵਰਤੋਂਕਾਰ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  driver_details:"ਡਰਾਈਵਰ ਵੇਰਵੇ",
  update:"ਅੱਪਡੇਟ ਕਰੋ",
  addApi_key:"API ਕੁੰਜੀ ਸ਼ਾਮਲ ਕਰੋ",

  logingUsers:"ਲੌਗਇਨ ਉਪਭੋਗਤਾ",
  supportCentre:"ਸਹਾਇਤਾ ਕੇਂਦਰ",


  name:"ਨਾਮ",
  dot:"ਡੀ.ਓ.ਟੀ",
  timezone:"ਸਮਾਂ ਖੇਤਰ",
  country:"ਦੇਸ਼",
  address_line1:"ਪਤਾ ਲਾਈਨ 1",
  suit_bldg:"Suit.,ਇਮਾਰਤ",
  city:"ਸ਼ਹਿਰ",
  state:"ਰਾਜ",
  zip:"ਜ਼ਿਪ",
  contactName:"ਸੰਪਰਕ ਨਾਮ",
  email:"ਈ - ਮੇਲ",
  phone:"ਫ਼ੋਨ",
  role:"ਭੂਮਿਕਾ",
  dispatchName:"ਡਿਸਪੈਚ ਨਾਮ",
  note:"ਨੋਟ:",
  info:"ਡਿਸਪੈਚ ਜਾਣਕਾਰੀ ਸਿਰਫ਼ ਲੋੜੀਂਦਾ ਹੈ, ਜੇਕਰ ਤੁਸੀਂ ਪੁਸ਼ ਸੂਚਨਾਵਾਂ ਦੇ ਤੌਰ 'ਤੇ ELD ਡਿਵਾਈਸ 'ਤੇ ਡਿਸਪੈਚ ਭੇਜਣਾ ਚਾਹੁੰਦੇ ਹੋ।",
  save:"ਸੇਵ ਕਰੋ",
  saving:"ਰੱਖਿਅਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  cancel:"ਰੱਦ ਕਰੋ",
  close:"ਬੰਦ ਕਰੋ",
  roles:"ਭੂਮਿਕਾਵਾਂ",
  access:"ਪਹੁੰਚ",
  cargo:"ਕਾਰਗੋ",
  hos:"HOS",
  dutyLogs:"ਡਿਊਟੀ ਲੌਗਸ",
  contact:"ਸੰਪਰਕ ਕਰੋ",
  required:"ਲੋੜੀਂਦਾ ਹੈ",
  billing: "ਬਿਲਿੰਗ",


  accountName:"ਅਕਾਉਂਟ ਦਾ ਨਾਂ",
  contactEmail:"ਸੰਪਰਕ ਈਮੇਲ",
  contactPhone:"ਸੰਪਰਕ ਫ਼ੋਨ",
  contactRole:"ਸੰਪਰਕ ਭੂਮਿਕਾ",
  stripe:"ਧਾਰੀ",
  lastActive:"ਆਖਰੀ ਕਿਰਿਆਸ਼ੀਲ",


 driverId:"ਡਰਾਈਵਰ ਆਈ.ਡੀ",
 firstName:"ਪਹਿਲਾ ਨਾਂ",
 lastName:"ਆਖਰੀ ਨਾਂਮ",
 mobile:"ਮੋਬਾਈਲ",
 password:"ਪਾਸਵਰਡ",
 licCountry:"ਲਾਇਸੰਸ ਦੇਸ਼",
 licState:"ਲਾਇਸੰਸ ਰਾਜ",
 licNumber:"ਲਾਇਸੰਸ ਨੰਬਰ",
 cycleUsa:"ਚੱਕਰ ਅਮਰੀਕਾ",
 cycleCA:"ਚੱਕਰ ਕੈਲੀਫੋਰਨੀਆ",
 startTime:"ਸ਼ੁਰੂਆਤੀ ਸਮਾਂ",
 receiverMail:"ਪ੍ਰਾਪਤਕਰਤਾ ਈਮੇਲ",
 personal:"ਨਿੱਜੀ",
 yardMoves:"ਵਿਹੜੇ ਦੀ ਚਾਲ",
 allowEdit:"ਸੰਪਾਦਨ ਦੀ ਇਜਾਜ਼ਤ ਦਿਓ",
 tracking:"ਟਰੈਕਿੰਗ",
 exempt:"ਛੋਟ",
 exemptDriver:"ਛੋਟ ਡਰਾਈਵਰ",
 exemptAnnotation:"ਛੋਟ ਐਨੋਟੇਸ਼ਨ",
 assignedVehicle:"ਨਿਰਧਾਰਤ ਵਾਹਨ",
 currentVehicle:"ਮੌਜੂਦਾ ਵਾਹਨ",
 currentTrailer:"ਮੌਜੂਦਾ ਟ੍ਰੇਲਰ",
 enterMobileNumber:"ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ",
 appHome_View:"ਐਪ ਹੋਮ ਵਿਊ",
 firebase:"ਫਾਇਰਬੇਸ",
 apiKey:"ਆਪਿ ਕੁੰਜੀ",
 scopes:"ਦਾਇਰੇ",
 message:"ਸੁਨੇਹਾ",
 sendNow:"ਹੁਣੇ ਭੇਜੋ",
 loading:"ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
 sendVia_whatsapp:"ਵਟਸਐਪ ਰਾਹੀਂ ਭੇਜੋ",
 send_sms:"SMS ਭੇਜੋ",
 speed:"ਗਤੀ",



 prefix:"ਅਗੇਤਰ",
 state_mileage_report:"ਸਟੇਟ ਮਾਈਲੇਜ ਰਿਪੋਰਟ",
 pdf:"PDF",
 details:"ਵੇਰਵੇ",
 carrierName:"ਕੈਰੀਅਰ ਦਾ ਨਾਮe",
 vehicleName:"ਵਾਹਨ ਦਾ ਨਾਮ",
 miles:"ਮੀਲ",
 mileage_miles:"ਮਾਈਲੇਜ (ਮੀਲ)",
 quarter:"ਤਿਮਾਹੀ",
 report:"ਰਿਪੋਰਟ",
 select_vehicle:"ਵਾਹਨ ਦੀ ਚੋਣ ਕਰੋ",
 odometer:"ਓਡੋਮੀਟਰ",
 superAdmin:"ਸੁਪਰ ਐਡਮਿਨ",
 supportAdmin:"ਐਡਮਿਨ ਦਾ ਸਮਰਥਨ ਕਰੋ",


  No_vehicle_is_assignedTO_deiver:"ਕੋਈ ਵੀ ਵਾਹਨ ਡਰਾਈਵਰ ਨੂੰ ਨਹੀਂ ਸੌਂਪਿਆ ਗਿਆ",
  please_set_current_vehicle:"ਕਿਰਪਾ ਕਰਕੇ ਮੌਜੂਦਾ ਵਾਹਨ ਸੈੱਟ ਕਰੋ",



 
  endTime:"ਸਮਾਪਤੀ ਸਮਾਂ",
  duration:"ਮਿਆਦ",
  recordStatus:"ਰਿਕਾਰਡ ਸਥਿਤੀ",
  recordOrigin:"ਰਿਕਾਰਡ ਮੂਲ",
  location:"ਟਿਕਾਣਾ",
  assign:"ਅਸਾਈਨ ਕਰੋ",
  driver:"ਡਰਾਈਵਰ",


  diagostic$Malfunction:"ਡਾਇਗਨੌਸਟਿਕ/ਗਲਤੀ",
  eventCode:"ਇਵੈਂਟ ਕੋਡ",
  eventTime:"ਘਟਨਾ ਦਾ ਸਮਾਂ",





  status:"ਸਥਿਤੀ",
  terminal:"ਅਖੀਰੀ ਸਟੇਸ਼ਨ",
  address:"ਪਤਾ",
  title:"ਸਿਰਲੇਖ",
  body:"ਸਰੀਰ",
  time:"ਸਮਾਂ",
  user:"ਉਪਭੋਗਤਾ",
  allUser:"ਸਾਰੇ ਉਪਭੋਗਤਾ",
  sendNotification:"ਸੂਚਨਾ ਭੇਜੋ",
  send:"ਭੇਜੋ",
  syncAll:"ਸਭ ਨੂੰ ਸਿੰਕ ਕਰੋ",
  inspector:"ਇੰਸਪੈਕਟਰ",
  condition:"ਹਾਲਤ",
  start_end:"ਸ਼ੁਰੂ/ਅੰਤ",
  view:"ਦੇਖੋ",
  date:"ਤਾਰੀਖ਼",
  _note:"ਨੋਟ ਕਰੋ",
  _details:"ਵੇਰਵੇ",
  _view:"ਦੇਖੋ",
  settings:"ਸੈਟਿੰਗਾਂ",
  select:"ਚੁਣੋ",
  coordinates:"ਕੋਆਰਡੀਨੇਟਸ",
  event:"ਘਟਨਾ",
  table:"ਸਾਰਣੀ",
  map:"ਨਕਸ਼ਾ",
  trips:"ਯਾਤਰਾਵਾਂ",
  delete:"ਮਿਟਾਓ",
  edit:"ਸੰਪਾਦਿਤ ਕਰੋ",
  



  driver_vehicle_inspection_report:" ਡਰਾਈਵਰ ਦੀ ਵਾਹਨ ਜਾਂਚ ਰਿਪੋਰਟ (DVIR)",
  AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS:"D.O.T ਦੁਆਰਾ ਲੋੜ ਅਨੁਸਾਰ ਫੈਡਰਲ ਮੋਟਰ ਕੈਰੀਅਰ ਸੁਰੱਖਿਆ ਨਿਯਮ",
  dvirNumber:"DVIR ਨੰਬਰ:-",
 start_inspection:"ਜਾਂਚ ਸ਼ੁਰੂ ਕਰੋ",
 endInspection:"ਮੁਆਇਨਾ ਸਮਾਪਤ ਕਰੋ",
 inspection_type:"ਨਿਰੀਖਣ ਦੀ ਕਿਸਮ",
 inspection_Area:"ਨਿਰੀਖਣ ਖੇਤਰ",
 I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements:"ਮੈਂ ਘੋਸ਼ਣਾ ਕਰਦਾ/ਕਰਦੀ ਹਾਂ ਕਿ ਇਸ ਸੰਪਤੀ ਦੀ ਜਾਂਚ ਲਾਗੂ ਲੋੜਾਂ ਦੇ ਅਨੁਸਾਰ ਕੀਤੀ ਗਈ ਹੈ।",
 driver_signature:"ਡਰਾਈਵਰ ਦੇ ਦਸਤਖਤ",



  download:"ਡਾਊਨਲੋਡ ਕਰੋ",
  downloading:"ਡਾਊਨਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  not_able_to_download:"ਡਾਊਨਲੋਡ ਕਰਨ ਦੇ ਯੋਗ ਨਹੀਂ",
  missingFile:"ਗੁੰਮ ਫਾਈਲ",



  history:"ਇਤਿਹਾਸ",
  send_to_fmcsa:"FMCSA ਨੂੰ ਭੇਜੋ",
  email_to_fmcsa:"FMCSA ਨੂੰ ਈਮੇਲ ਕਰੋ",
  SelectDriver:"ਡਰਾਈਵਰ ਚੁਣੋ",
  fromDate:"ਮਿਤੀ ਤੋਂ",
  toDate:"ਮਿਤੀ ਤੱਕ",
  comments:"ਟਿੱਪਣੀਆਂ",
  fmcsa_data_transfer:"FMCSA ਡੇਟਾ ਟ੍ਰਾਂਸਫਰ (ਡੇਟਾ ਟ੍ਰਾਂਸਫਰ ਕਰਨ ਲਈ ਕਦਮ)",
  selectDateRange:"ਮਿਤੀ ਸੀਮਾ ਚੁਣੋ",
  enterComment:"ਟਿੱਪਣੀ ਦਰਜ ਕਰੋ",
  sendDataTo_fmcsa:"FMCSA ਨੂੰ ਡਾਟਾ ਟ੍ਰਾਂਸਫਰ ਕਰਨ ਲਈ \"FMCSA ਨੂੰ ਭੇਜੋ\" 'ਤੇ ਕਲਿੱਕ ਕਰੋ",




   showall:"ਸਾਰੇ ਦਿਖਾਓ",
   vehicle:"ਵਾਹਨ",
   trailer:"ਟ੍ਰੇਲਰ",
   search:"ਖੋਜ...",
   add:"ਸ਼ਾਮਲ ਕਰੋ",
   liveshare:"ਲਾਈਵ ਸ਼ੇਅਰ",
   create_ifta:"IFTA ਬਣਾਓ",
   no_data_found:" ਕੋਈ ਡਾਟਾ ਨਹੀਂ ਮਿਲਿਆ!",
   no_recordFound:"ਕੋਈ ਰਿਕਾਰਡ ਨਹੀਂ ਮਿਲਿਆ",


   regulationMode:"ਰੈਗੂਲੇਸ਼ਨ ਮੋਡ",
   plate:"ਪਲੇਟ",
   eld:"ELD",
   created_edited:"ਬਣਾਇਆ/ਸੋਧਿਆ",
   created:"ਬਣਾਇਆ",
   edited:"ਸੰਪਾਦਿਤ ਕੀਤਾ",


   rowsperpage: "ਪ੍ਰਤੀ ਪੰਨਾ ਕਤਾਰਾਂ",


//   <------ADD equipment Form------->

   vin:"Vin",
   make:"ਬਣਾਉ",
   model:"ਮਾਡਲ",
   year:"ਸਾਲ",
   fuel:"ਬਾਲਣ",
   type:"ਕਿਸਮ",
   licPlate:"ਲਾਇਸੰਸ ਪਲੇਟ",
   dvirForm:"DVIR ਫਾਰਮ",
   gps_source:"GPS ਸਰੋਤ",
   devicetype:"ਜੰਤਰ ਦੀ ਕਿਸਮ",
   devicename:"ਡਿਵਾਈਸ ਦਾ ਨਾਮ",
   macAddrerss:"MAC ਪਤਾ",
   eld_serial:"ELD ਸੀਰੀਅਲ",
   eld_firmware:"ELD ਫਰਮਵੇਅਰ",
   eld_BleVersion:"ELD BleVersion",
   connectionType:"ਕੁਨੈਕਸ਼ਨ ਦੀ ਕਿਸਮ",
   dashcam:"ਡੈਸ਼ਕੈਮ",
   odometer_offset:"ਓਡੋਮੀਟਰ ਆਫਸੈੱਟ",
   active:"ਕਿਰਿਆਸ਼ੀਲ",
   codes:"ਕੋਡ",
   ifta:"Ifta",
   vbusConnection:"ਗੱਡੀ ਚਲਾਉਣ ਲਈ VBUS ਕਨੈਕਸ਼ਨ ਦੀ ਲੋੜ ਹੈ",
   carrier:"ਕੈਰੀਅਰ",
   dvir :"Dvir",
   licExpiry:"ਲਾਇਸੰਸ ਦੀ ਮਿਆਦ",
   adminScope:"ਐਡਮਿਨ ਸਕੋਪਸ",
   assetType:"ਸੰਪਤੀ ਦੀ ਕਿਸਮ",
   dueby:"ਦੁਆਰਾ ਬਕਾਇਆ",
   notes:"ਨੋਟਸ",
   


   dashcameraId:"ਡੈਸ਼ਕੈਮਰਾ ਆਈ.ਡੀ",
   reseller:"ਵਿਕਰੇਤਾ",
   updateFrequency:"ਅੱਪਡੇਟ ਬਾਰੰਬਾਰਤਾ",
   bucket:"ਬਾਲਟੀ",
   theme:"ਥੀਮ",
   kioskpin:"Kioskpin",
   hereAppId:"ਇੱਥੇ ਐਪ ਆਈ.ਡੀ",
   hereApiKey:"ਇੱਥੇ API ਕੁੰਜੀ",
   updateLocation:"ਟਿਕਾਣਾ ਅੱਪਡੇਟ ਕਰੋ",
   debug:"ਡੀਬੱਗ ਕਰੋ",
   allLogs:"ਸਾਰੇ ਲੌਗਸ",
   editLogs:"ਐਡਿਟਲੌਗਸ",
   documents:"ਦਸਤਾਵੇਜ਼",
   notification:"ਸੂਚਨਾਵਾਂ",
   addDvir:"Dvir ਸ਼ਾਮਲ ਕਰੋ",
   appSettings:"ਐਪ ਸੈਟਿੰਗਾਂ",
   appAll_Log:"ਐਪ ਸਾਰੇ ਲੌਗਸ",
   appAllLogsFmcsa:"ਐਪ ਸਾਰੇ ਲੌਗਸ Fmcsa",
   estimatedWaitTime:"ਅਨੁਮਾਨਿਤ ਉਡੀਕ ਸਮਾਂ",
   minutes:"ਮਿੰਟ",
   maintenance:"ਰੱਖ-ਰਖਾਅ",
   updateDvir:"Dvir ਨੂੰ ਅਪਡੇਟ ਕਰੋ",
   vehicletype:"ਵਾਹਨ ਦੀ ਕਿਸਮ",
   form:"ਫਾਰਮ",
   _vehicle:"ਵਾਹਨ",
   StartDate:"ਤਾਰੀਖ ਸ਼ੁਰੂ",
   EndDate:"ਸਮਾਪਤੀ ਮਿਤੀ",
   _trailer:"ਟ੍ਰੇਲਰ",
   equipmentconditions:"ਉਪਕਰਣ ਦੀਆਂ ਸਥਿਤੀਆਂ",
   date_is_required:"ਮਿਤੀ ਲੋੜੀਂਦੀ ਹੈ",






  noteinfo:"ਨੋਟ: ਇੱਕ ਵਾਰ ਜਦੋਂ ਤੁਸੀਂ ਨਵਾਂ ਵਾਹਨ ਜੋੜਦੇ ਹੋ, ਤਾਂ ਫਾਈਲ 'ਤੇ ਤੁਹਾਡੇ ਕਾਰਡ ਨੂੰ ਸਵੈਚਲਿਤ ਤੌਰ 'ਤੇ ਚਾਰਜ ਕੀਤਾ ਜਾਵੇਗਾ।",
 ifta_info_note: "IFTA ਸਿਰਫ਼ ਸਰਗਰਮ ਟਰੱਕਾਂ ਲਈ ਉਪਲਬਧ ਹੈ।",
 ifta_info:"ਜੇਕਰ ਤੁਸੀਂ ਟਰੱਕ ਨੂੰ ਅਯੋਗ ਜਾਂ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਅਯੋਗ ਕਾਰਵਾਈ ਤੋਂ ਪਹਿਲਾਂ ਸਟੇਟ ਮਾਈਲੇਜ ਡਾਊਨਲੋਡ ਕਰੋ।",
 saved_Successfully:"ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ",
 updateSuccessfully:"ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ",
 sendSuccessfully:"SMS ਸਫਲਤਾਪੂਰਵਕ ਭੇਜਿਆ ਗਿਆ",
 successMessage_carrier:"ਕੈਰੀਅਰ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ ਹੈ",
 successMessage: "ਟ੍ਰੇਲਰ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ ਹੈ",
 successMessage_Equipment: "ਉਪਕਰਣ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ ਹੈ",
 please_try_aftersometime:"ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
 successMessage_user:"ਉਪਭੋਗਤਾ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਜੋੜਿਆ ਗਿਆ ਹੈ",
 successMessage_Api_Key:"Api ਕੁੰਜੀ ਸਫਲਤਾਪੂਰਵਕ ਸ਼ਾਮਲ ਕੀਤੀ ਗਈ ਹੈ",
 response:"ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਸਮੇਂ ਦੇ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
 errorMessage:"ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਸਮੇਂ ਦੇ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
 editReasonUpdated:"ਸੰਪਾਦਨ ਕਾਰਨ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤੇ ਗਏ ਹਨ।",
 logs_are_successfully_submitted:"ਲੌਗਸ ਸਫਲਤਾਪੂਰਵਕ ਸਪੁਰਦ ਕੀਤੇ ਗਏ ਹਨ।",
 user_is_updated_successfully:"ਉਪਭੋਗਤਾ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ ਹੈ।",
 driver_is_cleaned_successfully:"ਡਰਾਈਵਰ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸਾਫ਼ ਕੀਤਾ ਗਿਆ ਹੈ।",
 account_is_updated_successfully:"ਖਾਤਾ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ ਹੈ",
 Note_only_requiredforSetNewPassword:"ਨੋਟ: ਸਿਰਫ਼ ਲੋੜੀਂਦਾ ਹੈ, ਜੇਕਰ ਤੁਸੀਂ ਨਵਾਂ ਪਾਸਵਰਡ ਸੈੱਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
 driver_is_required:"ਡਰਾਈਵਰ ਦੀ ਲੋੜ ਹੈ।",
 from_date_is_required:"ਮਿਤੀ ਤੋਂ ਲੋੜੀਂਦੀ ਹੈ।",
 to_date_is_required:"ਅੱਜ ਤੱਕ ਦੀ ਲੋੜ ਹੈ। ",
 comment_is_required:"ਟਿੱਪਣੀ ਦੀ ਲੋੜ ਹੈ।",
 comment_is_invalid:"ਟਿੱਪਣੀ ਅਵੈਧ ਹੈ।",

 are_you_sure_WantTo_delete:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ dvir ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",
 errorMassage:"ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
 deletedsucessfully:"ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾਇਆ ਗਿਆ।",
 deleting:"ਮਿਟਾਇਆ ਜਾ ਰਿਹਾ ਹੈ...",
 deleteViolation:"ਉਲੰਘਣਾਵਾਂ ਨੂੰ ਮਿਟਾਓ",
 are_you_sure_wantTo_delete_all_violation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਸਾਰੀਆਂ ਉਲੰਘਣਾਵਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",






}
