export const LABELS = {
  refresh: "Обновить",
  recap: "Резюме",
  date: "Дата",
  newTab: "Новая вкладка",
  download: "Скачать",
  Audit: "Аудит",
  AuditEvent:"События аудита",
  openInNewTab:"открыть в новой вкладке",
  ReassignMultipleStatus: "Переназначить несколько статусов",
  AddMultipleStatus: "Добавить несколько статусов",
  EditMultipleStatus: "Редактировать множественный статус",
  EditMultipleStatusAdmin: "Редактировать множественный статус (Администратор)",
  AddStatus: "Добавить статус",
  SendMessage: "Отправить сообщение",
  SendNotification: "Отправить уведомление",
  AddShippingOrTrailer: "Добавить доставку или трейлер",
  AddStatusAdmin: "Добавить статус (Администратор)",
  ReassignMultipleStatusAdmin: "Переназначение нескольких статусов (Администратор)",

  
  driver: "Водитель",
  vehicle: "Транспортное средство",
  trailer: "Трейлер",
  email: "Электронная почта",
  startTime: "Время начала",
  carrier: "Перевозчик",
  terminal: "Домашний терминал",
  shipping: "Перевозки",
  app: "Приложение",
  cycle: "Цикл",
  coDriver: "Со-водитель",
  distance: "Расстояние",
  engineHr: "ДвигательЧас",
  device: "Устройство",
  allLogs: "Все журналы",
  state:"Состояние",
  action:"Действие",
  odometerMi:"Одометр (Ми)",



  
  editAdmin: "Редактировать администратора",
  DeactivateAdmin: "Деактивировать администратора",
  DuplicateAdmin: "Дубликат администратора",

  time: "Время",
  status: "Положение дел",
  Location: "Расположение",
  Odometer: "Одометр",
  EngineHours: "Часы работы двигателя",
  Origin: "Источник",
  Annotation: "Аннотация",
  days: "Дни",
  hours: "Часы",
  minutes: "Минуты",
  seconds: "Секунды",
  odometer: "Одометр",
  subtract: "Вычесть",
  add: "Добавлять",
  edit: "Редактировать",
  deactivate: "Деактивировать",
  reason:"Причина",
  details:"Подробности",
  notes:"Примечания",
  selectDriver:"Выберите драйвер",
  required:"Необходимый",
  note:"Примечание",
  find:"НАХОДИТЬ",
  log:"Бревно",
  admin:"Админ",
  adminEvents:"Административные события",
  dvir:"Dvir",


  DATE:"ДАТА",
  START_TIME:"ВРЕМЯ НАЧАЛА",
  CYCLE:"ЦИКЛ",
  DRIVER:"ВОДИТЕЛЬ",
  CARRIER:"ПЕРЕВОЗЧИК",
  CO_DRIVERS:"ВОДИТЕЛИ СО",
  VEHICLES:"ТРАНСПОРТНЫЕ СРЕДСТВА",
  HOMETERMINAL:"ГЛАВНЫЙ ТЕРМИНАЛ",
  DISTANCE:"РАССТОЯНИЕ",
  TRAILERS:"ПРИЦЕПЫ",
  SHIPPING_DOCS:"ДОСТАВОЧНЫЕ ДОКУМЕНТЫ",
  ENGINE_HOURS:"НАРАТЫ ДВИГАТЕЛЯ",
  EMAIL:"ЭЛЕКТРОННАЯ ПОЧТА",
  


  

  eventTime:"Время события",
  CoordCode:"Код координат",
  latitude:"Широта",
  longitude:"Долгота",
  reasonForEdit:"Причина редактирования",
  recordOrigin:"Запись происхождения",
  recordStatus:"Статус записи",
  eventType:"Тип события",
  subType:"Подтип",
  sequenceId:"Идентификатор последовательности",
  shippingDoc:"Отгрузочный документ",
  accumulatedHours:"Накопленные часы",
  accumulatedMiles:"Накопленные мили",




  manageDrivers:"Управление драйверами",
  save:"СОХРАНЯТЬ",
  allDrivers:"Все драйверы",
  saving:"Сохранение...",
  close:"ЗАКРЫВАТЬ",
  cancel:"ОТМЕНА",
  assignDriver:"Назначить водителя",

  editMultipleStatus:"ИЗМЕНИТЬ НЕСКОЛЬКО СТАТУСОВ",
  addMultipleLogs:"ДОБАВИТЬ НЕСКОЛЬКО ЖУРНАЛОВ",

  drActionReqMsg: "Требуется действие водителя",
  drConfirmReqMsg:
    "Пожалуйста, подтвердите водителю, чтобы принять или отклонить запрос.",
  automaticDrMsg: "Автоматический привод не подлежит модификации.",
  successMessage: "Успешно сохранено",
  errorMessage: "Что-то пошло не так, попробуйте через некоторое время.",
  automaticDriveTimeError:
    "Проверьте время мероприятия. Вы не можете добавить статус после автоматической поездки.",

    editsuccessMessage:"Запрос на редактирование успешно отправлен",

    No_logingFound_for:"Не найден логин для",
    For_selected_date:"на выбранную дату.",
    noDutyStatusFound:"Дежурный статус не найден.",
    errorMasage_selectCo_driverTOReassignLogs:"Выберите штурмана для переназначения журналов",

   updateTrailerMassage_shippingDocs_application:"Обновленный прицеп или отгрузочный документ будет применим только к будущему статусу, а не к какому-либо прошлому статусу."

};
