import axios from "./axios";
import _get from "lodash/get";

export function getSignImageUrl(params) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        try {
            const response = await axios({
                url: `${BASE_URL}/${accountId}/drivers/${params}/sign`,
                method: "get",
                // params
            })
            const _response = _get(response, "data");
            return _response;

        }
        catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }

    };
}


export function postSignImage(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        try {
            const response = await axios({
                url: `${BASE_URL}/${accountId}/drivers/${params.driverId}/sign`,
                method: "put",
                data: params
            });
            const _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}