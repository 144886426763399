const defaultState = {
    apiKeyBusy: false,
    count: 0,
    apiKey: [],
    // selectedEquipment: {}
};

export default function user(state = defaultState, action) {
    switch (action.type) {
        case "API_KEY_COUNT":
            return {
                ...state,
                count: action.value
            };

        case "API_KEY":
            return {
                ...state,
                apiKey: action.value
            };
        case "SELECTED_KEY":
            return {
                ...state,
                selectedKey: action.value
            };
        case "APP_KEY_BUSY":
            return {
                ...state,
                apiKeyBusy: action.value
            };
        default:
            return state;
    }
}
