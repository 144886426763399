import React from "react";
// import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDate, formatDateTime } from "../../actions/momentActions";
import TodoTable from "./TodoTable";
import SearchBar from "./SearchBar";
import { getTodosCount, getTodos } from "../../actions/todoActions";
import AddTodoForm from "./AddTodoForm";

const styles = (theme) => ({
  addWrapper: {
    padding: 10,
  },
  searchText: {
    display: "inline-block",
    marginTop: 10,
    marginLeft: 5,
  },
});

export class Reseller extends React.Component {
  state = {
    isAdd: false,
    carrier: null,
    limit: 25,
    page: 0,
    accountCount: 0,
    todos: [],
    selected: {},
  };

  async componentDidMount() {
    await this.onReload();
  }

  onReload = async () => {
    await this.getTodosCount();
    await this.getTodos();
  };

  getTodosCount = async () => {
    const response = await this.props.getTodosCount({ accountId: "ALL" });
    if (response && response.count) {
      this.setState({ todoCount: response.count });
    }
  };

  getTodos = async (params = {}) => {
    const { limit, page } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    let paylaod = {
      limit,
      skip,
      accountId: "ALL",
    };

    if (params.status) {
      paylaod.status = params.status;
    }
    if (params.priority) {
      paylaod.priority = params.priority;
    }
    if (params.assignedId) {
      paylaod.assignedId = params.assignedId;
    }
    const todos = await this.props.getTodos(paylaod);
    this.setState({ todos, loading: false });
  };

  handleOpen = () => {
    this.setState({ isAdd: true });
  };

  handleClose = () => {
    this.onReload();
    this.setState({ isAdd: false, selected: null });
  };

  handleEdit = (selected) => {
    this.setState({ isAdd: true, selected });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getTodos);
  };

  timeOut = null;

  render() {
    const classes = this.props.classes;
    const { loading, isAdd, selected } = this.state;
    const todos = this.state.todos || [];

    return (
      <div>
        {isAdd && (
          <AddTodoForm
            isAdd={isAdd}
            handleClose={this.handleClose}
            selected={selected}
          />
        )}
        <Grid container className={classes.addWrapper}>
          <Grid item xs={12} sm={12} md={12}>
            <SearchBar
              handleAddClick={this.handleOpen}
              onSearch={this.getTodos}
            />
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TodoTable
              items={todos}
              count={this.state.todoCount}
              handleEdit={this.handleEdit}
              formatDate={this.props.formatDate}
              formatDateTime={this.props.formatDateTime}
              handleChange={this.handleChange}
              limit={this.state.limit}
              page={this.state.page}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatDateTime,
      getTodos,
      getTodosCount,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Reseller)
);
