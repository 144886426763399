import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Formik, Form } from "formik";
import { createTodo, updateTodo } from "../../actions/todoActions";
import { getAllUsers } from "../../actions/adminDashActions";
import { validate, defaultValues } from "./validate";
import Grid from '@mui/material/Grid';
import InputBox from "../../components/InputBox";
import InputDateBox from "../../components/InputDateBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";

import { formatDateTimeUtc, formatDateTimeToUtc } from "../../actions/momentActions";
import { LABELS as _LABELS} from "../../language";

const LABEL=_LABELS.supportForm;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddResellerForm extends React.Component {
  state = {
    users: [],
  };

  componentDidMount() {
    this.getAllUsers();
  }

  getAllUsers = async (accountId) => {
    const users = await this.props.getAllUsers({
      searchSupport: "ON",
      skip: 0,
      limit: 500,
    });
    this.setState({ users: users || [] });
  };

  handleSubmit = async (values) => {
    values.accountId = "ALL";
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response;
    values.dueDate = this.props.formatDateTimeToUtc(values.dueDate);
    if (values._id) {
      response = await this.props.updateTodo(values);
    } else {
      response = await this.props.createTodo(values);
    }

    if (response && response._id) {
      this.setState({
        successMessage: "Saved successfully",
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after some time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    // const accounts = this.state.accounts || [];
    const users = this.state.users || [];
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;

    if (selected && selected.dueDate) {
      selected.dueDate = this.props.formatDateTimeUtc(selected.dueDate);
    }

    let initialValues = {
      ...defaultValues,
      ...selected,
    };



    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={this.props.isAdd}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{LABEL.addtodo}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <SelectBox
                      md={3}
                      name="status"
                      label={LABEL.status}
                      items={constants.TODO_STATUS}
                    />
                    <SelectBox
                      md={3}
                      name="priority"
                      label={LABEL.priority}
                      items={constants.TODO_PRORIRY}
                    />
                    <InputDateBox type="datetime-local" md={3} name="dueDate" label="Duedate" />
                    <SelectBox
                      md={3}
                      selValue={"id"}
                      selLabel={"firstName"}
                      selLabelTwo={"lastName"}
                      selLabelThree={"status"}
                      name="assignedId"
                      label={LABEL.assignedto}
                      items={users}
                      onChange={(e) => {
                        const _id = e.target.value;
                        const sel = users.find((_e) => _e.id === _id);

                        setFieldValue("assignedTo.id", _id);
                        setFieldValue(
                          "assignedTo.name",
                          `${sel.firstName} ${sel.lastName}`
                        );
                        setFieldValue("assignedTo.email", sel.email);
                      }}
                    />
                    <InputBox md={12} name="description" label={LABEL.description}/>
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {`${loading ? LABEL.saving :LABEL.save}`}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABEL.closed}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, "authData.profile.user", {}),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllUsers,
      createTodo,
      updateTodo,
      formatDateTimeUtc,
      formatDateTimeToUtc
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddResellerForm))
);
