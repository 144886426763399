// import { settings } from "firebase/analytics";

export const LABELS = {
  accounts: "Счета",
  drivers: "Драйверы",
  equipments: "Оборудование",

  allCompany: "Вся компания",
  company: "Компания",
  dutyStatus: "Статус обязанности",
  connection: "Связь",
  connected: "Связанный",
  disconnected: "Отключено",
  offDuty: "Вне службы",
  onDuty: "На службе",
  driving: "Вождение",
  sleeping: "Спать",
  driverInfo: "Информация о водителе",
  name: "Имя",
  status: "Положение дел",
  violations: "Нарушения",
  date: "Дата",
  eLDConnection: "ЭЛД-соединение",
  total: "Общий",
  formsSignatures: "Формы и подписи",
  time_period:"Временной период",
  today:"Сегодня",
  yesterday:"Вчера",
  thisWeek:"На этой неделе",
  thisMonth:"Этот месяц",
  sleeper:"Спальное место",
  remark:"Примечание",
  break:"Перерыв",
  OnDutyND:"OnDutyND",
  callback:"Перезвонить",







  count: "Считать",
  search: "Поиск",
  add: "Добавлять",
  contact: "Контакт",
  payment: "Оплата",
  created: "Созданный",
  actions: "Действия",
  edit: "Редактировать",
  edited:"Отредактировано",
  details: "Подробности",
  leads: "лиды",
  added:"Добавлен",
  saving:"Сохранение...",
  settings:"Настройки",

  account: "Счет",
  driver: "Водитель",
  clear: "Прозрачный",
  new: "Новый",
  info: "Информация",
  caller: "Звонящий",
  detail: "Деталь",
  other: "Другой",
  clean:"ЧИСТЫЙ",
  delete:"Удалить",
  support:"Поддерживать",
  assigned:"Назначенный",

  provider: "Поставщик",
  carrier: "Перевозчик",
  shipdate: "Дата отправки",
  tracking: "Отслеживание",
  addedby: "Добавлено",
  role:"Роль",
  terminal:"Терминал",
  dispatch:"Отправлять",
  address:"Адрес",
  link:"Связь",
  expiry:"Срок действия",
  start_expiry_date:"Дата начала истечения срока действия",
  end_expiry_date:"Конечная дата истечения срока действия",

  commingSoon: "Вскоре",

  startDate: "Дата начала",
  endDate: "Дата окончания",
  logs: "Журналы",
  viewLogs: "Просмотр журналов",
  rowsperpage: "Строков на странице",
  logIn: "Авторизоваться",

  email: "Электронная почта",
  error: "Ошибка",
  time: "Время", 
  phone:"Телефон",
  reason:"Причина",
  App:"Приложение",
  Device:"Устройство",


  searchbyemailorname: "Поиск по электронной почте или имени",
  supportUsers: "Поддержка пользователей",
  norecordsarefound: "Записи не найдены",
  selectVehicle:"Выберите автомобиль",

  syncRealTime: "СИНХРОНИЗАЦИЯ В РЕАЛЬНОМ ВРЕМЕНИ",
  syncingRealTime:"СИНХРОНИЗАЦИЯ В РЕАЛЬНОМ ВРЕМЕНИ",

  reseller: "Реселлер",
  billing: "Биллинг",
  overdues: "Задолженность",
  missingstripe: "Отсутствует полоса",
  notes: "Примечания",

  annual: "Ежегодно",
  monthly: "Ежемесячно",
  enabled: "Включено",
  disabled: "Неполноценный",
  sync: "Синхронизировать",
  syncing:"Синхронизации...",
  activate: "Активировать",
  cancel: "Отмена",
  confirm: "Подтверждать",
  pleaseConfirm: "Пожалуйста, подтвердите, если вы хотите",

  deactivate: "Деактивировать",
  activeManagers: "Активные менеджеры",
  inactiveManagers: "Неактивные менеджеры",
  activeDrivers: "Активные драйверы",
  inactiveDrivers: "Неактивные драйверы",
  allowedVehicles: "Разрешенные транспортные средства",
  activeVehicles: "Активные транспортные средства",
  inactiveVehicles: "Неактивные транспортные средства",
  plan: "План",
  planInfo: "Информация о плане",
  apiAccess: "Доступ к API:",
  dues: "Взносы",
  noduesfound: "Взносов не найдено",
  iftaAccess: "Ифта Доступ",
  addUser: "Менеджеры",



 standard:"Стандартный",
 premium:"Премиум",
 enterprise:"Предприятие",


  editplan:"РЕДАКТИРОВАТЬ ПЛАН",
  allowedVehicle:"Разрешенный автомобиль",
  currentVehicle:"Текущий автомобиль",
  Name:"Имя",
  ifta:"IFTA",
  api:"API",
  truck:"Грузовик",
  note:'Примечание',
  addDue:"ДОБАВИТЬ СРОК",
  save:"СОХРАНЯТЬ",
  close:"ЗАКРЫВАТЬ",
  addUsers:"ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ",
  editUser:'ИЗМЕНИТЬ ПОЛЬЗОВАТЕЛЯ',
  edit_User:"Изменить пользователя",
  managers:"Managers",

  callqueues:"Очереди звонков",
  callInQueue:"звонки в очереди.",
  loading:"Загрузка...",
  refresh:"Обновить",
  view:"Вид",
  signOut:"Выход",
  AllAccounts:"Все аккаунты",
  notification:"Уведомления",
  CustomerSupportPackage:"Пакет поддержки клиентов",
  queues:"Очереди",
  all:"Все",

  invoices:"Счета-фактуры",

  allEquipments:"Все оборудование",
  allStates:"Все штаты",

  chat:"Чат",
  unread:"Непрочитано",
  nounreadchats:"Нет непрочитанных чатов",
  view_all_chats:"Просмотреть все чаты",


  shippingSaveSuccessfully:"Доставка успешно сохранена",
  planIs_updated_successfully:"План успешно обновлен",
  errorMasage_tryafterSometime:"Что-то пошло не так, попробуйте через некоторое время.",


};
