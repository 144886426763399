const defaultState = {
    notificationBusy: false,
    notifications: [],
    count: 0,
};

export default function carrier(state = defaultState, action) {
    switch (action.type) {
        case "NOTIFICATIONS":
            return {
                ...state,
                notifications: action.value
            };
        case "NOTIFICATIONS_COUNT":
            return {
                ...state,
                count: action.value
            };
        case "NOTIFICATIONS_BUSY":
            return {
                ...state,
                notificationBusy: action.value
            };
        default:
            return state;
    }
}
