const validate = values => {
    const errors = {};

    if (!values.name) {
        errors.name = "Required";
    } else if (!values.scopes) {
        errors.scopes = "Required";
    }
    if (!values.expiry) {
        errors.expiry = "Required";
    }

    return errors;

};

const validateSms = values => {
    const errors = {};

    if (!values.to) {
        errors.to = "Required";
    } else if (values.to && values.to.toString().length !== 10) {
        errors.to = "Invalid phone number";
    }
    if (!values.message) {
        errors.message = "Required";
    }

    return errors;
};

export { validateSms };
export default validate;
