// import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { withStyles } from '@mui/styles';
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MapIcon from "@mui/icons-material/Map";
import AppsIcon from "@mui/icons-material/Apps";
import PieChartIcon from "@mui/icons-material/PieChart";
import WarningIcon from "@mui/icons-material/Warning";
import MessageIcon from "@mui/icons-material/Message";
import Attachment from "@mui/icons-material/Attachment";
import Publish from "@mui/icons-material/Publish";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import SettingsIcon from "@mui/icons-material/Settings";
import NavigatorLinks from "./NavigatorLinks";
import HistoryIcon from "@mui/icons-material/History";
import ListAltIcon from "@mui/icons-material/ListAlt";
import EditIcon from "@mui/icons-material/Edit";
import UnknownIcon from "@mui/icons-material/Help";
import ShareIcon from "@mui/icons-material/Share";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Warning from "@mui/icons-material/Warning";
import LocalShipping from "@mui/icons-material/LocalShipping";
// import HelpIcon from "@mui/icons-material/Help";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStationOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { isUnity, isSmartelds, isEldBooks, isVulcansols } from "../configureStore";
import { LABELS } from "../language";

const ALL_LABELS = LABELS.navigation;
const appRoutes = [
  {
    id: "Dashboard",
    label: ALL_LABELS.dashboard,
    url: "/dashboard",
    children: [
      {
        id: "Overview",
        label: ALL_LABELS.overview,
        icon: <PieChartIcon />,
        url: "/overview",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: false, // Partner only route
        adminSupport: true,
      },
      {
        id: "Dashboard",
        label: ALL_LABELS.dashboard,
        icon: <DashboardIcon />,
        url: "/admin",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: false, // Partner only route
        adminSupport: true,
      },
      {
        id: "Accounts",
        label: ALL_LABELS.accounts,
        icon: <AppsIcon />,
        url: "/accounts",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: true, // Partner only route
        adminSupport: true,
      },
      {
        id: "Support",
        label: ALL_LABELS.support,
        icon: <SupportAgentIcon />,
        url: "/support",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: true, // Partner only route
        adminSupport: true,
        adminSupportPlan: true,
      },
      {
        id: "whatsapp",
        label: ALL_LABELS.whatsapp,
        icon: <WhatsAppIcon />,
        url: "/whatsapp",
        admin: isSmartelds || isEldBooks || isVulcansols, // Admin only route
        superAdmin: isSmartelds || isEldBooks || isVulcansols, // Admin only route
        partner: isSmartelds || isEldBooks || isVulcansols, // Partner only route
        adminSupport: isSmartelds || isEldBooks || isVulcansols,
        adminSupportPlan: isSmartelds || isEldBooks || isVulcansols,
      },
      {
        id: "Queue",
        label: ALL_LABELS.queue,
        icon: <FormatListNumberedIcon />,
        url: "/support/queue",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: true, // Partner only route
        adminSupport: true,
        adminSupportPlan: true,
      },
      {
        id: "Shipping",
        label: ALL_LABELS.shipping,
        icon: <LocalShipping />,
        url: "/shipping",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: true, // Partner only route
        adminSupport: true,
        adminSupportPlan: true,
      },
      {
        id: "leads",
        label: ALL_LABELS.leads,
        icon: <ListAltIcon />,
        url: "/leads",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: true, // Partner only route
        adminSupport: true,
        adminSupportPlan: true,
      },

      {
        id: "AdminFmcsa",
        label: ALL_LABELS.fmcsa,
        icon: <Publish />,
        url: "/fmcsa",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: true, // Partner only route
        adminSupport: true,
      },
      {
        id: "Audit",
        label: ALL_LABELS.audit,
        icon: <Warning />,
        url: "/admin-audit",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: false, // Partner only route
        adminSupport: true,
      },
      {
        id: "admin-todo",
        label: ALL_LABELS.todo,
        icon: <ListAltIcon />,
        url: "/admin-todo",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: false, // Partner only route
        adminSupport: true,
        adminSupportPlan: true,
      },
      {
        id: "Users",
        label: ALL_LABELS.users,
        icon: <SupervisorAccount />,
        url: "/admin-users",
        admin: true, // Admin only route
        superAdmin: true, // Admin only route
        partner: true, // Partner only route
        adminSupport: true,
      },

      {
        id: "Admin Settings",
        label: ALL_LABELS.settings,
        icon: <SettingsIcon />,
        url: "/admin-settings",
        admin: false, // Admin only route
        superAdmin: true, // Admin only route
        adminSupport: true,
        adminSmEld: true, // Admin Smart eld route
      },
      {
        id: "Admin Reseller",
        label: ALL_LABELS.reseller,
        icon: <SettingsIcon />,
        url: "/admin-reseller",
        admin: false, // Admin only route
        superAdmin: true, // Admin only route
        adminReseller: true,
        adminSmEld: true, // Admin Smart eld route
        adminEbEld: true, // Admin Eldbooks route
        adminBhEld: true, // Admin Eldbooks route
      },
      {
        id: "Duty Status",
        label: ALL_LABELS.dutyStatus,
        icon: <AppsIcon />,
        url: "/status/diary/:driverId",
        driver: true,
      },
      {
        id: "Dvir",
        label: ALL_LABELS.dvir,
        icon: <AppsIcon />,
        url: "/dvir",
        driver: true,
      },
      {
        id: "Daily Logs",
        label: ALL_LABELS.dailyLogs,
        icon: <HistoryIcon />,
        active: true,
        url: "/status/view",
        manager: true,
      },
      // {
      //     id: "Daily Logs Beta",
      //     label: "Daily Logs Beta",
      //     icon: <HistoryIcon />,
      //     active: true,
      //     url: "/status/view/beta",
      //     manager: true
      // },

      // {
      //     id: "Daily Logs GMap",
      //     label: "Daily Logs GMap",
      //     icon: <HistoryIcon />,
      //     active: true,
      //     url: "/status/view/Gmap",
      //     manager: true
      // },
      {
        id: "Tracking",
        label: ALL_LABELS.tracking,
        icon: <MapIcon />,
        url: "/tracking",
        manager: true,
      },
      {
        id: "Unidentified",
        label: ALL_LABELS.unidentified,
        icon: <UnknownIcon />,
        url: "/unidentified",
        manager: true,
      },
      {
        id: "Malfunctions",
        label: ALL_LABELS.malfunctions,
        icon: <WarningIcon />,
        url: "/malfunctions",
        manager: true,
      },
      {
        id: "Violations",
        label: ALL_LABELS.violations,
        icon: <WarningIcon />,
        url: "/violations",
        manager: true,
      },
      {
        id: "Notifications",
        label: ALL_LABELS.notifications,
        icon: <MessageIcon />,
        url: "/notifications",
        manager: true,
      },
      {
        id: "Dvir",
        label: ALL_LABELS.dvir,
        icon: <PictureAsPdf />,
        url: "/dvir",
        manager: true,
      },
      {
        id: "Document",
        label: ALL_LABELS.document,
        icon: <Attachment />,
        url: "/document",
        manager: isUnity ? !isUnity : true,
      },
      {
        id: "Fmcsa",
        label: ALL_LABELS.fmcsa,
        icon: <Publish />,
        url: "/fmcsa/create",
        manager: isUnity ? !isUnity : true,
      },
      {
        id: "EditLogs",
        label: ALL_LABELS.logEdits,
        icon: <EditIcon />,
        url: "/log-edits",
        manager: true,
      },
      {
        id: "Liveshare",
        label: ALL_LABELS.liveShare,
        icon: <ShareIcon />,
        url: "/live-share",
        manager: isUnity ? !isUnity : true,
      },
      // {
      //   id: "StateMileage",
      //   label: "State Mileage",
      //   icon: <LocalGasStationIcon />,
      //   url: "/state-mileage",
      //   manager: isUnity ? !isUnity : true,
      // },
      {
        id: "StateMileage",
        label: ALL_LABELS.stateMileage,
        icon: <LocalGasStationIcon />,
        url: "/state-mileage",
        manager: isUnity ? !isUnity : true,
      },
      // {
      //     id: "Ifta Mileage",
      //     label: "Ifta Mileage",
      //     icon: <LocalGasStationIcon />,
      //     url: "/ifta-mileage",
      //     manager: true
      // }
      // {
      //     id: "Fmcsa",
      //     label: "Fmcsa",
      //     icon: <Publish />,
      //     url: "/fmcsa/history",
      //     manager: true
      // }
      {
        id: "Reports",
        label: ALL_LABELS.reports,
        icon: <AssessmentIcon />,
        url: "/reports/fmcsa/create",
        manager: isUnity,
      },
      {
        id: isUnity ? "Fleet manager" : "Settings",
        label: isUnity ? ALL_LABELS.fleetManager : ALL_LABELS.settings,
        icon: <SettingsIcon />,
        url: "/settings/carrier",
        manager: true,
      },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  headerLogo: {
    width: 55,
    margin: "auto",
  },
  item: {
    paddingTop: 8,
    paddingBottom: 8,
    margin: "4px 0",
    fontSize: 14,
    fontWeight: 400,
    color: "#41536e",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#f4f5f7",
    },
  },
  itemCategory: {
    backgroundColor: "#171F2A",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 10,
    paddingRight: 10,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "#009ddd26",
    },
  },
  itemActiveItem: {
    color: "#009ddd",
    backgroundColor: "#009ddd26",
    position: "relative",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    backgroundColor: "#dfe1e6",
    height: 2,
    marginTop: 0,
  },
});

class MainNavigator extends React.Component {
  render() {
    const { classes, onClose, show, setShow, ...other } = this.props;

    return (
      <Drawer
        anchor="left"
        variant="permanent"
        {...other}
        onClose={() => {
          onClose();
        }}
      >
        <List style={{ marginTop: 10 }} className="sidebar-list">
          <NavigatorLinks
            index={1}
            show={show}
            appRoutes={appRoutes}
            classes={classes}
            onClose={onClose}
          />
        </List>
        <Paper>
          <div className="slide-toggle">
            <div className="slide-togglebar "></div>
            <div className="toggle-line">
              <span className="css-xwsnrl"></span>
            </div>
            <div>
              <button
                type="button"
                className={`toggle-btn ${
                  show ? "toggle-btn-r1" : "toggle-btn-r2"
                }`}
                onClick={setShow}
              >
                <span className="css-pxzk9z">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="presentation"
                  >
                    <path
                      d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
                      fill="currentColor"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <div className="css-z8pkji"></div>
              </button>
            </div>
          </div>
        </Paper>
      </Drawer>
    );
  }
}

MainNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
export default withStyles(styles)(connect(mapStateToProps, {})(MainNavigator));
