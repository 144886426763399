import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
import { getShippingOrTrailer, addShippingOrTrailer } from "../../actions/driverActions";
import Grid from '@mui/material/Grid';
import ReduxButton from "../../components/ReduxButton";
import Error from "../Error";
import Success from "../Success";
import Alert from '@mui/material/Alert';
import { LABELS as _LABELS } from "../../language";

const LABELS=_LABELS.dutyPage;


const styles = (theme) => ({
  textField: {
    padding: 4,
    width: "100%",
  },
  // formControl: {
  //     // margin: theme.spacing(1),
  //     minWidth: 120
  // },
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddShippingDialog extends React.Component {
  state = {
    successMessage: "",
    errorMessage: "",
    loading: false,
  };

  componentDidMount() {
    this.getCurrent()
  }

  getCurrent = async () => {
    const accountId = _get(this, 'props.accountId');
    const driverId = _get(this, 'props.match.params.driverId');
    try {

      const params = { accountId, driverId }
      const response = await this.props.getShippingOrTrailer(params);
      const _shipping = _get(response, "[0].shipping", "");
      const _trailer = _get(response, "[0].trailer", "");

      this.setState({shipping: _shipping, trailer: _trailer});
    } catch (e) {}
  };

  save = async () => {
    let { shipping, trailer } = this.state;
    const accountId = _get(this, 'props.accountId');
    const driverId = _get(this, 'props.match.params.driverId');
    this.setState({ successMessage: "", errorMessage: "" });

    this.setState({ loading: true });
    let response;
    try {
      const params = { accountId, driverId, shipping, trailer }
      // console.log('params == ', params);
      response = await this.props.addShippingOrTrailer(params);
      // console.log('response == ', response);
    } catch (e) {
      //
    }

    if (response && response.aId) {
      this.setState({
        loading: false,
        successMessage: LABELS.successMessage,
      });
      setTimeout(() => this.props.handleClose(), 1000);
    } else {
      this.setState({
        loading: false,
        errorMessage: LABELS.errorMessage,
      });
    }
  };

  handleChange = (event, name) => {
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  render() {
    const { classes } = this.props;
    // const {  users, driverId, isUnity } = this.props;
    const {
      errorMessage,
      successMessage,
      loading = false,
      shipping = "",
      trailer = "",
    } = this.state;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h2 style={{ textAlign: "center", padding: 0 }}>
          {LABELS.AddShippingOrTrailer}
        </h2>
        <Alert
            severity="info"
            style={{
                marginTop: 10,
                marginBottom: 10,
                width: "100%"
            }}
        >
            {LABELS.updateTrailerMassage_shippingDocs_application}
        </Alert>
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="shipping"
                label={LABELS.shipping}
                className={classes.textField}
                value={shipping}
                onChange={(e) => this.handleChange(e, "shipping")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="trailer"
                label={LABELS.trailer}
                className={classes.textField}
                value={trailer}
                onChange={(e) => this.handleChange(e, "trailer")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Error top={20} message={errorMessage} />
              <Success top={20} message={successMessage} />
            </Grid>
          </Grid>
          <DialogActions className={classes.DialogActions}>
            <Button onClick={this.props.handleClose} color="primary">
              {LABELS.cancel}
            </Button>
            <ReduxButton
              width
              loading={loading}
              onClick={this.save}
              marginTop={25}
              label={LABELS.save}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    accountId: _get(state, "authData.accountId"),
    errorMessage: _get(state, "authData.errorMessage", ""),
    isUnity: !_get(state, "appData.isUnity", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getShippingOrTrailer,
      addShippingOrTrailer,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddShippingDialog))
);
