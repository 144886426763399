import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { acceptTerms } from "../../actions/auth/authActions";
import Grid from "@mui/material/Grid";
import ReduxButton from "../../components/ReduxButton";
import Error from "../Error";
import Success from "../Success";
import TermsTextDetail from "./TermsTextDetail";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.termCondition;


const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  DialogActionsCancel: {
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class TermsDialog extends React.Component {
  state = {
    successMessage: "",
    errorMessage: "",
  };

  componentDidMount() {}

  save = async () => {
    const response = await this.props.acceptTerms({ terms: true });

    if (response.message) {
      this.setState({
        errorMessage: LABELS.please_try_after_sometime,
      });
    } else {
      this.props.onSuccess();
    }
  };

  decline = () => {
    this.setState({
      errorMessage:
        LABELS.not_able_to_be_continue,
    });
    setTimeout(() => this.props.decline(), 5000);
  };

  render() {
    const { classes, loading = false } = this.props;
    const { errorMessage, successMessage } = this.state;
    // const termsText = getTermsText()

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h2 style={{ textAlign: "center", padding: 0 }}>
          {LABELS.term_and_condition}
        </h2>
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TermsTextDetail />
            </Grid>
            {(errorMessage || successMessage) && (
              <Grid item md={12} xs={12}>
                <Error top={20} message={errorMessage} />
                <Success top={20} message={successMessage} />
              </Grid>
            )}
            <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" onClick={this.save} color="primary">
                {loading ? LABELS.loading : LABELS.accept_and_continue}
              </Button>
              <Grid item md={12} xs={12}></Grid>
              <Button onClick={this.decline} color="primary">
                {LABELS.decline}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
    clientName: _get(state, "appData.clientApp", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      acceptTerms,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsDialog))
);
