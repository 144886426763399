import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  formatDate,
  formatTime,
  formatDateTimeSec,
} from "../../actions/momentActions";
import {
  getNotifications,
  getNotificationCount,
} from "../../actions/notificationsActions";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationsTable from "./NotificationsTable";
import SearchBar from "./SearchBar";
import MessageDialog from "./MessageDialog";

class Notifications extends React.Component {
  state = {
    limit: 10,
    page: 0,
    isMessageDialog: false,
    loading: false,
  };

  async componentDidMount() {
    await this.getNotifications();
    await this.getNotificationCount();
  }

  getNotificationCount = async () => {
    const { userId } = this.state;
    await this.props.getNotificationCount({ userId });
  };

  getNotifications = async () => {
    this.setState({ loading: true });
    const { limit, page, userId } = this.state;
    await this.props.getNotifications({ limit, page, userId });
    this.setState({ loading: false });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getNotifications);
  };

  handleUserChange = ({ userId }) => {
    this.setState({ userId }, this.getNotifications);
  };

  handleClose = () => {
    this.getNotificationCount();
    this.getNotifications();
    this.setState({ isMessageDialog: false });
  };

  showMessageDialog = () => {
    this.setState({ isMessageDialog: true });
  };

  render() {
    const { isMessageDialog, loading } = this.state;
    return (
      <React.Fragment>
        {isMessageDialog && (
          <MessageDialog
            open={isMessageDialog}
            handleClose={this.handleClose}
          />
        )}
        <div>
          <SearchBar
            handleChange={this.handleUserChange}
            showMessageDialog={this.showMessageDialog}
            loading={loading}
          />
          {loading && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <CircularProgress />
            </div>
          )}
          <NotificationsTable
            count={this.props.count}
            items={this.props.notifications}
            formatDate={this.props.formatDate}
            formatTime={this.props.formatTime}
            limit={this.state.limit}
            page={this.state.page}
            handleChange={this.handleChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: _get(state, "notificationData.notifications", []),
    count: _get(state, "notificationData.count", 0),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatTime,
      formatDateTimeSec,
      getNotifications,
      getNotificationCount,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
