import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
// import MomentUtils from "@date-io/moment";
import SearchIcon from "@mui/icons-material/Search";
import { getDrivers } from "../../actions/driverActions";
import { getEquipments } from "../../actions/equipmentActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import {
  formatDate,
  formatTime,
  formatDateTimeSec,
} from "../../actions/momentActions";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.adminPage;

const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: 25,
    textAlign: "center",
  },
});

const SearchBar = (props) => {
  const {
    classes,
    accountId,
    constants,
    drivers = [],
    accounts = [],
    loading = false,
    options: {
      isComp = false,
      isEqp = false,
      isTrailer = false,
      isDriver = false,
      isState = false,
      isDownload = false,
      isStartDate = false,
      isEndDate = false,
      // isTime = false,
      // formatDate = "MM/DD/yyyy",
      // format = "MM/DD/yyyy hh:mm a",
    } = {},
  } = props;

  const allStates = constants.US_STATES;
  const _equipments = props.equipments || [];
  const equipments = isTrailer
    ? _equipments
    : _equipments.filter((item) => item.assetType === "VEHICLE");
  const [stateId, setState] = useState("");
  const [driverId, setDriver] = useState("");
  const [companyId, setCompany] = useState("");
  const [vehicleId, setVehicle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let isValid = true;

  if (isStartDate && !startDate) {
    isValid = false;
  }
  if (isEndDate && !endDate) {
    isValid = false;
  }

  useEffect(() => {
    async function fetchData() {
      if (isDriver) {
        await props.getDrivers({ skip: 0, limit: 250 });
      }

      if (isEqp) {
        await props.getEquipments({ skip: 0, limit: 250 });
      }

      await props.getAccounts({ skip: 0, limit: 250 });
    }
    fetchData();
    // eslint-disable-next-line
  }, [accountId]);

  const onSearch = (e) => {
    e.preventDefault();
    let payload = {};
    if (isComp) {
      payload.companyId = companyId;
    }
    if (isDriver) {
      payload.driverId = driverId;
    }
    if (isEqp) {
      payload.vehicleId = vehicleId;
    }
    if (isStartDate) {
      payload.start = new Date(startDate);
    }
    if (isEndDate) {
      payload.end = new Date(endDate);
    }

    props.onSearch(payload);
  };

  const onCancel = (e) => {
    e.preventDefault();
    let payload = {};

    if (isDriver) {
      payload.driverId = "";
      setDriver("");
    }
    if (isEqp) {
      payload.vehicleId = "";
      setVehicle("");
    }
    if (isComp) {
      payload.companyId = "";
      setCompany("");
    }
    if (isStartDate) {
      payload.start = null;
      // setStartDate(null);
      setStartDate("");
    }
    if (isEndDate) {
      payload.end = null;
      // setEndDate(null);
      setEndDate("");
    }

    props.onSearch(payload);
  };
  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={1}>
        {isComp && (
          <Grid item sm xs={12} md>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="companyId">{LABELS.accounts}</InputLabel>
              <Select
                className={classes.select}
                value={companyId}
                onChange={(e) => setCompany(e.target.value)}
                inputProps={{
                  name: "companyId",
                  id: "companyId",
                }}
              >
                <MenuItem value={""}>All Accounts</MenuItem>
                {Array.isArray(accounts) &&
                  accounts.map((item, i) => (
                    <MenuItem key={i} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isDriver && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="driverId">Driver</InputLabel>
              <Select
                className={classes.select}
                value={driverId}
                onChange={(e) => setDriver(e.target.value)}
                inputProps={{
                  name: "driverId",
                  id: "driverId",
                }}
              >
                <MenuItem value={""}>All Drivers</MenuItem>
                {Array.isArray(drivers) &&
                  drivers.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isEqp && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="vehicleId">All Equipment</InputLabel>
              <Select
                className={classes.select}
                value={vehicleId}
                onChange={(e) => setVehicle(e.target.value)}
                inputProps={{
                  name: "vehicleId",
                  id: "vehicleId",
                }}
              >
                <MenuItem value={""}>All equipments</MenuItem>
                {Array.isArray(equipments) &&
                  equipments.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                      {isTrailer ? (
                        <small
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          {` (${item.assetType})`}{" "}
                        </small>
                      ) : (
                        ""
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isState && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="stateId">All States</InputLabel>
              <Select
                className={classes.select}
                value={stateId}
                onChange={(e) => setState(e.target.value)}
                inputProps={{
                  name: "stateId",
                  id: "stateId",
                }}
              >
                <MenuItem value={""}>All States</MenuItem>
                {Array.isArray(allStates) &&
                  allStates.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isStartDate && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth>
              <TextField
                value={startDate}
                type={"datetime-local"}
                id="outlined-required"
                label={LABELS.startDate}
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        )}
        {isEndDate && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth>
              <TextField
                value={endDate}
                type={"datetime-local"}
                id="outlined-required"
                label={LABELS.endDate}
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item sm xs={12}>
          <div className={classes.buttonGroup}>
            <ButtonGroup
              size="small"
              disableElevation
              variant="contained"
              color="primary"
            >
              <Button
                color="primary"
                variant="contained"
                startIcon={<SearchIcon />}
                disabled={!isValid || props.loading}
                onClick={onSearch}
              >
                {/* {loading ? "Searching..." : "SEARCH"} */}
                {`${loading ? "Searching..." : LABELS.search}`}
              </Button>
              <Button color="error" onClick={onCancel}>
                {LABELS.cancel}
              </Button>
              {isDownload ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={props.onDownload}
                >
                  DOWNLOAD
                </Button>
              ) : null}
            </ButtonGroup>
          </div>
        </Grid>
      </Grid>
    </div>
  );
  // }
};

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    equipments: _get(state, "equipmentData.equipments", []),
    accounts: _get(state, "accountData.accounts", []),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDrivers,
      getEquipments,
      getAccounts,
      formatDate,
      formatTime,
      formatDateTimeSec,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);
