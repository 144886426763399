
export const LABELS={
    addAccount:"ਖਾਤਾ ਸ਼ਾਮਲ ਕਰੋ",
  

    name:"ਨਾਮ",
    status:"ਸਥਿਤੀ",
    ein:"Ein",
    type:"ਕਿਸਮ",
    email:"ਈ - ਮੇਲ",
    phone:"ਫ਼ੋਨ",
    role:"Rਭੂਮਿਕਾ",
    billing_name:"ਬਿਲਿੰਗ ਦਾ ਨਾਮ",
    billing_email:"ਬਿਲਿੰਗ ਈਮੇਲ",
    billing_phone:"ਬਿਲਿੰਗ ਫ਼ੋਨ",
    billing_role:"ਬਿਲਿੰਗ ਰੋਲ",
    stripe:"ਧਾਰੀ",
    note:"ਨੋਟ ਕਰੋ",
    detail:"ਵੇਰਵੇ",
    contact:"ਸੰਪਰਕ ਕਰੋ",
    billing:"ਬਿਲਿੰਗ",
    loging_users:"ਲੌਗਇਨ ਉਪਭੋਗਤਾ",

    saving:"ਰੱਖਿਅਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
    save:"ਸੇਵ ਕਰੋ",
    close:"ਬੰਦ ਕਰੋ",


    cleanDriver:"ਕੀ ਤੁਸੀਂ ਸੂਚੀ ਵਿੱਚੋਂ ਡਰਾਈਵਰ ਨੂੰ ਸਾਫ਼ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    cleanNow:"ਹੁਣੇ ਸਾਫ਼ ਕਰੋ",
    cleaning:"ਸਫਾਈ...",



    // <-------for Support user----->

    addSupportUser:"ਸਪੋਰਟ ਯੂਜ਼ਰ ਸ਼ਾਮਲ ਕਰੋ",
  note: "ਨੋਟ:- ਇਹ ਸਿਰਫ ਨਵੇਂ ਸਮਰਥਨ ਉਪਭੋਗਤਾ ਨੂੰ ਜੋੜਨ ਲਈ ਹੈ। ਸਹਾਇਤਾ ਉਪਭੋਗਤਾ ਕੋਲ ਤੁਹਾਡੇ ਸਾਰੇ ਖਾਤੇ ਅਤੇ ਕੈਰੀਅਰ ਵੇਰਵਿਆਂ ਤੱਕ ਪਹੁੰਚ ਹੋਵੇਗੀ.",
  firstName:"ਪਹਿਲਾ ਨਾਂ",
  lastName:"ਆਖਰੀ ਨਾਂਮ",
  mobile:"ਮੋਬਾਈਲ",
  password:"ਪਾਸਵਰਡ",
  reseller:"ਵਿਕਰੇਤਾ",
  superAdmin:"ਸੁਪਰ ਐਡਮਿਨ",




 

}