export const LABELS={
    leads:"ВЕСТИ",

    channel:"Канал",
    status:"Положение дел",
    dot:"ТОЧКА",
    name:"Имя",
    adress_line:"Адресная строка",
    address_city:"Адрес Город",
    address_state:"Состояние адреса",
    address_zip:"Адрес zip",
    contact_name:"Контактное лицо",
    contact_email:"Почта для связи",
    contact_phone:" Контактный телефон",
    callback:"Перезвонить",
    description:"Описание",

    assignedto:"Назначено",
    assignee_email:"Адрес электронной почты правопреемника",

    saving:"Сохранение",
    save:"СОХРАНЯТЬ",
    close:"ЗАКРЫВАТЬ",



    lead_is_saved_successfully:"Лид успешно сохранен",
    something_went_wrong_please_try_after_some_time:"Что-то пошло не так, попробуйте через некоторое время.",




}
