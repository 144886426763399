const validate = values => {
    const errors = {};

    if (!values.type) {
        errors.name = "Required";
    }

    return errors;
};

export default validate;
