import * as React from "react";
import _get from "lodash/get";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

const replys = [
  "We attempted to reach you by phone, but nobody answered.",
  "Thanks for reaching out! Have a great day!.",
  "Hi! How can we assist you today?",
  "We are currently processing your request and will keep you updated on our progress.",
  "Request complete. Please log out and log back in.",
  "Request complete. Please sync your app using the button in the top right corner."
];

export default function ChatInput(props) {
  // console.log('handleChatReply');
  const [loading, setLoaing] = useState(false);
  const [message, setMessage] = useState("");

  async function onSendClick() {
    setLoaing(true);
    try {
      const id = _get(props, "chat.chatId");
      await props.handleChatReply({ id, message });
      setMessage("");
    } catch (e) {
      console.log("e ::: ", e);
    }
    setLoaing(false);
  }

  return (
    <div>
      {loading && (
        <Box sx={{ width: "100%", marginTop: 5 }}>
          <LinearProgress />
        </Box>
      )}
      <div style={{ width: "100%" }}>
        {replys.map((item, idx) => (
          <Chip style={{ marginTop: 2 }} key={`key-${idx}`} label={item} onClick={()=>setMessage(item)}/>
        ))}
      </div>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton sx={{ p: "10px" }} aria-label="menu" disabled={true}>
          <AttachFileIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter message"
          inputProps={{ "aria-label": "search google maps" }}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          onKeyPress={(e) => {
            if (message && e.key === "Enter") {
              e.preventDefault();
              onSendClick();
            }
          }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          disabled={!message}
          color="primary"
          sx={{ p: "10px" }}
          aria-label="Send"
          onClick={onSendClick}
        >
          {loading ? <CircularProgress /> : <SendIcon />}
        </IconButton>
      </Paper>
    </div>
  );
}
