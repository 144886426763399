import React from "react";
import _get from "lodash/get";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const supportPlanPremium = process.env.REACT_APP_STRIPE_SP_PREMIUM;
const supportPlanEnterprise = process.env.REACT_APP_STRIPE_SP_ENTERPRISE;

const useStyles = makeStyles({
  wrapper: {
    maxWidth: 950,
    margin: "auto",
  },
  table: {},
  DialogActions: {
    marginTop: 20,
  },
  wrapperButton: {
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: 4,
    top: 4,
    color: "grey",
  },
  caption: {
    paddingTop: "5px!important",
    paddingBottom: "5px!important",
  },
});

export default function CustomerSupportPackage(props) {
  const classes = useStyles();

  const plans = [
    {
      title: "Price",
      standard: "$0.00",
      premium: "$49.00/Driver",
      elite: "$99.00/Driver",
    },
    {
      title: "Support channel",
      standard: "Email",
      premium: "Email & phone",
      elite: "Email, chat & phone",
    },
    {
      title: "Hours of operation",
      standard: "Business hours",
      standardHelp: "Business hours",
      premium: "Business hours",
      elite: "24/7",
    },
    {
      title: "Support priority",
      standard: "Standard",
      premium: "High Priority",
      elite: "Highest Priority",
    },
    {
      title: "Response time",
      standard: "< 24 Hours",
      premium: "< 4 Hours",
      elite: "< 30 Minutes",
    },
    {
      title: "Driver training",
      standard: "At additional fee",
      premium: "Included",
      elite: "Included",
    },
    {
      title: "Compliance",
      standard: "At additional fee",
      premium: "At additional fee",
      elite: "Included",
    },
    {
      title: "Vehicle maintenance",
      standard: "At additional fee",
      premium: "At additional fee",
      elite: "Included",
    },
  ];

  const planName = _get(props, "plan.name");
  const accountSerial = _get(props, "account.accountSerial");
  const email = _get(props, "profile.user.email");

  const prefillInfo = `prefilled_email=${email}&client_reference_id=${accountSerial}`;
  const supportPlanPremiumUrl = `${supportPlanPremium}?${prefillInfo}`;
  const supportPlanEnterpriseUrl = `${supportPlanEnterprise}?${prefillInfo}`;
  const isStandard = planName === "STANDARD";
  const isPremium = planName === "PREMIUM";
  const isEnterprise = planName === "ENTERPRISE";

  let hideStandard = false;
  let hidePremium = false;

  if (isPremium) {
    hideStandard = true;
  }
  if (isEnterprise) {
    hideStandard = true;
    hidePremium = true;
  }

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      maxWidth={"xl"}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>{"Support Center"}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper} className={classes.wrapper}>
          <Table className={classes.table} aria-label="simple table">
            <caption className={classes.caption}>
              *All plans are subjected to availability.
            </caption>
            <caption className={classes.caption}>
              *Business hours - 9 Am to 5 Pm, Monday to Friday.
            </caption>
            <caption className={classes.caption}>
              *Switching to higher plan may take up to 1 week, as we will need
              to scale resources.
            </caption>
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={4}>
                  {"Customer Support Package"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Features</TableCell>
                <TableCell align="center">Standard</TableCell>
                <TableCell align="center">Premium</TableCell>
                <TableCell align="center">Enterprise</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((item) => (
                <TableRow key={item.title}>
                  <TableCell component="th" scope="row">
                    <div style={{ fontWeight: 600 }}> {item.title}</div>
                  </TableCell>
                  <TableCell align="center">{item.standard}</TableCell>
                  <TableCell align="center">{item.premium}</TableCell>
                  <TableCell align="center">{item.elite}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="center">
                  {hideStandard ? null : isStandard ? (
                    <strong>{"SELECTED"}</strong>
                  ) : (
                    <Button size="small" variant="contained" color="primary">
                      {"SELECT"}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">
                  {hidePremium ? null : isPremium ? (
                    <strong>{"SELECTED"}</strong>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      href={supportPlanPremiumUrl}
                    >
                      {"SELECT"}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">
                  {isEnterprise ? (
                    <strong>{"SELECTED"}</strong>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      href={supportPlanEnterpriseUrl}
                    >
                      {"SELECT"}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
