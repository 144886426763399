const defaultState = {
    count: 0,
    liveShareBusy: false,
    liveShares: [],
    selectedLiveShare: {}
};

export default function liveShare(state = defaultState, action) {
    switch (action.type) {
        case "LIVE_SHARES_COUNT":
            return {
                ...state,
                count: action.value
            };

        case "LIVE_SHARES":
            return {
                ...state,
                liveShares: action.value
            };
        case "LIVE_SHARES_BUSY":
            return {
                ...state,
                liveShareBusy: action.value
            };
        default:
            return state;
    }
}
