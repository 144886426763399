// import _get from "lodash/get";
// import _set from "lodash/set";

const defaultValues = {
  startTime: null,
  endTime: null,
  inspectionType: "",
  vehicleType: "",
  form: "",
  equipmentCondition: "",
  location: "",
  odometer: "",
  signature: "",
};

const validate = (values) => {
  let errors = {};
  const requiredFields = [
    "startTime",
    "endTime",
    "inspectionType",
    "vehicleType",
    "form",
    "equipmentCondition",
    "location",
    "odometer",
    "signature",
  ];

  requiredFields.forEach((field) => {
    if (!values[field] || !values[field].trim()) {
      errors[field] = "Required";
    }
  });

  return errors;
};

export { validate, defaultValues };
