import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

export default function PageTwo(props) {
  const LABELS = props.LABELS;
  return (
    <React.Fragment>
      <Container
        maxWidth="sm"
        style={{
          backgroundColor: "white",
          height: "450px",
          textAlign: "center",
        }}
      >
        <img
          style={{ width: 300, marginTop: 25 }}
          src="/assets/images/osrit.png"
          alt="Osrit"
        />
        <Typography variant="h4">
          {LABELS.header}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ marginTop: 25, marginBottom: 25 }}
        >
          {LABELS.header2}
        </Typography>
        <Typography component="span">{LABELS.header3}</Typography>

        <Button color="primary" href="https://www.osrit.com/">
          {LABELS.header3Link}
        </Button>
      </Container>
    </React.Fragment>
  );
}
