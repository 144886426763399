export const LABELS = {
  map: "नक्शा",
  table: "तालिका",
  search: "खोज",
  name: "नाम",
  status: "स्थिति",
  location: "स्थान",
  vehicle: "वाहन",
  break: "विराम",
  drive: "ड्राइव",
  shift: "शिफ्ट",
  cycle: "साइकिल",
  violations: "उल्लंघन",
  eld: "ईएलडी",
  update: "अपडेट",
  app: "ऐप",
};

