// import _get from "lodash/get";
// import _set from "lodash/set";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.settingPage;


const defaultValues = {
  accountId: "",
  resellerId: "",
  type: "SUPPORT",
  status: "NEW",
  priority: "MEDIUM",
  description: "",
  dueDate: null,
  assignedTo: { name: "", email: "", id: "" },
};

const validate = (values) => {
  let errors = {};
  const requiredFields = ["status", "priority", "dueDate", "description"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.required;
    }
  });

  return errors;
};

export { validate, defaultValues };
