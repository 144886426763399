import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./carrierActionTypes";

export const setCarriers = value => ({
    type: TYPES.CARRIERS,
    value
});

export const selectedCarrier = value => ({
    type: TYPES.SELCTED_CARRIER,
    value
});

export const appCarrierBusy = value => ({
    type: TYPES.APP_CARRIER_BUSY,
    value
});

export function getCarriers(params = {}, accId) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        let accountId = "";

        if (accId) {
            accountId = accId
        }
        else {
            accountId = _get(getState(), "authData.profile.user.accountId");
        }

        dispatch(appCarrierBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/carriers`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setCarriers(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appCarrierBusy(false));
                return response;
            });
    };
}

export function createCarrier(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        dispatch(appCarrierBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/carriers`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appCarrierBusy(false));
                return response;
            });
    };
}

export function updateCarrier(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        dispatch(appCarrierBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/carriers/${params.id}`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appCarrierBusy(false));
                return response;
            });
    };
}
