import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import { IconButton } from "@mui/material";
import Button from '@mui/material/Button';
import Signature from './Signature';

const STYLES = {
    signtable: {
        border: "0.1px solid #ccc",
        width: "95%",
        borderCollapse: "collapse",
        margin: "auto",
        fontSize: "13px",
        height: "125px"
    },

    td: {
        border: "0.2px solid #ccc",
        width: "35%",
        textAlign: "left",
        padding: 5
    },

    sign: {
        border: "0.2px solid #ccc",
        width: "35%",
        textAlign: "center",
        padding: 5
    },
    signImg: {
        width: "150px",
        height: "110px",
        cursor: "pointer"
    },
    modalText: {
        fontSize: "20px",
        textAlign: "center"
    },

};
export default function SignatureTable({ signOpen,
    handleClose,
    saving,
    getImage,
    webview,
    dDiary,
    signature,
    imageUrl,
    openSign }) {

    return (
        <>
            <table style={STYLES.signtable}>
                <tbody>
                    <tr style={STYLES.td}>
                        <td style={STYLES.sign}>
                            DRIVER'S SIGNATURE
                                        </td>
                        <td style={STYLES.sign}>
                            {signOpen && !webview &&
                                <Dialog open={signOpen}>
                                    <span style={{ textAlign: "right" }}>
                                        <IconButton aria-label="close" onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </span>
                                    <p style={STYLES.modalText}>Add signature below</p>
                                    <Signature
                                        saving={saving}
                                        getImage={getImage}
                                        handleClose={handleClose}
                                    />
                                </Dialog>
                            }

                            {webview && signature && dDiary.certified &&

                                <img
                                    src={`data:image/png;base64,${signature}`}
                                    alt="driver'signature"
                                    onClick={openSign}
                                    style={STYLES.signImg} />

                            }

                            {signature && !webview &&

                                <img
                                    src={`data:image/png;base64,${signature}`}
                                    alt="driver'signature"
                                    onClick={openSign}
                                    style={STYLES.signImg} />

                            }

                            {!imageUrl && !webview && !signature && <Button color="primary" onClick={openSign} >
                                Click here to add signature.
                                            </Button>}

                        </td>
                        {/* <td style={STYLES.sign}>
                                            DATE : {dateToday}
                                        </td> */}
                    </tr>
                </tbody>
            </table>
        </>
    )
}
