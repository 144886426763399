import axios from "./axios";
import _get from "lodash/get";

export function getIftaLogs(params) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    try {
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/statemiles`,
        method: "get",
        params,
      });
      const _response = _get(response, "data");
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}

export function emailIftaLogs(params) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    try {
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/email/statemiles`,
        method: "post",
        data: params,
      });
      const _response = _get(response, "data");
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}

export function deleteIftaLogs(params) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    try {
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/statemiles/${params.id}`,
        method: "delete",
        params,
      });
      const _response = _get(response, "data");
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}

export function getStateMileage(params) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    try {
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/statemileage`,
        method: "get",
        params,
      });
      const _response = _get(response, "data");
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}

export function updateStateMileage(params = {}) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    try {
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/statemileage`,
        method: "put",
        data: params,
      });
      const _response = _get(response, "data", {});
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}

export function getEqpLogs(params = {}) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    try {
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/eqps/${params.eqpId}/status/search`,
        method: "post",
        data: params,
      });
      const _response = _get(response, "data", {});
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}
