const defaultState = {
    driverBusy: false,
    drivers: [],
    selectedDriver: {},
    driverRealTime: []
};

export default function driver(state = defaultState, action) {
    switch (action.type) {
        case "DRIVERS":
            return {
                ...state,
                drivers: action.value
            };
        case "SELECTED_DRIVER":
            return {
                ...state,
                selectedDriver: action.value
            };
        case "DRIVER_COUNT":
            return {
                ...state,
                driversCount: action.value
            };
        case "APP_DRIVER_BUSY":
            return {
                ...state,
                driverBusy: action.value
            };
        case "DRIVER_REAL_TIME":
            return {
                ...state,
                driverRealTime: action.value
            };
        default:
            return state;
    }
}
