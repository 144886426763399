import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import ApiAddEditForm from "./Forms/ApiAddEdit";
import { getNotificationsSetup } from "../../../actions/notificationsActions";
import ReduxButton from "../../../components/ReduxButton";
import NotificationSetupTable from "./NotificationSetupTable";
import { formatDateTime } from "../../../actions/momentActions";
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

const styles = theme => ({
    addButtonWrapper: {
        width: "99%",
        textAlign: "right",
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100px",
        textAlign: "left"
    },
    grow: {
        flexGrow: 1,
        // textAlign: "center",
        float: "left",
        marginTop: "10px",
        marginBottom: "10px"
    },
    loader: {
        flexGrow: 1,
        textAlign: "center",
        marginTop: "20px"
    }
});

export class NotificationSetup extends React.Component {
    state = {
        open: false,
        selTab: 0,
        page: 0,
        limit: 10,
        searching: false,
        showAll: false,
        notifications: []
    };

    async componentDidMount() {
        await this.getNotificationsSetup();
    }

    getNotificationsSetup = async () => {
        const { page, limit, query, showAll } = this.state;
        const skip = parseInt(page) * parseInt(limit);
        const payload = {
            limit,
            skip,
            query
        };
        if (!showAll) {
            payload.active = true;
        }

        this.setState({ searching: true, notifications: [] });
        const response = await this.props.getNotificationsSetup(payload);
        // console.log("response :: ", response);
        this.setState({ searching: false, notifications: response });
    };

    handleClose = async () => {
        this.setState({ isAdd: false, selected: null });
        await this.getNotificationsSetup();
    };

    handleAddClose = async () => {
        this.setState({ isAdd: false, selected: null }, async () => {
            await this.getNotificationsSetup();
        });
    };

    handleEdit = selected => {
        this.setState({ isAdd: true, isEdit: true, selected });
    };

    handleChange = ({ limit, page, order }) => {
        this.setState(
            { limit, page, order, query: "" },
            this.getNotificationsSetup
        );
    };

    onSearch = query => {
        const { page, limit, order } = this.state;
        this.setState(
            { limit, page, order, query },
            this.getNotificationsSetup
        );
    };

    switchChange = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll }, () => {
            this.getNotificationsSetup();
        });
    };
    render() {
        const { classes } = this.props;
        const { isAdd, isEdit, selected, showAll, searching } = this.state;

        return (
            <div>
                {isAdd && (
                    <ApiAddEditForm
                        open={isAdd}
                        isEdit={isEdit}
                        selected={selected}
                        scopes={this.state.scopes}
                        isAdmin={this.props.isAdmin}
                        handleClose={this.handleAddClose}
                        updateUserScope={this.props.updateUserScope}
                    />
                )}

                <AppBar position="static" color="default">
                    <Toolbar>
                        <div className={classes.grow}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showAll}
                                        onChange={this.switchChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={LABELS.showall}
                            />
                        </div>
                        {/* <div style={{ float: "right" }}>
                            <SearchInput
                                onChange={this.onSearch}
                                query={this.state.query}
                            />
                        </div> */}
                        <div>
                            <ReduxButton
                                width
                                onClick={() => {
                                    this.setState({
                                        isAdd: true,
                                        isEdit: false,
                                        selected: null
                                    });
                                }}
                                label={`${LABELS.add}`}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                {searching ? (
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                ) : (
                    <NotificationSetupTable
                        items={this.state.notifications}
                        limit={this.state.limit}
                        page={this.state.page}
                        count={this.props.count}
                        formatDateTime={this.props.formatDateTime}
                        handleEdit={this.handleEdit}
                        handleSms={this.handleSms}
                        handleChange={this.handleChange}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        count: _get(state, "apiData.count", 0),
        apiKeys: _get(state, "apiData.apiKey", []),
        apiBusy: _get(state, "apiData.apiKeyBusy", false),
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),
        isAdmin: !!_get(state, "authData.profile.adminId")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            formatDateTime,
            getNotificationsSetup
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NotificationSetup)
);
