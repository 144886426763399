import React from "react";
import _get from "lodash/get";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import DateWithButton from "../../components/DateWithButton";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import {
  CircularProgress,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getDriverById } from "../../actions/driverActions";
import { setTimeZone } from "../../actions/app/appActions";
import {
  getDriverLogs,
  getDriverDaily,
  setSelectedLog,
  deleteDriverLog,
  getEditRequest,
  getDriverLogById,
  deleteRequest,
  getAllCalcus,
  updateDriverDaily,
} from "../../actions/logActions";
import {
  deleteAdminLogs,
  updateAdminLogs,
} from "../../actions/account/accountAdminActions";
import {
  getDailyLogsDays,
  formatTime,
  formatDateTime,
  formatRawDate,
  getEndOfDay,
  getStartOfDay,
  formatDateTimeUtc,
} from "../../actions/momentActions";
import { getLogPostUrl, getLogGetUrl } from "../../actions/documentUpload";
import { getAllDayEvents, filterDriverLogs } from "./helpers";

import { getFireBaseErrors } from "../../actions/adminDashActions";
import {
  EVENT_TYPE_OPTIONS,
  SUB_EVENT_TYPE_OPTIONS,
} from "../../constantsStatus";
import { validateEngHr, validateOdometer } from "./helpers";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  AppBar: {
    paddingLeft: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    // minHeight: 200,
    // overflow: "scroll",
    marginBottom: 10,
  },
  driverHeader: {
    textAlign: "center",
    fontWeight: 500,
  },
  driverTitle: {
    fontWeight: 400,
    fontSize: 12,
    marginTop: 10,
  },
  driverContent: {
    fontSize: 12,
    marginTop: 5,
    paddingLeft: 10,
  },
  carrierHeader: {
    textAlign: "center",
    fontWeight: 500,
    marginTop: 20,
  },
  textRight: {
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  menuWrapper: {
    minHeight: 50,
    paddingTop: 6,
  },
  buttonContainer: {
    padding: 8,
  },
  statusCheckBoxWrapper: {
    paddingLeft: 25,
  },
  sepLine: {
    display: "inline-block",
    borderLeft: "1px solid lightgrey",
    height: "33px",
    position: "absolute",
  },
  errorText: {
    color: "red",
  },
  container: {
    maxHeight: 800,
  },
});

export class DailyStatusDetail extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(window.location.search);

    this.state = {
      loading: true,
      selectedDate: values.selectedDate || new Date(),
      errors: [],
      eventType: "",
      eventSubType: "",
      isDetail: false,
    };
  }

  componentDidMount() {
    this.searchLogs(this.state.selectedDate);
  }

  componentWillUnmount() {
    const timezone = _get(this, "props.defaultTimezone");
    this.props.setTimeZone(timezone);
  }

  reload = () => {
    this.searchLogs(this.state.selectedDate);
  };

  searchLogs = async (selectedDate, showAll) => {
    this.setDateinQuery(selectedDate);
    const driverId = _get(this, "props.match.params.driverId");
    const selDriver = await this.props.getDriverById({ driverId });

    this.setState({ selectedDate, loading: true }, this.getDriverDaily);

    const timezone = _get(selDriver, "terminal.timezone");
    this.props.setTimeZone(timezone);

    const days = this.props.getDailyLogsDays(selectedDate, 1);

    const allLogs =
      (await this.props.getDriverLogs({
        showAll,
        driverId,
        ...days,
      })) || {};

    // const driverLogs = allLogs.driverLogs || [];
    // const allEvents = driverLogs

    const driverLogs = allLogs.driverLogs || [];
    const driverLastLogs = allLogs.driverLastLogs || {};
    const driverNextLogs = allLogs.driverNextLogs || {};

    const allEvents = getAllDayEvents(
      timezone,
      selectedDate,
      driverLogs,
      driverLastLogs,
      driverNextLogs
    );

    this.setState({
      loading: false,
      driverLogs,
      allDayEvents: filterDriverLogs(allEvents, []),
      allEvents,
    });
  };

  setDateinQuery = (selectedDate) => {
    const date = this.props.formatRawDate(selectedDate);
    const currenUrl = _get(this, "props.history.location.pathname");

    this.props.history.push({
      pathname: currenUrl,
      search: `?selectedDate=${date}`,
    });
  };

  render() {
    if (!this.props.adminKey) {
      return "Access Denied";
    }
    const { classes, selectedDriver = {} } = this.props;
    const { loading, selectedDate } = this.state;
    const { driverLogs = [], isDetail } = this.state;
    const { allDayEvents, eventType, eventSubType } = this.state;

    let allDayEvents2 = allDayEvents;
    if (eventType) {
      allDayEvents2 = allDayEvents.filter((e) => e.eventType === eventType);
    }
    if (eventSubType) {
      allDayEvents2 = allDayEvents.filter(
        (e) => e.eventSubType === eventSubType
      );
    }

    const noData = <span className="no-data">!</span>;
    return (
      <div>
        <AppBar position="static" color="default">
          <Toolbar className={classes.AppBar}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={3}>
                <DateWithButton
                  disabled={this.state.loading}
                  onChange={this.searchLogs}
                  selectedDate={selectedDate}
                />
              </Grid>
              <Grid
                item
                sm={1}
                md={8}
                className={`${classes.textRight} ${classes.menuWrapper}`}
              >
                <Grid container>
                  <Grid item sm={2}>
                    Sub Event
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: "100%" }}
                    >
                      <Select
                        value={this.state.eventSubType}
                        defaultValue={""}
                        size="small"
                        onChange={(e) => {
                          this.setState({ eventSubType: e.target.value });
                        }}
                        inputProps={{
                          name: "Sub Event",
                        }}
                      >
                        {SUB_EVENT_TYPE_OPTIONS.map((item, i) => (
                          <MenuItem key={i} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={2}>
                    Event Type:
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: "100%" }}
                    >
                      <Select
                        defaultValue={""}
                        label={"Event Type"}
                        value={this.state.eventType}
                        onChange={(e) => {
                          this.setState({ eventType: e.target.value });
                        }}
                        inputProps={{
                          name: "Event Type",
                          defaultValue: "",
                        }}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value={""}>Event Type</MenuItem>
                        {EVENT_TYPE_OPTIONS.map((item, i) => (
                          <MenuItem key={i} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={1}
                className={`${classes.textRight} ${classes.menuWrapper}`}
              >
                <Tooltip title="Refresh">
                  <IconButton
                    aria-label="Reload"
                    color="primary"
                    onClick={this.reload}
                  >
                    <SyncIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              {this.props.adminKey &&
                (driverLogs && driverLogs.length > 700 ? (
                  <Alert severity="error">
                    {`${driverLogs.length} logs were recorded. Driver may have any issue. Check driver's all logs.`}
                  </Alert>
                ) : driverLogs && driverLogs.length > 200 ? (
                  <Alert severity="warning">
                    {`${driverLogs.length} logs were recorded. Driver have an issue. Check driver's all logs.`}{" "}
                  </Alert>
                ) : null)}

              <Paper className={classes.paper}>
                <>
                  {selectedDriver.firstName} {selectedDriver.lastName}
                  <span style={{ display: "inline-block", float: "right" }}>
                    <input
                      onClick={() => this.setState({ isDetail: !isDetail })}
                      type="checkbox"
                      name="viewDetails"
                    />
                    <label htmlFor="View Details"> View Details</label>
                  </span>
                </>
              </Paper>
            </Grid>
            {loading ? (
              <Grid item xs={12} md={12} lg={12}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />
                </div>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <TableContainer className={classes.container}>
                    <Table size="small" style={{ fontSize: 10 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Details</TableCell>
                          <TableCell>SQ/Time</TableCell>
                          <TableCell>Event</TableCell>
                          <TableCell>Vehicle</TableCell>
                          <TableCell>Odometer</TableCell>
                          <TableCell>Location</TableCell>
                          {isDetail && <TableCell>Codes</TableCell>}
                          <TableCell>ELD</TableCell>
                          {isDetail && <TableCell>Ids</TableCell>}
                          {isDetail && <TableCell>Notes</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(allDayEvents2) &&
                          allDayEvents2.map((item, i) => {
                            item.coordinates = item.coordinates || [];
                            const driver = item.driver || {};
                            const vehicle = item.vehicle || {};
                            const prevLog = allDayEvents2[i - 1];
                            const prevLog2 = allDayEvents2[i - 2];
                            const isEhValid = validateEngHr(
                              item,
                              prevLog,
                              prevLog2
                            );
                            const isOdoValid = validateOdometer(
                              item,
                              prevLog,
                              prevLog2
                            );

                            // console.log("\n item == ", item);
                            // console.log("isOdoValid == ", isOdoValid);
                            if (item.isLast) {
                              return null;
                            }

                            return (
                              <TableRow
                                key={i}
                                style={{ fontSize: 11 }}
                                id="verify"
                              >
                                <TableCell>
                                  D: {`${item.driver?.firstName}`}
                                  <br />
                                  V: {`${item.vehicle?.name}`}
                                  {isDetail && (
                                    <div>
                                      {item.trailer && `T: ${item.trailer}`}
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <div>SQ: {item.sequenceId}</div>
                                  {isDetail && <div>SQ#: {item.seqId}</div>}
                                  <div>
                                    {this.props.formatRawDate(item.eventTime)}
                                  </div>
                                  <div>
                                    {this.props.formatTime(item.eventTime)}
                                  </div>
                                  {item.endEventTime && (
                                    <div>
                                      End:{" "}
                                      {this.props.formatTime(item.endEventTime)}
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  EC: {item.eventCode}
                                  {isDetail && <div>ET: {item.eventType}</div>}
                                  <div>
                                    RS: {item.recordStatus}{" "}
                                    <small>({item.eventSubType})</small>
                                  </div>
                                  RO: {item.recordOrigin}
                                </TableCell>
                                <TableCell>
                                  {`Vehicle: ${vehicle.name}`}
                                  {isDetail && <div>{vehicle.vin}</div>}
                                  <div>{`${driver.firstName} ${driver.lastName}`}</div>
                                  {isDetail && <div>{driver.email}</div>}
                                </TableCell>
                                <TableCell>
                                  {isDetail && <div>SP: {item.speed}</div>}
                                  <div
                                    className={
                                      isOdoValid ? "" : classes.errorText
                                    }
                                  >
                                    OD: {item.odometer}
                                  </div>
                                  AM: {item.accumulatedMiles}
                                  <div
                                    className={
                                      isEhValid ? "" : classes.errorText
                                    }
                                  >
                                    EH: {item.engineHours}
                                  </div>
                                  AH: {item.accumulatedHours}
                                </TableCell>
                                <TableCell>
                                  Lat: {item.coordinates[0]}
                                  <br />
                                  Lng: {item.coordinates[1]}
                                  <br />
                                  L: {item.location}
                                  {isDetail && (
                                    <div>
                                      C-S: {item.city}-{item.state}
                                    </div>
                                  )}
                                </TableCell>
                                {isDetail && (
                                  <TableCell>
                                    DC: {item.dataCheck}
                                    <br />
                                    L: {item.locked ? "Y" : "N"}
                                    <br />
                                    CC: {item.coordCode}
                                    <br />
                                    {/* VC: {item.lastValidCoor} */}
                                    M:{" "}
                                    {item.malfunctionCode && (
                                      <span>
                                        {item.malfunction ? "" : "C-"}{" "}
                                        {item.malfunctionCode}
                                      </span>
                                    )}
                                    <br />
                                    D:{" "}
                                    {item.diagnosticCode && (
                                      <span>
                                        {item.diagnostic ? "" : "C-"}{" "}
                                        {item.diagnosticCode}
                                      </span>
                                    )}
                                  </TableCell>
                                )}
                                <TableCell>
                                  ELD: {item.connected ? "C" : "NC "}
                                  <br />
                                  GS: {item.gpsSource || noData}
                                  <br />
                                  RM: {item.regulationMode || noData}
                                </TableCell>
                                {isDetail && (
                                  <TableCell>
                                    ID: {item.id}
                                    <br />
                                    CID: {item.cid}
                                    <br />
                                    EID: {item.editId}
                                  </TableCell>
                                )}
                                {isDetail && (
                                  <TableCell>
                                    Note: {item.annotation}
                                    <br />
                                    Res: {item.reason}
                                    <br />
                                    SD: {item.shippingDoc}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");
  let showAllLogs = _get(
    state,
    "authData.profile.accountProperties.settings.showAllLogs",
    false
  );
  let isDebug = _get(
    state,
    "authData.profile.accountProperties.settings.debug",
    true
  );
  if (adminKey && isDebug) {
    showAllLogs = true;
  }
  return {
    logBusy: _get(state, "logData.logBusy", false),
    adminAccess: _get(state, "authData.adminAccess", false),
    adminScope: _get(state, "authData.profile.scopes"),
    driverCalcus: _get(state, "logData.driverCalcus", []),
    selectedLog: _get(state, "logData.selectedLog", {}),
    driverBusy: _get(state, "driverData.driverBusy", false),
    selectedDriver: _get(state, "driverData.selectedDriver", {}),
    defaultTimezone: _get(state, "appData.defaultTimezone"),
    timezone: _get(state, "appData.timezone"),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    accountId: _get(state, "authData.accountId"),
    adminKey: _get(state, "authData.profile.adminId"),
    profile: _get(state, "authData.profile.roles"),
    showAllLogs,
    isDebug: !!adminKey,
    isQa: _get(state, "appData.isQa"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTimeZone,
      getDriverById,
      getDriverDaily,
      getDriverLogs,
      formatTime,
      formatRawDate,
      formatDateTime,
      getEndOfDay,
      getStartOfDay,
      getDailyLogsDays,
      setSelectedLog,
      deleteDriverLog,
      getEditRequest,
      getDriverLogById,
      deleteRequest,
      getAllCalcus,
      updateDriverDaily,
      getLogPostUrl,
      getLogGetUrl,
      getFireBaseErrors,
      deleteAdminLogs,
      updateAdminLogs,
      formatDateTimeUtc,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DailyStatusDetail)
);
