const defaultState = {
    documentAll: [],
    count: 0
};

export default function document(state = defaultState, action) {
    switch (action.type) {
        case "DOCUMENT":
            return {
                ...state,
                documentAll: action.value
            };
        case "DOCUMENT_COUNT":
            return {
                ...state,
                count: action.value
            };
        case "DOCUMENT_BUSY":
            return {
                ...state,
                documentBusy: action.value
            };
        default:
            return state;
    }
}
