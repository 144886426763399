import React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({}));

const DtcCodeDetailModal = ({ open, onClose, dtc, dtcMessage }) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={!!open}
      onClose={onClose}
      aria-labelledby="dtc-dialog-title"
      aria-describedby="dtc-dialog-description"
    >
      <DialogTitle id="dtc-dialog-title">
        <Typography variant="div">
          {dtcMessage && dtcMessage.detail
            ? `SPN ${dtc.spn} ${dtc.fmi} ${dtc.cm} ${dtc.oc}`
            : ""}
        </Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {dtcMessage && dtcMessage.detail ? (
        <DialogContent style={{ padding: 25 }}>
          <Typography variant="subtitle1">
            {`Source: ${dtcMessage?.source}`}
          </Typography>

          {dtcMessage?.detail &&
            dtcMessage.detail.split("\n").map((line, index) => (
              <div key={index} style={{ marginTop: 15 }}>
                <Typography
                  variant="body2"
                  className={classes.detailLine}
                  style={{ fontSize: "15px" }}
                >
                  {line}
                </Typography>
              </div>
            ))}
          <div style={{ fontWeight: 600, textAlign: "center", marginTop: 15 }}>
            <small>{`Note: OpenAI can make mistakes. Check important info.`}</small>
          </div>

          <Box display="flex" justifyContent="center">
            <Button
              soze="small"
              onClick={onClose}
              variant="outlined"
              style={{ marginTop: 25 }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <h1>Please wait...</h1>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DtcCodeDetailModal;
