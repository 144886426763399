import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";
import MaintenanceForm from "./Forms/MaintenanceForm";
import {
  getMaintenance,
  getMaintenanceCount,
} from "../../../actions/maintenanceActions";
import ReduxButton from "../../../components/ReduxButton";
import MaintenanceTable from "./MaintenanceTable";
import { formatDateTime } from "../../../actions/momentActions";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100px",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    // textAlign: "center",
    float: "left",
    marginTop: "10px",
    marginBottom: "10px",
  },
  loader: {
    flexGrow: 1,
    textAlign: "center",
    marginTop: "20px",
  },
});

export class Maintenance extends React.Component {
  state = {
    open: false,
    selTab: 0,
    page: 0,
    limit: 10,
    loading: false,
    active: true,
    selected: null,
    maintenances: [],
  };

  async componentDidMount() {
    await this.getMaintenance();
  }

  getMaintenance = async () => {
    const { active, page, limit, query } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    const payload = {
      limit,
      skip,
      query,
    };

    if (active) {
      payload.active = active;
    }

    this.setState({ loading: true });
    const response = await this.props.getMaintenance(payload);
    this.setState({ loading: false, maintenances: response });
  };

  handleClose = async () => {
    this.setState({ isAdd: false, selected: null });
    await this.getMaintenance();
  };

  // handleAddClose = async () => {
  //   this.setState({ isAdd: false, selected: null }, async () => {
  //     await this.getMaintenance();
  //   });
  // };

  handleEdit = (selected) => {
    this.setState({ isAdd: true, selected });
  };

  handleChange = ({ limit, page, order }) => {
    this.setState({ limit, page, order, query: "" }, this.getMaintenance);
  };

  onSearch = (query) => {
    const { page, limit, order } = this.state;
    this.setState({ limit, page, order, query }, this.getMaintenance);
  };

  viewDetails = (driver) => {
    const { accountBaseUrl } = this.props;
    this.props.history.push(
      `${accountBaseUrl}/settings/user/${driver.driverId}`
    );
  };

  render() {
    const { classes, apiBusy } = this.props;
    const { isAdd, selected, active, loading } = this.state;
    const maintenances = this.state.maintenances || [];

    return (
      <div>
        {isAdd && (
          <MaintenanceForm
            open={isAdd}
            selected={selected}
            scopes={this.state.scopes}
            isAdmin={this.props.isAdmin}
            handleClose={this.handleClose}
            updateUserScope={this.props.updateUserScope}
          />
        )}

        <AppBar position="static" color="default">
          <Toolbar>
            <div className={classes.grow}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!active}
                    onChange={() => {
                      this.setState({ active: !active }, () => {
                        this.getMaintenance();
                      });
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={LABELS.showall}
              />
            </div>
            <div>
              <ReduxButton
                width
                onClick={() => {
                  this.setState({
                    isAdd: true,
                    isEdit: false,
                    selected: null,
                  });
                }}
                label={`${LABELS.add}`}
              />
            </div>
          </Toolbar>
        </AppBar>
        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <MaintenanceTable
              items={maintenances}
              limit={this.state.limit}
              page={this.state.page}
              count={this.props.count}
              formatDateTime={this.props.formatDateTime}
              handleEdit={this.handleEdit}
              handleSms={this.handleSms}
              handleChange={this.handleChange}
              viewDetails={this.viewDetails}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    count: _get(state, "apiData.count", 0),
    apiKeys: _get(state, "apiData.apiKey", []),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    isAdmin: !!_get(state, "authData.profile.adminId"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMaintenance,
      getMaintenanceCount,
      formatDateTime,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Maintenance)
);
