export const LABELS = {
  refresh: "Rafraîchir",
  recap: "résumer",
  date: "Date",
  newTab: "Nouvel onglet",
  download: "Télécharger",
  Audit: "Audit",
  AuditEvent:"Événements d'audit",
  openInNewTab:"ouvrir dans un nouvel onglet",
  ReassignMultipleStatus: "Réaffecter plusieurs statuts",
  AddMultipleStatus: "Ajouter plusieurs statuts",
  EditMultipleStatus: "Modifier plusieurs statuts",
  EditMultipleStatusAdmin: "Modifier plusieurs statuts (administrateur)",
  AddStatus: "Ajouter un statut",
  SendMessage: "Envoyer le message",
  SendNotification: "Envoyer une notification",
  AddShippingOrTrailer: "Ajouter une expédition ou une remorque",
  AddStatusAdmin: "Ajouter un statut (administrateur)",
  ReassignMultipleStatusAdmin: "Réattribuer plusieurs statuts (administrateur)",


  driver: "Conducteur",
  vehicle: "Véhicule",
  trailer: "Bande-annonce",
  email: "E-mail",
  startTime: "Heure de début",
  carrier: "Transporteur",
  terminal: "Terminal d'accueil",
  shipping: "Expédition",
  app: "Application",
  cycle: "Faire du vélo",
  coDriver: "Copilote",
  distance: "Distance",
  engineHr: "Heure moteur",
  device: "Appareil",
  allLogs: "Tous les journaux",
  state:"État",
  action:"Action",
  odometerMi:"Odomètre (Mi)",
  dvir:"Dvir",



  
  editAdmin: "Modifier l'administrateur",
  DeactivateAdmin: "Désactiver l'administrateur",
  DuplicateAdmin: "Administrateur en double",

  time: "Temps",
  status: "Statut",
  Location: "Emplacement",
  Odometer: "Odomètre",
  EngineHours: "Heures de moteur",
  Origin: "Origine",
  Annotation: "Annotation",
  days: "Jours",
  hours: "Heures",
  minutes: "Minutes",
  seconds: "Seconds",
  odometer: "Odomètre",
  subtract: "Soustraire",
  add: "Ajouter",
  edit: "Modifier",
  deactivate: "Désactiver",
  reason:"Raison",
  details:"Détails",
  notes:"Remarques",
  selectDriver:"Sélectionnez le pilote",
  required:"Requis",
  note:"Note",
  find:"TROUVER",
  log:"Enregistrer",
  admin:"Administrateur",
  adminEvents:"Événements d'administration",


  DATE:"DATE",
  START_TIME:"HEURE DE DÉBUT",
  CYCLE:"FAIRE DU VÉLO",
  DRIVER:"CONDUCTEUR",
  CARRIER:"TRANSPORTEUR",
  CO_DRIVERS:"CO PILOTES",
  VEHICLES:"VÉHICULES",
  HOMETERMINAL:"TERMINAL À DOMICILE",
  DISTANCE:"DISTANCE",
  TRAILERS:"BANDES ANNONCES",
  SHIPPING_DOCS:"DOCUMENTS D'EXPÉDITION",
  ENGINE_HOURS:"HEURES MOTEUR",
  EMAIL:"E-MAIL",
  


  

  eventTime:"Heure de l'évènement",
  CoordCode:"Code de coordination",
  latitude:"Latitude",
  longitude:"Longitude",
  reasonForEdit:"Raison de la modification",
  recordOrigin:"Origine de l'enregistrement",
  recordStatus:"Statut de l'enregistrement",
  eventType:"Type d'événement",
  subType:"Sous-type",
  sequenceId:"ID de séquence",
  shippingDoc:"Document d'expédition",
  accumulatedHours:"Heures accumulées",
  accumulatedMiles:"Miles accumulés",




  manageDrivers:"Gérer les pilotes",
  save:"SAUVEGARDER",
  allDrivers:"Tous les pilotes",
  saving:"Économie...",
  close:"FERMER",
  cancel:"ANNULER",
  assignDriver:"Attribuer un chauffeur",

  editMultipleStatus:"MODIFIER PLUSIEURS STATUTS",
  addMultipleLogs:"AJOUTER PLUSIEURS JOURNAUX",

  drActionReqMsg: "Action du conducteur requise",
  drConfirmReqMsg:
    "Veuillez confirmer auprès du chauffeur pour accepter ou rejeter la demande.",
  automaticDrMsg: "La conduite automatique ne peut pas être modifiée.",
  successMessage: "Enregistré avec succès",
  errorMessage: "Quelque chose s'est mal passé, veuillez réessayer plus tard.",
  automaticDriveTimeError:
    "Vérifiez l’heure de l’événement. Vous ne pouvez pas ajouter de statut après le lecteur automatique.",

    editsuccessMessage:"La demande de modification a été envoyée avec succès",

    No_logingFound_for:"Aucune connexion trouvée pourr",
    For_selected_date:"pour la date sélectionnée.",
    noDutyStatusFound:"Aucun statut de service trouvé.",
    errorMasage_selectCo_driverTOReassignLogs:"Sélectionnez le copilote pour réaffecter les journaux",

   updateTrailerMassage_shippingDocs_application:"La remorque ou le document d'expédition mis à jour ne sera applicable qu'aux statuts futurs et non aux statuts passés."

};
