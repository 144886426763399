export const LABELS = {
  map: "Carte",
  table: "Tableau",
  search: "Recherche",
  name: "Nom",
  status: "Statut",
  location: "Emplacement",
  vehicle: "Véhicule",
  break: "Casser",
  drive: "Conduire",
  shift: "Changement",
  cycle: "Faire du vélo",
  violations: "Violations",
  eld: "ELD",
  update: "Mise à jour",
  app: "Application",
};
