import React from "react";
// import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
// import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 1,
  },
  // search: {
  //   position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  //   marginLeft: 0,
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(1),
  //     width: "auto",
  //   },
  // },




}));

export default function SearchAppBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <Toolbar>
      <Typography className={classes.title} noWrap />
      <FormControl className={classes.search}>
        <TextField style={{width:"20rem",}}
          size="small"
          variant="standard"
          placeholder={`${LABELS.search}...`}
          onChange={props.onChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),

          }}
        />
      </FormControl>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={props.handleOpen}
      >
        {LABELS.add}
      </Button>
    </Toolbar>
  </div>
  );
}
