import React, { Component } from "react";
import * as d3 from "d3";
import * as constants from "./constants";

class LogChart extends Component {
    componentDidMount() {
        this.drawChart();
    }

    drawChart() {
        const border = 1;
        const borderColor = "#bdbdbd";
        const width = (24 * 60) / 2;
        const height = 25 * 4;
        const prefix = 35;
        const textPrefix = 30;
        const topLabels = constants.topLabels;
        const leftLabels = constants.leftLabels;

        //Make an SVG Container
        const mainContainer = d3
            .select(this.props.containerId)
            .append("svg")
            .attr("width", width + 110)
            .attr("height", height + 60);

        const svgContainer = mainContainer.append("g");
        svgContainer
            .append("rect")
            .attr("x", prefix)
            .attr("y", prefix)
            .attr("width", width)
            .attr("height", height)
            .style("stroke", borderColor)
            .style("fill", "none")
            .style("stroke-width", border);

        const topLabelsGroup = svgContainer.append("g");
        for (let i = 0; i < topLabels.length; i++) {
            const text = topLabels[i];
            topLabelsGroup
                .append("text")
                .attr("dx", textPrefix + 30 * i)
                .attr("dy", "30px")
                .text(text)
                .attr("font-weight", "600")
                .attr("font-size", "12px");
        }

        const leftLabelsGroup = svgContainer.append("g");
        for (let i = 0; i < leftLabels.length; i++) {
            const text = leftLabels[i];
            leftLabelsGroup
                .append("text")
                .attr("dx", "1")
                .attr("dy", 60 + 26 * i)
                .text(text)
                .attr("font-weight", "600")
                .attr("font-size", "12px");
        }

        const boxGroup = svgContainer.append("g");
        for (let i = 0; i < 24; i++) {
            const left = prefix + i * 30;
            for (let j = 0; j < 4; j++) {
                const row = prefix + 25 * j;
                const rowWidth = width / 24;
                const rowHeight = height / 4;

                boxGroup
                    .append("rect")
                    .attr("x", left)
                    .attr("y", row)
                    .attr("width", rowWidth)
                    .attr("height", rowHeight)
                    .style("stroke", borderColor)
                    .style("fill", "none")
                    .style("stroke-width", border);

                const firstLineX1 = 42 + 30 * i;
                const secondLineX1 = 49 + 30 * i;
                const thirdLineX1 = 57 + 30 * i;

                // First row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 35) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 43); // y position of the second end of the line

                // First row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 35) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 48); // y position of the second end of the line

                // First row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 35) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 43); // y position of the second end of the line

                // Second row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 60) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 68); // y position of the second end of the line

                // Second row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 60) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 73); // y position of the second end of the line

                // First row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 60) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 68); // y position of the second end of the line

                // third row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 102) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 110); // y position of the second end of the line

                // third row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 96) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 110); // y position of the second end of the line

                // third row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 102) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 110); // y position of the second end of the line

                // forth row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 128) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 136); // y position of the second end of the line

                // forth row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 125) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 136); // y position of the second end of the line

                // forth row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 128) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 136); // y position of the second end of the line

                const rightLabelsGroup = svgContainer.append("g");
                rightLabelsGroup
                    .append("text")
                    .attr("dx", "760")
                    .attr("dy", "53px")
                    .text("00:00")
                    .attr("font-weight", "600")
                    .attr("font-size", "12px");
                rightLabelsGroup
                    .append("text")
                    .attr("dx", "760")
                    .attr("dy", "77px")
                    .text("00:00")
                    .attr("font-weight", "600")
                    .attr("font-size", "12px");
                rightLabelsGroup
                    .append("text")
                    .attr("dx", "760")
                    .attr("dy", "101px")
                    .text("00:00")
                    .attr("font-weight", "600")
                    .attr("font-size", "12px");
                rightLabelsGroup
                    .append("text")
                    .attr("dx", "760")
                    .attr("dy", "126px")
                    .text("00:00")
                    .attr("font-weight", "600")
                    .attr("font-size", "12px");
            }
        }
    }

    render() {
        const containerId = this.props.containerId;
        return (
            <div
                style={{
                    margin: "5px auto",
                    textAlign: "center",
                    fontWeight: 200
                }}
                id={`#${containerId}`}
            />
        );
    }
}

export class EmptyLogGrid extends React.Component {
    state = {
        open: false
    };

    componentDidMount() {}

    render() {
        return (
            <LogChart
                driverLogs={this.props.driverLogs}
                containerId={this.props.containerId}
            />
        );
    }
}

export default EmptyLogGrid;
