import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
// import { CircularProgress } from "@mui/material";
import Button from '@mui/material/Button';
import { Formik, Form } from "formik";
import validate from "./validate";
import Grid from '@mui/material/Grid';
import InputBox from "../../../../components/InputBox";
import SelectBox from "../../../../components/SelectBox";
import Error from "../../../Error";
import Success from "../../../Success";
import { createApiKey, updateApiKey } from "../../../../actions/apiKeyActions";
import { addYears, formatDateTimeUtc } from "../../../../actions/momentActions";
// import InputDateBox from "../../../../components/InputDateBox";
import {
    API_STATUS,
} from "../../../../constants";
import { getInitialValues } from "./helpers";
import {LABELS as _LABELS} from "../../../../language";

const LABELS=_LABELS.settingPage;

const styles = theme => ({
    wrapper: {},
    buttons: {
        marginTop: 20,
        textAlign: "right"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class ApiForm extends React.Component {
    state = {
        errorMessage: "",
        successMessage: ""
    };

    // async componentDidMount() {
    //     this.setState({ loading: true })
    //     await this.props.getCarriers();
    //     await this.props.getTerminals();
    //     await this.getEquipments();

    //     this.setState({ loading: false })
    // }

    handleSubmit = async values => {
        this.setState({ loading: true });
        let response;
        if (this.props.isEdit) {
            const id = _get(this.props, "selected._id", "");
            let editPayload = {
                name: values.name,
                scopes: values.scopes.toUpperCase(),
                // expiry: this.props.formatDateTimeUtc(values.expiry),
                active: values.active,
                id
            };

            response = await this.props.updateApiKey(editPayload);
        } else {
            let payload = {
                name: values.name,
                scopes: values.scopes.toUpperCase(),
                // expiry: values.expiry,
                active: values.active
            }
            response = await this.props.createApiKey(payload);
        }

        if (response && response._id) {
            this.setState({ successMessage: "Api Key is added successfully" });
            setTimeout(() => {
                this.props.handleClose();
            }, 1000);
        } else if (response && response.status === 400) {
            this.setState({ errorMessage: response.message });
        }
        else if (response && response.message) {
            this.setState({ errorMessage: response.message });
        }
        else {
            if (typeof response !== String) {
                response = "Something went wrong, please try after time.";
            }
            this.setState({ errorMessage: response });
        }
        this.setState({ loading: false });
    };


    render() {
        const { classes,
            // constants, isEdit 
        } = this.props;
        const { errorMessage, successMessage, loading } = this.state;
        const initialValues = getInitialValues(this.props, addYears);

        return (
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={this.handleSubmit}
                enableReinitialize={true}
            >
                {({ values = {}, handleSubmit, setFieldValue, errors }) => {

                    return (
                        <Form style={{ padding: "10px 10px" }}>
                            <Grid container spacing={3}>

                                <InputBox md={4} name="name" label={LABELS.name}/>

                                <SelectBox
                                    md={4}
                                    name="active"
                                    label={LABELS.status}
                                    items={API_STATUS}
                                />
                                <InputBox md={4} name="scopes" label={LABELS.scopes} />

                                {/* <InputDateBox
                                    md={2}
                                    name="expiry"
                                    label="Expiry"
                                /> */}

                            </Grid>
                            {successMessage && (
                                <Success message={successMessage} />
                            )}
                            {errorMessage && <Error message={errorMessage} />}
                            <div className={classes.buttons}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {/* {loading ? "Saving..." : "SAVE"} */}
                                    {loading ? `${LABELS.saving}` :`${LABELS.save}`}
                                </Button>{" "}
                                <Button
                                    variant="contained"
                                    onClick={this.props.handleClose}
                                >
                                    {LABELS.close}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

const mapStateToProps = state => {
    return {
        constants: _get(state, "appData.constants", {}),
        isAdmin: !!_get(state, "authData.profile.adminId")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createApiKey,
            updateApiKey
            , formatDateTimeUtc,
            addYears
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ApiForm)
    )
);
