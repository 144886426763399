import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import { withStyles } from '@mui/styles';
import PeopleIcon from "@mui/icons-material/People";
import Button from '@mui/material/Button';

const reportTabs = {
  baseUrl: "/dashboard",
  tabs: [
    {
      id: "Fmcsa",
      label: "Fmcsa",
      icon: <PeopleIcon />,
      active: true,
      url: "/reports/fmcsa/create",
    },
    {
      id: "State Mileage",
      label: "State Mileage",
      icon: <PeopleIcon />,
      active: true,
      url: "/reports/state-mileage",
    },
    {
      id: "Live Share",
      label: "Live Share",
      icon: <PeopleIcon />,
      active: true,
      url: "/reports/live-share",
    },
    {
      id: "Documents",
      label: "Documents",
      icon: <PeopleIcon />,
      active: true,
      url: "/reports/document",
    },
  ],
};

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  button: {
    margin: theme.spacing(1),
    // color: "white",
    // paddingBottom: 2,
    // fontSize: "0.875rem"
    // borderRadius: 0,
    // borderBottom: "3px solid trabsparent",
  },
  active: {
    // borderBottom: "3px solid white",
    // fontSize: "1rem",
    // paddingBottom: 0
  },
});

class UserNavigator extends React.Component {
  handleClick = (url) => {
    this.props.history.push(url);
  };

  render() {
    const { classes, accountId, settings } = this.props;
    const baseUrl = reportTabs.baseUrl;

    return (
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="transparent"
        position="static"
        elevation={0}
      >
        <div>
          {reportTabs.tabs.map(({ id, label, url }) => {
            if (accountId) {
              url = `${baseUrl}/${accountId}${url}`;
            }

            // let buttonColor = "default";

            if (url === _get(this, "props.location.pathname")) {
              // buttonColor = "primary";
            }

            if (id === "State Mileage" && !settings.isIfta) {
              return null;
            }
            if (id === "Live Share" && !settings.isShare) {
              return null;
            }
            if (id === "Documents" && !settings.isDoc) {
              return null;
            }

            return (
              <Button
                key={id}
                className={classes.button}
                onClick={() => this.handleClick(url)}
              >
                {label}
              </Button>
            );
          })}
        </div>
      </AppBar>
    );
  }
}

UserNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserNavigator));
