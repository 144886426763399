export const LABELS = {
  refresh: "ਤਾਜ਼ਾ ਕਰੋ",
  recap: "ਰੀਕੈਪ",
  date: "ਤਾਰੀਖ਼",
  newTab: "ਨਵੀਂ ਟੈਬ",
  download: "ਡਾਊਨਲੋਡ ਕਰੋ",
  Audit: "ਆਡਿਟ",
  AuditEvent:"ਆਡਿਟ ਇਵੈਂਟਸ",
  openInNewTab:"ਨਵੀਂ ਟੈਬ ਵਿੱਚ ਖੋਲ੍ਹੋ",
  ReassignMultipleStatus: "ਇੱਕ ਤੋਂ ਵੱਧ ਸਥਿਤੀਆਂ ਨੂੰ ਮੁੜ-ਸਾਈਨ ਕਰੋ",
  AddMultipleStatus: "ਇੱਕ ਤੋਂ ਵੱਧ ਸਥਿਤੀ ਸ਼ਾਮਲ ਕਰੋ",
  EditMultipleStatus: "ਕਈ ਸਥਿਤੀਆਂ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  EditMultipleStatusAdmin: "ਕਈ ਸਥਿਤੀਆਂ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰੋ (ਪ੍ਰਬੰਧਕ)",
  AddStatus: "ਸਥਿਤੀ ਸ਼ਾਮਲ ਕਰੋ",
  SendMessage: "ਸੁਨੇਹਾ ਭੇਜੋ",
  SendNotification: "ਸੂਚਨਾ ਭੇਜੋ",
  AddShippingOrTrailer: "ਸ਼ਿਪਿੰਗ ਜਾਂ ਟ੍ਰੇਲਰ ਸ਼ਾਮਲ ਕਰੋ",
  AddStatusAdmin: "ਸਥਿਤੀ ਸ਼ਾਮਲ ਕਰੋ (ਪ੍ਰਬੰਧਕ)",
  ReassignMultipleStatusAdmin: "ਕਈ ਸਥਿਤੀਆਂ ਨੂੰ ਮੁੜ-ਸਪੁਰਦ ਕਰੋ (ਪ੍ਰਬੰਧਕ)",

  
  driver: "ਡਰਾਈਵਰ",
  vehicle: "ਵਾਹਨ",
  trailer: "ਟ੍ਰੇਲਰ",
  email: "ਈ - ਮੇਲ",
  startTime: "ਸ਼ੁਰੂਆਤੀ ਸਮਾਂ",
  carrier: "ਕੈਰੀਅਰ",
  terminal: "ਹੋਮ ਟਰਮੀਨਲ",
  shipping: "ਸ਼ਿਪਿੰਗ",
  app: "ਐਪ",
  cycle: "ਚੱਕਰ",
  coDriver: "ਸਹਿ ਡਰਾਈਵਰ",
  distance: "ਦੂਰੀ",
  engineHr: "ਇੰਜਣ ਐਚ.ਆਰ",
  device: "ਡਿਵਾਈਸ",
  allLogs: "ਸਾਰੇ ਲੌਗਸ",
  state:"ਰਾਜ",
  action:"ਕਾਰਵਾਈ",
  odometerMi:"ਓਡੋਮੀਟਰ(Mi)",



  
  editAdmin: "ਐਡਮਿਨ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  DeactivateAdmin: "ਐਡਮਿਨ ਨੂੰ ਅਕਿਰਿਆਸ਼ੀਲ ਕਰੋ",
  DuplicateAdmin: "ਡੁਪਲੀਕੇਟ ਐਡਮਿਨ",

  time: "ਸਮਾਂ",
  status: "ਸਥਿਤੀ",
  Location: "ਟਿਕਾਣਾ",
  Odometer: "ਓਡੋਮੀਟਰ",
  EngineHours: "ਇੰਜਣ ਦੇ ਘੰਟੇ",
  Origin: "ਮੂਲ",
  Annotation: "ਐਨੋਟੇਸ਼ਨ",
  days: "ਦਿਨ",
  hours: "ਘੰਟੇ",
  minutes: "ਮਿੰਟ",
  seconds: "ਸਕਿੰਟ",
  odometer: "ਓਡੋਮੀਟਰ",
  subtract: "ਘਟਾਓ",
  add: "ਸ਼ਾਮਲ ਕਰੋ",
  edit: "ਸੰਪਾਦਿਤ ਕਰੋ",
  deactivate: "ਅਕਿਰਿਆਸ਼ੀਲ ਕਰੋ",
  reason:"ਕਾਰਨ",
  details:"ਵੇਰਵੇ",
  notes:"ਨੋਟਸ",
  selectDriver:"ਡਰਾਈਵਰ ਚੁਣੋ",
  required:"ਲੋੜੀਂਦਾ ਹੈ",
  note:"ਨੋਟ ਕਰੋ",
  find:"ਲੱਭੋ",
  log:"ਲਾਗ",
  admin:"ਐਡਮਿਨ",
  adminEvents:"ਐਡਮਿਨ ਇਵੈਂਟਸ",
  dvir:"Dvir",


  DATE:"ਤਾਰੀਖ਼",
  START_TIME:"ਸਮਾਂ ਸ਼ੁਰੂ ਕਰੋ",
  CYCLE:"ਚੱਕਰ",
  DRIVER:"ਡਰਾਈਵਰ",
  CARRIER:"ਕੈਰੀਅਰ",
  CO_DRIVERS:"CO ਡਰਾਈਵਰ",
  VEHICLES:"ਵਾਹਨ",
  HOMETERMINAL:"ਹੋਮ ਟਰਮੀਨਲ",
  DISTANCE:"ਦੂਰੀ",
  TRAILERS:"ਟ੍ਰੇਲਰ",
  SHIPPING_DOCS:"ਸ਼ਿਪਿੰਗ ਡੌਕਸ",
  ENGINE_HOURS:"ਇੰਜਣ ਦੇ ਘੰਟੇ",
  EMAIL:"ਈ - ਮੇਲ",
  


  

  eventTime:"ਇਵੈਂਟ ਸਮਾਂ",
  CoordCode:"ਕੋਰਡ ਕੋਡ",
  latitude:"ਵਿਥਕਾਰ",
  longitude:"ਲੰਬਕਾਰ",
  reasonForEdit:"ਸੰਪਾਦਨ ਲਈ ਕਾਰਨ",
  recordOrigin:"ਰਿਕਾਰਡ ਮੂਲ",
  recordStatus:"ਰਿਕਾਰਡ ਸਥਿਤੀ",
  eventType:"ਘਟਨਾ ਦੀ ਕਿਸਮ",
  subType:"ਉਪ ਕਿਸਮ",
  sequenceId:"ਕ੍ਰਮ ਆਈ.ਡੀ",
  shippingDoc:"ਸ਼ਿਪਿੰਗ ਦਸਤਾਵੇਜ਼",
  accumulatedHours:"ਇਕੱਠੇ ਕੀਤੇ ਘੰਟੇ",
  accumulatedMiles:"ਇਕੱਠੇ ਕੀਤੇ ਮੀਲ",




  manageDrivers:"ਡਰਾਈਵਰਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ",
  save:"ਸੇਵ ਕਰੋ",
  allDrivers:"ਸਾਰੇ ਡਰਾਈਵਰ",
  saving:"ਰੱਖਿਅਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  close:"ਬੰਦ ਕਰੋ",
  cancel:"ਰੱਦ ਕਰੋ",
  assignDriver:"ਡਰਾਈਵਰ ਨੂੰ ਸੌਂਪੋ",

  editMultipleStatus:"ਕਈ ਸਥਿਤੀਆਂ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  addMultipleLogs:"ਇੱਕ ਤੋਂ ਵੱਧ ਲੌਗਸ ਸ਼ਾਮਲ ਕਰੋ",

  drActionReqMsg: "ਡਰਾਈਵਰ ਕਾਰਵਾਈ ਦੀ ਲੋੜ ਹੈ",
  drConfirmReqMsg:
    "ਕਿਰਪਾ ਕਰਕੇ ਬੇਨਤੀ ਨੂੰ ਸਵੀਕਾਰ ਜਾਂ ਅਸਵੀਕਾਰ ਕਰਨ ਲਈ ਡਰਾਈਵਰ ਨਾਲ ਪੁਸ਼ਟੀ ਕਰੋ।",
  automaticDrMsg: "ਆਟੋਮੈਟਿਕ ਡਰਾਈਵ ਨੂੰ ਸੋਧਿਆ ਨਹੀਂ ਜਾ ਸਕਦਾ ਹੈ।",
  successMessage: "ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ",
  errorMessage: "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
  automaticDriveTimeError:
    "ਘਟਨਾ ਦੇ ਸਮੇਂ ਦੀ ਜਾਂਚ ਕਰੋ. ਤੁਸੀਂ ਆਟੋਮੈਟਿਕ ਡਰਾਈਵ ਤੋਂ ਬਾਅਦ ਸਥਿਤੀ ਨੂੰ ਸ਼ਾਮਲ ਨਹੀਂ ਕਰ ਸਕਦੇ ਹੋ।",

    editsuccessMessage:"ਸੰਪਾਦਨ ਬੇਨਤੀ ਸਫਲਤਾਪੂਰਵਕ ਭੇਜੀ ਗਈ ਹੈ",

    No_logingFound_for:"ਲਈ ਕੋਈ ਲੌਗਇਨ ਨਹੀਂ ਮਿਲਿਆ",
    For_selected_date:"ਚੁਣੀ ਮਿਤੀ ਲਈ.",
    noDutyStatusFound:"ਕੋਈ ਡਿਊਟੀ ਸਥਿਤੀ ਨਹੀਂ ਮਿਲੀ।",
    errorMasage_selectCo_driverTOReassignLogs:"ਲੌਗਾਂ ਨੂੰ ਮੁੜ ਅਸਾਈਨ ਕਰਨ ਲਈ ਸਹਿ-ਡਰਾਈਵਰ ਚੁਣੋ",

   updateTrailerMassage_shippingDocs_application:"ਅੱਪਡੇਟ ਕੀਤਾ ਟ੍ਰੇਲਰ ਜਾਂ ਸ਼ਿਪਿੰਗ ਦਸਤਾਵੇਜ਼ ਸਿਰਫ਼ ਭਵਿੱਖ ਦੀ ਸਥਿਤੀ 'ਤੇ ਲਾਗੂ ਹੋਵੇਗਾ, ਨਾ ਕਿ ਕਿਸੇ ਪਿਛਲੀ ਸਥਿਤੀ 'ਤੇ।"

};
