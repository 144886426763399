import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { getCarriers } from "../../../actions/carrierActions";
import {
    getEquipments,
    setAssetType,
    selectedEquipment,
    getEquipmentsCount
} from "../../../actions/equipmentActions";
import { getTrailers, getTrailersCount } from "../../../actions/trailerActions";
import { formatDate } from "../../../actions/momentActions";
import AddEquipmentForm from "./AddEquipmentForm";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EquipmentTable from "./EquipmentTable";
// import SearchInput from "../../../components/SearchInput";
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';
import Trailertable from "../Trailer/TrailerTable";
import AddTrailerForm from "../Trailer/AddTrailerForm";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const styles = theme => ({
    addButtonWrapper: {
        width: "99%",
        textAlign: "right",
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100px",
        textAlign: "left"
    },
    secondaryBar: {
        zIndex: 0
    },
    button: {
        margin: theme.spacing(1)
        // color: "white"
    },
    grow: {
        flexGrow: 1,
        // textAlign: "center",
        float: "left",
        marginTop: "10px",
        marginBottom: "10px"
    },
    loader: {
        flexGrow: 1,
        textAlign: "center",
        marginTop: "20px"
    }
});

export class Equipment extends React.Component {
    state = {
        selTab: 0,
        open: false,
        page: 0,
        limit: 10,
        searching: false,
        showAll: false,
        query: ""
    };

    async componentDidMount() {
        await this.getEquipments();
        await this.getEquipmentsCount();
        await this.props.getCarriers();
    }

    getEquipmentsCount = async () => {
        const { selTab } = this.state;
        const payload = {
            assetType: selTab === 0 ? "VEHICLE" : "TRAILER"
        };
        await this.props.getEquipmentsCount(payload);
    };

    getEquipments = async () => {
        const { page, limit, selTab, query, showAll } = this.state;
        const skip = parseInt(page) * parseInt(limit);
        const assetType = selTab === 0 ? "VEHICLE" : "TRAILER";
        const payload = {
            skip,
            limit,
            query,
            assetType
        };
        if (!showAll) {
            payload.active = "true";
        }
        this.setState({ searching: true });
        await this.props.getEquipments(payload);
        this.setState({ searching: false });
    };

    getTrailers = async () => {
        const { page, limit, query, showAll } = this.state;
        const skip = parseInt(page) * parseInt(limit);
        const payload = {
            skip,
            limit,
            query
        };
        if (!showAll) {
            payload.active = "true";
        }
        this.setState({ searching: true });
        await this.props.getTrailers(payload);
        this.setState({ searching: false });
    };

    handleOpen = () => {
        const { selTab } = this.state;
        if (selTab === 1) {
            this.setState({
                openTrailer: true,
                isEditTrailer: false,
                trailer: {}
            });
        } else {
            let carrierId = this.props.carrierId;
            let carriers = this.props.carriers || [];

            if (!carrierId && carriers && carriers[0]) {
                carrierId = carriers[0].id;
            }

            this.props.selectedEquipment({
                carrierId,
                active: true,
                vbusConnection: true,
                assetType: "VEHICLE",
                dvir: "TractorTrailer",
                regulationMode: "ELD",
                license: { country: "US" },
                settings: {
                    codes: true,
                    ifta: true
                }
            });
            this.setState({ open: true, isEdit: false });
        }
    };

    handleClose = async () => {
        this.setState({ open: false, query: "" });
        await this.getEquipmentsCount();
        await this.getEquipments();
    };

    handleEdit = equipment => {
        this.props.selectedEquipment(equipment);
        this.setState({ open: true, isEdit: true });
    };

    handleEqpChange = (event, selTab) => {
        this.setState({ selTab, page: 0, limit: 10, query: "" }, () => {
            if (selTab === 1) {
                this.props.getTrailersCount();
                this.getTrailers();
            } else {
                this.getEquipmentsCount();
                this.getEquipments();
            }
        });
    };

    handleChange = ({ limit, page, order }) => {
        this.setState({ limit, page, order, query: "" }, this.getEquipments);
    };

    handleSaveTrailer = async () => {
        this.setState({ openTrailer: false, query: "" });
        await this.props.getTrailersCount();
        await this.getTrailers();
    };

    handleEditTrailer = async trailer => {
        this.setState({ openTrailer: true, isEditTrailer: true, trailer });
    };

    handleCloseTrailer = async () => {
        this.setState({ openTrailer: false, query: "" });
    };

    handleChangeTrailer = ({ limit, page, order }) => {
        this.setState({ limit, page, order, query: "" }, this.getTrailers);
    };

    // onSearch = query => {
    //     const { page, limit, order, selTab } = this.state;
    //     if (selTab === 0) {
    //         this.setState({ limit, page, order, query }, this.getEquipments);
    //     } else {
    //         this.setState({ limit, page, order, query }, this.getTrailers);
    //     }
    // };

    onSearch = query => {
        const { page, limit, order, selTab } = this.state;
        if (query.trim() === "") {
            // If query is empty, reset it to empty string and trigger search for all items
            this.setState({ limit, page, order, query: "" }, () => {
                if (selTab === 0) {
                    this.getEquipments();
                } else {
                    this.getTrailers();
                }
            });
        } else {
            // If there's a query, perform search as usual
            if (selTab === 0) {
                this.setState({ limit, page, order, query }, this.getEquipments);
            } else {
                this.setState({ limit, page, order, query }, this.getTrailers);
            }
        }
    };
    switchChange = () => {
        const { showAll, selTab } = this.state;

        if (selTab === 0) {
            this.setState({ showAll: !showAll }, () => {
                this.getEquipments();
            });
        } else {
            this.setState({ showAll: !showAll }, () => {
                this.getTrailers();
            });
        }
    };

    render() {
        const {
            classes,
            equipmentsCount,
            trailers,
            trailersCount,
            adminKey,
            isViewOnly
        } = this.props;
        const {
            open,
            isEdit,
            selTab,
            assetType,
            searching,
            showAll,
            openTrailer,
            isEditTrailer,
            trailer,
            query,
        } = this.state;
        const { limit, page} = this.state;
        const isEld = assetType === "VEHICLE";

        return (
            <div>
                <AppBar color="default" position="static">
                    <Toolbar>
                        <div className={classes.grow}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showAll}
                                        onChange={this.switchChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={LABELS.showall}
                            />
                        </div>
                        <Tabs
                            value={selTab}
                            onChange={this.handleEqpChange}
                            aria-label="equipment tabs"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label={LABELS.vehicle} {...a11yProps(0)} />
                            {/* <Tab label="TRAILER" {...a11yProps(1)} /> */}
                            <Tab label={LABELS.trailer} {...a11yProps(2)} />
                        </Tabs>
                        <div className={classes.loader} />
                        {/* <SearchInput onChange={this.onSearch} query={query} /> */}
                        <FormControl className={classes.search}>
                            <TextField style={{ width: "15rem",marginRight:"2rem" }}
                                size="small"
                                variant="standard"
                                placeholder={LABELS.search}
                                onChange={(e) => this.onSearch(e.target.value)} // Modified to pass value directly
                                value={query}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),

                                }}
                            />
                        </FormControl>
                        {!isViewOnly && <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleOpen}
                        >
                            {LABELS.add}
                        </Button>}
                    </Toolbar>
                </AppBar>

                {searching ? (
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {open && selTab === 0 ? (
                            <AddEquipmentForm
                                open={open}
                                isEdit={isEdit}
                                handleClose={this.handleClose}
                                adminKey={adminKey}
                            />
                        ) : (
                            openTrailer && (
                                <AddTrailerForm
                                    open={openTrailer}
                                    isEdit={isEditTrailer}
                                    trailer={trailer}
                                    handleClose={this.handleCloseTrailer}
                                    handleSave={this.handleSaveTrailer}
                                />
                            )
                        )}

                        {selTab === 1 ? (
                            <Trailertable
                                trailerCount={trailersCount}
                                items={trailers}
                                formatDate={this.props.formatDate}
                                rowsPerPage={this.props.rowsPerPage}
                                handleEdit={this.handleEditTrailer}
                                handleChange={this.handleChangeTrailer}
                                limit={limit}
                                page={page}
                                showAll={showAll}
                                isViewOnly={isViewOnly}
                            />
                        ) : (
                            <EquipmentTable
                                eld={isEld}
                                showAll={showAll}
                                adminKey={this.props.adminKey}
                                equipmentsCount={equipmentsCount}
                                items={this.props.equipments}
                                formatDate={this.props.formatDate}
                                rowsPerPage={this.props.rowsPerPage}
                                handleEdit={this.handleEdit}
                                handleChange={this.handleChange}
                                limit={limit}
                                page={page}
                                isViewOnly={isViewOnly}
                            />
                        )}
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        assetType: _get(state, "equipmentData.assetType"),
        carrierBusy: _get(state, "carrierData.carrierBusy", false),
        equipmentBusy: _get(state, "equipmentData.equipmentBusy", false),

        equipments: _get(state, "equipmentData.equipments", []),
        equipmentsCount: _get(state, "equipmentData.equipmentsCount", 0),

        carriers: _get(state, "carrierData.carriers", []),
        carrierId: _get(state, "authData.profile.account.carrierId"),
        trailers: _get(state, "trailerData.trailers", []),
        trailersCount: _get(state, "trailerData.trailersCount"),

        pagination: _get(state, "equipmentData.pagination"),
        adminKey: _get(state, "authData.profile.adminId"),
        isViewOnly: _get(state, "authData.isViewOnly", false),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCarriers,
            setAssetType,
            formatDate,
            getEquipments,
            selectedEquipment,
            getEquipmentsCount,
            getTrailers,
            getTrailersCount
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Equipment)
);
