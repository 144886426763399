export const LABELS = {
  viewLogs: "Regardes les connexions",
  date: "Date",
  detailsMulti: "Multi",
  certified: "Agréé",
  uncertified: "Non certifié",
  detail: "Détail",
  violation: "Violation",
  driving: "Conduite",
  offDuty: "En congé",
  onDutyND: "En serviceND",
  sleeper: "Dormeur",

  deleteLabel: "Supprimer les violations",
  deleteMsg: "Êtes-vous sûr de vouloir supprimer toutes les violations",
};
