import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./adminSupportTypes";

export const setAdminSupport = (value) => ({
  type: TYPES.ADMIN_SUPPORT,
  value,
});

export const setAdminShipping = (value) => ({
  type: TYPES.ADMIN_SHIPPING,
  value,
});

export const setAdminQueueCount = (value) => ({
  type: TYPES.ADMIN_QUEUE_COUNT,
  value,
});

export function getAdminSupportCount(params = {}) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    try {
      const _response = await axios({
        url: `${BASE_URL}/admin/support/count`,
        method: "get",
        params,
      });
      return _response && _response.data;
    } catch (e) {
      return null;
    }
  };
}

export function getAdminSupport(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/support`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createAdminSupport(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/support`,
      method: "post",
      params: { searchPath: params.searchPath },
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateAdminSupport(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/support`,
      method: "put",
      params: { searchPath: params.searchPath },
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAdminShippingCount(params = {}) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    try {
      const _response = await axios({
        url: `${BASE_URL}/admin/shipping/count`,
        method: "get",
        params,
      });
      return _response && _response.data;
    } catch (e) {
      return null;
    }
  };
}

export function getAdminShipping(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/shipping`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createAdminShipping(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/shipping`,
      method: "post",
      params: { searchPath: params.searchPath },
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateAdminShipping(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/shipping`,
      method: "put",
      params: { searchPath: params.searchPath },
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAdminWaitlistCount(params = {}) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    try {
      const _response = await axios({
        url: `${BASE_URL}/admin/waitlist/count`,
        method: "get",
        params,
      });
      return _response && _response.data;
    } catch (e) {
      return null;
    }
  };
}

export function getAdminWaitlist(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/waitlist`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createAdminWaitlist(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/waitlist`,
      method: "post",
      params: { searchPath: params.searchPath },
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateAdminWaitlist(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/waitlist`,
      method: "put",
      params: { searchPath: params.searchPath },
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAdminSupportQueueCount(params = {}) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    try {
      const response = await axios({
        url: `${BASE_URL}/admin/queue/count`,
        method: "get",
        params,
      });
      const _response = _get(response, "data");
      dispatch(setAdminQueueCount(_response.count));

      return _response;
    } catch (e) {
      return null;
    }
  };
}

export function getAdminSupportQueue(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/queue`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createAdminSupportQueue(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/queue`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateAdminSupportQueue(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/queue`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
