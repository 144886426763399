import _get from "lodash/get";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Formik, Form } from "formik";
// import { validate, defaultValues } from "./validate";
import Grid from "@mui/material/Grid";
// import InputBox from "../../components/InputBox";
// import InputDateBox from "../../components/InputDateBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
// import { getAllUsers } from "../../actions/adminDashActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
// import AdminAccountSearch from "../../components/AdminAccountSearch";
import {LABELS as _LABELS} from "../../language"

const LABELS = _LABELS.adminPage;

const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: 25,
    textAlign: "center",
  },
});

export function SearchAppBar(props) {
  const [accounts, setAccounts] = useState([]);
  // const classes = props.classes;
  const constants = props.constants;
  const { errorMessage, successMessage, loading } = {};

  let initialValues = {};

  function validate() {}

  function handleSubmit(values) {
    props.onSearch(values);
  }

  async function _getAccounts() {
    try {
      const _accounts = await props.getAccounts({});
      setAccounts(_accounts);
    } catch (e) {
      console.log(e);
    }
  }

  const [visible, setVisible] = useState(true);
  function forceRerender() {
    setVisible(false);
    setTimeout(function () {
      setVisible(true);
    }, 10);
  }

  useEffect(() => {
    _getAccounts();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ padding: "20px 10px" }}>
            <Grid container spacing={3}>
              <Grid item sm>
                <Typography variant="h5" component="h5">{LABELS.leads}</Typography>
              </Grid>
              <SelectBox
                md={3}
                name="status"
                label={LABELS.status}
                items={constants.LEAD_STATUS}
              />
              <Grid item sm xs={12} md={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {/* {loading ? "Saving..." : "Search"} */}
                  {loading ? LABELS.saving :LABELS.search}
                </Button>{" "}
                <Button
                  variant="contained"
                  onClick={() => {
                    setFieldValue("accountId", "");
                    setFieldValue("status", "");
                    setFieldValue("provider", "");
                    forceRerender();
                    props.onSearch({});
                  }}
                >
                  {LABELS.clear}
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.handleAddClick}
                >
                  {`${LABELS.new}`}
                </Button>{" "}
              </Grid>
            </Grid>

            {successMessage && <Success message={successMessage} />}
            {errorMessage && <Error message={errorMessage} />}
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    equipments: _get(state, "equipmentData.equipments", []),
    accounts: _get(state, "accountData.accounts", []),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccounts,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchAppBar)
);
