import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { Formik, Form,  FieldArray } from "formik";
import { getTimeZone } from "../../../actions/momentActions";
import { formatCurrency } from "../../../utils/numberHelpers";
import { createBillInvoice } from "../../../actions/adminBillActions";
import {
  getBillPrice,
  getBillTaxrate,
} from "../../../actions/adminBillActions";
// import validate from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import Error from "../../Error";
import Success from "../../Success";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddBillInvoice extends React.Component {
  state = {
    prices: [],
    taxRates: [],
    isPrice: false,
  };

  componentDidMount() {
    this.setUp();
  }

  setUp = async () => {
    if (!this.props.adminAccess) {
      return null;
    }
    try {
      const prices = await this.props.getBillPrice();
      const taxRates = await this.props.getBillTaxrate();

      if (prices && prices.data && Array.isArray(prices.data)) {
        const __prices = prices.data.filter((item) => !item.recurring);
        const _prices = __prices.map((item) => {
          const _name = item.product && item.product.name;
          const _amount = item.unit_amount
            ? parseFloat(item.unit_amount) / 100
            : 0;
          return {
            id: item.id,
            name: `${_name} (${formatCurrency(_amount)})`,
            detail: item,
          };
        });
        this.setState({ prices: _prices });
      }
      if (taxRates && taxRates.data && Array.isArray(taxRates.data)) {
        const _taxRates = taxRates.data.map((item) => {
          const percentage = item.effective_percentage || item.percentage;
          return {
            id: item.id,
            detail: item,
            name: `${item.display_name} (${percentage}%)`,
          };
        });
        this.setState({ taxRates: _taxRates });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    if (values.customerId && values.customerId.includes("SE:")) {
      values.customerId = values.customerId.split("SE:")[1];
    }

    let response = await this.props.createBillInvoice(values);

    if (response && response.number) {
      this.setState({
        successMessage: "Invoice is created successfully",
        loading: false,
      });
      setTimeout(() => {
        this.props.handleSave();
      }, 1000);
    } else {
      this.setState({
        errorMessage: '"Something went wrong, please try after some time."',
        loading: false,
      });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const { adminAccess, resellers } = this.props;
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;
    const prices = this.state.prices || [];
    const taxRates = this.state.taxRates || [];

    const initialValues = {
      customerId: _get(this, "props.account.stripeBuss"),
      taxRateId: "",
      description:
        "Note: All sales are final and are non refundable and non transferable.",
      footer: "Thank you for your business",
      prices: [],
    };

    function getItemTotal(item) {
      const _price = prices.find((_i) => _i.id === item.id);
      const qty = item.quantity ? parseInt(item.quantity) : 1;
      const pName = _get(_price, "detail.product.name");
      const _pRate = _get(_price, "detail.unit_amount", 0);
      const pRate = parseFloat(_pRate) / 100;
      const pTotal = pRate * qty;
      return { pTotal, pName, pRate, qty };
    }

    function getAllTotal(values) {
      const _prices = values.prices;
      const _total = _prices.reduce((a, b) => {
        const _price = prices.find((_i) => _i.id === b.id);
        const _unitAmt = _get(_price, "detail.unit_amount", 0);
        const _qty = b.quantity ? parseInt(b.quantity) : 0;
        const _t = parseFloat(_unitAmt) * _qty;
        return a + _t;
      }, 0);

      const _taxRate = taxRates.find((item) => item.id === values.taxRateId);
      const _taxPer =
        (_taxRate &&
          _taxRate.detail &&
          _taxRate.detail.percentage &&
          _taxRate.detail.percentage &&
          parseFloat(_taxRate.detail.percentage)) ||
        0;

      const _fee = (_total * _taxPer) / 100;

      return { total: (_total + _fee) / 100, fee: _fee / 100 };
    }

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{LABELS.addInvoice}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              const _taxRate = taxRates.find(
                (item) => item.id === values.taxRateId
              );

              const _total = getAllTotal(values);
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <SelectBox
                      md={4}
                      name="taxRateId"
                      label={"Transaction Fee"}
                      items={taxRates}
                    />
                    <InputBox md={8} name="footer" label="Footer" />
                    <InputBox md={12} name="description" label="Memo" />

                    {values.prices &&
                      values.prices[10] &&
                      values.prices[0].quantity && (
                        <Grid md={12} item>
                          <h3>{"Rate table"}</h3>
                          <Table
                            size="small"
                            style={{ marginTop: 25, marginBottom: 25 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Rate</TableCell>
                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.prices.map((item) => {
                                if (!item.quantity) {
                                  return null;
                                }
                                const _sel = getItemTotal(item);
                                return (
                                  <TableRow key={item.id}>
                                    <TableCell>{`${_sel.pName}`}</TableCell>
                                    <TableCell align="right">{`${_sel.pRate}`}</TableCell>
                                    <TableCell align="right">{`${_sel.qty}`}</TableCell>
                                    <TableCell align="right">{`${_sel.pTotal}`}</TableCell>
                                  </TableRow>
                                );
                              })}
                              <TableRow>
                                <TableCell align="right" colSpan={3}>
                                  Fee
                                </TableCell>
                                <TableCell align="right">{"$30.00"}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right" colSpan={3}>
                                  Total
                                </TableCell>
                                <TableCell align="right">{"$400.00"}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      )}

                    <Grid xm={12} sm={12} md={12} item>
                      <h3>{"Rate info"}</h3>
                      <FieldArray
                        name="prices"
                        render={(arrayHelpers) => (
                          <Grid container spacing={3}>
                            {values.prices.map((_item, index) => {
                              const _sel = getItemTotal(_item);
                              return (
                                <React.Fragment key={index}>
                                  <SelectBox
                                    md={4}
                                    name={`prices[${index}].id`}
                                    label={"Price"}
                                    items={prices}
                                  />
                                  <InputBox
                                    xs={6}
                                    md={4}
                                    name={`prices.${index}.quantity`}
                                    label="Quantity"
                                  />
                                  <Grid xm={3} md={3} item>
                                    <div
                                      style={{
                                        textAlign: "right",
                                        marginTop: 25,
                                      }}
                                    >
                                      {`${formatCurrency(_sel.pTotal)}`}
                                    </div>
                                  </Grid>
                                  <Grid xm={3} md={1} item>
                                    <button
                                      type="button"
                                      style={{ marginTop: 20 }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </Grid>
                                </React.Fragment>
                              );
                            })}
                            <Grid item xs={12} sm={12} md={12}>
                              <button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({ id: "", quantity: 1 })
                                }
                              >
                                {"Add item"}
                              </button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={11}
                              style={{ textAlign: "right", fontWeight: 500 }}
                            >
                              <span>
                                {(_taxRate && _taxRate.name) || "Fee: "}
                              </span>

                              <span
                                style={{
                                  minWidth: 125,
                                  display: "inline-block",
                                }}
                              >
                                {`${formatCurrency(_total.fee)}`}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={11}
                              style={{ textAlign: "right", fontWeight: 600 }}
                            >
                              <span>{"Total: "}</span>
                              <span
                                style={{
                                  minWidth: 125,
                                  display: "inline-block",
                                }}
                              >
                                {`${formatCurrency(_total.total)}`}
                              </span>
                            </Grid>
                          </Grid>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {loading ? LABELS.saving : LABELS.save}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
    adminAccess: _get(state, "authData.profile.adminAccess", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTimeZone,
      getBillPrice,
      getBillTaxrate,
      createBillInvoice,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBillInvoice))
);
