import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import ListItemText from '@mui/material/ListItemText';
import SearchBar from "./SearchBar";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.settingPage;

export class Fmcsa extends React.Component {
    componentDidMount() { }

    handleSearchChange = () => { };

    viewHistory = () => {
        const { accountBaseUrl } = this.props;
        this.props.history.push(`${accountBaseUrl}/fmcsa/history`);
    };

    render() {
        return (
            <Grid container spacing={0} style={{ padding: 10 }}>
                <Grid item xs={12} sm={12} style={{ padding: 10 }}>
                    <Alert
                        severity="warning"
                        style={{
                            width: "100%"
                        }}
                    >
                        <strong>
                            {/* FMCSA Data Transfer (Steps to transfer data) */}
                            {LABELS.fmcsa_data_transfer}
                        </strong>
                        <ol>
                            <li>
                                <ListItemText primary={LABELS.SelectDriver} />
                            </li>
                            <li>
                                <ListItemText primary={LABELS.selectDateRange} />
                            </li>
                            <li>
                                <ListItemText primary={LABELS.enterComment} />
                            </li>
                            <li>
                                <ListItemText
                                    primary={`${LABELS.sendDataTo_fmcsa}`}
                                />
                            </li>
                        </ol>
                    </Alert>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <SearchBar
                        handleChange={this.handleSearchChange}
                        viewHistory={this.viewHistory}
                    />
                </Grid>
            </Grid>
        );
    }
}

reduxForm({
    form: "fmcsaForm"
})(Fmcsa);

const mapStateToProps = state => {
    return {
        accountBaseUrl: _get(state, "authData.accountBaseUrl")
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Fmcsa)
);
