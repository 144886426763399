export const LABELS = {
  map: "карта",
  table: "Стол",
  search: "Поиск",
  name: "Имя",
  status: "Положение дел",
  location: "Расположение",
  vehicle: "Транспортное средство",
  break: "Перерыв",
  drive: "Водить машину",
  shift: "Сдвиг",
  cycle: "Цикл",
  violations: "Нарушения",
  eld: "ELD",
  update: "Обновлять",
  app: "Приложение",
};
