import { offset, drivingEvents } from "./constants";
import { findDateBetween } from "../../utils/momentHelpers";
const moment = require("moment-timezone");

moment.createFromInputFallback = function (config) {
  config._d = new Date(config._i);
};

function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

export function getFirstEvents(timezone, selectedDate, allEvents, lastEvent) {
  for (const ev of allEvents) {
    if (
      moment.tz(ev.eventTime, timezone).toISOString() ===
      moment.tz(selectedDate, timezone).startOf("day").toISOString()
    ) {
      return [];
    }
  }

  if (!lastEvent || (lastEvent && !lastEvent.eventCode)) {
    return [
      {
        eventTime: moment.tz(selectedDate, timezone).startOf("day"),
        eventCode: "OFFDUTY",
        recordOrigin: "EDITED_ENTERED",
        recordStatus: "ACTIVE",
        eventType: "CHANGE_DUTY_STATUS",
        isStartDay: true,
      },
    ];
  } else {
    if (lastEvent) {
      lastEvent.eventTimeOrg = lastEvent.eventTime;
      lastEvent.eventTime = moment.tz(selectedDate, timezone).startOf("day");
      if (lastEvent.eventCode === "BREAK") {
        lastEvent.eventCode = "OFFDUTY";
      }
      return [lastEvent];
    } else {
      return [];
    }
  }
}

export function getLastEvent(
  timezone,
  selectedDate,
  allEvents,
  nextEvent,
  selectedDateEvents
) {
  const today = new Date();
  const isToday = moment.tz(today, timezone).diff(selectedDate, "days") === 0;
  if (isToday || !nextEvent) {
    return null;
  } else if (nextEvent && nextEvent.eventTime) {
    nextEvent.eventTime = moment.tz(selectedDate, timezone).endOf("day");
    nextEvent.isLast = true;
    return nextEvent;
  }

  let _nextEvent = {
    eventTime: moment.tz(selectedDate, timezone).endOf("day"),
    isLast: true,
    recordStatus: "ACTIVE",
  };
  for (let i = 0; i < selectedDateEvents.length; i++) {
    if (
      selectedDateEvents[i] &&
      selectedDateEvents[i].eventCode &&
      selectedDateEvents[i].recordStatus === "ACTIVE" &&
      selectedDateEvents[i].eventType === "CHANGE_DUTY_STATUS"
    ) {
      _nextEvent.eventCode = selectedDateEvents[i].eventCode;
      _nextEvent.recordOrigin = selectedDateEvents[i].recordOrigin;
      _nextEvent.eventType = selectedDateEvents[i].eventType;
    }
  }

  for (let i = 0; i < allEvents.length; i++) {
    if (
      allEvents[i] &&
      allEvents[i].eventCode &&
      allEvents[i].recordStatus === "ACTIVE" &&
      allEvents[i].eventType === "CHANGE_DUTY_STATUS"
    ) {
      _nextEvent.eventCode = allEvents[i].eventCode;
      _nextEvent.recordOrigin = allEvents[i].recordOrigin;
      _nextEvent.eventType =
        selectedDateEvents[i] && selectedDateEvents[i].eventType;
    }
  }

  return _nextEvent;
}

function getNextValidEvent(selectedDate, allEvents, index) {
  let nextEvent;
  for (let i = index; i < allEvents.length; i++) {
    const selEvt = allEvents[i];

    if (selEvt) {
      const eventTime = selEvt.eventTime;
      const eventCode = selEvt.eventCode;
      const isSameDay = moment(eventTime).isSame(selectedDate, "day");

      if (drivingEvents.includes(eventCode) && isSameDay) {
        nextEvent = selEvt;
        break;
      }
    }
  }

  return nextEvent;
}

function getClosingEvent(selectedDate, timezone, allEvt) {
  if (allEvt && Array.isArray(allEvt)) {
    const elEvt = allEvt[allEvt.length - 1];
    const isSameDay = moment().isSame(selectedDate, "day");
    let closingEvt = {
      eventType: "CHANGE_DUTY_STATUS",
      eventTime: isSameDay
        ? moment()
        : moment.tz(new Date(), timezone).endOf("day"),
      eventCode: elEvt.eventCode || "OFFDUTY",
      recordOrigin: elEvt.recordOrigin,
      recordStatus: "ACTIVE",
      isLast: true,
    };

    for (let i = 0; i < allEvt.length; i++) {
      if (
        allEvt[i] &&
        allEvt[i].eventCode &&
        allEvt[i].eventType === "CHANGE_DUTY_STATUS"
      ) {
        closingEvt.eventCode = allEvt[i].eventCode;
      }
    }

    return closingEvt;
  }
}

// export function getAllMultiEvents(
//   timezone,
//   startDate,
//   endDate,
//   driverLogs,
//   driverLastLogs,
//   driverNextLogs
// ) {
//   let allDates = {};
//   // console.log("driverLastLogs = ", driverLastLogs);
//
//   const dutyLogs = driverLogs.filter((item) => {
//     return (
//       item.eventType === "CHANGE_DUTY_STATUS" &&
//       item.recordStatus === "ACTIVE" &&
//       item.eventSubType === "status"
//     );
//   });
//
//   for (let i = 0; i < driverLogs.length; i++) {
//     const _eDate = driverLogs[i] && driverLogs[i].eventTime;
//     const _nDate = driverLogs[i + 1] && driverLogs[i + 1].eventTime;
//     const _lDate = driverLogs[i - 1] && driverLogs[i - 1].eventTime;
//     const startTime = moment.tz(eDate, timezone).startOf("day");
//     const eDate = moment.tz(_eDate, timezone).format("MM/D/YYYY");
//
//     allDates[eDate] = allDates[eDate] || {};
//     allDates[eDate].selectedDate = startTime;
//     allDates[eDate].allEvents = allDates[eDate].allEvents || [];
//
//     // Adding fist last event
//     if (i === 0) {
//       allDates[eDate].lastEvent = driverLastLogs;
//     }
//
//     // if (_lDate && !moment.tz(_eDate, timezone).isSame(_lDate, "day")) {
//     //   // allDates[eDate].lastEvent = driverLogs[i - 1];
//     //
//     //   let find = true;
//     //
//     //   for (let j = dutyLogs.length - 1; j >= 0; j--) {
//     //     let _dtDate = dutyLogs[j] && dutyLogs[j].eventTime;
//     //     if (moment.tz(_eDate, timezone).isSame(_dtDate, "day")) {
//     //       find = true;
//     //     }
//     //     if (find && !moment.tz(_eDate, timezone).isSame(_dtDate, "day")) {
//     //       find = false;
//     //       // allDates[eDate].lastEvent = dutyLogs[j];
//     //       // allDates[eDate].allEvents.push(dutyLogs[j]);
//     //
//     //       console.log("dutyLogs[j] ======== ", dutyLogs[j]);
//     //       allDates[eDate].lastEvent = dutyLogs[j];
//     //     }
//     //   }
//     // }
//
//     allDates[eDate].allEvents.push(driverLogs[i]);
//
//     // if (_nDate && !moment.tz(_eDate, timezone).isSame(_nDate, "day")) {
//     //   // allDates[eDate].nextEvent = driverLogs[i + 1];
//     //
//     //   let find = true;
//     //   for (let j = 0; j < dutyLogs.length; j++) {
//     //     let _dtDate = dutyLogs[j] && dutyLogs[j].eventTime;
//     //     if (moment.tz(_eDate, timezone).isSame(_dtDate, "day")) {
//     //       find = true;
//     //     }
//     //     if (find && !moment.tz(_eDate, timezone).isSame(_dtDate, "day")) {
//     //       // allDates[eDate].nextEvent = dutyLogs[j];
//     //       // allDates[eDate].allEvents.push(dutyLogs[j]);
//     //       // console.log('dutyLogs[j] ======== ', dutyLogs[j]);
//     //       // allDates[eDate].lastEvent = dutyLogs[j];
//     //     }
//     //   }
//     // }
//
//     // Adding last next event
//     if (i + 1 === driverLogs.length) {
//       allDates[eDate].nextEvent = driverNextLogs;
//     }
//   }
//   const _response = Object.values(allDates);
//
//   // console.log("_response :::: ", _response);
//   const response = _response.map((item, idx) => {
//     // console.log("item ===== ", item);
//     // if (!item.lastEvent) {
//     //   const pEvts = (_response[idx - 1] && _response[idx - 1].allEvents) || [];
//     //   // console.log("pEvts ===== ", pEvts);
//     //   for (let j = pEvts.length - 1; j >= 0; j--) {
//     //     const ee = pEvts[j];
//     //     if (
//     //       ee.eventType === "CHANGE_DUTY_STATUS" &&
//     //       ee.recordStatus === "ACTIVE"
//     //     ) {
//     //       // console.log("ee ===== ", ee);
//     //       item.lastEvent = ee;
//     //       // item.lastEvent.eventTime = item.selectedDate;
//     //       break;
//     //     }
//     //   }
//     // }
//     //
//     // if (!item.nextEvent) {
//     //   const pEvts = (_response[idx + 1] && _response[idx + 1].allEvents) || [];
//     //   // console.log("pEvts ===== ", pEvts);
//     //   for (let j = 0; j < pEvts.length; j++) {
//     //     const ee = pEvts[j];
//     //     if (
//     //       ee.eventType === "CHANGE_DUTY_STATUS" &&
//     //       ee.recordStatus === "ACTIVE"
//     //     ) {
//     //       // console.log("ee ===== ", ee);
//     //       item.nextEvent = ee;
//     //       // item.lastEvent.eventTime = item.selectedDate;
//     //       break;
//     //     }
//     //   }
//     // }
//     return getAllDayEvents(
//       timezone,
//       item.selectedDate,
//       item.allEvents,
//       item.lastEvent,
//       item.nextEvent
//     );
//   });
//
//   return response;
// }

export function getAllDayEvents(
  timezone,
  selectedDate,
  allEvents,
  lastEvent,
  nextEvent
) {
  let selectedDateEvents = getFirstEvents(
    timezone,
    selectedDate,
    allEvents,
    lastEvent
  );

  for (let i = 0; i < allEvents.length; i++) {
    const selectedEvt = allEvents[i];
    const eventTime = selectedEvt.eventTime;
    // const isSameDay = moment(eventTime).isSame(selectedDate, "day");
    const isSameDay = moment
      .tz(eventTime, timezone)
      .isSame(selectedDate, "day");

    if (isSameDay) {
      selectedDateEvents.push(selectedEvt);
    }
  }

  const endEvt = getLastEvent(
    timezone,
    selectedDate,
    allEvents,
    nextEvent,
    selectedDateEvents
  );

  if (endEvt) {
    selectedDateEvents.push(endEvt);
  } else {
    const closingEvt = getClosingEvent(
      selectedDate,
      timezone,
      selectedDateEvents
    );

    if (closingEvt) {
      selectedDateEvents.push(closingEvt);
    }
  }

  const startSeq = selectedDateEvents[0].sequenceId;
  // console.log("startSeq = ", startSeq);
  const formatedEvents = selectedDateEvents.map((item, i) => {
    const odoPrev = selectedDateEvents[i - 1]
      ? selectedDateEvents[i - 1].odometer
      : item.odometer;
    const engineHoursPrev = selectedDateEvents[i - 1]
      ? selectedDateEvents[i - 1].engineHours
      : item.engineHours;

    const odoCur = item.odometer;
    const engineHoursCur = item.engineHours;

    if (startSeq + i !== item.sequenceId) {
      item.aSeq = startSeq + i;
    }
    if (odoPrev > odoCur) {
      item.odoError = true;
    }
    if (engineHoursPrev > engineHoursCur) {
      item.engineHoursError = true;
    }

    // console.log('odoPrev == ', odoPrev);
    // console.log('engineHoursPrev == ', engineHoursPrev);

    // console.log('odoCur == ', odoCur);
    // console.log('odoPrev == ', odoPrev);
    return item;
  });

  return formatedEvents;
}

export function getLineX(timezone, eventTime) {
  if (eventTime) {
    // const startTime = moment(eventTime).startOf("day");
    const startTime = moment.tz(eventTime, timezone).startOf("day");
    // const endTime = moment(eventTime);
    const endTime = moment.tz(eventTime, timezone);

    const duration = moment.duration(endTime.diff(startTime));
    const minutes = duration.asMinutes();
    const finalMinutes = 35 + minutes / 2;
    return finalMinutes;
  }
}

function getLine(timezone, event, date) {
  if (event) {
    const eventTime = event.eventTime;
    // const startTime = moment(eventTime).startOf("day");
    let startTime = moment.tz(eventTime, timezone).startOf("day");
    let date_ = moment.tz(date, timezone).startOf("day");
    let end_ = moment.tz(date, timezone).endOf("day");

    // const endTime = moment(eventTime);
    let endTime = moment.tz(eventTime, timezone);
    if (startTime > moment()) {
      return 0;
    } else if (endTime > moment()) {
      endTime = moment();
    }
    if (endTime > end_) {
      endTime = end_;
    }

    if (startTime > date_) {
      startTime = date_;
    }

    const duration = moment.duration(endTime.diff(startTime));
    const minutes = duration.asMinutes();
    const finalMinutes = 35 + minutes / 2;
    return finalMinutes;
  }
}

function getAllowedEvents(_allEvents) {
  let _events = [];
  for (let i = 0; i < _allEvents.length; i++) {
    const eventCode = _allEvents[i].eventCode;
    if (drivingEvents.includes(eventCode)) {
      _events.push(_allEvents[i]);
    }
  }
  return _events;
}

function formatMinutes(minutes) {
  minutes = Math.round(minutes);
  let hours = String(Math.round(Math.floor(minutes / 60)));
  let realmin = String(Math.round(minutes % 60));

  const fHours = hours.length === 1 ? `0${hours}` : hours;
  const fMin = realmin.length === 1 ? `0${realmin}` : realmin;

  return `${fHours}:${fMin}`;
}

export function getChartEvents(timezone, _allEvents) {
  let allEvents = getAllowedEvents(_allEvents);
  let events = [];

  const date = (allEvents[0] || {}).eventTime || "";
  for (let i = 0; i < allEvents.length; i++) {
    const selected = allEvents[i];
    const eventCode = selected.eventCode;
    const eventType = selected.eventType;
    const eventSubType = selected.eventSubType;

    const eventNext = allEvents[i + 1] || selected;
    const start = getLine(timezone, selected, date);
    const end = getLine(timezone, eventNext, date);
    const minutes = (end - start) * 2;

    if (start !== 0 && !selected.isLast) {
      events.push({
        start: start - 1,
        end: end + 1,
        height: offset[eventCode],
        eventCode,
        eventType,
        eventSubType,
        minutes,
        _id: selected._id,
        data: {
          speed: selected.speed,
          odometer: selected.odometer,
          engineHours: selected.engineHours,
          location: selected.location,
        },
      });
    }
  }
  return events;
}

export function getChartIntEvents(timezone, allEvents) {
  // let allEvents = getAllowedEvents(_allEvents);
  let events = [];

  const date = (allEvents[0] || {}).eventTime || "";
  for (let i = 0; i < allEvents.length; i++) {
    const selected = allEvents[i];
    const eventCode = selected.eventCode;
    const eventType = selected.eventType;
    const eventSubType = selected.eventSubType;

    // const eventNext = allEvents[i + 1] || selected;
    const start = getLine(timezone, selected, date);
    // const end = getLine(timezone, eventNext, date);
    // const minutes = (end - start) * 2;

    if (start !== 0) {
      events.push({
        start: start - 1,
        // end: end + 1,
        height: offset[eventCode],
        eventCode,
        eventType,
        eventSubType,
        _id: selected._id,
        data: {
          speed: selected.speed,
          odometer: selected.odometer,
          engineHours: selected.engineHours,
          location: selected.location,
        },
      });
    }
  }
  return events;
}

export function getChartDurationEvents(timezone, _allEvents) {
  let allEvents = getAllowedEvents(_allEvents);
  let events = [];

  for (let i = 0; i < allEvents.length; i++) {
    const eventCode = allEvents[i].eventCode;
    const eventCurrent = allEvents[i];
    const eventNext = allEvents[i + 1] || allEvents[i];
    const start = getLine(timezone, eventCurrent);
    const end = getLine(timezone, eventNext);
    const minutes = (end - start) * 2;
    if (start !== 0)
      events.push({
        start: start - 13 + (end - start) / 2,
        end: end + 1,
        height: offset[eventCode] - 3,
        eventCode,
        text: minutes > 45 ? formatMinutes(minutes) : "",
      });
  }
  return events;
}

export function getChartChangeEvents(timezone, _allEvents) {
  let allEvents = getAllowedEvents(_allEvents);
  let events = [];

  for (let i = 0; i < allEvents.length; i++) {
    const eventNextType = allEvents[i + 1] && allEvents[i + 1].eventCode;
    if (eventNextType && !allEvents[i + 1].isLast) {
      const eventCode = allEvents[i].eventCode;
      const eventNext = allEvents[i + 1] || allEvents[i];
      events.push({
        start: getLine(timezone, eventNext),
        top: offset[eventCode],
        bottom: offset[eventNextType],
      });
    }
  }
  return events;
}

export function getTotalTime(timezone, allEvents) {
  let totalMinutes = 0;

  let offDuty = 0;
  let sleeper = 0;
  let driving = 0;
  let onDutyND = 0;

  for (let i = 0; i < allEvents.length; i++) {
    const eventCode = allEvents[i].eventCode;
    const minutes = allEvents[i].minutes;

    totalMinutes = totalMinutes + minutes;
    if (eventCode === "OFFDUTY" || eventCode === "BREAK") {
      offDuty = offDuty + minutes;
    }
    if (eventCode === "SLEEPER") {
      sleeper = sleeper + minutes;
    }
    if (eventCode === "DRIVING") {
      driving = driving + minutes;
    }
    if (eventCode === "ONDUTY_ND") {
      onDutyND = onDutyND + minutes;
    }
  }

  return {
    total: formatMinutes(totalMinutes),
    offDuty: formatMinutes(offDuty),
    sleeper: formatMinutes(sleeper),
    driving: formatMinutes(driving),
    onDutyND: formatMinutes(onDutyND),
  };
}

export function getDailyTime(selectedDate, allEvents) {
  let totalMinutes = 0;
  let offDuty = 0;
  let sleeper = 0;
  let driving = 0;
  let onDutyND = 0;
  let waitingAtWellSite = 0;

  for (var i = 0; i < allEvents.length; i++) {
    const selectedEvt = allEvents[i];
    const eventTime = selectedEvt.eventTime;
    const eventCode = selectedEvt.eventCode;
    const isSameDay = moment(eventTime).isSame(selectedDate, "day");

    if (drivingEvents.includes(eventCode) && isSameDay) {
      const nextEvent = getNextValidEvent(selectedDate, allEvents, i + 1);

      if (nextEvent) {
        const nextTime = moment(nextEvent.eventTime);
        // const nextType = nextEvent.eventCode;
        const duration = moment.duration(nextTime.diff(moment(eventTime)));
        const minutes = duration.asMinutes();
        if (eventCode === "OFFDUTY" || eventCode === "break") {
          offDuty = offDuty + minutes;
        }
        if (eventCode === "SLEEPER") {
          sleeper = sleeper + minutes;
        }
        if (eventCode === "DRIVING") {
          driving = driving + minutes;
        }
        if (eventCode === "ONDUTY_ND") {
          onDutyND = onDutyND + minutes;
        }
        if (eventCode === "waitingAtWellSite") {
          waitingAtWellSite = waitingAtWellSite + minutes;
        }

        totalMinutes = totalMinutes + parseFloat(minutes);
      }
    }
  }

  return {
    onDutyND: formatMinutes(onDutyND),
    driving: formatMinutes(driving),
    sleeper: formatMinutes(sleeper),
    offDuty: formatMinutes(offDuty),
    waitingAtWellSite: formatMinutes(waitingAtWellSite),
    totalMinutes: formatMinutes(totalMinutes),
  };
}

export function checkForViolationOld(selectedDate, allEvents) {
  // console.log("selectedDate :: ", selectedDate);
  // console.log("allEvents :: ", allEvents);
  // let totalMinutes = 0;
  let offDuty = 0;
  let sleeper = 0;
  let driving = 0;
  let onDutyND = 0;

  // console.log("selectedDate :::: ", selectedDate);
  // console.log("allEvents :::: ", allEvents);

  for (var i = 0; i < allEvents.length; i++) {
    const selectedEvt = allEvents[i];
    const eventTime = selectedEvt.eventTime;
    const eventCode = selectedEvt.eventCode;
    const isSameDay = moment(eventTime).isSame(selectedDate, "day");

    if (drivingEvents.includes(eventCode) && isSameDay) {
      const nextEvent = getNextValidEvent(selectedDate, allEvents, i + 1);

      if (nextEvent) {
        const nextTime = moment(nextEvent.eventTime);
        // const nextType = nextEvent.eventCode;
        const duration = moment.duration(nextTime.diff(moment(eventTime)));
        const minutes = duration.asMinutes();

        if (eventCode === "OFFDUTY" || eventCode === "BREAK") {
          offDuty = offDuty + minutes;
          allEvents[i]["OFFDUTY"] = {
            total: offDuty,
            totalHr: formatMinutes(offDuty),
            currentHr: formatMinutes(minutes),
          };
        }
        if (eventCode === "BREAK") {
          offDuty = offDuty + minutes;
          allEvents[i]["BREAK"] = {
            total: offDuty,
            totalHr: formatMinutes(offDuty),
            currentHr: formatMinutes(minutes),
          };
        }
        if (eventCode === "SLEEPER") {
          sleeper = sleeper + minutes;
          allEvents[i]["SLEEPER"] = {
            total: sleeper,
            totalHr: formatMinutes(sleeper),
            currentHr: formatMinutes(minutes),
          };
        }
        if (eventCode === "DRIVING") {
          driving = driving + onDutyND + minutes;
          allEvents[i]["DRIVING"] = {
            total: driving,
            totalHr: formatMinutes(driving),
            currentHr: formatMinutes(minutes),
          };
        }
        if (eventCode === "ONDUTY_ND") {
          onDutyND = onDutyND + driving + minutes;
          allEvents[i]["ONDUTY_ND"] = onDutyND;
          allEvents[i]["ONDUTY_ND"] = {
            total: onDutyND,
            totalHr: formatMinutes(onDutyND),
            currentHr: formatMinutes(minutes),
          };
        }
      }
    }
  }
  return allEvents;
}

export function checkForViolation(
  timezone,
  selectedDate,
  driverLogs,
  driverLastLogs,
  driverNextLogs
) {
  const alleventCodes = [
    "onDutyND",
    "driving",
    "sleeper",
    "break",
    "offDuty",
    "waitingAtWellSite",
  ];
  // console.log("timezone :: ", timezone);
  // console.log("selectedDate :: ", selectedDate);
  // console.log("\n\n\n\ndriverLogs :::: ", driverLogs.length);
  // console.log("driverLastLogs :::: ", driverLastLogs);
  // console.log("driverNextLogs :::: ", driverNextLogs);s
  // let formatedLogs = [];

  for (var i = 0; i < driverLogs.length; i++) {
    const current = driverLogs[i];
    const next = driverLogs[i + 1];
    const eventCode = current.eventCode;
    const eventTime = moment(current.eventTime);
    const nextEventTime = next && next.eventTime && moment(next.eventTime);
    const recordStatus = current.recordStatus;
    // console.log("_current :::: ", eventCode);

    if (alleventCodes.includes(eventCode) && recordStatus === "ACTIVE") {
      // console.log("eventTime :::: ", eventTime);
      if (eventCode === "SLEEPER" || eventCode === "OFFDUTY") {
        // console.log("eventTime :::: ", eventTime);
        // console.log("eventCode :::: ", eventCode);
        // console.log("current :::: ", current);
        // console.log("next :::: ", next);
      }

      if (nextEventTime && eventTime) {
        const duration = moment.duration(nextEventTime.diff(moment(eventTime)));
        const minutes = duration.asMinutes();
        console.log("minutes :: ", minutes);
      }
    }
  }
  // console.log("formatedLogs :: ", formatedLogs);
}

export function filterDriverLogs(driverLogs = [], driverCalcus = []) {
  let all = [];
  let noFound = true;
  let allShiftReset = [];

  for (let i = 0; i < driverLogs.length; i++) {
    let curLog = driverLogs[i] || {};
    let nextLog = driverLogs[i + 1] || {};
    let start = curLog.eventTime;
    let next = nextLog.eventTime;

    all.push(curLog);
    if (start && next) {
      for (let j = 0; j < driverCalcus.length; j++) {
        const cal = driverCalcus[j] || {};
        const cycleReset = cal.cycleStartTimestamp;
        if (findDateBetween(cycleReset, start, next) && noFound) {
          noFound = false;
          all.push(cal);
        }

        const shiftReset = cal.shiftResetTimestamp;
        if (shiftReset && Array.isArray(shiftReset)) {
          for (let k = 0; k < shiftReset.length; k++) {
            let _sR = shiftReset[k];
            if (
              findDateBetween(_sR, start, next) &&
              !allShiftReset.includes(_sR)
            ) {
              allShiftReset.push(_sR);
              all.push({
                _id: cal._id,
                shiftReset: _sR,
              });
            }
          }
        }
      }
    }
  }

  return all;
}

export function getAutoDrLimits(logs) {
  const driverEvents = [];

  let start;
  let startid;
  for (var i = 0; i < logs.length; i++) {
    const log = logs[i] || {};
    if (
      !start &&
      log.recordOrigin === "AUTOMATICALLY" &&
      log.eventCode === "DRIVING" &&
      log.recordStatus === "ACTIVE" &&
      log.eventSubType === "status"
    ) {
      start = log.eventTime;
      startid = log.cid;
    }
    if (
      start &&
      log.eventType === "CHANGE_DUTY_STATUS" &&
      log.eventCode !== "DRIVING" &&
      log.recordStatus === "ACTIVE" &&
      log.eventSubType === "status"
    ) {
      driverEvents.push({ start, startid, end: log.eventTime, endid: log.cid });
      start = null;
      startid = null;
    }
  }
  return driverEvents;
}

export function validateEngHr(cur, prev, prev2) {
  // console.log("\ncurrent == ", cur && cur.engineHours, cur && cur.odometer);
  // console.log("prev == ", prev && prev.engineHours, prev && prev.odometer);
  // console.log("prev2 == ", prev2 && prev2.engineHours, prev2 && prev2.odometer);
  cur = cur || {};
  prev = prev || {};
  prev2 = prev2 || {};
  const val = pF(cur.engineHours);
  const valPrev = pF(prev.engineHours);
  const valPrev2 = pF(prev2.engineHours);

  if (valPrev2 > val || valPrev > val) {
    return false;
  } else {
    return true;
  }
}

export function validateOdometer(cur, prev, prev2) {
  const val = pF(cur && cur.odometer);
  const valPrev = pF(prev && prev.odometer);
  const valPrev2 = pF(prev2 && prev2.odometer);

  if (cur && prev && valPrev > val) {
    return false;
  } else if (cur && prev2 && valPrev2 > val) {
    return false;
  } else {
    return true;
  }
}

export function validateLocation(cur, prev, prev2) {
  let isError = false;

  const curVal = cur && cur.coordinates;

  // const curEvt = cur && cur.eventCode;
  const curLat = curVal && curVal[0];
  // const curLng = curVal && curVal[1];

  const prevVal = prev && prev.coordinates;

  const prevEvt = prev && prev.eventCode;
  const prevLat = prevVal && prevVal[0];
  // const prevLng = prevVal && prevVal[1];

  // const prev2Val = prev2 && prev2.coordinates;

  // const prev2Evt = prev2 && prev2.eventCode;
  // const prev2Lat = prev2Val && prev2Val[0];
  // const prev2Lng = prev2Val && prev2Val[1];

  // if (prevLat && curLat) {
  if (prevEvt === "DRIVING") {
    if (prevLat === curLat) {
      isError = true;
    }
  } else {
    if (prevLat !== curLat) {
      isError = true;
    }
  }
  // }
  return isError;
}
