import _get from "lodash/get";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import {
  getAccount,
  updateAccount,
} from "../../../actions/account/accountActions";
import { getCarriers } from "../../../actions/carrierActions";
import { getTerminals } from "../../../actions/terminalActions";
// import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SelectBox from "../../../components/SelectBox";
import Button from "@mui/material/Button";
import Error from "../../Error";
import Success from "../../Success";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const validate = (values, LABELS) => {
  const errors = {};

  if (!values.carrierId) {
    errors.carrierId = LABELS.required;
  }
  if (!values.terminalId) {
    errors.terminalId = LABELS.required;
  }

  return errors;
};

const styles = (theme) => ({});

export function DetailCard(props) {
  const LABELS = props.LABELS;
  const classes = props.classes;
  const [account, setAccount] = useState({});
  const [carriers, setCarriers] = useState([]);
  const [terminals, setTerminals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  async function _getCarriers() {
    const carriers = await props.getCarriers();
    setCarriers(carriers);
  }

  async function _getTerminals() {
    const terminals = await props.getTerminals();
    setTerminals(terminals);
  }

  useEffect(() => {
    async function _getAccount() {
      const account = await props.getAccount();

      setAccount(account);
      setInitialValues({
        carrierId: account.carrierId || "",
        terminalId: account.terminalId || "",
      });
      _getCarriers();
      _getTerminals();
    }
    _getAccount();
    // eslint-disable-next-line
  }, []);

  async function onSubmit(values) {
    try {
      setLoading(true);
      const payload = {
        carrierId: values.carrierId,
        terminalId: values.terminalId,
      };
      const response = await props.updateAccount(payload);
      if (response && response._id) {
        setSuccessMsg("Saved sucessfully.");
      } else {
        setErrorMsg("Not able to save.");
      }
    } catch (e) {
      setErrorMsg("Not able to save.");
    }
    setLoading(false);
  }

  return (
    <Card variant="outlined" style={{ minHeight: 425 }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          style={{ marginTop: 15 }}
        >
          {LABELS.accountInfo}
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
          component="p"
          style={{ marginTop: 25 }}
        >
          <span className={classes.title}>{LABELS.name}: </span>
          {`${account.name}`}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          <span className={classes.title}>{LABELS.email}: </span>
          {`${account.email}`}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          <span className={classes.title}>{LABELS.account} ID: </span>
          {`sm-${account.accountSerial}`}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          <span className={classes.title}>{LABELS.phone}: </span>
          {`${account.phone}`}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          <span className={classes.title}>{LABELS.status}: </span>
          {`${account.status}`}
        </Typography>
        <Formik
          initialValues={initialValues}
          validate={(e) => validate(e, LABELS)}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values = {}, handleSubmit, setFieldValue, errors }) => {
            return (
              <Form>
                <Grid container spacing={3}>
                  <SelectBox
                    md={12}
                    name="carrierId"
                    label={LABELS.carrier}
                    items={carriers}
                  />
                  <SelectBox
                    md={12}
                    name="terminalId"
                    label={LABELS.terminal}
                    items={terminals}
                  />
                </Grid>
                {successMsg && <Success message={successMsg} />}
                {errorMsg && <Error message={errorMsg} />}
                <div className={classes.buttons}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    size="small"
                    style={{ marginTop: 10 }}
                  >
                    {loading ? `${LABELS.save}...` : LABELS.save}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    carrierBusy: _get(state, "carrierData.carrierBusy", false),
    carriers: _get(state, "carrierData.carriers", []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCarriers,
      getTerminals,
      getAccount,
      updateAccount,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DetailCard)
);
