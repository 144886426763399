import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import validate from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../../../components/InputBox";
import SelectBox from "../../../../components/SelectBox";
import Error from "../../../Error";
import Success from "../../../Success";
import { getCarriers } from "../../../../actions/carrierActions";
import { getTerminals } from "../../../../actions/terminalActions";
import { createUser, updateUser } from "../../../../actions/userActions";
import { getEquipments } from "../../../../actions/equipmentActions";
import { getTrailers } from "../../../../actions/trailerActions";

import {
  ALL_OPTIONS,
  ALL_STATUS_OPTIONS,
  ENABLE_DISABLE,
  DRIVER_VIEW_OPTIONS,
} from "../../../../constants";
import VehicleAssignment from "./VehicleAssignment";
import { getDriver, getPayload, getInitialValues } from "./helpers";
import { LABELS as _LABELS } from "../../../..//language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  buttons: {
    marginTop: 20,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddAccountForm extends React.Component {
  state = {
    errorMessage: "",
    successMessage: "",
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.getCarriers();
    await this.props.getTerminals();
    await this.getEquipments();
    this.getTrailers();
    this.setState({ loading: false });
  }

  getTrailers = async () => {
    const array = await this.props.getTrailers({ limit: 250, active: true });
    if (array && Array.isArray(array)) {
      this.setState({ trailers: array });
    }
  };

  getEquipments = async () => {
    const eqps = await this.props.getEquipments({
      skip: 0,
      limit: 250,
      active: true,
    });
    if (eqps && Array.isArray(eqps)) {
      const vehicles = eqps.filter((item) => item.assetType === "VEHICLE");
      // const trailers = eqps.filter(item => item.assetType === "TRAILER");
      this.setState({
        vehicles,
        // , trailers
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({ loading: true });
    let response;
    if (this.props.isEdit) {
      let editPayload = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: values.mobile,
        status: values.userStatus,
        userId: values.id,
        isFb: values.isFb,
      };
      if (values.role === "DRIVER") {
        editPayload.driver = getDriver(values);
        editPayload.driver.driverId = values.driverId;
      }
      if (values.password) {
        editPayload.password = values.password;
      }
      response = await this.props.updateUser(editPayload);
    } else {
      let payload = getPayload(values);
      if (values.role === "DRIVER") {
        payload.driver = getDriver(values);
      }
      response = await this.props.createUser(payload);
    }

    if (response && response.uuid) {
      this.setState({ successMessage: "User is added successfully" });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message });
    } else if (response && response.status === 400) {
      this.setState({ errorMessage: "Email already exist." });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after time.";
      }
      this.setState({ errorMessage: response });
    }
    this.setState({ loading: false });
  };

  render() {
    const { classes, constants, isEdit } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const { carriers, terminals } = this.props;
    const { vehicles, trailers } = this.state;
    const pwdHelp = isEdit
      ? "Note: Only required, if you want to set new password"
      : "";
    const initialValues = getInitialValues(this.props);

    const selDriver = isEdit ? _get(this, "props.selected.driver", {}) : {};
    const selIds = isEdit
      ? _get(this, "props.selected.driver.equipments", [])
      : [];
    let selectedEqp = [];

    // if (loading) {
    //     return <div style={{ textAlign: "center" }}><CircularProgress /></div>
    // }

    if (vehicles && vehicles.length > 0) {
      selectedEqp =
        selIds.map((item) => vehicles.find((_item) => _item.id === item)) || [];
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Alert severity="warning">
              To add or edit a user, add an equipment first!
            </Alert>
          )}
        </div>
      );
    }

    return (
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >
        {({ values = {}, handleSubmit, setFieldValue, errors }) => {
          const ALL_STATES =
            values.country === "CA"
              ? constants.CA_STATES
              : values.country === "MX"
              ? constants.MX_STATES
              : constants.US_STATES;
          const isDriver = values.role === "DRIVER";
          const isExempt = values.exemptDriver === "ON";

          return (
            <Form style={{ padding: "10px 10px" }}>
              <Grid container spacing={3}>
                <SelectBox
                  md={isDriver ? 3 : 4}
                  name="role"
                  label={LABELS.role}
                  items={constants.USER_ROLES}
                />
                {isDriver && (
                  <InputBox md={3} name="alias" label={LABELS.driverId} />
                )}
                <InputBox
                  md={isDriver ? 3 : 4}
                  name="firstName"
                  label={LABELS.firstName}
                />
                <InputBox
                  md={isDriver ? 3 : 4}
                  name="lastName"
                  label={LABELS.lastName}
                />
                <SelectBox
                  md={3}
                  name="userStatus"
                  label={LABELS.status}
                  disabled={!isEdit}
                  items={ALL_STATUS_OPTIONS}
                />
                <InputBox md={3} name="mobile" label={LABELS.mobile} />
                <InputBox md={3} name="email" label={LABELS.email} />
                <InputBox
                  md={3}
                  name="password"
                  label={LABELS.password}
                  helpText={pwdHelp}
                />

                {isDriver ? (
                  <React.Fragment>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.DriverDetail}
                    >
                      <strong>{LABELS.driver_details}</strong>
                    </Grid>

                    <SelectBox
                      md={2}
                      name="country"
                      label={LABELS.licCountry}
                      selLabel={"id"}
                      items={constants.COUNTRIES}
                    />

                    <SelectBox
                      md={2}
                      name="state"
                      label={LABELS.licState}
                      selLabel={"id"}
                      selLabelThree={"name"}
                      items={ALL_STATES}
                    />
                    <InputBox md={2} name="number" label={LABELS.licNumber} />

                    <SelectBox
                      md={2}
                      name="cargo"
                      label={LABELS.cargo}
                      items={constants.DRIVER_CARGO}
                    />

                    {!isEdit && (
                      <SelectBox
                        md={2}
                        name="regulation"
                        disabled={true}
                        label={LABELS.regulationMode}
                        items={constants.REGULATION}
                      />
                    )}

                    <SelectBox
                      md={2}
                      name="cycleCountry"
                      label={LABELS.country}
                      selLabel={"id"}
                      items={constants.COUNTRIES}
                    />

                    <SelectBox
                      md={2}
                      name="status"
                      label={LABELS.status}
                      items={ALL_STATUS_OPTIONS}
                    />
                    <SelectBox
                      md={2}
                      name="cycleUsa"
                      label={LABELS.cycleUsa}
                      items={constants.DRIVER_CYCLE_USA}
                    />
                    <SelectBox
                      md={2}
                      name="cycleCa"
                      label={LABELS.cycleCA}
                      disabled={true}
                      items={constants.DRIVER_CYCLE_CA}
                    />

                    <SelectBox
                      md={3}
                      name="terminalId"
                      label={LABELS.terminal}
                      selLabel={"name"}
                      items={terminals}
                    />
                    <SelectBox
                      md={3}
                      name="carrierId"
                      label={LABELS.carrier}
                      selLabel={"name"}
                      items={carriers}
                    />

                    <SelectBox
                      md={2}
                      name="startTime"
                      label={LABELS.startTime}
                      keyName={"name"}
                      selLabel={"name"}
                      items={constants.DRIVER_START_TIME}
                    />

                    <InputBox
                      md={3}
                      name="receiverEmail"
                      label={LABELS.receiverMail}
                    />
                    <SelectBox
                      md={2}
                      name="personal"
                      label={LABELS.personal}
                      items={ALL_OPTIONS}
                    />

                    <SelectBox
                      md={2}
                      name="yardMoves"
                      label={LABELS.yardMoves}
                      items={ALL_OPTIONS}
                    />
                    <SelectBox
                      md={2}
                      name="allowEdit"
                      label={LABELS.allowEdit}
                      items={ALL_OPTIONS}
                    />
                    <SelectBox
                      md={2}
                      name="tracking"
                      label={LABELS.tracking}
                      items={ALL_OPTIONS}
                    />
                    <SelectBox
                      md={1}
                      name="exemptDriver"
                      label={LABELS.exempt}
                      items={ALL_OPTIONS}
                    />

                    {isExempt && (
                      <InputBox
                        md={2}
                        name="exemptAnnotation"
                        label={LABELS.exemptAnnotation}
                      />
                    )}

                    <Grid item xs={12} sm={12} md={12}>
                      <VehicleAssignment
                        error={errors}
                        equipments={vehicles}
                        selected={selectedEqp}
                        trailers={trailers}
                        driver={selDriver}
                        onChange={(params) => {
                          setFieldValue("trailerId", params.trailerId);
                          setFieldValue("vehicleId", params.vehicleId);
                          setFieldValue("equipments", params.equipments);
                        }}
                      />
                    </Grid>
                    <SelectBox
                      md={2}
                      name="drView"
                      label={LABELS.appHome_View}
                      items={DRIVER_VIEW_OPTIONS}
                    />
                    <SelectBox
                      md={2}
                      name="isTimer"
                      label={"Show Timer"}
                      items={ALL_OPTIONS}
                    />
                  </React.Fragment>
                ) : null}
                {this.props.isAdmin && (
                  <React.Fragment>
                    <SelectBox
                      md={2}
                      name="isFb"
                      label={LABELS.firebase}
                      items={ENABLE_DISABLE}
                    />
                  </React.Fragment>
                )}
                {/*this.props.isAdmin && (
                                    <React.Fragment>
                                        <SelectBox
                                            md={2}
                                            name="kiosk"
                                            label="Kiosk"
                                            items={ALL_OPTIONS}
                                        />
                                    </React.Fragment>
                                )*/}
              </Grid>
              {successMessage && <Success message={successMessage} />}
              {errorMessage && <Error message={errorMessage} />}
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {/* {loading ? "Saving..." : "SAVE"} */}
                  {loading ? LABELS.saving : LABELS.save}
                </Button>{" "}
                <Button variant="contained" onClick={this.props.handleClose}>
                  {LABELS.close}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    carriers: _get(state, "carrierData.carriers", []),
    terminals: _get(state, "terminalData.terminals", []),
    carrierId: _get(state, "authData.profile.carrier.id"),
    terminalId: _get(state, "authData.profile.terminal.id"),
    isAdmin: !!_get(state, "authData.profile.adminId"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCarriers,
      getEquipments,
      getTrailers,
      getTerminals,
      createUser,
      updateUser,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAccountForm))
);
