import moment from "moment-timezone";

export function getStartOfDay(date) {
    return moment(date).startOf("day");
}

export function getEndOfDay(date) {
    return moment(date).endOf("day");
}

export function isPastDate(date) {
    if (moment(date).isValid()) {
        const days = moment().diff(moment(date), "days");
        return days >= 0;
    } else {
        return false;
    }
}

export function getLastFiveYears(count = 5) {
    let years = [];
    const currentYear = moment().format("YYYY");

    for (var i = 0; i < count; i++) {
        years.push(parseInt(currentYear) - i);
    }
    return years;
}


export function searchIftaMonths(year) {
    return [
        // {
        //     id: `${year}`,
        //     value: `${year}`,
        //     label: `${year}`,
        //     start: getStartOfDay(`${year}-01-01`),
        //     end: getEndOfDay(`${year}-12-31`)
        // },
        {
            id: `Q4_${year}`,
            value: `Q4_${year}`,
            label: `Q4 ${year}`,
            start: getStartOfDay(`${year}-10-01`),
            end: getEndOfDay(`${year}-12-31`)
        },
        {
            id: `Q3_${year}`,
            value: `Q3_${year}`,
            label: `Q3 ${year}`,
            start: getStartOfDay(`${year}-07-01`),
            end: getEndOfDay(`${year}-09-30`)
        },
        {
            id: `Q2_${year}`,
            value: `Q2_${year}`,
            label: `Q2 ${year}`,
            start: getStartOfDay(`${year}-04-01`),
            end: getEndOfDay(`${year}-06-30`)
        },
        {
            id: `Q1_${year}`,
            value: `Q1_${year}`,
            label: `Q1 ${year}`,
            start: getStartOfDay(`${year}-01-01`),
            end: getEndOfDay(`${year}-03-31`)
        }
    ];
}

export function searchIftaYear(count = 5) {
    let allMonths = [];
    const years = getLastFiveYears(count);

    years.forEach(year => {
        const months = searchIftaMonths(year);
        allMonths = [...allMonths, ...months];
    });

    return allMonths;
}