import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { getTerms } from "./helpers";
import { LOGO_URL, LOGIN_ADS, APP_STYLE_ID } from "../../config/index";
import { year, name, website, companyName, email } from "../../clientConfig";
const items = getTerms();

const styles = (theme) => ({
  paper: { margin: 15, overflow: "hidden" },
});

function Privacy(props) {
  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <Grid
        style={{ margin: "auto", marginTop: 15, maxWidth: 1000 }}
        container
        spacing={2}
        alignItems="center"
      >
        <Grid xs={12} sm={12} md={12} item>
          <img alt="Logo" src={LOGO_URL} style={{ maxHeight: 60 }} />
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <h1>Privacy Policy</h1>
          <p>Effective Date: 27 October 2024</p>
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          {items.map((item, idx) => {
            return (
              <div style={{ marginTop: 25 }} key={`id-${idx}`}>
                <h3 style={{ marginBottom: 5 }}>{item.title}</h3>
                <div>{item.content}</div>
              </div>
            );
          })}
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <div style={{ marginTop: 50, marginBottom: 50, textAlign: "center" }}>
            {`© ${year} ${companyName}. All rights reserved.`}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

Privacy.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Privacy);
