const defaultState = {
    dvirTemplates: [],
    dvirAll: [],
    count: 0
};

export default function driver(state = defaultState, action) {
    switch (action.type) {
        case "DVIR_TEMPLATES":
            return {
                ...state,
                dvirTemplates: action.value
            };
        case "DVIR_ALL":
            return {
                ...state,
                dvirAll: action.value
            };
        case "DVIR_COUNT":
            return {
                ...state,
                count: action.value
            };
        default:
            return state;
    }
}
