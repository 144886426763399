export function validate(values = {}) {
    const errors = {};

    if (!values.status) {
        errors.status = "Required.";
    }

    if (!(values.driverId || values.vehicleId)) {
        errors.driverId = "Required.";
        errors.vehicleId = "Required.";
    }

    if (!values.expiry) {
        errors.expiry = "Required.";
    }

    return errors;
}
