import Grid from "@mui/material/Grid";
import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import moment from "moment-timezone";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import NotFoundTableRow from "../NotFoundTableRow";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import ViewAccountDetail from "./ViewAccountDetail";
import { ADMIN_CONFIG } from "../../config/index";
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

const styles = (theme) => ({
  root: {
    maxWidth: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1.5),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 190,
  },
  dataLargeRow: {
    minWidth: 150,
  },
  dataMedRow: {
    minWidth: 110,
  },
  dataSmallRow: {
    minWidth: 90,
  },
  button: {
    marginTop: 2,
    minWidth: 95,
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { isStripe, classes, isPayment, item = {} } = this.props;
    const planDues = _get(item, "plan.dues", []);
    const planName = _get(item, "plan.plan.name", "Standard");
    const allowed = _get(item, "plan.vehicles.allowed");
    const current = _get(item, "plan.vehicles.current");

    let duesOver = 0;
    let duesMonth = 0;

    if (isPayment) {
      planDues.forEach((item) => {
        if (moment(item.date) < moment()) {
          duesOver = duesOver + 1;
        } else if (moment(item.date) <= moment().endOf("month")) {
          duesMonth = duesMonth + 1;
        }
      });
    }

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          <div className={classes.dataLargeRow}>
            <strong>{item.name}</strong>
            <div>{item.allowed}</div>
            <div>{item.email}</div>
          </div>
        </TableCell>
        <TableCell>
          {item.status}
          <div>{`Allowed: ${allowed}`}</div>
          <div>{`Current: ${current}`}</div>
        </TableCell>
        <TableCell>
          {item.contact && item.contact.name && (
            <div className={classes.dataLargeRow}>
              {item.contact.name && (
                <div className={classes.capitalize}>
                  {item.contact.name} ({item.contact.role})
                </div>
              )}
              {item.contact.email && <div>{item.contact.email}</div>}
              {item.contact.phone && <div>Ph: {item.contact.phone}</div>}
            </div>
          )}
        </TableCell>
        {isPayment && (
          <TableCell>
            <div>{`${LABELS.plan}: ${planName}`}</div>
            {duesOver !== 0 && (
              <div style={{ color: "red" }}>
                {`${LABELS.overdues}: ${duesOver}`}{" "}
              </div>
            )}
            {duesMonth !== 0 && (
              <div style={{ color: "orange" }}>
                {`Dues this month: ${duesMonth}`}{" "}
              </div>
            )}
            {duesMonth === 0 && duesOver === 0 && <div>No dues </div>}
            {isStripe && (
              <>
                {item.stripeBuss ? (
                  <div>
                    <small>{item.stripeBuss}</small>
                  </div>
                ) : (
                  <div style={{ color: "red" }}>{LABELS.missingstripe}</div>
                )}
              </>
            )}
          </TableCell>
        )}

        <TableCell>
          <div className={classes.dataLargeRow}>
            {item.lastActive && item.status !== "ACTIVE" && (
              <strong style={{ color: "red" }}>
                Active: {this.props.formatDate(item.lastActive)}
              </strong>
            )}
            {item.created && (
              <div>
                {LABELS.created}: {this.props.formatDate(item.created)}
              </div>
            )}
            {item.edited && (
              <div>
                {LABELS.edited}: {this.props.formatDate(item.edited)}
              </div>
            )}
          </div>
        </TableCell>
        <TableCell>
          {/* <Button
                        size="small"
                        className={classes.button}
                        variant="contained"
                        disabled={item.status !== "ACTIVE"}
                        color="primary"
                        onClick={e => this.props.handleDetails(item)}
                    >
                        DETAILS
                    </Button> */}
          {/* <br /> */}
          <Button
            size="small"
            variant="text"
            disabled={item.status !== "ACTIVE"}
            color="primary"
            onClick={(e) => this.props.handleAccountDetails(item)}
          >
            {LABELS.details}
          </Button>
          <br />
          <Button
            size="small"
            variant="text"
            color="primary"
            onClick={(e) => this.props.handleEdit(item)}
          >
            {LABELS.edit}
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export class AccountTable extends React.Component {
  state = { open: false, selectedAccount: null, order: "asc" };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleDetails = async (selectedAccount) => {
    this.setState({ loading: true });
    this.setState({ open: true });
    this.props.selectedAccount(selectedAccount);
    const accountId = selectedAccount._id;
    await this.props.getAccountDetails({ accountId });
    this.setState({ loading: false });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.selectedAccount(null);
  };

  render() {
    const { eld, classes, items = [], count = 1000 } = this.props;
    const { page, limit } = this.props;

    const { open, loading } = this.state;
    const isPayment = ADMIN_CONFIG.payments;
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <ViewAccountDetail
            open={open}
            handleClose={this.handleClose}
            loading={loading}
          />
          <Paper className={classes.root}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{LABELS.name}</TableCell>
                  <TableCell>{LABELS.status}</TableCell>
                  <TableCell>{LABELS.contact}</TableCell>
                  {isPayment && <TableCell>{LABELS.payment}</TableCell>}
                  <TableCell>{LABELS.created}</TableCell>
                  <TableCell>{LABELS.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(items) &&
                  items.map((item, i) => (
                    <ReduxTableRow
                      item={item}
                      key={i}
                      eld={eld}
                      isPayment={isPayment}
                      classes={classes}
                      handleDetails={this.handleDetails}
                      handleEdit={this.props.handleEdit}
                      formatDate={this.props.formatDate}
                      isStripe={this.props.isStripe}
                      handleAccountDetails={this.props.handleAccountDetails}
                    />
                  ))}
              </TableBody>
            </Table>
            <NotFoundTableRow items={items} />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              labelRowsPerPage={LABELS.rowsperpage}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

AccountTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountTable);
