import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import { signIn } from "../../../actions/auth/authActions";
import { getTimeZone } from "../../../actions/momentActions";
import { createCarrier, updateCarrier } from "../../../actions/carrierActions";
import { getBillCustomer } from "../../../actions/adminBillActions";
import { validateEdit } from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import InputDateBox from "../../../components/InputDateBox";
import Error from "../../Error";
import Success from "../../Success";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 25,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class EditAccountForm extends React.Component {
  state = {
    customers: [],
  };

  componentDidMount() {
    this.getBillCustomer();
  }

  getBillCustomer = async () => {
    if(!this.props.isStripe){
      return
    }
    if (this.props.isStripe) {
      const selected = this.props.selected;

      if (selected && selected.email) {
        const response = await this.props.getBillCustomer({
          limit: 100,
          email: selected.email,
        });

        if (response && Array.isArray(response.data)) {
          this.setState({
            customers: response.data,
            loading: false,
          });
        }
      }
    }
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response = await this.props.updateAccount(values);

    if (response && response._id) {
      this.setState({
        successMessage: LABELS.account_is_updated_successfully,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = LABELS.response;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants, selected } = this.props;
    const { isSuperAdmin, resellers, isStripe } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const customers = this.state.customers || [];

    return (
      <Formik
        initialValues={selected}
        validate={validateEdit}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => {
          return (
            <Form style={{ padding: "20px 10px" }}>
              <Grid container spacing={3}>
                <InputBox md={4} name="name" label={LABELS.accountName} />
                <SelectBox
                  md={2}
                  name="status"
                  label={LABELS.status}
                  items={constants.ALL_ACCOUNT_STATUS_OPTIONS}
                />
                <InputBox md={2} name="phone" label={LABELS.phone} />
                <InputBox md={2} name="email" label={LABELS.email} />
                <InputDateBox
                  md={2}
                  name="lastActive"
                  label={LABELS.lastActive}
                  format={"MM/DD/yyyy"}
                  type="date"
                />

                <InputBox
                  md={3}
                  name="contact.name"
                  label={LABELS.contactName}
                />
                <InputBox
                  md={3}
                  name="contact.email"
                  label={LABELS.contactEmail}
                />
                <InputBox
                  md={3}
                  name="contact.phone"
                  label={LABELS.contactPhone}
                />
                <InputBox
                  md={3}
                  name="contact.role"
                  label={LABELS.contactRole}
                />

                {isStripe && (
                  <SelectBox
                    md={3}
                    selValue={"id"}
                    selLabel="name"
                    selLabelTwo="email"
                    selLabelThree="id"
                    name="stripeBuss"
                    label={LABELS.stripe}
                    items={customers}
                  />
                )}

                <InputBox
                  md={isStripe ? 9 : 12}
                  name="notes"
                  label={LABELS.note}
                />
                {isSuperAdmin && (
                  <SelectBox
                    md={12}
                    name="resellerId"
                    label={LABELS.reseller}
                    items={resellers}
                    selValue={"_id"}
                  />
                )}
              </Grid>
              {successMessage && <Success message={successMessage} />}
              {errorMessage && <Error message={errorMessage} />}
              <div className={classes.DialogActions}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {/* {loading ? "Saving..." : "SAVE"} */}
                  {loading ? LABELS.saving : LABELS.save}
                </Button>{" "}
                <Button variant="contained" onClick={this.props.handleClose}>
                  {LABELS.close}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  const userRoles = _get(state, "authData.profile.roles", []);
  let isStripe = _get(state, "appData.appConfig.isStripe", "OFF") === "ON";

  if (userRoles.includes("PARTNER")) {
    isStripe = false;
  }
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
    isStripe,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signIn,
      createCarrier,
      updateCarrier,
      getTimeZone,
      getBillCustomer,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAccountForm))
);
