export const LABELS = {
    leads: "लीड",
  
    channel: "चैनल",
    status: "स्थिति",
    dot: "डॉट",
    name: "नाम",
    adress_line: "पता पंक्ति",
    address_city: "पता शहर",
    address_state: "पता राज्य",
    address_zip: "पता पिनकोड",
    contact_name: "संपर्क नाम",
    contact_email: "संपर्क ईमेल",
    contact_phone: "संपर्क फोन",
    callback: "कॉलबैक",
    description: "विवरण",
  
    assignedto: "सौंपा गया",
    assignee_email: "सौंपने वाला ईमेल",
  
    saving: "सहेजा जा रहा है",
    save: "सहेजें",
    close: "बंद करें",
  
    lead_is_saved_successfully: "लीड सफलतापूर्वक सहेजा गया है",
    something_went_wrong_please_try_after_some_time: "कुछ गलत हो गया, कृपया कुछ समय बाद पुनः प्रयास करें।",
  };
  