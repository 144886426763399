export function validate(values = {}) {
    const errors = {};

    if (!values.shippingDoc) {
        errors.shippingDoc = "Required.";
    }
    else if (values.shippingDoc.length >= 41) {
        errors.shippingDoc = "Max limit 40 characters only .";
    }

    return errors;
}
