import axios from "./axios";
import _get from "lodash/get";

export function getTodosCount(params = {}, accId) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/${params.accountId}/todo/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getTodos(params = {}, accId) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/${params.accountId}/todo`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createTodo(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/${params.accountId}/todo`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateTodo(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/${params.accountId}/todo`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
