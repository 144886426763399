const validate = values => {
    const errors = {};
    let requiredFields = ["role", "firstName", "lastName", "email"];

    if (values.role === "DRIVER") {
        requiredFields = [
            "role",
            "alias",
            "firstName",
            "lastName",
            "email",
            "terminalId",
            "carrierId",
            "country",
            "regulation",
            "state",
            "number",
            "exemptDriver",
            "equipments",
            "vehicleId"
        ];
    }
    if (values.firstName && values.firstName.trim().length < 2) {
        errors.firstName = "First Name must be at least 2 characters";
    } else if (values.firstName && values.firstName.trim().length > 30) {
        errors.firstName = "First Name cannot exceed 30 characters";
    }
    if (values.lastName && values.lastName.trim().length < 2) {
        errors.lastName = "Last Name must be at least 2 characters";
    } else if (values.lastName && values.lastName.trim().length > 30) {
        errors.lastName = "Last Name cannot exceed 30 characters";
    }

    if (values.number && values.number.trim().length < 1) {
        errors.number = "Name must be at least 1 characters";
    } else if (values.number && values.number.trim().length > 20) {
        errors.number = "Name cannot exceed 20 characters";
    }
    if (!values.isEdit) {
        requiredFields.push("password");
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    } else if (values.exemptDriver === "ON" && !values.exemptAnnotation) {
        errors.exemptAnnotation = `Required.`;
    }
    return errors;
};

const validateSms = values => {
    const errors = {};

    if (!values.to) {
        errors.to = "Required";
    } else if (values.to && values.to.toString().length !== 10) {
        errors.to = "Invalid phone number";
    }
    if (!values.message) {
        errors.message = "Required";
    }

    return errors;
};

export { validateSms };
export default validate;
