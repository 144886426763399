import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./locationActionTypes";

export const setLocations = value => ({
    type: TYPES.LOCATIONS,
    value
});

export const setLocationsCount = value => ({
    type: TYPES.LOCATIONS_COUNT,
    value
});

export const appLocationBusy = value => ({
    type: TYPES.LOCATION_BUSY,
    value
});

export const setPagination = value => ({
    type: TYPES.LOCATION_PAGINATION,
    value
});

export function getLocationCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.REALTIME_URL");
        return axios({
            url: `${BASE_URL}/realtime/locations/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setLocationsCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getLocations(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.REALTIME_URL");
        dispatch(appLocationBusy(true));

        return axios({
            url: `${BASE_URL}/realtime/locations`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.locations", []);
                dispatch(setLocations(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appLocationBusy(false));
                return response;
            });
    };
}
