import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import { createQueue, updateQueue } from "../../actions/supportActions";
import { getUsers } from "../../actions/userActions";
import { validate, defaultValues } from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
// import AdminAccountSearch from "../../components/AdminAccountSearch";
import Error from "../Error";
import Success from "../Success";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.supportForm;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddResellerForm extends React.Component {
  state = {
    accounts: [],
    drivers: [],
  };

  componentDidMount() {
    this.getDrivers();
  }

  getDrivers = async () => {
    const payload = {
      limit: 500,
      skip: 0,
      status: "ACTIVE",
    };

    const drivers = await this.props.getUsers(payload);
    this.setState({ drivers: drivers || [] });
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response;

    values.plan = this.props.planName;
    if (values._id) {
      response = await this.props.updateQueue(values);
    } else {
      values.accountName = _get(this, "props.profile.account.name");
      values.accountId = _get(this, "props.profile.user.accountId");
      values.accountName = _get(this, "props.profile.account.name");
      values.status = "NEW";
      values.plan = _get(this, "props.profile.plan.plan.name");
      values.product = "ELD";
      values.callTm = new Date();
      values.app = {};

      response = await this.props.createQueue(values);
    }

    if (response && response._id) {
      this.setState({
        successMessage: LABELS.successMessage,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = LABELS.response;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants, } = this.props;
    const drivers = this.state.drivers || [];
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;
    let initialValues = {
      ...defaultValues,
      ...selected,
    };

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={this.props.isAdd}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{`${LABELS.addsupportcase}`}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <SelectBox
                      md={6}
                      selValue={"id"}
                      selLabel={"firstName"}
                      selLabelTwo={"lastName"}
                      selLabelThree={"status"}
                      name="driverId"
                      label={LABELS.driver}
                      items={drivers}
                      onChange={(e) => {
                        const _dId = e.target.value;
                        const _driver = drivers.find(
                          (item) => item.id === _dId
                        );
                        const _d = {
                          name: `${_driver.firstName} ${_driver.lastName}`,
                          email: _driver.email,
                          phone: _driver.mobile,
                        };
                        setFieldValue("driver", _d);
                      }}
                    />
                    <SelectBox
                      md={6}
                      name="reason"
                      label={"Reason"}
                      items={constants.QUEUE_REASON}
                    />
                    <InputBox md={6} name="driver.phone" label={"Phone"} />
                    <InputBox md={6} name="driver.email" label={"Email"} />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? LABELS.saving : LABELS.save}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: _get(state, "authData.profile", {}),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    planName: _get(state, "authData.profile.plan.plan.name", "STANDARD"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers,
      createQueue,
      updateQueue,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddResellerForm))
);
