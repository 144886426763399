import React, { useState } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { Alert, AlertTitle } from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttomWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: "20px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusButton: {
    margin: "0 2vh 0 2vh",
    float: "right",
  },
}));

const UpdateButton = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [response, setResponse] = useState("");
  const isSmallScreen = window.innerWidth < 800;

  function onClose() {
    setLoading(false);
    setErrorMessage("");
    setSuccessMessage("");
    setConfirmModal(false);
  }

  const changeStatus = async (user) => {
    const { updateUserAccount, getAccountDetails } = props;
    setLoading(true);
    if (user.status === "ACTIVE") {
      user.status = "IN_ACTIVE";
    } else {
      user.status = "ACTIVE";
    }
    const response = await updateUserAccount(user);
    let params = {
      accountId: user.account,
    };
    await getAccountDetails(params);
    setResponse(response);

    if (response && user.status === "ACTIVE") {
      setSuccessMessage("Account activated successfully!");
      setErrorMessage("");
      setLoading(false);
    } else if (response && user.status === "IN_ACTIVE") {
      setSuccessMessage("Account deactivated successfully!");
      setErrorMessage("");
      setLoading(false);
    } else {
      setSuccessMessage("");
      setErrorMessage("Something went wrong!");
      setLoading(false);
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
      setConfirmModal(false);
    }, 2000);
  };

  const { active, user, accountAuthBusy } = props;

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        size="small"
        className={classes.statusButton}
        disabled={accountAuthBusy || loading}
        style={{
          color: active ? "red" : "green",
          borderColor: active ? "red" : "green",
        }}
        onClick={() => {
          setConfirmModal(true);
        }}
      >
        {active ? LABELS.deactivate : LABELS.activate}
      </Button>
      {isConfirmModal && (
        <Dialog
          fullScreen={isSmallScreen}
          fullWidth={true}
          maxWidth={"md"}
          open={isConfirmModal}
        >
          <DialogTitle id="alert-dialog-title" style={{ maxHeight: "9rem" }}>
            <p style={{ marginTop: 0, textAlign: "center" }}>
              {`${LABELS.pleaseConfirm} ${
                active ? LABELS.deactivate : LABELS.activate
              }`}
            </p>
            <IconButton
              style={{ marginTop: 5 }}
              aria-label="Close"
              className={classes.closeButton}
              onClick={() => {
                setConfirmModal(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {successMessage && (
              <Alert severity="success">
                <AlertTitle>{successMessage}</AlertTitle>
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error">
                <AlertTitle>{errorMessage}</AlertTitle>
              </Alert>
            )}
            {loading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}
            <div className={classes.buttomWrapper}>
              <Button
                variant="outlined"
                color="primary"
                disabled={loading || (response && response.email)}
                onClick={() => {
                  changeStatus(user);
                }}
              >
               { active ? LABELS.deactivate : LABELS.activate}
              </Button>
            </div>
            <div className={classes.buttomWrapper}>
              <Button color="secondary" onClick={onClose}>
                {LABELS.cancel}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default UpdateButton;
