import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { createBillCustomer } from "../../../actions/adminBillActions";
import { updateAccount } from "../../../actions/account/accountAdminActions";
import Error from "../../Error";
import Success from "../../Success";
// import { LABELS as _LABELS } from "../../../language";

// const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export class AddBillCustomer extends React.Component {
  state = {};

  componentDidMount() {}

  handleSave = async () => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    const payload = {
      description: "Field customer",
      email: _get(this, "props.account.email"),
      name: _get(this, "props.account.name"),
    };

    let response = await this.props.createBillCustomer(payload);

    if (response && response.id) {
      const accPayload = {
        _id: _get(this, "props.account._id"),
        stripeBuss: response.id,
      };
      await this.props.updateAccount(accPayload);
      this.setState({
        successMessage: "Customer is created successfully",
        loading: false,
      });
      setTimeout(() => {
        this.props.handleSave();
      }, 1000);
    } else {
      this.setState({
        errorMessage: '"Something went wrong, please try after some time."',
        loading: false,
      });
    }
  };

  render() {
    const { errorMessage, successMessage, loading } = this.state;
    const { classes, } = this.props;
    // const { adminAccess, resellers } = this.props;
    const isSmallScreen = window.innerWidth < 400;
    const name = _get(this, "props.account.name");
    const email = _get(this, "props.account.email");
    const status = _get(this, "props.account.status");

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{"Add Customer"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {`Status: ${status}`}
              </Typography>
              <Typography variant="body2">{email}</Typography>

              {successMessage && <Success message={successMessage} />}
              {errorMessage && <Error message={errorMessage} />}
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                size="small"
                disabled={loading}
                onClick={this.handleSave}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    accountValues: _get(state, "form.accountForm.values", {}),
    adminAccess: _get(state, "authData.profile.adminAccess", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createBillCustomer,
      updateAccount,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBillCustomer))
);
