// import _get from "lodash/get";
import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
// import Chip from "@mui/material/Chip";
// import Paper from "@mui/material/Paper";
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    height: 14,
  },
  dot: {
    height: 10,
    width: 10,
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 5,
  },
  formsColor: {
    backgroundColor: "#f5cb42",
  },
}));

function TimeCircle({ per, color, time, type, backColor }) {
  return (
    <div className="time-circle svg">
      <h2>{time}</h2>
      <h3>{type}</h3>
      <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
        <g>
          <title>{type}</title>
          <circle
            id="circle-back"
            strokeDasharray={440}
            r="69.85699"
            cy="81"
            cx="81"
            strokeWidth="8"
            stroke={backColor}
            fill="none"
          />
          <circle
            id="circle"
            strokeDashoffset={(per * 440) / 100}
            strokeDasharray={440}
            r="69.85699"
            cy="81"
            cx="81"
            strokeWidth="8"
            stroke={color}
            fill="none"
          />
        </g>
      </svg>
    </div>
  );
}

export default function DriverRecap(props) {
  const classes = useStyles();
  const data = props.data || {};
  const violations = data.violations ? parseInt(data.violations) : 0;
  const forms = data.forms ? parseInt(data.forms) : 0;
  const total = violations + forms;
  const violationsPer = data.violations
    ? Math.round((violations / total) * 100)
    : 0;
  const formsPer = data.forms ? Math.round((forms / total) * 100) : 0;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TimeCircle
          per={formsPer}
          color={"red"}
          time={total}
          type={LABELS.total}
          backColor={"#f5cb42"}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <div>
          <span className={classes.dot}></span>
          {LABELS.violations}
        </div>
        <div>{`${violations} (${violationsPer}%)`}</div>
      </Grid>
      <Grid item xs={12} md={12}>
        <div>
          <span className={`${classes.dot} ${classes.formsColor}`}></span>
          {LABELS.formsSignatures}
        </div>
        <div>{`${forms} (${formsPer}%)`}</div>
      </Grid>
    </Grid>
  );
}
