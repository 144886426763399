import VIN from "vehicle-identification-number";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

const validate = values => {
    const errors = {};
    let requiredFields = [
        "name",
        "vin",
        "licPlate",
        "dvir",
        "carrierId",
        "regulationMode"
    ];
    if (values.name && values.name.trim().length < 1) {
        errors.name = "Name must be at least 1 characters long";

    } else if (values.name && values.name.trim().length > 9) {
        errors.name = "Name cannot exceed 9 characters";
    }

    if (values.assetType === "TRAILER") {
        requiredFields = ["name", "licPlate", "dvir", "carrierId"];
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${LABELS.required}`;
        } else if (
            field === "name" &&
            values[field] &&
            values[field].length > 10
        ) {
            errors.name = "Too long";
        }
    });
    if (values.vin && values.assetType !== "TRAILER") {
        try {
            VIN.parse(values.vin);
        } catch (e) {
            errors.vin = `VIN is not valid.`;
        }
    }
    return errors;
};

export default validate;
