
import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader , Marker, InfoWindow } from "@react-google-maps/api";
import Card from "@mui/material/Card";
import { gMapApiKey } from "../../../utils/constants";

const Map = ({ allStatus, selIndex}) => {
  // console.log("allStatus,",allStatus)
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [center, setCenter] = useState({ lat: 37.658428, lng: -121.876999 });
  const [zoom, setZoom] = useState(5);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gMapApiKey ,
  });

  useEffect(() => {
    if (allStatus.length > 0 && selIndex !== null && selIndex >= 0) {
      const selectedData = allStatus[selIndex];
      console.log("selected", selectedData);

      const { coordinates } = selectedData;

      const newMarkers = [{
        lat: parseFloat(coordinates[0]),
        lng: parseFloat(coordinates[1]),
        data: selectedData,
      }];

      setMarkers(newMarkers);

      setCenter({ lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1]) });
      setZoom(12);
    }
  }, [allStatus, selIndex]);

  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const RenderMarker = ({ marker }) => {
    // console.log("Marker lat:", marker.lat);
    // console.log("Marker lng:", marker.lng);

    return (
      <Marker
        position={{ lat: marker.lat, lng: marker.lng }}
        onClick={() => onMarkerClick(marker)}
      />
    );
  };

  return (
    <Card variant="outlined">
      <div style={{ width: "100%", height: "600px" }}>
      {isLoaded ? (
          <GoogleMap
            zoom={zoom}
            center={center}
            mapContainerStyle={{ width: "100%", height: "100%" }}
          >
            {markers.map((marker, index) => (
              <RenderMarker key={`marker-${index}`} marker={marker} />
            ))}

            {selectedMarker && (
              <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div 
                style={{
                  textAlign: "center",
                  padding: "5px 2px 0px 2px",
                  fontWeight: 450,
                  fontSize: "12px",
                  color: "#333333",
                  cursor: "default",
 
                }}
                >
                  <p>Status: {selectedMarker.data.eventCode}</p>
                  <p> Location:-{`${selectedMarker.data.city} ${selectedMarker.data.state}`}</p>     
                  <p>Latitude: {selectedMarker.lat}</p>
                  <p>Longitude: {selectedMarker.lng}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
          ) : (
            <></>
          )}
      </div>
    </Card>
  );
};

export default Map;