import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Field } from "redux-form";
import TextField from "@mui/material/TextField";

const renderTextField = ({
  label,
  input,
  onChange,
  type = "text",
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    fullWidth={true}
    size="small"
    variant="standard"
    label={label}
    placeholder={label}
    error={touched && invalid}
    onChange={onChange}
    helperText={touched && error}
    type={type}
    {...input}
    {...custom}
  />
);

export default class ReduxInput extends React.Component {
  static defaultProps = {
    onChange: () => {},
  };

  onChange = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    const {
      xs = 12,
      sm = 12,
      name,
      label,
      helpText,
      type,
      disabled,
    } = this.props;

    const _label = label ? label : name;

    return (
      <Grid
        item
        xs={xs}
        sm={sm}
        style={{
          textAlign: "center",
          paddingRight: 5,
          paddingLeft: 5,
          paddingTop: 15,
        }}
      >
        <Field
          name={name}
          component={renderTextField}
          label={_label}
          type={type}
          onChange={this.onChange}
          disabled={disabled}
        />
        {helpText && (
          <div style={{ textAlign: "left", fontSize: 10 }}>{helpText}</div>
        )}
      </Grid>
    );
  }
}

ReduxInput.propTypes = {
  onChange: PropTypes.func,
};
