export const LABELS = {
  profileInfo: "Profile Info",
  accountInfo: "Информация об аккаунте",
  name: "Имя",
  email: "Электронная почта",
  status: "Положение дел",
  language: "Язык",
  uuid: "UUID",
  roles: "Роли",
  account: "Счет",
  accountId: "Идентификатор аккаунта",
  save: "Сохранять",
  edit: "Редактировать",
  phone: "Телефон",
  carrier: "Перевозчик",
  terminal: "Терминал",
  required: "Необходимый",
};
