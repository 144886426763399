import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Formik, Form } from "formik";
import {
  createReseller,
  updateReseller,
} from "../../actions/reseller/resellerActions";
import { validate, defaultValues } from "./validate";
import Grid from '@mui/material/Grid';
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.resellerForm;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddResellerForm extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response;

    if (values._id) {
      response = await this.props.updateReseller(values);
    } else {
      response = await this.props.createReseller(values);
    }

    if (response && response._id) {
      this.setState({
        successMessage: "Reseller is created successfully",
        loading: false,
      });
      // this.props.getAccounts();
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after some time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;
    const initialValues = { ...defaultValues, ...selected };

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.isAdd}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{LABELS.addAccount}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {

              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <InputBox md={3} name="name" label={LABELS.name} />
                    <SelectBox
                      md={3}
                      name="status"
                      label={LABELS.status}
                      items={constants.ALL_STATUS_OPTIONS}
                    />
                    <InputBox md={3} name="ein" label="Ein" />

                    <SelectBox
                      md={3}
                      name="bussType"
                      label={LABELS.type}
                      items={constants.BUSS_TYPE}
                    />

                    <InputBox md={3} name="contact.name" label={LABELS.name}/>
                    <InputBox md={3} name="contact.email" label={LABELS.email} />
                    <InputBox md={3} name="contact.phone" label={LABELS.phone} />
                    <InputBox md={3} name="contact.role" label={LABELS.role} />

                    <InputBox md={3} name="billing.name" label={LABELS.billing_name}/>
                    <InputBox
                      md={3}
                      name="billing.email"
                      label={LABELS.billing_email}
                    />
                    <InputBox
                      md={3}
                      name="billing.phone"
                      label={LABELS.billing_phone}
                    />
                    <InputBox md={3} name="billing.role" label={LABELS.billing_role}/>

                    <InputBox md={4} name="stripeBuss" label={LABELS.stripe}/>
                    <InputBox md={8} name="notes" label={LABELS.note} />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {`${loading ? LABELS.saving: LABELS.save}`}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createReseller,
      updateReseller,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddResellerForm))
);
