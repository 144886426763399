import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LABELS as _LABELS } from "../../../../language";
import { Formik, Form } from "formik";
import validate from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../../../components/InputBox";
import SelectBox from "../../../../components/SelectBox";
import Error from "../../../Error";
import Success from "../../../Success";
import { addYears, formatDateTimeUtc } from "../../../../actions/momentActions";
import {
  createMaintenance,
  updateMaintenance,
} from "../../../../actions/maintenanceActions";
import { getEquipments } from "../../../../actions/equipmentActions";

const LABELS = _LABELS.settingPage;

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttons: {
    marginTop: 20,
    textAlign: "right",
  },
}));

function MaintenanceForm(props) {
  const constants = props.constants || {};
  const isSmallScreen = window.innerWidth < 400;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [equipments, setEquipments] = useState([]);

  async function getEqps() {
    const payload = {
      skip: 0,
      limit: 100,
      assetType: "VEHICLE",
      active: "true",
    };

    const response = await props.getEquipments(payload);
    setEquipments(response);
  }

  useEffect(() => {
    getEqps();
  }, []);

  async function handleSubmit(values) {
    try {
      setLoading(true);

      const _sel = equipments.find((item) => item.id === values.assetId);
      const assetInfo = _sel.vehicle || {};

      if (values._id) {
        assetInfo.mntn = values.status !== "COMPLETE";
      } else {
        assetInfo.mntn = true;
      }

      values.assetInfo = assetInfo;

      const response = values._id
        ? await props.updateMaintenance(values)
        : await props.createMaintenance(values);
      setLoading(false);
      if (response && response._id) {
        setSuccessMessage(`${LABELS.saved_Successfully}`);
        setTimeout(() => props.handleClose(), 1000);
      } else {
        setErrorMessage(`${LABELS.errorMassage}`);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(`${LABELS.errorMassage}`);
    }
  }

  const initialValues = {
    type: "OIL_CHANGE",
    status: "NEW",
    dueBy: "MILEAGE",
    notes: "",
    assetId: "",
    assetName: "",
    assetType: "VEHICLE",
    ...props.selected,
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <React.Fragment>{LABELS.maintenance}</React.Fragment>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values = {}, handleSubmit, setFieldValue, errors }) => {
            return (
              <Form style={{ padding: "10px 10px" }}>
                <Grid container spacing={3}>
                  <SelectBox
                    md={4}
                    name="type"
                    label={`${LABELS.type}`}
                    items={constants.MAINTENANCE_TYPE}
                  />
                  <SelectBox
                    md={4}
                    name="status"
                    label={`${LABELS.status}`}
                    items={constants.MAINTENANCE_STATUS}
                  />
                  <SelectBox
                    md={4}
                    name="assetType"
                    label={`${LABELS.assetType}`}
                    items={constants.MAINTENANCE_ASSET_TYPE}
                  />
                  <SelectBox
                    md={4}
                    name="assetId"
                    label={`${LABELS.vehicle}`}
                    items={equipments}
                    onChange={(e) => {
                      const _sel = equipments.find(
                        (item) => item.id === e.target.value
                      );
                      setFieldValue("assetName", _sel.name);
                    }}
                  />
                  <SelectBox
                    md={4}
                    name="dueBy"
                    label={`${LABELS.dueby}`}
                    items={constants.MAINTENANCE_DUE_BY}
                  />
                  {values.dueBy === "MILEAGE" && (
                    <InputBox md={4} name="odometer" label={`${LABELS.odometer}`} />
                  )}
                  {values.dueBy === "TIME" && (
                    <InputBox type="date" md={4} name="date" label={`${LABELS.date}`} />
                  )}
                  <InputBox md={12} name="notes" label={`${LABELS.notes}`} />
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
                <div className={classes.buttons}>
                  <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    disabled={!!loading}
                  >
                    {loading ? `${LABELS.saving}` : `${LABELS.save}`}
                  </Button>{" "}
                  <Button
                    size="small"
                    variant="contained"
                    onClick={props.handleClose}
                  >
                    {LABELS.close}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    isAdmin: !!_get(state, "authData.profile.adminId"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createMaintenance,
      updateMaintenance,
      formatDateTimeUtc,
      addYears,
      getEquipments,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MaintenanceForm)
);
