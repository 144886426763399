import * as cookie from "react-cookie";

const cookies = new cookie.Cookies();

let language = "en";

try {
  language = cookies.get("language", {
    path: "/",
  });

  if (!language || language === "undefined") {
    // const _language = "en-US";
    // const _language = "es-US";
    const _language = navigator.language || "";
    language = _language.split("-")[0];
  }
} catch (e) {}

export function updateLang(_lang) {
  cookies.set("language", _lang, {
    path: "/",
  });
  window.location.reload();
}

export const LANGS = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "es",
    name: "Español",
  },
  {
    id: "fr",
    name: "Français",
  },
  {
    id: "rs",
    name: "Русский",
  },
  {
    id:"pb",
    name:"ਪੰਜਾਬੀ"
  },
  {
    id:'hn',
     name:"हिंदी"
  }

];

if (language !== "en" && language !== "es" && language !== "fr" && language !== "rs" && language !=="pb" && language !=="hn") {
  language = "en";
}

export const selectedLang = language;

function getLabels() {
  const navigation = require(`./${language}/navigation`);
  const logInPage = require(`./${language}/logInPage`);
  const realtimePage = require(`./${language}/realtimePage`);
  const diaryPage = require(`./${language}/diaryPage`);
  const profilePage = require(`./${language}/profilePage`);
  const adminPage = require(`./${language}/adminPage`);
  const dutyPage = require(`./${language}/dutyPage`);
  const messages = require(`./${language}/messages`);
  const supportForm = require(`./${language}/supportForm`);
  const leadForm = require(`./${language}/leadsForm`);
  const resellerForm = require(`./${language}/resellerForm`);
  const settingPage = require(`./${language}/settingPage`);
  const termCondition=require(`./${language}/termCondition`)

  const params = {
    language: language,
    navigation: navigation.LABELS,
    logInPage: logInPage.LABELS,
    realtimePage: realtimePage.LABELS,
    diaryPage: diaryPage.LABELS,
    dutyPage: dutyPage.LABELS,
    profilePage: profilePage.LABELS,
    adminPage: adminPage.LABELS,
    messages: messages.LABELS,
    supportForm: supportForm.LABELS,
    leadForm: leadForm.LABELS,
    resellerForm: resellerForm.LABELS,
    settingPage: settingPage.LABELS,
    termCondition:termCondition.LABELS
  };

  return params;
}

export const LABELS = getLabels();
