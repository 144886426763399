import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import EditIcon from "@mui/icons-material/Edit";
import NotFoundTableRow from "../../NotFoundTableRow";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;


const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableRowTitle: {
    display: "inline-block",
    width: 50,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, isViewOnly, item = {} } = this.props;

    const license = _get(item, "license", {});
    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          {item.name}
        </TableCell>
        <TableCell>{item.active ? "ACTIVE" : "IN ACTIVE"}</TableCell>

        <TableCell>
          <div>{license.plate && <div>{license.plate}</div>}</div>
        </TableCell>
        <TableCell>{item.vin && item.vin}</TableCell>

        <TableCell>
          <div>
            {item.created && (
              // <div>Created: {this.props.formatDate(item.created)}</div>
              <div>{LABELS.created}: {this.props.formatDate(item.created)}</div>
            )}
          </div>
          <div>
            {item.edited && (
              // <div>Edited: {this.props.formatDate(item.edited)}</div>
              <div>{LABELS.edited}: {this.props.formatDate(item.edited)}</div>
            )}
          </div>
        </TableCell>
        {!isViewOnly && (
          <TableCell>
            <EditIcon
              className={classes.EditIcon}
              onClick={() => this.props.handleEdit(item)}
            />
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export class ReduxTable extends React.Component {
  state = {
    order: "asc",
  };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ limit, page });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ limit, page });
  };

  handleSort = (field) => {
    const { page, limit } = this.props;
    const order = this.state.order === "desc" ? "asc" : "desc";

    this.setState({ order });
    this.props.handleChange({ page, limit, order: `${field} ${order}` });
  };

  render() {
    const { eld, classes, items = [], trailerCount = 0 } = this.props;
    const { page, limit, isViewOnly } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.name}</TableCell>
              <TableCell>{LABELS.status}</TableCell>
              {eld && <TableCell>{LABELS.eld}</TableCell>}
              <TableCell>{LABELS.licPlate}</TableCell>
              <TableCell>{LABELS.vin}</TableCell>
              <TableCell>{LABELS.created_edited}</TableCell>

              {!isViewOnly && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  eld={eld}
                  classes={classes}
                  handleEdit={this.props.handleEdit}
                  formatDate={this.props.formatDate}
                  isViewOnly={isViewOnly}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          component="div"
          count={trailerCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          labelRowsPerPage={LABELS.rowsperpage}
        />
      </Paper>
    );
  }
}

ReduxTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReduxTable);
