const validate = (values = {}) => {
    const errors = {};

    if (!values.speedThreshold) {
        errors.speedThreshold = "Speed threshold is required.";
    }
    if (!values.updateFrequency) {
        errors.updateFrequency = "Update frequency is required.";
    }
    if (!values.theme) {
        errors.theme = "Theme is required.";
    }

    return errors;
};

export default validate;
