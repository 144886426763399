import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import NotFoundTableRow from "../../NotFoundTableRow";
import BuildIcon from "@mui/icons-material/Build";
import { LABELS as _LABELS } from "../../../language";
const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableRowTitle: {
    display: "inline-block",
    width: 50,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const {
      eld,
      classes,
      item = {},
      adminKey,
      isViewOnly,
      showAll,
    } = this.props;
    const isEdit = item.active ? item.active : adminKey ? true : false;
    const engine = item.engine || {};
    const vehicle = item.vehicle || {};

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          {item.name}
          {vehicle.mntn && <BuildIcon color="primary" style={{ fontSize: 12 }} />}
          <div>
            <small>{item.vin}</small>
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          {engine.odo && <div>{`Odometer: ${engine.odo} Miles`}</div>}
          {engine.enghr && <div>{`Engine hr: ${engine.enghr} Hours`}</div>}
        </TableCell>
        {showAll && (
          <TableCell>{item.active ? "ACTIVE" : "IN ACTIVE"}</TableCell>
        )}
        <TableCell>{item.regulationMode}</TableCell>
        {eld && (
          <TableCell>
            {item.assetType === "VEHICLE" && item.eldDevice && (
              <div className={classes.capitalize}>
                {item.eldDevice.name && (
                  <div>
                    <span className={classes.tableRowTitle}>Name:</span>
                    {item.eldDevice.name}
                  </div>
                )}
                {item.eldDevice.connectionType && (
                  <div>
                    <span className={classes.tableRowTitle}>Type: </span>
                    {item.eldDevice.connectionType}
                  </div>
                )}
                {item.eldDevice.deviceType && (
                  <div>
                    <span className={classes.tableRowTitle}>Device:</span>{" "}
                    {item.eldDevice.deviceType}
                  </div>
                )}
                {item.eldDevice.serial && (
                  <div>
                    <span className={classes.tableRowTitle}>Serial:</span>{" "}
                    {item.eldDevice.serial}
                  </div>
                )}
              </div>
            )}
          </TableCell>
        )}
        <TableCell>
          {item.license && (
            <div>
              {item.license.state}-{item.license.plate}
            </div>
          )}
        </TableCell>
        <TableCell>
          <div>
            {item.created && (
              // <div>Created: {this.props.formatDate(item.created)}</div>
              <div>
                {LABELS.created}: {this.props.formatDate(item.created)}
              </div>
            )}
            {item.edited && (
              // <div>Edited: {this.props.formatDate(item.edited)}</div>
              <div>
                {LABELS.edited}: {this.props.formatDate(item.edited)}
              </div>
            )}
          </div>
        </TableCell>
        {!isViewOnly && (
          <TableCell>
            {isEdit && (
              <EditIcon
                className={classes.EditIcon}
                onClick={() => this.props.handleEdit(item)}
              />
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export class ReduxTable extends React.Component {
  state = {
    order: "asc",
  };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ limit, page });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ limit, page });
  };

  handleSort = (field) => {
    const { page, limit } = this.props;
    const order = this.state.order === "desc" ? "asc" : "desc";

    this.setState({ order });
    this.props.handleChange({ page, limit, order: `${field} ${order}` });
  };

  render() {
    const {
      eld,
      classes,
      items = [],
      equipmentsCount,
      isViewOnly,
      showAll,
    } = this.props;
    const { page, limit, adminKey } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.name}</TableCell>
              <TableCell>{"Detail"}</TableCell>
              {showAll && <TableCell>{LABELS.status}</TableCell>}
              <TableCell>{LABELS.regulationMode}</TableCell>
              {eld && <TableCell>{LABELS.eld}</TableCell>}
              <TableCell>{LABELS.plate}</TableCell>
              <TableCell>{LABELS.created_edited}</TableCell>
              {!isViewOnly && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  eld={eld}
                  adminKey={adminKey}
                  classes={classes}
                  showAll={showAll}
                  handleEdit={this.props.handleEdit}
                  formatDate={this.props.formatDate}
                  isViewOnly={isViewOnly}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={equipmentsCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          labelRowsPerPage={LABELS.rowsperpage}
        />
      </Paper>
    );
  }
}

ReduxTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReduxTable);
