import { LABELS } from "../../language";

const SIGN_IN_FORM = LABELS.logInPage.SIGN_IN_FORM;

const validate = (values) => {
  const errors = {};
  const requiredFields = ["password", "email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = SIGN_IN_FORM.required;
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = SIGN_IN_FORM.inValidEmail;
  }
  return errors;
};

const validateForgetPwd = (values) => {
  const errors = {};
  const requiredFields = ["email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = SIGN_IN_FORM.required;
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = SIGN_IN_FORM.inValidEmail;
  }
  return errors;
};

const validateUpdatePwd = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = SIGN_IN_FORM.required;
  } else if (values.password && values.password.length < 4) {
    errors.password = SIGN_IN_FORM.invalidPassword;
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = SIGN_IN_FORM.required;
  }

  if (
    values.password &&
    values.confirmPassword &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = SIGN_IN_FORM.passwordNoMatch;
  }
  return errors;
};

export { validateForgetPwd, validateUpdatePwd };
export default validate;
