export const LABELS = {
  dashboard: "Панель приборов",
  overview: "Обзор",
  accounts: "Счета",
  support: "Поддерживать",
  queue:"Очередь",
  shipping: "Перевозки",
  todo: "Делать",
  leads: "лиды",
  fmcsa: "Fmcsa",
  audit: "Аудит",
  users: "Пользователи",
  settings: "Настройки",
  reseller: "Реселлер",
  dailyLogs: "Ежедневные журналы",
  dvir: "Dvir",
  dutyStatus: "Статус обязанности",
  tracking: "Отслеживание",
  unidentified: "Неопознанный",
  malfunctions: "Неисправности",
  violations: "Нарушения",
  notifications: "Уведомления",
  document: "Документ",
  logEdits: "Изменения журнала",
  liveShare: "Прямая трансляция",
  stateMileage: "Государственный пробег",
  reports: "Отчеты",
  fleetmanager: "Управляющий флотом",

  carrier: "Перевозчик",
  terminal: "Терминал",
  equipment: "Оборудование",
  editReason: "Изменить причину",
  profile: "Профиль",
  api: "Api",
  alerts: "Оповещения",
  maintenance:"Обслуживание",
  whatsapp:"Whatsapp"
};
