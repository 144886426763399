import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ButtonGroup from "@mui/material/ButtonGroup";
import { getUsers } from "../../actions/userActions";
import { sendNotification } from "../../actions/notificationsActions";
import Grid from "@mui/material/Grid";
import ReduxButton from "../../components/ReduxButton";
import Error from "../Error";
import Success from "../Success";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.settingPage;


export class TermsDialog extends React.Component {
  state = {
    successMessage: "",
    errorMessage: "",
    loading: false,
  };

  componentDidMount() {
    this.props.getUsers({ skip: 0, limit: 250, status: "ACTIVE" });
  }

  save = async () => {
    let { userId, title, body } = this.state;
    const { driverId } = this.props;
    userId = driverId ? driverId : userId;
    this.setState({ successMessage: "", errorMessage: "" });

    if (!userId || !title || !body) {
      this.setState({
        errorMessage: "User, title and body is required.",
      });
      return;
    }
    this.setState({ loading: true });
    let response;
    try {
      response = await this.props.sendNotification({ userId, title, body });
    } catch (e) {
      //
    }

    if (response._id) {
      this.setState({
        loading: false,
        successMessage: "Message is send successfully.",
      });
      setTimeout(() => this.props.handleClose(), 1000);
    } else {
      this.setState({
        loading: false,
        errorMessage: "Something went wrong, please try after sometime.",
      });
    }
  };

  sendSilent = async (key) => {
    let { userId } = this.state;
    const { driverId } = this.props;
    userId = driverId ? driverId : userId;
    this.setState({ successMessage: "", errorMessage: "" });

    if (!userId) {
      this.setState({
        errorMessage: "User is required.",
      });
      return;
    }
    this.setState({ loading: true });
    let response = {};
    try {
      response = await this.props.sendNotification({
        userId,
        title: key,
        body: key,
        silent: true,
        key,
      });
    } catch (e) {
      //
    }

    if (response.silent) {
      this.setState({
        loading: false,
        successMessage: "Message is send successfully.",
      });
      setTimeout(() => this.props.handleClose(), 1000);
    } else {
      this.setState({
        loading: false,
        errorMessage: "Something went wrong, please try after sometime.",
      });
    }
  };

  handleChange = (event, name) => {
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  render() {
    const { users, driverId } = this.props;
    // const {isUnity } = this.props;
    const {
      errorMessage,
      successMessage,
      loading = false,
      userId = "",
      title = "",
      body = "",
    } = this.state;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h2 style={{ textAlign: "center", padding: 0 }}>
          {"Send Notification"}
        </h2>
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <IconButton
            aria-label="Close"
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md>
              <FormControl fullWidth size="small" variant="standard">
                <Select
                  id="userId"
                  value={driverId ? driverId : userId}
                  label="Select"
                  onChange={(e) => this.handleChange(e, "userId")}
                  disabled={driverId ? true : false}
                  style={{ marginTop: 15 }}
                >
                  {Array.isArray(users) &&
                    users.map((option) => {
                      if (
                        option.fcmToken ||
                        option.fcmIosToken ||
                        option.fcmWebToken
                      ) {
                      } else {
                        return null;
                      }
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.firstName} {option.lastName}
                          <small>
                            {option.email}
                            {` (${option.status})`}
                          </small>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md>
              <TextField
                fullWidth
                size="small"
                variant="standard"
                id="title"
                label={LABELS.title}
                value={title}
                onChange={(e) => this.handleChange(e, "title")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md>
              <TextField
                fullWidth
                size="small"
                variant="standard"
                id="body"
                label={LABELS.body}
                value={body}
                onChange={(e) => this.handleChange(e, "body")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {driverId && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ marginTop: 10, textAlign: "center" }}
              >
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.sendSilent("SYNC_ALL");
                    }}
                  >
                    {`${LABELS.syncAll}`}
                  </Button>
                </ButtonGroup>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <Error top={20} message={errorMessage} />
              <Success top={20} message={successMessage} />
            </Grid>
          </Grid>
          <DialogActions>
            <ReduxButton
              width
              loading={loading}
              onClick={this.save}
              label={`${LABELS.send}`}
            />
            <Button onClick={this.props.handleClose} color="primary">
              {`${LABELS.cancel}`}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    users: _get(state, "userData.users", []),
    errorMessage: _get(state, "authData.errorMessage", ""),
    isUnity: !_get(state, "appData.isUnity", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers,
      sendNotification,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TermsDialog)
);
