import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { sendSms } from "../../../../actions/userActions";
import Grid from '@mui/material/Grid';
import Error from "../../../Error";
import Success from "../../../Success";
import { Formik, Form } from "formik";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {LABELS as _LABELS} from "../../../../language";

const LABELS=_LABELS.settingPage;

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 40,
        justifyContent: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

function RenderCheckBox(props) {
    const [checked, setChecked] = useState(props.checked);

    function handleChange(event) {
        const _checked = event.target.checked;
        setChecked(_checked);
        props.handleChange(_checked, props.data);
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name="checkedA"
                    color="primary"
                />
            }
            label={props.name}
        />
    );
}

function RenderItem(props) {
    const scopes = props.scopes || [];
    const items = props.items || {};

    let view = items.find(item => item.name.includes("VIEW_"));
    let add = items.find(item => item.name.includes("ADD_"));
    let edit = items.find(item => item.name.includes("EDIT_"));
    let admin = items.find(item => item.name.includes("ADMIN_ALL"));

    let viewChecked = view ? scopes.includes(view._id) : false;
    let addChecked = add ? scopes.includes(add._id) : false;
    let editChecked = edit ? scopes.includes(edit._id) : false;
    let adminChecked = admin ? scopes.includes(admin._id) : false;

    if (editChecked) {
        viewChecked = true;
        addChecked = true;
    }
    if (addChecked) {
        viewChecked = true;
    }

    return (
        <React.Fragment>
            {admin && (
                <RenderCheckBox
                    name={"All"}
                    data={admin}
                    checked={adminChecked}
                    handleChange={props.handleChange}
                />
            )}

            {edit && (
                <RenderCheckBox
                    name={"Edit"}
                    data={edit}
                    checked={editChecked}
                    handleChange={props.handleChange}
                />
            )}
            {add && (
                <RenderCheckBox
                    name={"Add"}
                    data={add}
                    checked={addChecked}
                    handleChange={props.handleChange}
                />
            )}
            {view && (
                <RenderCheckBox
                    name={"View"}
                    data={view}
                    checked={viewChecked}
                    handleChange={props.handleChange}
                />
            )}
        </React.Fragment>
    );
}

function RenderListItem(props) {
    const data = props.data || {};
    const items = data.data || [];
    // const selected = props.scopes || {};
    const _scopes = props.scopes || [];
    const scopes = _scopes.map(item => item._id);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} style={{ marginTop: 15, fontWeight: 600 }}>
                {data.name}
            </Grid>

            <FormGroup row style={{ marginLeft: 25 }}>
                <RenderItem
                    name={data.name}
                    scopes={scopes}
                    items={items}
                    handleChange={props.handleChange}
                />
            </FormGroup>
        </Grid>
    );
}

export class RoleForm extends React.Component {
    constructor(props) {
        super();

        const scopes = _get(props, "selected.scopes", []);
        this.state = {
            email: "",
            successMessage: "",
            errorMessage: "",
            scopes
        };
    }

    componentDidMount() { }

    updateScope = async values => {
        const user = this.props.selected || {};
        const scopes = this.state.scopes || [];

        this.setState({
            loading: true,
            errorMessage: "",
            successMessage: ""
        });

        const response = await this.props.updateUserScope({
            userId: user.id,
            scopes: scopes.map(item => item._id)
        });

        if (response && response.uuid) {
            this.setState({
                successMessage: LABELS.saved_Successfully,
                loading: false
            });

            setTimeout(() => this.props.handleClose(), 1000);
        } else {
            this.setState({
                loading: false,
                errorMessage: LABELS.response
            });
        }
    };

    handleChange = (isCheck, params) => {
        const scopes = this.state.scopes || [];

        if (isCheck) {
            scopes.push(params);
            this.setState({ scopes });
        } else {
            const _scopes = scopes.filter(item => item._id !== params._id);
            this.setState({ scopes: _scopes });
        }
    };

    render() {
        const allScopes = this.props.allScopes || [];
        // const selected = this.props.selected || {};
        const { errorMessage, successMessage, loading } = this.state;

        return (
            <Formik
                initialValues={{}}
                validate={() => { }}
                onSubmit={this.updateScope}
            >
                {({ values, handleSubmit, setFieldValue }) => {
                    return (
                        <Form style={{ padding: "20px 10px" }}>
                            {allScopes.map(item => (
                                <RenderListItem
                                    key={item.name}
                                    data={item}
                                    scopes={this.state.scopes}
                                    handleChange={this.handleChange}
                                />
                            ))}
                            <Grid container spacing={2}>
                                <Error top={20} message={errorMessage} />
                                <Success top={20} message={successMessage} />
                            </Grid>

                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    marginTop: 45,
                                    textAlign: "center"
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    style={{
                                        minWidth: 250
                                    }}
                                >
                                    {/* {loading ? "Loading..." : "Save"} */}
                                    {loading ? LABELS.loading :LABELS.save}
                                </Button>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    marginTop: 15,
                                    textAlign: "center"
                                }}
                            >
                                <Button onClick={this.props.handleClose}>
                                    {LABELS.cancel}
                                </Button>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: _get(state, "authData.termsBusy", false),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "authData.errorMessage", "")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ sendSms }, dispatch);

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(RoleForm)
    )
);
