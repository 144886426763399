import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { alpha } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
}}));

export default function SearchAppBar({ LABELS = {}, count = 0, onChange }) {
  const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* <AppBar position="static" color="transparent"> */}
            <Toolbar>
                <Typography className={classes.title} noWrap />

                <FormControl className={classes.search} style={{ marginTop: "5px" }}>
                    <TextField style={{ width: "20rem", }}
                        size="small"
                        variant="outlined"
                        placeholder="Search..."
                        onChange={onChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),

                        }}
                    />
                </FormControl>
            </Toolbar>
        </div>
    
 
  );
}
