import _get from "lodash/get";
import { bindActionCreators } from "redux";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
import {
  updateAdminLogs,
  deleteAdminLogs,
} from "../../actions/account/accountAdminActions";
import { getDrivers } from "../../actions/driverActions";
import { formatDateTimeToUtc } from "../../actions/momentActions";
import ConfirmDialog from "../../components/ConfirmDialog";
import EditShTrDrDialog from "./EditShTrDrDialog";
// import Error from "../Error";
// import Success from "../Success";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.dutyPage;

const items = Array.from(Array(60), (_, i) => i);

function SelectField(props) {
  return (
    <FormControl fullWidth>
      <InputLabel style={{ marginTop: 10 }} id="hours">
        {props.label}
      </InputLabel>
      <Select
        labelId={props.label}
        id={props.label}
        value={props.value}
        onChange={props.onChange}
        input={<Input />}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function AllLogEdit(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEdit, setEditOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [isSub, setIsSub] = React.useState(false);

  const [action, setAction] = React.useState("");
  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [odometer, setOdometer] = React.useState(0);
  const [engineHours, setEngineHours] = React.useState(0);

  const [drivers, setDrivers] = React.useState([]);

  const timezone = useSelector((state) => state.appData.timezone);

  const _selectedLogs = props.selectedLogs || [];
  const selectedLogs = _selectedLogs.filter((item) => !item.isLast);

  const dispatch = useDispatch();

  useEffect(() => {
    _getDrivers();
  }, []);

  async function onSave(action, data) {
    setLoading(true);
    setSuccess("");
    setError("");
    let _logs = [];
    let logs = selectedLogs.filter((item) => item.isSelected);

    const _odometer = parseFloat(odometer);
    const _engineHours = parseFloat(engineHours);
    const _days = parseFloat(days) || 0;
    const _hours = parseFloat(hours) || 0;
    const _minutes = parseFloat(minutes) || 0;
    const _seconds = parseFloat(seconds) || 0;
    const _dSec = _days * 24 * 60 * 60;
    const _hSec = _hours * 60 * 60;
    const _mSec = _minutes * 60;
    const totalSec = _dSec + _hSec + _mSec + _seconds;

    if (action === "DELETE") {
      _logs = logs.map((item) => item);
    } else if (action === "ADD") {
      _logs = logs.map((item) => {
        const _et = item.eventTime;
        const _odo = item.odometer ? parseFloat(item.odometer) : 0;
        const _eh = item.engineHours ? parseFloat(item.engineHours) : 0;

        if (_odometer && _odo > 0 && _odo > _odometer) {
          item.odometer = _odo + _odometer;
        }
        if (_engineHours && _eh > 0 && _eh > _engineHours) {
          item.engineHours = _eh + _engineHours;
        }

        if (totalSec && totalSec > 0) {
          item.eventTime = moment(_et).add(totalSec, "seconds");
        }

        return item;
      });
    } else if (action === "EDIT") {
      _logs = logs.map((item) => {
        const _et = item.eventTime;
        const _odo = item.odometer ? parseFloat(item.odometer) : 0;
        const _eh = item.engineHours ? parseFloat(item.engineHours) : 0;

        if (data.trailer) {
          item.trailer = data.trailer;
        }
        if (data.shippingDoc) {
          item.shippingDoc = data.shippingDoc;
        }
        if (data.driver) {
          item.driverId = data.driver.id;
          item.driver = {
            id: data.driver.id,
            email: data.driver.email,
            firstName: data.driver.firstName,
            lastName: data.driver.lastName,
          };
        }
        return item;
      });
    } else if (action === "SUBTRACT") {
      _logs = logs.map((item) => {
        const _et = item.eventTime;
        const _odo = item.odometer ? parseFloat(item.odometer) : 0;
        const _eh = item.engineHours ? parseFloat(item.engineHours) : 0;

        if (_odometer && _odo > 0 && _odo > _odometer) {
          item.odometer = _odo - _odometer;
        }

        if (_engineHours && _eh > 0 && _eh > _engineHours) {
          item.engineHours = _eh - _engineHours;
        }

        if (totalSec && totalSec > 0) {
          item.eventTime = moment(_et).subtract(totalSec, "seconds");
        }

        return item;
      });
    }
    await saveLogs(_logs, action);
  }

  async function saveLogs(logs, action) {
    try {
      if (logs.length === 0) {
        setError("No log are selected.");
        return;
      }
      // console.log("saveLogs logs :::: ", logs);
      const arrayOfPromises =
        action === "DELETE"
          ? logs.map((item, idx) => _deleteLogs(item, idx))
          : logs.map((item, idx) => {
              item.eventTime = props.formatDateTimeToUtc(
                item.eventTime,
                timezone
              );
              return _saveLogs(item, idx);
            });
      await Promise.all(arrayOfPromises);

      setLoading(false);
      setSuccess("Logs are saved sucessfully.");
      props.reloadAllLogs();
      reset();
    } catch (e) {
      console.log(e);
      setLoading(false);
      reset();
      setError("Not able to logs.");
    }
  }

  async function _getDrivers() {
    try {
      const response = await dispatch(getDrivers({ skip: 0, limit: 500 }));
      setDrivers(response);
    } catch (e) {
      console.log("e :: ", e);
    }
  }

  async function _saveLogs(log, idx) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const response = await dispatch(updateAdminLogs(log));
          resolve(response);
        } catch (e) {
          resolve(null);
        }
      }, idx * 100);
    });
  }

  async function _deleteLogs(log, idx) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const response = await dispatch(deleteAdminLogs(log));
          resolve(response);
        } catch (e) {
          resolve(null);
        }
      }, idx * 100);
    });
  }

  function reset() {
    setTimeout(() => {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      setOdometer(0);
      setEngineHours(0);
      setSuccess("");
      setError("");
    }, 1000);
  }

  let buttonDisabled = false;
  let _logs = selectedLogs.filter((item) => item.isSelected);

  if (
    _logs.length === 0 ||
    (days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds === 0 &&
      odometer === 0 &&
      engineHours === 0)
  ) {
    buttonDisabled = true;
  } else {
    buttonDisabled = false;
  }

  if (!_logs.length) {
    return null;
  }

  return (
    <form noValidate autoComplete="off">
      <ConfirmDialog
        open={isOpen}
        label={"Test mode: Driver action required"}
        agreeLabel={"Accept"}
        disAgreeLabel={"Reject"}
        text={
          "Please handover control to driver to confirm the action. Accept or Reject."
        }
        onClose={(isTrue) => {
          if (isTrue) {
            onSave(action);
          }
          setIsOpen(false);
        }}
      />
      {isEdit && (
        <EditShTrDrDialog
          open={isEdit}
          drivers={drivers}
          onSave={(_data) => {
            setAction("EDIT");
            onSave("EDIT", _data);

            setEditOpen(false);
          }}
          onClose={() => {
            setEditOpen(false);
          }}
        />
      )}
      <Grid
        container
        spacing={1}
        style={{
          marginTop: 5,
          marginBottom: 25,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          {success && <Alert severity="success">{success}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
        {isSub ? (
          <>
            <Grid item xs={12} md={1} lg={1}>
              <SelectField
                label={LABELS.days}
                value={days}
                onChange={(e) => setDays(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <SelectField
                label={LABELS.hours}
                value={hours}
                onChange={(e) => setHours(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <SelectField
                label={LABELS.minutes}
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={1}>
              <SelectField
                label={LABELS.seconds}
                value={seconds}
                onChange={(e) => setSeconds(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={1}>
              <TextField
                size="small"
                variant="standard"
                type="number"
                id="odometer"
                label={LABELS.odometer}
                value={odometer}
                onChange={(e) => setOdometer(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={1}>
              <TextField
                size="small"
                variant="standard"
                type="number"
                id="engineHours"
                label={LABELS.engineHr}
                value={engineHours}
                onChange={(e) => setEngineHours(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <Button
                size="small"
                color="warning"
                variant="contained"
                disabled={buttonDisabled || loading}
                onClick={() => {
                  setAction("SUBTRACT");
                  setIsOpen(true);
                }}
                fullWidth
              >
                {LABELS.subtract}
              </Button>{" "}
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <Button
                size="small"
                color="success"
                variant="contained"
                disabled={buttonDisabled || loading}
                onClick={() => {
                  setAction("ADD");
                  setIsOpen(true);
                }}
                fullWidth
              >
                {LABELS.add}
              </Button>{" "}
            </Grid>
          </>
        ) : (
          <Grid item lg={9} />
        )}
        <Grid item xs={12} md={2} lg={1} style={{ float: "right" }}>
          <Button
            size="small"
            color="primary"
            disabled={!_logs.length || loading}
            onClick={() => {
              setAction("EDIT");
              setEditOpen(true);
            }}
            fullWidth
            variant="contained"
          >
            {LABELS.edit}
          </Button>{" "}
        </Grid>
        <Grid item xs={12} md={2} lg={1}>
          <Button
            size="small"
            color="error"
            disabled={!_logs.length || loading}
            onClick={() => {
              setAction("DELETE");
              setIsOpen(true);
            }}
            fullWidth
            variant="contained"
          >
            {LABELS.deactivate}
          </Button>
        </Grid>
        <Grid item xs={12} md={2} lg={1}>
          <Button
            size="small"
            color="info"
            disabled={!_logs.length || loading}
            onClick={() => {
              setIsSub(!isSub);
            }}
            fullWidth
            variant="contained"
          >
            {"TESTMODE"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    timezone: _get(state, "appData.timezone"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDateTimeToUtc,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AllLogEdit);
