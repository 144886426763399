export const topLabels = [
    "M",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "N",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "M"
];

export const leftLabels = ["OFF", "SB", "DR", "ON"];

export const offset = {
    BREAK: 48,
    OFFDUTY: 48,
    SLEEPER: 74,
    DRIVING: 98,
    ONDUTY_ND: 122
};

export const violationLimits = {
    shiftDriving: 8 * 60,
    sleeper: 11 * 60,
    onDutyND: 14 * 60
};

export const drivingEvents = [
    "BREAK",
    "OFFDUTY",
    "SLEEPER",
    "DRIVING",
    "ONDUTY_ND",
    "WAITING_AT_SITE"
];
