import _get from "lodash/get";
import React, { useState } from "react";
import { Field } from "formik";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";


const InputField = ({
  field,
  type = "datetime",
  disabled,
  form: { touched, errors, values, handleChange, handleBlur },
  onChange,
  onBlur,
  autoComplete = "on",
  helpText,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  let error = _get(errors, `${field.name}`);
  let touch = _get(touched, `${field.name}`);
  let value = _get(values, `${field.name}`);

  return (
    <TextField
      size="small"
      variant="standard"
      type={inputType}
      name={field.name}
      label={props.label}
      placeholder={props.placeholder}
      defaultValue={value}
      autoComplete={autoComplete}
      onChange={(e) => {
        onChange(e);
        handleChange(e);
      }}
      onBlur={(e) => {
        onBlur(e);
        handleBlur(e);
      }}
      helperText={error ? error : helpText}
      error={touch && Boolean(error)}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly: disabled,
        inputProps: { step: "1" }
      }}
    />
  );
};

const InputBox = ({
  name,
  label,
  type = "text",
  placeholder,
  // autoComplete = "on",
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  md = 4,
  xs = 12,
  value,
  helpText = "",
}) => {
  return (
    <Grid item md={md} xs={xs}>
      <Field
        type={type}
        name={name}
        label={label || name}
        disabled={disabled}
        component={InputField}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        helpText={helpText}
      />
    </Grid>
  );
};

export default InputBox;
