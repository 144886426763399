import _get from "lodash/get";
const moment = require("moment-timezone");

moment.createFromInputFallback = function (config) {
  config._d = new Date(config._i);
};

const defaultTimeZone = moment.tz.guess();

// Utility function to get the timezone from the state or use default
const getTimezone = (getState, path = "appData.timezone") => {
  return _get(getState(), path, defaultTimeZone);
};

// Utility function to check if the date is valid
const isValidDate = (date) => moment(date).isValid();

export function formatRawDate(date) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      // return moment.tz(date, timezone).format("MM/D/YYYY");
      return moment.tz(date, timezone).format("YYYY/MM/DD");
    } else {
      return null;
    }
  };
}

export function formatUsDate(date) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      // return moment.tz(date, timezone).format("MM/D/YYYY");
      return moment.tz(date, timezone).format("MM/DD/YY");
    } else {
      return null;
    }
  };
}

export function formatDateTimeSec(date) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return moment.tz(date, timezone).format("MMMM Do YYYY, h:mm:ss a");
    } else {
      return null;
    }
  };
}

export function getDate(date, timezone) {
  return (dispatch, getState) => {
    if (!timezone) {
      timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    }

    if (moment(date).isValid()) {
      return moment.tz(date, timezone);
    } else {
      return null;
    }
  };
}

export function formatDateTime(date, timezone) {
  return (dispatch, getState) => {
    if (!timezone) {
      timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    }
    if (moment(date).isValid()) {
      return moment.tz(date, timezone).format("MMM Do YYYY @ hh:mm A");
    } else {
      return null;
    }
  };
}

export function formatDate(date, timezone) {
  return (dispatch, getState) => {
    if (!timezone) {
      // timezone: _get(state, "authData.profile.account.carrier.timezone"),
      timezone = _get(
        getState(),
        "authData.profile.account.carrier.timezone",
        defaultTimeZone
      );
    }
    if (moment(date).isValid()) {
      return moment.tz(date, timezone).format("MMM Do YYYY");
    } else {
      return null;
    }
  };
}

export function formatTime(date, timezone) {
  return (dispatch, getState) => {
    if (moment(date).isValid()) {
      if (!timezone) {
        // timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        timezone = _get(
          getState(),
          "authData.profile.account.carrier.timezone",
          defaultTimeZone
        );
      }
      return moment.tz(date, timezone).format("hh:mm A");
      // return moment(date).format("hh:mm A");
    } else {
      return null;
    }
  };
}

export function formatTimeSec(date, timezone) {
  return (dispatch, getState) => {
    if (moment(date).isValid()) {
      if (!timezone) {
        // timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        timezone = _get(
          getState(),
          "authData.profile.account.carrier.timezone",
          defaultTimeZone
        );
      }
      return moment.tz(date, timezone).format("hh:mm:ss A");
      // return moment(date).format("hh:mm A");
    } else {
      return null;
    }
  };
}

export function formatDateTimeUtc(date) {
  return (dispatch, getState) => {
    if (moment(date).isValid()) {
      const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
      return moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss");
      // return moment(date).format("hh:mm A");
    } else {
      return null;
    }
  };
}

export const formatDateTimeToUtc2 = (date) => (dispatch, getState) => {
  const timezone = getTimezone(getState);
  return isValidDate(date) ? moment.tz(date, timezone).toISOString() : null;
};

export function formatDateTimeToUtc(date) {
  return (dispatch, getState) => {
    if (moment(date).isValid()) {
      let fmt = "YYYY-MM-DDTHH:mm.sssZ";
      const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
      return moment.tz(moment(date), fmt, timezone).toISOString();
      // return moment(date).format("hh:mm A");
    } else {
      return null;
    }
  };
}

export function getTimeZone() {
  return (dispatch, getState) => {
    return moment.tz.guess();
  };
}

export function isToday(date) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return moment.tz(timezone).isSame(date, "day");
    } else {
      return null;
    }
  };
}

export function isYesterday(date) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      const yesterday = moment
        .tz(timezone)
        .clone()
        .subtract(1, "days")
        .startOf("day");
      return yesterday.tz(timezone).isSame(date, "day");
    } else {
      return null;
    }
  };
}

export function addDay(date, days = 1) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return moment.tz(date, timezone).add(days, "days").format("MM/D/YYYY");
    } else {
      return null;
    }
  };
}

export function addYears(date, days = 1) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return moment.tz(date, timezone).add(1, "years").format("MM/D/YYYY");
    } else {
      return null;
    }
  };
}

export function subtractDay(date, days = 1) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return moment
        .tz(date, timezone)
        .subtract(days, "day")
        .format("MM/D/YYYY");
    } else {
      return null;
    }
  };
}

export function getDailyLogsDays(date, days = 30) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return {
        to: moment.tz(date, timezone).endOf("day"),
        from: moment.tz(date, timezone).subtract(days, "day").startOf("day"), // TODO: - will think if increase or decrease

        endOfDay: moment.tz(date, timezone).endOf("day"),
        startOfDay: moment.tz(date, timezone).startOf("day"),
      };
    } else {
      return null;
    }
  };
}

export function getDaysCount(startDate, endDate) {
  return (dispatch, getState) => {
    const days = moment(endDate).diff(moment(startDate), "days");
    return days;
  };
}

export function getDailyLogsDayRange(startDate, endDate) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(startDate).isValid() && moment(endDate).isValid()) {
      let currDate = moment(startDate).startOf("day");
      let lastDate = moment(endDate).startOf("day");
      let _currDate = currDate.clone().toDate();
      let _lastDate = lastDate.clone().toDate();
      let dates = [_currDate];

      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate());
      }

      if (!moment(startDate).isSame(endDate, "day")) {
        dates.push(_lastDate);
      }

      return dates.map((selDate) => ({
        to: moment.tz(selDate, timezone).endOf("day"),
        from: moment.tz(selDate, timezone).startOf("day"),

        endOfDay: moment.tz(selDate, timezone).endOf("day"),
        startOfDay: moment.tz(selDate, timezone).startOf("day"),
      }));
    } else {
      return null;
    }
  };
}

export function buildDate(date, time) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    return moment.tz(`${date} ${time}`, timezone);
  };
}

export function getStartOfDay(date, hour = 0) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return moment.tz(`${date}`, timezone).startOf("day").add(hour, "hours");
    } else {
      return null;
    }
  };
}

export function getEndOfDay(date, hour = 0) {
  return (dispatch, getState) => {
    const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
    if (moment(date).isValid()) {
      return moment.tz(`${date}`, timezone).endOf("day").add(hour, "hours");
    } else {
      return null;
    }
  };
}
