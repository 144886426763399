import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import InputDateBox from "../../../components/InputDateBox";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import { createTrailer, updateTrailer } from "../../../actions/trailerActions";
import { getDvirTemplates } from "../../../actions/dvirActions";
import { formatDateTimeUtc, addDay } from "../../../actions/momentActions";
import validate from "./validate";
import Error from "../../Error";
import Success from "../../Success";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  error: {
    marginTop: "2px",
    color: "red",
  },
});

const STATUS = [
  {
    name: "Active",
    id: "ACTIVE",
  },
  {
    name: "Inactive",
    id: "INACTIVE",
  },
];

export class AddTrailerForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      active: props.trailer?.active ?? "-",
      trailerName: props.trailer?.name || "",
    };
  }

  async componentDidMount() {
    this.setState({ errorMessage: "", successMessage: "" });
    await this.props.getDvirTemplates();
  }

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    values.license = {
      plate: values.plate,
      state: values.state,
      country: values.country,
      expiry: values.expiry,
    };

    values.active = values.active === "ACTIVE" ? true : false;
    let response;

    if (this.props.isEdit) {
      const id = _get(this.props, "trailer.id", "");
      values.name = values.name.trim(" ");
      values.id = id;
      response = await this.props.updateTrailer(values);
    } else {
      values.name = values.name.trim(" ");
      response = await this.props.createTrailer(values);
    }

    this.setState({ loading: false });
    if (response.id) {
      this.setState({
        successMessage: LABELS.successMessage,
      });
      setTimeout(() => {
        this.props.handleSave();
      }, 1000);
    } else if (response.message) {
      this.setState({ errorMessage: response.message });
    } else {
      if (typeof response !== String) {
        response = LABELS.response;
      }
      this.setState({ errorMessage: response });
    }
  };

  render() {
    const { classes, isEdit, trailer, constants, dvirTemplates } = this.props;
    const license = _get(trailer, "license", {});
    const active = _get(trailer, "active", true);
    const initialValues = {
      ...trailer,
      active: active ? "ACTIVE" : "INACTIVE",
      expiry: this.props.formatDateTimeUtc(this.props.addDay(new Date(), 30)),
      state: "",
      country: "US",
      ...license,
    };
    const { errorMessage, successMessage, loading } = this.state;

    const isSmallScreen = window.innerWidth < 400;
    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            {/* {isEdit ? "EDIT TRAILER" : "ADD TRAILER"} */}
            {isEdit ? LABELS.editTrailer : LABELS.addTrailer}
          </Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              const ALL_STATES =
                values.country === "CA"
                  ? constants.CA_STATES
                  : values.country === "MX"
                  ? constants.MX_STATES
                  : constants.US_STATES;

              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <InputBox md={2} name="name" label={LABELS.name} />
                    <SelectBox
                      md={2}
                      name="active"
                      label={LABELS.status}
                      items={STATUS}
                    />
                    {/* <InputBox
                        md={3}
                        name="dvir"
                        label="Dvir"
                    /> */}
                    <SelectBox
                      md={4}
                      name="dvir"
                      label={LABELS.dvir}
                      selLabel={"name"}
                      selValue={"name"}
                      items={dvirTemplates}
                    />
                    <InputBox md={3} name="vin" label={LABELS.vin} />
                    <SelectBox
                      md={2}
                      name="country"
                      label={LABELS.country}
                      selLabel={"id"}
                      items={constants.COUNTRIES}
                    />
                    <InputBox md={3} name="plate" label={LABELS.licPlate} />

                    <SelectBox
                      md={2}
                      name="state"
                      label={LABELS.licState}
                      selLabel={"id"}
                      selLabelThree={"name"}
                      items={ALL_STATES}
                    />

                    <InputDateBox
                      md={4}
                      name="expiry"
                      label={LABELS.licExpiry}
                    />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {loading ? LABELS.saving : LABELS.save}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");
  return {
    constants: _get(state, "appData.constants", {}),
    accountProperties: _get(state, "authData.profile.accountProperties", {}),
    dvirTemplates: _get(state, "dvirData.dvirTemplates", []),
    loading: _get(state, "trailerData", false),
    errorMessage: _get(state, "authData.errorMessage", ""),
    isAdmin: !!adminKey,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDay,
      formatDateTimeUtc,
      createTrailer,
      updateTrailer,
      getDvirTemplates,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTrailerForm))
);
