import { isUnity, isEldBooks, isBluehorse } from "../../configureStore";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.termCondition;
export default function TermsTextDetail() {
  let name = "Smartelds";
  let website = "https://www.smartelds.com";

  if (isUnity) {
    name = "Motion ELD";
    website = "https://motioneld.com";
  } else if (isEldBooks) {
    name = "Eldbooks";
    website = "https://eldbooks.com";
  } else if (isBluehorse) {
    name = "Bluehorseeld";
    website = "https://bluehorseeld.com";
  }
  const textStyle = {
    lineHeight: "1.6",
     // Adjust the line height as needed
  };
  return (
    <div style={textStyle}>
      <h3>{`${LABELS.Enduseragreement}`}</h3>
      {`${LABELS.SOFTWARE_AGREEMENT}`}
      <br />
      {`${LABELS.ImportantReadcarefully}`}
      <br />
      <h4>{`${LABELS.Definition}`}</h4>
      {`${LABELS.terms_and_condition_Details}`}
      <br />
      <h4>{`${LABELS.Order}`}</h4>
      {`${LABELS.The_terms_and_conditions_of_this_agreement_will_control_all_orders_services}`}
      <h4>{`${LABELS.Access_to_plans_restrictions_on_use_licenses}`}</h4>
      {`${LABELS.Access_to_hours_of_service_plan}`},
      <br />
      {`${LABELS.Use_by_sub_account_holders}`},
      <br />
      {`${LABELS.Restrictions_on_use}`}
      <br />
      {`${LABELS.Firmwarelicense}`}
      <br />
      {`${LABELS.Ownership}`}
      <br />
      <br />
      <h4>{`${LABELS.Service}`}</h4>
      {`${LABELS.Availabilityofservices}`}
      <br />
      {`${LABELS.Security}`}
      <br />
      {`${LABELS.hoursofserviceplansupport}`}
      <br />
      <br />
      <h4>{`${LABELS.Customerdata}`}</h4>
      {`${LABELS.useofcustomerdata}`}
      <br />
      {`${LABELS.Disclosuresofcustomerdata}`}
      <br />
      {`${LABELS.Retentionofcustomerdata}`}
      <br />
      <br />
      <h4>{`${LABELS.Customerresponsibilities}`}</h4>
      {`${LABELS.Use_of_services_you_acknowledge}`}
      <br />
      {`${LABELS.Access_to_hosted_services}`}
      <br />
      {`${LABELS.Equipment_and_utilities}`}
      <br />
      {`${LABELS.Consents_you_be_responsible_for_obtaining_the_necessary}`}
      <br />
      {`${LABELS.Credit_you_shall_cooperate_with_the_timely_completion_ofthe_credit_application}`}
      <br />
      {`${LABELS.Carrier_customer_expressly_understands_and_agrees}`}
      <br />
      <h4>{`${LABELS.Disclaimer}`}</h4>
      {`${LABELS.Although_has_attempted_to_provide_accurate_information_on_this_web_site}`}
      <br />
      {`${LABELS.All_information_software_products_and_services_provided}`}
      <br />
      {`${LABELS.shall_not_be_liable_for_any_indirect_special_consequential}`}
    </div>
  );
}
