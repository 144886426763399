const defaultState = {
    overviewAll: [],
};

export default function driver(state = defaultState, action) {
    switch (action.type) {

        case "OVERVIEW_ALL":
            return {
                ...state,
                overviewAll: action.value
            };
        default:
            return state;
    }
}
