import _get from "lodash/get";
import React from "react";
import { Field } from "formik";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const InputField = ({
  field,
  disabled,
  form: { touched, errors, values, handleChange, handleBlur },
  onChange,
  onBlur,
  items,
  selValue = "id",
  selLabel,
  selLabelTwo,
  selLabelThree,
  ...props
}) => {
  let error = _get(errors, `${field.name}`);
  let touch = _get(touched, `${field.name}`);
  let value = _get(values, `${field.name}`);
  // const tName = field.name.replace(/\./g, "");

  if (!Array.isArray(items)) {
    console.error(` --------- ${field.name} has error items --------- `);
  }

  return (
    <TextField
      name={field.name}
      select={true}
      label={props.label}
      placeholder={props.placeholder}
      value={value || ""}
      variant="standard"
      onChange={(e) => {
        onChange(e);
        handleChange(e);
      }}
      onBlur={(e) => {
        onBlur(e);
        handleBlur(e);
      }}
      helperText={touch ? error : ""}
      error={touch && Boolean(error)}
      fullWidth={true}
      InputProps={{
        readOnly: disabled,
      }}
    >

      {Array.isArray(items) &&
        items.map((option, idx) => {
          const disLabel =
            selLabelThree && selLabelTwo
              ? `${option[selLabel]} ${option[selLabelTwo]} (${option[selLabelThree]})`
              : selLabelThree
              ? `${option[selLabel]} (${option[selLabelThree]})`
              : selLabelTwo
              ? `${option[selLabel]} ${option[selLabelTwo]}`
              : option[selLabel];
          return (
            <MenuItem
              key={`${idx}-${option[selValue]}`}
              value={option[selValue]}
            >
              {disLabel}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

const SelectBox = ({
  name,
  label,
  placeholder,
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  md = 4,
  xs = 12,
  value,
  items = [],
  selValue = "id",
  selLabel = "name",
  selLabelTwo,
  selLabelThree,
}) => {
  return (
    <Grid item md={md} xs={xs}>
      <Field
        name={name}
        label={label || name}
        placeholder={placeholder || label}
        disabled={disabled}
        selValue={selValue}
        selLabel={selLabel}
        selLabelTwo={selLabelTwo}
        selLabelThree={selLabelThree}
        component={InputField}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        items={items}
      />
    </Grid>
  );
};

export default SelectBox;
