import _get from "lodash/get";
import React from "react";
import { makeStyles } from "@mui/styles";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Chip from "@mui/material/Chip"; 
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

const useStyles = makeStyles((theme) => ({
  paperDriver: {
    color: "grey",
    textAlign: "center",
  },
  paperOffDuty: {
    color: "#000",
    textAlign: "center",
  },
  paperOnDuty: {
    color: "#009ddd",
    textAlign: "center",
  },
  paperDriving: {
    color: "#3fca3f",
    textAlign: "center",
  },
  paperSleep: {
    color: "#f5cb42",
    textAlign: "center",
  },
}));

export default function DriverRecap(props) {
  const classes = useStyles();

  const total = _get(props, "data.drivers.length", "0");
  const offDuty = _get(props, "data.offDuty", "0");
  const onDuty = _get(props, "data.onDuty", "0");
  const driving = _get(props, "data.driving", "0");
  const sleeper = _get(props, "data.sleeper", "0");

  return (
    <Grid container spacing={3}>
      <Grid item xs={3} md={3}>
        <div className={classes.paperDriver}>
          {LABELS.drivers}
          <span> {total}</span>
        </div>
      </Grid>
      <Grid item xs={2} md={2}>
        <div className={classes.paperOffDuty}>
          {LABELS.offDuty}
          <span> {offDuty}</span>
        </div>
      </Grid>
      <Grid item xs={2} md={2}>
        <div className={classes.paperOnDuty}>
          {LABELS.onDuty}
          <span> {onDuty}</span>
        </div>
      </Grid>
      <Grid item xs={2} md={2}>
        <div className={classes.paperDriving}>
          {LABELS.driving}
          <span> {driving}</span>
        </div>
      </Grid>
      <Grid item xs={2} md={2}>
        <div className={classes.paperSleep}>
          {LABELS.sleeping}
          <span> {sleeper}</span>
        </div>
      </Grid>
    </Grid>
  );
}
