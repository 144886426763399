const defaultState = {
    terminalBusy: false,
    terminals: [],
    selectedTerminal: {}
}

export default function carrier(state = defaultState, action) {
    switch (action.type) {
    case 'TERMINALS':
        return {
            ...state,
            terminals: action.value
        }
    case 'SELCTED_TERMINAL':
        return {
            ...state,
            selectedTerminal: action.value
        }
    case 'APP_TERMINAL_BUSY':
        return {
            ...state,
            terminalBusy: action.value
        }
    default:
        return state
    }
}
