import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDate, formatDateTime } from "../../actions/momentActions";
import SupportTable from "./SupportTable";
import SearchBar from "./SearchBar";
import {
  getAdminSupportCount,
  getAdminSupport,
} from "../../actions/adminSupportActions";
import AddSupportForm from "./AddSupportForm";


const styles = (theme) => ({
  addWrapper: {
    padding: 10,
  },
  searchText: {
    display: "inline-block",
    marginTop: 10,
    marginLeft: 5,
  },
});

export class Reseller extends React.Component {
  state = {
    isAdd: false,
    isQueue: false,
    carrier: null,
    limit: 25,
    page: 0,
    supportCount: 0,
    supports: [],
    queues: [],
    queuesCount: 0,
    selected: {},
    selectedQueus: {},
  };

  async componentDidMount() {
    await this.onReload();
  }

  onReload = async () => {
    await this.getAdminSupportCount();
    await this.getAdminSupport();
  };

  getAdminSupportCount = async () => {
    try {
      const response = await this.props.getAdminSupportCount({});
      if (response && response.count) {
        this.setState({ supportCount: response.count });
      }
    } catch (e) {}
  };

  getAdminSupport = async (params) => {
    try {
      const { limit, page } = this.state;
      const skip = parseInt(page) * parseInt(limit);
      const supports = await this.props.getAdminSupport({
        limit,
        skip,
        ...params,
      });
      this.setState({ supports, loading: false });
    } catch (e) {}
  };

  handleOpen = () => {
    this.setState({ isAdd: true });
  };

  handleClose = () => {
    this.onReload();
    this.setState({ isAdd: false, isQueue: false, selected: null });
  };

  handleEdit = (selected) => {
    this.setState({ isAdd: true, selected });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getAdminSupport);
  };

  timeOut = null;

  render() {
    const classes = this.props.classes;
    const { loading, isAdd, selected } = this.state;
    // const { isQueue } = this.state;
    const supports = this.state.supports || [];
    // const queuesCount = this.state.queuesCount || 0;

    return (
      <div>
        {isAdd && (
          <AddSupportForm
            isAdd={isAdd}
            handleClose={this.handleClose}
            selected={selected}
          />
        )}
        <Grid container className={classes.addWrapper}>
          <Grid item xs={12} sm={12} md={12}>
            <SearchBar
              handleAddClick={this.handleOpen}
              onSearch={this.getAdminSupport}
            />
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <SupportTable
              items={supports}
              count={this.state.supportCount}
              handleEdit={this.handleEdit}
              formatDate={this.props.formatDate}
              formatDateTime={this.props.formatDateTime}
              handleChange={this.handleChange}
              limit={this.state.limit}
              page={this.state.page}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatDateTime,
      getAdminSupport,
      getAdminSupportCount,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Reseller)
);
