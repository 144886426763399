import React, { useRef, useState } from 'react';
import SignatureCanvas from "react-signature-canvas";
import { Button } from '@mui/material';


function Signature({ saving, getImage, handleClose }) {
    const [imageUrl, setImageUrl] = useState(null)
    const [saveState, setSaveState] = useState(false)
    const signCanvas = useRef({});
    const clear = () => {
        signCanvas.current.clear();
        setImageUrl(null);
        getImage();
        setSaveState(false)
    }
    const save = () => {
        if (imageUrl) {
            getImage(imageUrl.trimmedDataURL);
            setSaveState(true);
            handleClose();

        }
        else {
            getImage();
            setSaveState(false);
        }
    }
    return (
        <div>
            <SignatureCanvas
                penColor="black"
                backgroundColor="white"
                canvasProps={{
                    width: 500,
                    height: 300,
                    className: "sigCanvas",
                }}
                ref={signCanvas}
                onEnd={() => {
                    setImageUrl({
                        trimmedDataURL: signCanvas.current
                            .getTrimmedCanvas()
                            .toDataURL(
                                "image/png"
                            )
                    });


                }}
            />

            <div style={{ textAlign: "center" }
            }>
                {!saving && imageUrl ?
                    <Button color={"primary"} onClick={save}
                        disabled={saving}>
                        {saveState ? "SAVED" : "SAVE"}
                    </Button> : null}

                {!saving && imageUrl ? <Button color="secondary" onClick={clear}>CLEAR</Button> : null}
            </div>

        </div>
    )
}
export default Signature;