import React from "react";
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.dutyPage;

export default function StatusButtons(props) {
    const [allLogs, setAllLogs] = React.useState(false);

    return (
        <div>
            <FormControlLabel
                style={{ padding: 0 }}
                control={
                    <Checkbox
                        checked={allLogs}
                        onChange={e => {
                            const isChecked = e.target.checked;
                            setAllLogs(isChecked);
                            props.onChange(isChecked);
                        }}
                        style={{ padding: 0 }}
                        name="allLogs"
                        color="primary"
                    />
                }
                label={`${LABELS.allLogs}`}
            />
        </div>
    );
}
