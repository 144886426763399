import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withStyles } from '@mui/styles';
import { bindActionCreators } from "redux";
import {
    getAccountProperties,
    updateAccountProperties
} from "../../actions/app/appActions";
import Grid from '@mui/material/Grid';
import AccountSettingDetail from "./AccountSettingDetail";

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(1),
        minHeight: 200,
        overflow: "scroll"
    }
});

export class AccountSetting extends React.Component {
    componentDidMount() {
        this.props.getAccountProperties();
    }

    render() {
        const { classes, updateAccountProperties } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={12}>
                        <AccountSettingDetail
                            updateAccountProperties={updateAccountProperties}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountSettings: _get(state, "appData.accountSettings", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAccountProperties,
            updateAccountProperties
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AccountSetting)
);
