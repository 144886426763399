export const LABELS = {
  saveMessage: "ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ।",
  errorMessage: "ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
  deleteLabel: "ਉਲੰਘਣਾਵਾਂ ਨੂੰ ਮਿਟਾਓ",
  deleteMsg: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਸਾਰੀਆਂ ਉਲੰਘਣਾਵਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",
  language: "ਭਾਸ਼ਾ",
  noDriverFound:'ਕੋਈ ਡਰਾਈਵਰ ਨਹੀਂ ਮਿਲਿਆ',

  selectLanguage:"ਭਾਸ਼ਾ ਚੁਣੋ",
  saving:"ਰੱਖਿਅਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  save:"Saveਬਚਾਓ",
  close:"ਬੰਦ ਕਰੋ",
  
};
