import React from "react";
import PropTypes from "prop-types";
// import _get from "lodash/get";
// import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

// import EditIcon from "@mui/icons-material/Edit";
import NotFoundTableRow from "../NotFoundTableRow";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Button from '@mui/material/Button';
import ViewAccountDetail from "./ViewAccountDetail";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.adminPage;



const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1.5),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
  button: {
    marginTop: 2,
    minWidth: 100,
  },
  buttonLink: {
    textDecoration: "none",
    color: "white",
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, item = {} } = this.props;
    const contact = item.contact || {};
    // const billing = item.billing || {};

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          <div className={classes.dataLargeRow}>
            <strong>{item.name}</strong>
            <div>
              <PhoneIcon className={classes.icon} />
              {contact.phone}
            </div>
            <div>
              <EmailIcon className={classes.icon} />
              {contact.email}
            </div>
          </div>
        </TableCell>
        <TableCell>{item.status}</TableCell>
        <TableCell>
          {item.contact && item.contact.name && (
            <div className={classes.dataLargeRow}>
              {item.contact.name && (
                <div className={classes.capitalize}>
                  <PersonIcon className={classes.icon} />
                  {item.billing.name} ({item.billing.role})
                </div>
              )}
              {item.billing.phone && (
                <div>
                  <PhoneIcon className={classes.icon} /> {item.billing.phone}
                </div>
              )}
              {item.billing.email && (
                <div>
                  <EmailIcon className={classes.icon} />
                  {item.billing.email}
                </div>
              )}
            </div>
          )}
        </TableCell>
        <TableCell>
          {item.stripeBuss ? (
            <div>
              <small>{item.stripeBuss}</small>
            </div>
          ) : (
            <div style={{ color: "red" }}>{"Missing stripe"}</div>
          )}
        </TableCell>

        <TableCell>
          <div className={classes.dataLargeRow}>
            {item.created && (
              <div>Created: {this.props.formatDate(item.created)}</div>
            )}
            {item.edited && (
              <div>Edited: {this.props.formatDate(item.edited)}</div>
            )}
          </div>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            className={classes.button}
            variant="contained"
            disabled={item.status !== "ACTIVE"}
            color="primary"
          >
            <Link
              className={classes.buttonLink}
              color="primary"
              to={`/dashboard/admin-reseller/${item._id}`}
            >
              {LABELS.details}
            </Link>
          </Button>
          <br />
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={classes.button}
            style={{ marginLeft: '5px' ,marginTop:"5px"}}
            onClick={(e) => this.props.handleEdit(item)}
          >
            {LABELS.edit}
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export class AccountTable extends React.Component {
  state = { open: false, selectedAccount: null, order: "asc" };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleDetails = async (selectedAccount) => {
    this.setState({ loading: true });
    this.setState({ open: true });
    this.props.selectedAccount(selectedAccount);
    const accountId = selectedAccount._id;
    await this.props.getAccountDetails({ accountId });
    this.setState({ loading: false });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.selectedAccount(null);
  };

  render() {
    const { eld, classes, items = [], count = 1000 } = this.props;
    const { page, limit } = this.props;

    const { open, loading } = this.state;
    return (
      <React.Fragment>
        <ViewAccountDetail
          open={open}
          handleClose={this.handleClose}
          loading={loading}
        />
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{LABELS.name}</TableCell>
                <TableCell>{LABELS.status}</TableCell>
                <TableCell>{LABELS.billing}</TableCell>
                <TableCell>{LABELS.payment}</TableCell>
                <TableCell>{LABELS.created}</TableCell>
                <TableCell>{LABELS.actions}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(items) &&
                items.map((item, i) => (
                  <ReduxTableRow
                    item={item}
                    key={i}
                    eld={eld}
                    classes={classes}
                    handleDetails={this.handleDetails}
                    handleEdit={this.props.handleEdit}
                    formatDate={this.props.formatDate}
                    handleAccountDetails={this.props.handleAccountDetails}
                  />
                ))}
            </TableBody>
          </Table>
          <NotFoundTableRow items={items} />
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            labelRowsPerPage={LABELS.rowsperpage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

AccountTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountTable);
