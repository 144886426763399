import React, { useState } from "react";
import { makeStyles } from '@mui/styles'
import _get from "lodash/get";
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ConfirmActionModal from "../ConfirmActionModal";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    oldStatus: {
        textDecoration: "line-through"
    }
}));

function RenderList({ formatDateTime, onDelete, item = {}, classes }) {
    if (item.editType === "ADD") {
        const data = item.data || [];
        let addStatus = {
            eventCode: "",
            eventTime: "",
            odometer: "",
            note: "",
        };
        data.forEach(item => {
            if (item.name === "eventCode") {
                addStatus.eventCode = item.value;
            }
            else if (item.name === "eventTime") {
                addStatus.eventTime = item.value;
            }
            else if (item.name === "odometer") {
                addStatus.odometer = item.value;
            }
            else if (item.name === "annotation") {
                addStatus.notes = item.value;
            }
        })
        return (
            <TableRow>
                <TableCell align="left">
                    <Avatar>
                        <AssignmentIcon />
                    </Avatar>
                </TableCell>
                <TableCell align="left">{addStatus.eventCode}</TableCell>
                <TableCell align="left">
                    <p>{formatDateTime(addStatus.eventTime)}</p>
                    {/* <p>{formatDateTime(originalLog.endEventTime)}</p> */}
                </TableCell>
                <TableCell align="left">{`${addStatus.odometer
                    } Mile(S)`}</TableCell>
                <TableCell align="left">{addStatus.notes}</TableCell>
            </TableRow>
        );
    }
    else if (item.editType === "DRIVING" || item.editType === "REASSIGN") {
        const originalLog = item.originalLog || {};

        return (
            <TableRow>
                <TableCell align="left">
                    <Avatar>
                        <AssignmentIcon />
                    </Avatar>
                </TableCell>
                <TableCell align="left">{originalLog.eventCode}</TableCell>
                <TableCell align="left">
                    <p>{formatDateTime(originalLog.eventTime)}</p>
                    {item.editType !== "REASSIGN" ? <p>{formatDateTime(originalLog.endEventTime)}</p> : null}
                </TableCell>
                <TableCell align="left">{`${originalLog.odometer} Mile(S)`}</TableCell>
                <TableCell align="left">{item.note}</TableCell>
            </TableRow>
        );
    }

    else {
        const data = (item && item.data) || [];
        const orgLog = _get(item, "originalLog", '');

        const _eventCode = data.find(ee => ee.name === "eventCode") || {};
        const _eventTime = data.find(ee => ee.name === "eventTime") || {};
        const _reason = data.find(ee => ee.name === "reason") || {};
        const _odometer = data.find(ee => ee.name === "odometer") || {};
        const _annotation = data.find(ee => ee.name === "annotation") || {};

        const odometer = _odometer.value ? _odometer.value : 0;
        const reason = _reason.value ? _reason.value : "";
        const annotation = _annotation.value ? _annotation.value : ".";
        return (
            <TableRow>
                <TableCell align="left">
                    <Avatar>
                        {item.editType === "ADD" ? <AddIcon /> : <EditIcon />}
                    </Avatar>
                </TableCell>
                <TableCell align="left">
                    <p className={classes.oldStatus}>{orgLog.eventCode}</p>
                    <p>{_eventCode.value}</p>
                </TableCell>
                <TableCell align="left">
                    <p className={classes.oldStatus}>{formatDateTime(orgLog.eventTime)}</p>
                    <p>{formatDateTime(_eventTime.value)}</p>
                </TableCell>
                <TableCell align="left">
                    <p className={classes.oldStatus}>{`${orgLog.odometer} Mile(S)`}</p>
                    <p>{`${odometer} Mile(S)`}</p>
                </TableCell>
                <TableCell align="left"><p>{annotation}</p>
                    <p>{reason}</p></TableCell>
                {/*<TableCell align="left">
                    <IconButton
                        color="secondary"
                        aria-label="delete"
                        onClick={() => onDelete(item)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>*/}
            </TableRow>
        );
    }
}

export default function PendingRequest({
    formatDateTime,
    deleteRequest,
    onDone,
    editRequests = []
}) {
    const classes = useStyles();

    const [isOpen, setOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [deleting, setDeleting] = useState(false);
    // const [success, setSuccess] = useState("");
    // const [error, setError] = useState("");
    // const [selected, setSelected] = useState();

    // async function handleDelete() {
    //     setDeleting(true);
    //     const response = await deleteRequest({
    //         id: selected._id,
    //         driverId: selected.driverId
    //     });

    //     if (response && response._id) {
    //         setSuccess("Deleted successfully.");
    //     } else {
    //         setError("Not able to delete.");
    //     }
    //     setDeleting(false);
    //     setIsConfirm(false);
    //     onDone();
    //     // setTimeout(() => setOpen(false), 5000);
    // }

    if (
        !editRequests ||
        !Array.isArray(editRequests) ||
        editRequests.length === 0
    ) {
        return null;
    }

    const isSmallScreen = window.innerWidth < 400;

    return (
        <div className={classes.root}>
            {isOpen && (
                <Dialog
                    fullScreen={isSmallScreen}
                    fullWidth={true}
                    maxWidth={"md"}
                    className={classes.wrapper}
                    open={isOpen}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        onClose={() => setOpen(false)}
                    >
                        {"Pending Driver Approval"}
                        <IconButton
                            aria-label="Close"
                            className={classes.closeButton}
                            onClick={() => setOpen(false)}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        {/* {error && <Alert severity="error">{error}</Alert>}
                        {success && <Alert severity="success">{success}</Alert>} */}
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableBody>
                                {editRequests.map(item => (
                                    <RenderList
                                        key={item._id}
                                        item={item}
                                        formatDateTime={formatDateTime}
                                        classes={classes}
                                        onDelete={_e => {
                                            setIsConfirm(true);
                                            // setSelected(_e);
                                        }}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                </Dialog>
            )}
            {isConfirm && (
                <ConfirmActionModal
                    open={isConfirm}
                    selected={"selected"}
                    loading={deleting}
                    handleClose={() => {
                        setIsConfirm(false);
                        setDeleting(false);
                        // setSelected(null);
                    }}
                    // handleConfirm={handleDelete}
                    label={"Delete"}
                    message={"Are you sure, you want to delete"}
                />
            )}
            <Alert severity="warning" >
                <div
                    onClick={() => setOpen(true)}
                    style={{ cursor: "pointer" }}
                >
                    {editRequests.length} {"Pending Driver Approval"}
                </div>
            </Alert>
        </div>
    );
}
