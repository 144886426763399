import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getDriverSignById } from "../../actions/driverActions";
import {
  formatDateTime,
  formatDate,
  formatTime,
} from "../../actions/momentActions";
import { pdfOptions } from "../../helpers/helpers";
import { LABELS as _LABELS } from "../../language";
import EditDvirDialog from "./EditDvirDialog";
import {
  getDriverDvir,
  deleteDvir,
  findDvirById,
} from "../../actions/dvirActions";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  table: { border: "1px solid #ccc", marginBottom: 25 },
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class DvirDialog extends React.Component {
  state = {
    email: "",
    carrier: null,
    selected: null,
    successMessage: "",
    errorMessage: "",
    loading: false,
  };

  componentDidMount() {
    this.findDvirById();
  }

  findDvirById = async (e) => {
    const id = _get(this, "props.match.params.dvirId");
    try {
      const response = await this.props.findDvirById({ id });
      if (response._id) {
        this.setState({ selected: response }, () => this.loadSign());
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  loadSign = async (e) => {
    const driverId = _get(this, "state.selected.driverId");
    const selected = this.state.selected || {};
    try {
      const response = await this.props.getDriverSignById({ driverId });
      if (response.carrier) {
        const signature = _get(response, "sign.data");
        this.setState({ carrier: response.carrier, signature });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  createPdf = async () => {
    try {
      this.setState({ loading: true });
      const vehicleName = _get(this, "state.selected.vehicleName");
      const insType = _get(this, "state.selected.inspectionType");
      const _startTime = _get(this, "state.selected.startTime");
      const startTime = this.props.formatDateTime(_startTime);
      const fileName = `dvir-${vehicleName}-${insType}-${startTime}.${"pdf"}`.replace(
        / /g,
        ""
      );

      const element = document.getElementById("dvirPdf");

      pdfOptions.filename = fileName;
      pdfOptions.margin = [0.3, 0.1];
      await html2pdf().set(pdfOptions).from(element).toPdf().save();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    // const { errorMessage, successMessage} = this.state;
    const signature = this.state.signature;
    const carrier = this.state.carrier || {};
    const selected = this.state.selected || {};
    const areas = selected.areas || [];
    const templateId = selected.templateId || "Tractor";
    const vehicleType = templateId.toUpperCase().includes("TRACTOR")
      ? "Tractor"
      : "Trailer";
    const isEdit = this.state.isEdit;

    return (
      <Grid container spacing={0} style={{ padding: 10 }}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            {isEdit && selected && (
              <EditDvirDialog
                open={isEdit}
                selected={selected}
                handleClose={() => {
                  this.setState({ isEdit: false });
                }}
                afterSave={() => {
                  this.setState({ isEdit: false }, () => this.findDvirById());
                }}
              />
            )}
            <Grid item xs={6} md={6}>
              {"DVIR"}
            </Grid>
            <Grid item xs={6} md={6}>
              <div style={{ textAlign: "right", padding: 0 }}>
                <ButtonGroup
                  size="small"
                  variant="outlined"
                  aria-label="Dvir action buttons"
                >
                  <Button disabled={loading} onClick={this.createPdf}>
                    {loading ? LABELS.downloading : LABELS.download}
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isEdit: true });
                    }}
                  >
                    {"Edit"}
                  </Button>
                  <Button
                    onClick={() => this.props.onDeleteConfirm(selected)}
                    color="error"
                  >
                    {"DELETE"}
                  </Button>
                </ButtonGroup>
              </div>
            </Grid>
          </Grid>

          <div style={{ maxWidth: 700, margin: "auto" }} id={"dvirPdf"}>
            <h2 style={{ textAlign: "center" }}>{carrier.name}</h2>
            <h3 style={{ textAlign: "center" }}>
              {LABELS.driver_vehicle_inspection_report}
            </h3>
            <h5 style={{ textAlign: "center" }}>
              {
                LABELS.AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS
              }
            </h5>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {LABELS.dvirNumber} {`${selected.dvirSerial}`}
                    </TableCell>
                    <TableCell>
                      {`${LABELS.location}:-`} {`${selected.location}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.carrier}:-`} {carrier.name}
                    </TableCell>
                    <TableCell>
                      Date:- {`${this.props.formatDate(selected.startTime)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.dot}:-`} {carrier.dot}
                    </TableCell>
                    <TableCell>
                      {`${LABELS.odometer}`} {`${selected.odometer}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.driver}:-`} {`${selected.driverName}`}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {`${vehicleType}`}:- {`${selected.vehicleName}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {`${LABELS.start_inspection} :-`}{" "}
                      {`${this.props.formatTime(selected.startTime)}`}
                    </TableCell>
                    <TableCell>
                      {`${LABELS.endInspection} :-`}{" "}
                      {`${this.props.formatTime(selected.endTime)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {`${LABELS.inspection_type} :-`}{" "}
                      {`${selected.inspectionType}`}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {`${LABELS.inspector}`} {`${selected.inspectorType}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{LABELS.inspection_Area}</TableCell>
                    <TableCell>{LABELS.status}</TableCell>
                    <TableCell style={{ maxWidth: 100 }}>
                      {LABELS.note}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {areas.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.value === "1" ? "PASS" : "FAIL"}
                      </TableCell>
                      <TableCell style={{ maxWidth: 100 }}>
                        {row.note}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ textAlign: "center" }}>
              <h5>
                {
                  LABELS.I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements
                }
              </h5>
              <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                  <img
                    src={`data:image/jpeg;base64,${signature}`}
                    style={{ width: 120 }}
                    alt="Signature"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h5>{LABELS.driver_signature}</h5>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDriverDvir,
      deleteDvir,
      findDvirById,
      getDriverSignById,
      formatDateTime,
      formatTime,
      formatDate,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DvirDialog))
);

// import _get from "lodash/get";
// import React from "react";
// import { connect } from "react-redux";
// import { reduxForm } from "redux-form";
// import { bindActionCreators } from "redux";
// import { withRouter } from "react-router-dom";
// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
// import {
//   getAllDvir,
//   getAllDvirCount,
//   getDriverDvir,
//   deleteDvir
// } from "../../actions/dvirActions";
// import {
//   getStartOfDay,
//   getEndOfDay,
//   formatDateTime,
// } from "../../actions/momentActions";
// import { startDay, currTime } from "../../utils/momentHelpers";
// import DvirTable from "./DvirTable";
// import AddDvirDialog from "./AddDvirDialog";
// import SearchBar from "../../components/SearchBar";
//
// export class Register extends React.Component {
//   state = {
//     limit: 10,
//     page: 0,
//     loading: false,
//     isAddDvir: false,
//     start: startDay,
//     end: currTime,
//     driverId: "",
//     vehicleId: "",
//   };
//
//   async componentDidMount() {
//     const roles = _get(this, "props.profile.roles");
//
//     if (!roles.includes("DRIVER")) {
//       await this.props.getAllDvirCount({});
//     }
//
//     await this.getAllDvir({});
//   }
//
//   getAllDvir = async () => {
//     this.setState({ loading: true });
//     const { limit, page, driverId, start, end, vehicleId } = this.state;
//     const skip = parseInt(page) * parseInt(limit);
//
//     const roles = _get(this, "props.profile.roles");
//
//     if (roles.includes("DRIVER")) {
//       await this.props.getDriverDvir({
//         limit,
//         skip,
//         driverId,
//         vehicleId,
//         start: start ? new Date(this.props.getStartOfDay(start)) : null,
//         end: end ? new Date(this.props.getEndOfDay(end)) : null,
//       });
//     } else {
//       await this.props.getAllDvir({
//         limit,
//         skip,
//         driverId,
//         vehicleId,
//         // start: start ? new Date(this.props.getStartOfDay(start)) : null,
//         // end: end ? new Date(this.props.getEndOfDay(end)) : null,
//       });
//     }
//     this.setState({ loading: false });
//   };
//
//   handleChange = ({ limit, page }) => {
//     this.setState({ limit, page }, this.getAllDvir);
//   };
//
//   handleSearchChange = ({ driverId, vehicleId, start, end }) => {
//     this.setState({ driverId, vehicleId, start, end }, this.getAllDvir);
//   };
//
//   onSearch = ({ driverId, start, end }) => {
//     this.setState({ driverId, start, end }, this.getAllDvir());
//   };
//
//   render() {
//     const { dvirs } = this.props;
//     const { loading, isAddDvir } = this.state;
//     const roles = _get(this, "props.profile.roles");
//
//     return (
//       <Grid container spacing={0} style={{ padding: 10 }}>
//         <Grid item xs={12} sm={12}>
//           {!roles.includes("DRIVER") && (
//             <SearchBar
//               options={{
//                 isEqp: true,
//                 isTrailer: true,
//                 isDriver: true,
//                 isStartDate: true,
//                 isEndDate: true,
//                 isTime: true,
//                 isTimeperiod: true,
//                 allDrivers: true,
//               }}
//               dl={true}
//               loading={loading}
//               onSearch={this.handleSearchChange}
//             />
//           )}
//           {loading && (
//             <div style={{ textAlign: "center" }}>
//               <CircularProgress />
//             </div>
//           )}
//           {isAddDvir && (
//             <AddDvirDialog
//               open={isAddDvir}
//               handleClose={() => this.setState({ isAddDvir: false })}
//               afterSave={() => {
//                 this.setState({ isAddDvir: false });
//                 this.getAllDvir();
//               }}
//             />
//           )}
//           {roles.includes("DRIVER") && (
//             <div style={{ textAlign: "right" }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 size="small"
//                 onClick={() => this.setState({ isAddDvir: true })}
//               >
//                 {"Add"}
//               </Button>
//             </div>
//           )}
//
//           <DvirTable
//             items={dvirs}
//             count={this.props.count}
//             getAllDvir={this.getAllDvir}
//             formatDateTime={this.props.formatDateTime}
//             limit={this.state.limit}
//             page={this.state.page}
//             handleChange={this.handleChange}
//             adminKey={this.props.adminKey}
//             deleteDvir={this.props.deleteDvir}
//           />
//         </Grid>
//       </Grid>
//     );
//   }
// }
//
// reduxForm({
//   form: "RegisterForm",
// })(Register);
//
// const mapStateToProps = (state) => {
//   return {
//     dvirs: state.dvirData.dvirAll,
//     count: state.dvirData.count,
//     profile: _get(state, "authData.profile"),
//     adminKey: _get(state, "authData.profile.adminId"),
//   };
// };
//
// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       getAllDvir,
//       getStartOfDay,
//       getEndOfDay,
//       formatDateTime,
//       getAllDvirCount,
//       getDriverDvir,
//       deleteDvir
//     },
//     dispatch
//   );
//
// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(Register)
// );
