import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Error from "../Error";
import Success from "../Success";
import { formatDateTime } from "../../actions/momentActions";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function IftaEmailModal(props) {
  const _email = _get(props, 'account.email')
  const [email, setEmail] = useState(_email);
  const [loading, seLoading] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const classes = props.classes;

  async function emailReport() {
    const data = props.data;
    const vehicle = props.vehicle;
    try {
      const payload = {
        toEmail: email,
        equipment: {
          name: _get(props, "vehicle.vehicle[0].name"),
          vin: _get(props, "vehicle.vehicle[0].vin"),
        },
        carrier: {
          name: _get(props, "account.carrier.name"),
          dot: _get(props, "account.carrier.dot"),
        },
        endDate: props.formatDate(vehicle.end),
        startDate: props.formatDate(vehicle.start),
        data: data,
      };
      const response = await props.emailIftaLogs(payload);
      if (response && response.messageId) {
        setSuccessMessage("Email is send sucessfully");
        setTimeout(() => props.handleClose(), 1000);
      } else {
        setSuccessMessage("Not able to send email, please try after sometime");
      }
    } catch (e) {
      setSuccessMessage("Not able to send email, please try after sometime");
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography>{"Email ifta report"}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper>
          <TextField
            fullWidth
            id="email"
            label="Email"
            value={email}
            variant="standard"
            style={{ marginTop: 25, marginBottom: 25 }}
            onChange={(e) => setEmail(e.target.value)}
          />
          {successMessage && <Success message={successMessage} />}
          {errorMessage && <Error message={errorMessage} />}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={emailReport} variant="outlined">
          {loading ? "Sending..." : "SEND"}
        </Button>
        <Button onClick={props.handleClose} variant="outlined">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    accountId: _get(state, "authData.accountId"),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ formatDateTime }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(IftaEmailModal)
);
