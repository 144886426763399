import React from "react";
import {LABELS as _LABELS} from '../../language';

const LABELS=_LABELS.adminPage;

function NotFoundTableRow({
    classes,
    items,
    message = `${LABELS.norecordsarefound}`,
    top = 10,
    bottom = 10,
    textAlign = "center"
}) {
    if (Array.isArray(items) && (items && items.length !== 0)) {
        return null;
    }

    return (
        <div
            style={{
                textAlign,
                marginTop: top,
                marginBottom: bottom,
                width: "100%",
                padding: "10px 25px"
            }}
        >
            {" "}
            {message}
        </div>
    );
}

export default NotFoundTableRow;
