import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./apiKeyActionTypes";

export const setApiKeyCount = value => ({
    type: TYPES.API_KEY_COUNT,
    value
});

export const setApiKey = value => ({
    type: TYPES.API_KEY,
    value
});

export const SelectedApiKey = value => ({
    type: TYPES.SELECTED_KEY,
    value
});

export const ApiBusy = value => ({
    type: TYPES.APP_KEY_BUSY,
    value
});

export function getApiCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(ApiBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/providers/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setApiKeyCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(ApiBusy(false));
                return response;
            });
    };
}

export function getApiKey(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(ApiBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/providers`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setApiKey(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(ApiBusy(false));
                return response;
            });
    };
}

export function createApiKey(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(ApiBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/providers`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                dispatch(ApiBusy(false));
                return response;
            });
    };
}

export function updateApiKey(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(ApiBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/providers`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(ApiBusy(false));
                return response;
            });
    };
}

export function getScopes(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/scopes`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function updateUserScope(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        return axios({
            url: `${BASE_URL}/manager/${accountId}/users/${params.userId}`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function sendSms(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/sms`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                return response;
            });
    };
}
