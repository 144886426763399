// import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
// import { Typography } from "@mui/material";
// import Download from "../../components/Download";
import NotFoundTableRow from "../NotFoundTableRow";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import ConfirmActionModal from "../ConfirmActionModal";
import LogCertifyDownload from "../DailyStatus/LogCertifyDownload";
import { getAllDayEvents, filterDriverLogs } from "../DailyStatus/helpers";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
  detailTitle: {
    display: "inline-block",
    minWidth: 70,
    lineHeight: 1.5,
  },
  logoDiv: {
    textAlign: "center",
  },
});

class ReduxTableRow extends React.Component {
  state = {
    openLogsDownload: false,
  };

  handleViewLogs = (e, item) => {
    this.props.handleViewLogs(item.logDate);
  };

  handleViewMultiLogs = (e, item) => {
    this.props.handleViewMultiLogs(item.logDate);
  };

  handleDownload = (e, item) => {
    console.log(item.media);
  };

  renderViolations = (item) => {
    const violations = item.violations || [];

    if (violations && Array.isArray(violations) && violations.length > 0) {
      return violations.map((violation, idx) => (
        <div key={`${violation.statusId}-${idx}`} style={{ marginBottom: 2 }}>
          <Chip
            size="small"
            label={violation.id}
            color="secondary"
            variant="outlined"
            style={{ minWidth: 30 }}
            onDelete={() => this.props.handleDelete(item)}
          />
        </div>
      ));
    } else {
      return null;
    }
  };

  getAllCalcus = async (driverId) => {
    const driverCalcus = await this.props.getAllCalcus({ driverId });
    return driverCalcus;
  };

  searchLogs = async (driverId) => {
    const showAll = false;
    const timezone = this.props.timezone;
    const selectedDiary = this.props.item || {};
    // const selDriver = this.props.selectedDriver || {};
    // const selectedDate = selectedDiary.logDate;
    const selectedDate = this.props.formatDateTimeToUtc(
      selectedDiary.logDate,
      timezone
    );

    const days = this.props.getDailyLogsDays(selectedDate, 1);
    const allLogs =
      (await this.props.getDriverLogs({
        showAll,
        driverId,
        ...days,
      })) || {};

    const allCalcus = await this.getAllCalcus();

    const driverLogs = allLogs.driverLogs || [];
    const driverLastLogs = allLogs.driverLastLogs || {};
    const driverNextLogs = allLogs.driverNextLogs || {};

    const allEvents = getAllDayEvents(
      timezone,
      selectedDate,
      driverLogs,
      driverLastLogs,
      driverNextLogs
    );

    const allDayEvents = filterDriverLogs(allEvents, allCalcus);

    this.setState({
      loading: false,
      driverLogs,
      allDayEvents,
      allEvents,
    });
  };

  openLogsDownload = async () => {
    this.setState({ loading: true });
    const selectedDiary = this.props.item || {};
    const driverId = selectedDiary.driverId;
    await this.getAllCalcus(driverId);
    await this.searchLogs(driverId);
    this.setState({ openLogsDownload: true, loading: false });
  };

  render() {
    const { classes, item = {}, isUnity, isQa, adminKey, LABELS } = this.props;
    const loading = this.state.loading;
    const timezone = this.props.timezone;
    const selectedDiary = this.props.item || {};
    const selectedDate = selectedDiary.logDate;

    // const isDownloadBtn = item.certified && item.media && item.media.name;
    let drivingHours = item.drivingHours;
    let onDutyNDHours = item.onDutyNDHours;

    if (isUnity && drivingHours) {
      drivingHours = drivingHours.replace("H", ":").replace("M", "");
    }
    if (isUnity && onDutyNDHours) {
      onDutyNDHours = onDutyNDHours.replace("H", ":").replace("M", "");
    }
    // console.log(item);
    const openLogsDownload = this.state.openLogsDownload;
    const selectedDriver = this.props.selectedDriver || {};

    const driverLogs = this.state.driverLogs || [];
    const allDayEvents = this.state.allDayEvents || [];

    return (
      <TableRow>
        {openLogsDownload && (
          <LogCertifyDownload
            hideResetLines={true}
            open={openLogsDownload}
            isCertify={openLogsDownload}
            isAuto={true}
            handleClose={() =>
              this.setState({ openLogsDownload: false, selectedDiary: null })
            }
            dailyDiary={[selectedDiary]}
            accountId={this.props.accountId}
            timezone={timezone}
            formatTime={this.props.formatTime}
            dataLoading={false}
            selectedDriver={selectedDriver}
            driverLogs={driverLogs}
            allDayEvents={allDayEvents}
            selectedDate={selectedDate}
            updateDriverDaily={this.props.updateDriverDaily}
          />
        )}
        <TableCell component="th" scope="row">
          {this.props.formatDate(item.date, timezone)}
        </TableCell>
        <TableCell>
          {item.certified ? (
            <span>Certified</span>
          ) : (
            <span>{LABELS.uncertified}</span>
          )}
        </TableCell>

        <TableCell>
          {item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>{LABELS.driving}:</span>
              {drivingHours}
            </div>
          )}
          {!isUnity && item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>{LABELS.offDuty}:</span>
              {item.offDutyHours}
            </div>
          )}
          {item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>{LABELS.onDutyND}:</span>
              {onDutyNDHours}
            </div>
          )}
          {!isUnity && item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>{LABELS.sleeper}:</span>
              {item.sleeperHours}
            </div>
          )}
        </TableCell>
        <TableCell>{this.renderViolations(item)}</TableCell>
        <TableCell component="th" scope="row">
          {item.iosSoftwareVersion ? (
            <div className={classes.logoDiv}>
              <AppleIcon fontSize="large" />
              <div style={{ marginTop: "-5px" }}>{item.iosSoftwareVersion}</div>
            </div>
          ) : null}

          {item.androidSoftwareVersion ? (
            <div className={classes.logoDiv}>
              <AndroidIcon fontSize="large" style={{ color: "green" }} />
              <div style={{ marginTop: "-8px" }}>
                {item.androidSoftwareVersion}
              </div>
            </div>
          ) : null}
        </TableCell>
        <TableCell align="left">
          <Button onClick={(e) => this.handleViewLogs(e, item)}>
            {LABELS.detail}
          </Button>
          <div>
            {adminKey ? (
              <Button onClick={(e) => this.handleViewMultiLogs(e, item)}>
                {LABELS.detailsMulti}
              </Button>
            ) : null}

            {/*isDownloadBtn && <Download media={item.media} />*/}
          </div>
          {item.certified && (
            <div>
              <Button disabled={loading} onClick={this.openLogsDownload}>
                {loading ? "Loading..." : "Download"}
              </Button>
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export class DocumentTable extends React.Component {
  state = {
    openLogsDownload: false,
    selectedDiary: {},
  };

  // handleChangePage = (event, page) => {
  //     this.setState({ page }, this.getDriverDaily);
  // };
  //
  // handleChangeRowsPerPage = event => {
  //     const limit = event.target.value;
  //     this.setState({ limit }, this.getDriverDaily);
  // };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  // getDriverDaily = () => {
  //     const { limit, page } = this.state;
  //     const { driverId } = this.props;
  //
  //     // console.log("limit :: ", limit);
  //     // console.log("page :: ", page);
  //     // console.log("driverId :: ", driverId);
  //
  //     this.props.getDriverDaily({
  //         limit,
  //         page,
  //         driverId
  //     });
  // };

  handleDelete = (diary) => {
    this.setState({
      isConfirmOpen: true,
      deleting: false,
      selected: diary,
    });
  };

  onDelete = async () => {
    this.setState({ deleting: true });

    const selected = this.state.selected;
    const payload = {
      _id: selected._id,
      date: selected.date,
      driverId: selected.driverId,
      violations: [],
    };

    await this.props.updateDriverDaily(payload);

    this.setState({
      deleting: false,
      isConfirmOpen: false,
      selected: null,
    });

    const { page, limit } = this.props;
    await this.props.handleChange({ page, limit });
  };

  render() {
    const {
      eld,
      classes,
      items = [],
      getDriverDaily,
      count = 100,
      limit,
      page,
      isUnity,
      isQa,
      adminKey,
      LABELS,
    } = this.props;
    const { isConfirmOpen, selected, deleting } = this.state;

    return (
      <Paper className={classes.root}>
        {isConfirmOpen && (
          <ConfirmActionModal
            open={isConfirmOpen}
            selected={selected}
            loading={deleting}
            handleClose={() =>
              this.setState({
                isConfirmOpen: false,
                deleting: false,
                selected: null,
              })
            }
            handleConfirm={this.onDelete}
            label={LABELS.deleteLabel}
            message={LABELS.deleteMsg}
          />
        )}

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.date}</TableCell>
              <TableCell>{LABELS.certified}</TableCell>
              <TableCell>{LABELS.detail}</TableCell>
              <TableCell>{LABELS.violation}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => {
                return (
                  <ReduxTableRow
                    item={item}
                    key={i}
                    eld={eld}
                    classes={classes}
                    timezone={this.props.timezone}
                    getDriverDaily={getDriverDaily}
                    formatDate={this.props.formatDate}
                    handleViewLogs={this.props.handleViewLogs}
                    handleViewMultiLogs={this.props.handleViewMultiLogs}
                    handleDelete={this.handleDelete}
                    isUnity={isUnity}
                    isQa={isQa}
                    adminKey={adminKey}
                    LABELS={LABELS}
                    openLogsDownload={this.openLogsDownload}
                    accountId={this.props.accountId}
                    selectedDriver={this.props.selectedDriver}
                    getDailyLogsDays={this.props.getDailyLogsDays}
                    getDriverLogs={this.props.getDriverLogs}
                    getAllCalcus={this.props.getAllCalcus}
                    formatDateTimeToUtc={this.props.formatDateTimeToUtc}
                  />
                );
              })}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

DocumentTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocumentTable);
