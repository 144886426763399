export const LABELS={
    leads:"DIRIGIR",


    leads: "Dirige",
    added:"Agregada",
    channel:"Canal",
    status:"Estado",
    dot:"PUNTO",
    name:"Nombre",
    adress_line:"Dirección",
    address_city:"Dirección ciudad",
    address_state:"Estado de la dirección",
    address_zip:"Dirección postal",
    contact_name:"Nombre de contacto",
    contact_email:"Email de contacto",
    contact_phone:" Teléfono de contacto",
    callback:"Llamar de vuelta",
    description:"Descripción",

    assignedto:"Asignado a",
    assignee_email:"Correo electrónico del cesionario",

    saving:"Ahorro",
    save:"AHORRAR",
    close:"Cerca",



    lead_is_saved_successfully:"El cliente potencial se guardó correctamente",
    something_went_wrong_please_try_after_some_time:"Algo salió mal, inténtalo después de un tiempo.",


}
