import _get from "lodash/get";
import React from "react";
import { Field } from "formik";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { LANGS, updateLang } from "../../language";
import LanguageIcon from "@mui/icons-material/Language";

const InputField = ({
  field,
  disabled,
  form: { touched, errors, values, handleChange, handleBlur },
  onChange,
  onBlur,
  items,
  ...props
}) => {
  let error = _get(errors, `${field.name}`);
  let touch = _get(touched, `${field.name}`);
  let value = _get(values, `${field.name}`);
  const tName = field.name.replace(/\./g, "-");

  return (
    <TextField
      name={tName}
      select={true}
      placeholder={props.placeholder}
      value={value || ""}
      variant="standard"
      onChange={(e) => {
        onChange(e);
        handleChange(e);
      }}
      onBlur={(e) => {
        onBlur(e);
        handleBlur(e);
      }}
      helperText={touch ? error : ""}
      error={touch && Boolean(error)}
      InputProps={{
        readOnly: disabled,
      }}
    >
      {LANGS.map((option, idx) => {
        return (
          <MenuItem key={`${idx}-${option.id}`} value={option.id}>
            {option.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

const SelectBox = ({
  name,
  label,
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  value,
  items = [],
}) => {
  return (
    <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
      <LanguageIcon fontSize="small" style={{ marginTop: 5, marginRight: 5 }} />
      <Field
        name={"language"}
        component={InputField}
        onChange={(e) => updateLang(e.target.value)}
        onBlur={onBlur}
        value={value}
        items={items}
      />
    </Grid>
  );
};

export default SelectBox;
