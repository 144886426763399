import React from "react";
// import { useSelector } from "react-redux";
// import moment from "moment-timezone";
// import _get from "lodash/get";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Support from "../Support/index";
// import { getWaittime } from "../../utils/waittimeHelpers";
import {LABELS as _LABELS} from "../../language";

const LABELS =_LABELS.settingPage;



// const supportPlanPremium = process.env.REACT_APP_STRIPE_SP_PREMIUM;
// const supportPlanEnterprise = process.env.REACT_APP_STRIPE_SP_ENTERPRISE;

export default function CustomerSupportPackage(props) {
  // const _waittime = useSelector((state) =>
  //   _get(state, "supportData.waittime", {})
  // );
  // const _planName = useSelector((state) =>
  //   _get(state, "authData.profile.plan.plan.name", "STANDARD")
  // );
  // const waittime = getWaittime(_waittime, _planName);

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      maxWidth={"xl"}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="support-dialog"
      aria-describedby="support-dialog"
    >
      <DialogTitle id="support-dialog" onClose={props.handleClose}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>{LABELS.supportCentre}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            {/*<Typography>{`${LABELS.estimatedWaitTime}: ${waittime} ${LABELS.minutes}`}</Typography>*/}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <IconButton
              aria-label="close"
              onClick={props.onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Support />
      </DialogContent>
    </Dialog>
  );
}
