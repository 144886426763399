const validate = (values = {}) => {
    const errors = {};

    if (!values.driverId) {
        errors.driverId = "Driver is required.";
    }

    if (!values.note) {
        errors.note = "Note is required.";
    }

    return errors;
};

export default validate;
