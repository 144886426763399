import _get from "lodash/get";
import _set from "lodash/set";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.logInPage;

const validate = (values) => {
  const errors = {};
  let requiredFields = ["firstName", "lastName", "mobile", "email", "password"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.SIGN_IN_FORM.required;
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = LABELS.SIGN_IN_FORM.inValidEmail;
  }
  return errors;
};

const validateEdit = (values) => {
  const errors = {};
  let requiredFields = ["firstName", "lastName", "mobile", "email"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.SIGN_IN_FORM.required;
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = LABELS.SIGN_IN_FORM.inValidEmail;
  }
  return errors;
};

export { validate, validateEdit };
