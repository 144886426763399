import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {LABELS as _LABELS} from "../../language";
import NotFoundTableRow from "../NotFoundTableRow";

const LABELS=_LABELS.adminPage;

const styles = (theme) => ({
});

class ReduxTableRow extends React.Component {
  render() {
    const { adminAccess, classes } = this.props;
    let _item = _get(this.props, "item", {});

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          <div className={classes.dataSmallRow}>{_item.email || ""}</div>
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>{_item.error}</div>
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>
            {this.props.formatDateTime(_item.time)}
          </div>
        </TableCell>
        {adminAccess && <TableCell>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => this.props.onDelete(_item)}
          >
            {`${LABELS.delete}`}
          </Button>
        </TableCell>}
      </TableRow>
    );
  }
}

export class auditTable extends React.Component {
  state = { open: false, selectedAccount: null, order: "asc" };


  render() {
    const { eld, adminAccess, classes} = this.props;
    // const { page, limit,count = 1000  } = this.props;
    const items = this.props.items || {};
    const _allItems = Object.keys(items);
    const allItems = _allItems.map((_item) => {
      const _data = items[_item];
      return {
        id: _item,
        ..._data[0],
      };
    });
    // let allItems = []
    // Object.values(items).forEach(e => {
    //     if (Array.isArray(e)) {
    //         allItems = [...allItems, ...e]
    //     } else {
    //         allItems = [...allItems, e]
    //     }
    // })
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{LABELS.email}</TableCell>
                <TableCell>{LABELS.error}</TableCell>
                <TableCell>{LABELS.time}</TableCell>
                {adminAccess && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {allItems.map((_item, i) => (
                <ReduxTableRow
                  item={_item}
                  key={i}
                  eld={eld}
                  classes={classes}
                  adminAccess={adminAccess}
                  onDelete={this.props.onDelete}
                  formatDateTime={this.props.formatDateTime}
                />
              ))}
            </TableBody>
          </Table>
          <NotFoundTableRow items={allItems} />
          {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                        component="div"
                        count={count}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    /> */}
        </Paper>
      </React.Fragment>
    );
  }
}

auditTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(auditTable);
