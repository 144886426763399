import * as TYPES from "../adminWhatsappActionTypes";

const defaultState = {
  count: 0,
};

export default function adminWhatsapp(state = defaultState, action) {
  switch (action.type) {
    case "UNREAD_CHATS":
      return {
        ...state,
        count: action.value,
      };

    default:
      return state;
  }
}
