// import _get from "lodash/get";
// import _set from "lodash/set";

const defaultValues = {
  name: "",
  ein: "",
  bussType: "",
  contact: {
    name: "",
    email: "",
    phone: "",
    role: "",
  },
  billing: {
    name: "",
    email: "",
    phone: "",
    role: "",
  },
  notes: "",
  stripeBuss: ""
};

const validate = (values) => {
  let errors = {};
  const requiredFields = ["name"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

export { validate, defaultValues };
