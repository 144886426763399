import React from "react";
import _get from "lodash/get";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import { getAccountAuth } from "../../actions/account/accountAdminActions";
import { getProfile } from "../../actions/auth/authActions";
import { updateUserAccount } from "../../actions/account/accountAdminActions";
import { getAccountDetails } from "../../actions/account/accountAdminActions";
import { getResellerById } from "../../actions/reseller/resellerActions";
import UpdateButton from "./UpdateButton";
import { formatDate } from "../../actions/momentActions";
import { syncRedis } from "../../actions/adminDashActions";
import { getBillInvoice } from "../../actions/adminBillActions";
import EditPlanForm from "./Forms/EditPlanForm";
import AddUserForm from "./Forms/AddUserForm";
import AddBillInvoice from "./Forms/AddBillInvoice";
import AddBillCustomer from "./Forms/AddBillCustomer";
import { formatCurrency } from "../../utils/numberHelpers";
import * as cookie from "react-cookie";
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

const cookies = new cookie.Cookies();
const styles = (theme) => ({});

function RenderUsers({
  user = {},
  handleLogin,
  accountAuthBusy,
  updateUserAccount,
  getAccountDetails,
  loading,
}) {
  const status = _get(user, "status", "");
  let active = false;
  if (status === "ACTIVE") {
    active = true;
  } else {
    active = false;
  }

  return (
    <Grid item xs={12} md={4}>
      <Paper style={{ marginTop: 10, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <p
              style={{
                fontWeight: "500",
                fontSize: "15px",
                margin: "0px",
              }}
            >
              {user.firstName} {user.lastName}
            </p>
            <div>
              <small>{user.email}</small>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={accountAuthBusy || loading || !active}
              style={{ margin: "2vh", float: "right" }}
              onClick={(e) => handleLogin(user)}
            >
              {LABELS.logIn}
            </Button>
            <UpdateButton
              active={active}
              accountAuthBusy={accountAuthBusy}
              user={user}
              updateUserAccount={updateUserAccount}
              getAccountDetails={getAccountDetails}
              label={LABELS.deactivate}
            />
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Paper>
    </Grid>
  );
}

function RenderDetails(props = {}) {
  const isStripe = props.isStripe;
  const stripeBussId = props.stripeBussId;
  const mdGrid = isStripe ? 3 : 4;
  const userActive = props.userActive;
  const userInActive = props.userInActive;

  const driverActive = props.driverActive;
  const driverInActive = props.driverInActive;

  const eqpActive = props.eqpActive;
  const eqpInActive = props.eqpInActive;
  const eqpAllowed = props.eqpAllowed;

  const invoices = props.invoices || [];

  function handleInvoice(item) {
    window.open(item.hosted_invoice_url, "_blank");
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <strong>{LABELS.details}</strong>
      </Grid>
      <Grid item md={mdGrid} xs={12}>
        <span
          style={{ display: "block" }}
        >{`${LABELS.activeManagers}: ${userActive}`}</span>
        <span>{`${LABELS.inactiveManagers}: ${userInActive}`}</span>
      </Grid>

      <Grid item md={mdGrid} xs={12}>
        <span
          style={{ display: "block" }}
        >{`${LABELS.activeDrivers}: ${driverActive}`}</span>
        <span>{`${LABELS.inactiveDrivers}: ${driverInActive}`}</span>
      </Grid>
      <Grid item md={mdGrid} xs={12}>
        <span>{`${LABELS.allowedVehicles}: ${eqpAllowed}`}</span>
        <span
          style={{ display: "block" }}
        >{`${LABELS.activeVehicles}: ${eqpActive}`}</span>
        <span
          style={{ display: "block" }}
        >{`${LABELS.inactiveVehicles}: ${eqpInActive}`}</span>
      </Grid>
      {isStripe && (
        <Grid item md={3} xs={12}>
          {stripeBussId ? (
            <>
              {LABELS.invoices}
              <IconButton
                aria-label="View"
                color="primary"
                onClick={props.addInvoice}
                style={{ marginTop: -10 }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
              {invoices &&
                invoices.map((item) => {
                  const amount = item.amount_remaining
                    ? parseFloat(item.amount_remaining) / 100
                    : 0;
                  if (item.status !== "open") {
                    return null;
                  }
                  return (
                    <div key={item.id}>
                      <Chip
                        label={formatCurrency(amount)}
                        variant="outlined"
                        size="small"
                      />
                      <Chip
                        label={item.status}
                        variant="outlined"
                        size="small"
                      />
                      <Chip
                        label={`Attempts: ${item.attempt_count}`}
                        variant="outlined"
                        size="small"
                      />

                      <IconButton
                        aria-label="View"
                        color="primary"
                        onClick={() => handleInvoice(item)}
                      >
                        <PictureAsPdfIcon />
                      </IconButton>
                    </div>
                  );
                })}
            </>
          ) : (
            <Button variant="text" size="small" onClick={props.addCustomer}>
              {"Add Customer"}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}

function RenderPlan(props) {
  const plan = props.plan || {};
  const dues = _get(plan, "dues", []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h3 style={{ margin: "0px" }}>
          {LABELS.planInfo}
          <Button
            style={{ color: "#009ce5" }}
            onClick={() => props.isEdit(true)}
          >
            {LABELS.edit}
          </Button>
        </h3>
        <Grid container>
          <Grid item xs={4}>
            <div>
              {`Plan: `}
              {plan.plan && plan.plan.name}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              {`${LABELS.apiAccess}: `}
              {plan.api ? LABELS.enabled : LABELS.disabled}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              {LABELS.iftaAccess}:{plan.ifta ? LABELS.enabled : LABELS.disabled}
            </div>
            {plan.plan && Array.isArray(plan.plan) && (
              <div>
                {`Plan: `}
                {plan.plan.annual ? LABELS.annual : ""}
                {plan.plan.monthly ? LABELS.monthly : ""}
              </div>
            )}
          </Grid>
          {props.notes && (
            <Grid item xs={12}>
              <h4>
                {LABELS.notes}: {props.notes}
              </h4>
            </Grid>
          )}
        </Grid>

        <Typography>
          <span>{LABELS.dues}</span>
        </Typography>
      </Grid>
      <Grid container>
        {dues.length > 0
          ? dues.map((item, idx) => {
              let textCol = "black";
              if (moment(item.date) < moment()) {
                textCol = "red";
              } else if (moment(item.date) <= moment().endOf("month")) {
                textCol = "orange";
              }
              return (
                <Grid item xs={4} key={`${idx}`}>
                  <div style={{ color: textCol }}>
                    {`${idx + 1}. Truck# `}
                    {item.truck} {"is due on "}
                    {props.formatDate(item.date)}{" "}
                  </div>
                </Grid>
              );
            })
          : "No dues found!"}
      </Grid>
    </Grid>
  );
}

export class ViewAccountDetail extends React.Component {
  state = {
    loading: true,
    isEditOpen: false,
    dataLoading: false,
    invoices: [],
  };

  async componentDidMount() {
    const accountId = _get(this, "props.match.params.accountId");
    await this.handleDetails(accountId);
  }

  getBillInvoice = async (customer) => {
    if (customer) {
      customer = customer.replace("SE:", "");
    }
    const response = await this.props.getBillInvoice({ customer });

    if (response && response.data) {
      this.setState({ invoices: response.data });
    }
  };

  handleDetails = async (accountId) => {
    this.setState({ loading: true });
    const selectedAccountData = await this.props.getAccountDetails({
      accountId,
    });
    const resellerId = _get(selectedAccountData, "account.resellerId");
    const stripeBussId = _get(selectedAccountData, "account.stripeBuss");
    if (resellerId) {
      this.handleReseller(resellerId);
    }
    if (this.props.isStripe && stripeBussId) {
      this.setState({ stripeBussId });
      this.getBillInvoice(stripeBussId);
    }

    this.setState({ selectedAccountData, loading: false });
  };

  handleReseller = async (id) => {
    try {
      const reseller = await this.props.getResellerById({ id });
      // console.log("reseller :: ", reseller);
      this.setState({ syncing: false });
    } catch (e) {
      console.log(e);
    }
  };

  handleLogin = async (user) => {
    this.setState({ loading: true });

    const selectedAccountData = this.state.selectedAccountData || {};
    const selectedAccount =
      this.props.selectedAccount && this.props.selectedAccount._id
        ? this.props.selectedAccount
        : selectedAccountData.account || {};

    const accountId = selectedAccount._id;
    const carrierId = selectedAccount.carrierId;
    const terminalId = selectedAccount.terminalId;

    const response = await this.props.getAccountAuth({
      accountId,
      carrierId,
      terminalId,
      userId: user.id,
    });

    cookies.set("token", response.token, {
      path: "/",
      maxAge: 43200,
    });

    await this.props.getProfile();

    this.props.history.push(`/dashboard/${accountId}/status/view`);
    this.setState({ loading: false });
  };

  isEdit = (value) => {
    this.setState({ isEditOpen: value });
  };

  handleAddFormSave = async () => {
    const accountId = _get(this, "props.match.params.accountId");
    await this.handleDetails(accountId);
    this.setState({ openAddForm: false, isEditOpen: false });
  };

  handleAddInvoiceSave = async () => {
    const accountId = _get(this, "props.match.params.accountId");
    await this.handleDetails(accountId);
    this.setState({ openAddInvoice: false });
  };

  handleAddCustomerSave = async () => {
    const accountId = _get(this, "props.match.params.accountId");
    await this.handleDetails(accountId);
    this.setState({ openAddCustomer: false });
  };

  sync = async () => {
    this.setState({ syncing: true });
    const accountId = _get(this, "props.match.params.accountId");
    await this.props.syncRedis({ accountId });
    this.setState({ syncing: false });
  };

  render() {
    const { classes, accountAuthBusy, isStripe } = this.props;
    const { stripeBussId, openAddInvoice, openAddCustomer } = this.state;
    const { openAddForm, isEditOpen } = this.state;
    const { loading, syncing } = this.state;
    const invoices = this.state.invoices || [];
    const selectedAccountData = this.state.selectedAccountData || {};
    const selectedAccount = selectedAccountData.account || {};

    let adminStatus = {
      activeDriver: 0,
      inactiveDriver: 0,
      activeUser: 0,
      inactiveUser: 0,
    };
    let equipStatus = {
      activeVehicle: 0,
      inactiveVehicle: 0,
    };
    if (!selectedAccount) {
      return null;
    }

    const admins = selectedAccountData.admins || [];
    const equipments = selectedAccountData.equipments || [];
    const plan = selectedAccount.plan || {};

    const eqpAllowed = (plan.vehicles && plan.vehicles.allowed) || 0;
    Array.isArray(equipments) &&
      equipments.forEach((item) => {
        if (item.assetType === "VEHICLE") {
          if (item.active) {
            equipStatus.activeVehicle += 1;
          } else {
            equipStatus.inactiveVehicle += 1;
          }
        }
      });

    Array.isArray(admins) &&
      admins.forEach((item) => {
        if (item.role === "DRIVER") {
          if (item.status === "ACTIVE") {
            adminStatus.activeDriver += 1;
          } else {
            adminStatus.inactiveDriver += 1;
          }
        } else {
          if (item.status === "ACTIVE") {
            adminStatus.activeUser += 1;
          } else {
            adminStatus.inactiveUser += 1;
          }
        }
      });

    return (
      <div>
        {openAddInvoice && (
          <AddBillInvoice
            open={openAddInvoice}
            handleClose={() => {
              this.setState({ openAddInvoice: false });
            }}
            handleSave={this.handleAddInvoiceSave}
            account={selectedAccountData?.account}
          />
        )}
        {openAddCustomer && (
          <AddBillCustomer
            open={openAddCustomer}
            handleClose={() => {
              this.setState({ openAddCustomer: false });
            }}
            handleSave={this.handleAddCustomerSave}
            account={selectedAccountData?.account}
          />
        )}
        {openAddForm && (
          <AddUserForm
            open={openAddForm}
            handleClose={() => {
              this.setState({ openAddForm: false });
            }}
            handleSave={this.handleAddFormSave}
          />
        )}

        {isEditOpen && (
          <EditPlanForm
            open={isEditOpen}
            userPlan={plan}
            handleClose={() => {
              this.setState({ isEditOpen: false });
            }}
            handleSave={this.handleAddFormSave}
          />
        )}
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div id="alert-dialog-title" style={{ textAlign: "center" }}>
              <p
                style={{
                  marginTop: 0,
                  fontWeight: 600,
                  fontSize: "17px",
                }}
              >
                {selectedAccount.name}
                <Button
                  disabled={syncing}
                  style={{ color: "#009ce5" }}
                  onClick={() => this.sync(true)}
                >
                  {syncing ? LABELS.syncing : LABELS.sync}
                </Button>
              </p>
            </div>
            <div style={{ marginTop: 25 }}>
              <RenderDetails
                isStripe={isStripe}
                invoices={invoices}
                userActive={adminStatus.activeUser}
                userInActive={adminStatus.inactiveUser}
                driverActive={adminStatus.activeDriver}
                driverInActive={adminStatus.inactiveDriver}
                eqpActive={equipStatus.activeVehicle}
                eqpInActive={equipStatus.inactiveVehicle}
                eqpAllowed={eqpAllowed}
                stripeBussId={stripeBussId}
                addInvoice={() => this.setState({ openAddInvoice: true })}
                addCustomer={() => this.setState({ openAddCustomer: true })}
              />
              <RenderPlan
                plan={plan}
                notes={selectedAccountData?.account?.notes}
                isEdit={this.isEdit}
                formatDate={this.props.formatDate}
              />
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <Typography>
                  <span>{LABELS.managers}</span>
                </Typography>

                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.addUser}
                  onClick={() => this.setState({ openAddForm: true })}
                >
                  {`${LABELS.addUsers}`}
                </Button>
              </Grid>
              <Grid container spacing={2}>
                {admins.map((user, i) => {
                  if (user.role !== "MANAGER") {
                    return null;
                  }
                  return (
                    <RenderUsers
                      accountAuthBusy={accountAuthBusy}
                      handleLogin={this.handleLogin}
                      classes={classes}
                      key={i}
                      user={user}
                      changeStatus={this.changeStatus}
                      loading={this.state.loading}
                      getAccountDetails={this.props.getAccountDetails}
                      updateUserAccount={this.props.updateUserAccount}
                      handleClose={this.props.handleClose}
                    />
                  );
                })}
              </Grid>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userRoles = _get(state, "authData.profile.roles", []);
  let isStripe = _get(state, "appData.appConfig.isStripe", "OFF") === "ON";

  if (userRoles.includes("PARTNER")) {
    isStripe = false;
  }
  return {
    isStripe,
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    selectedAccount: _get(state, "accountData.selectedAccount", {}),
    accountAuthBusy: _get(state, "accountData.accountAuthBusy", false),
    selectedAccountData: _get(state, "accountData.selectedAccountData", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccountAuth,
      getProfile,
      updateUserAccount,
      getAccountDetails,
      formatDate,
      syncRedis,
      getResellerById,
      getBillInvoice,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAccountDetail))
);
