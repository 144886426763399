import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    formatDate,
    formatTime,
    formatDateTimeSec,
    getStartOfDay,
    getEndOfDay,
    formatDateTime
} from "../../actions/momentActions";
import { getLogEditsCount, getLogEdits } from "../../actions/logActions";
import CircularProgress from '@mui/material/CircularProgress';
import LogEditsTable from "./LogEditsTable";
import SearchBar from "../../components/SearchBar";

class LogEdits extends React.Component {
    state = {
        limit: 10,
        page: 0,
        count:0,
        driverId: "",
        start: null,
        end: null,
        loading: false
    };

    async componentDidMount() {
        await this.getLogEdits();
        await this.getLogEditsCount();

    }
    getLogEditsCount = async () => {
        const response = await this.props.getLogEditsCount({});
        if (response) {
        this.setState({ count: response });

      }
      };
    async getLogEdits() {

        this.setState({ loading: true });
        const { limit, page, driverId, start, end } = this.state;
        const skip = parseInt(page) * parseInt(limit);

        await this.props.getLogEdits({
            limit,
            skip,
            driverId,
            start: start ? new Date(this.props.getStartOfDay(start)) : null,
            end: end ? new Date(this.props.getEndOfDay(end)) : null
            //    start
            //    ,
            //     end
        });
        this.setState({ loading: false });

        // this.props.getLogEditsCount({ limit, skip, driverId, start, end });


    }

    handleChange = ({ limit, page }) => {
        this.setState({ limit, page }, this.getLogEdits);
    };

    onSearch = ({ driverId, start, end }) => {
        this.setState({ driverId, start, end }, this.getLogEdits);
    };
    render() {
        const { loading } = this.state;
        return (
            <div>
                <SearchBar
                    options={{
                        isDriver: true,
                        isStartDate: true,
                        isEndDate: true,
                        isTime: true
                    }}
                    loading={loading}
                    onSearch={this.onSearch}
                />
                {loading && (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                )}
                <LogEditsTable
                    count={this.props.count}
                    items={this.props.logEdits}
                    formatDate={this.props.formatDate}
                    formatTime={this.props.formatTime}
                    limit={this.state.limit}
                    page={this.state.page}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        logEdits: _get(state, "logData.logEdits", []),
        count: _get(state, "logData.logEditsCount", 0)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            formatDate,
            formatTime,
            formatDateTimeSec,
            getLogEditsCount,
            getLogEdits,
            getStartOfDay,
            getEndOfDay,
            formatDateTime,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogEdits);
