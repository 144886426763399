import React, { Component } from "react";
import _get from "lodash/get";
// import html2pdf from "html2pdf.js";
// import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// import Alert from "@mui/material/Alert";
// import CircularProgress from "@mui/material/CircularProgress";
import LogCertify from "./LogCertify";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  table: {
    minWidth: 700,
  },
  small: {
    minWidth: 150,
  },
  medium: {
    minWidth: 200,
  },
  large: {
    minWidth: 300,
  },
  speed: {
    display: "inline-block",
    marginLeft: 10,
    fontWeight: 600,
  },
  buttonProgress: {
    color: "green[500]",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const STYLES = {
//   mainDiv: {
//     width: "100%",
//   },
//   table: {
//     border: "0.1px solid #ccc",
//     width: "90%",
//     borderCollapse: "collapse",
//     margin: "auto",
//     fontSize: "13px",
//   },
//   td: {
//     border: "0.2px solid #ccc",
//     width: "35%",
//     textAlign: "left",
//     padding: 5,
//   },
//   th: {
//     border: "0.2px solid #ccc",
//   },

//   tdLog: {
//     border: "0.1px solid #ccc",
//     width: "100%",
//     textAlign: "center",
//     padding: 5,
//   },
//   td2: {
//     border: "0.1px solid #ccc",
//     width: "20%",
//     textAlign: "center",
//     padding: 5,
//   },
//   tvalue: {
//     display: "inline-block",
//     margin: 0,
//     marginLeft: 15,
//   },
//   message: {
//     width: "90%",
//     margin: "auto",
//     marginTop: 20,
//   },
//   spanSt: {
//     float: "left",
//     minWidth: 170,
//   },
//   captVal: {
//     textTransform: "capitalize",
//     display: "inline-block",
//     margin: 0,
//     marginLeft: 15,
//   },
//   saveBtn: {
//     display: "inline-block",
//     marginTop: "20px ",
//     width: "10%",
//   },
//   downloadBtn: {
//     display: "inline-block",
//     margin: "20px 10px ",
//     width: "15%",
//   },
//   fSize: {
//     fontSize: "12px",
//     float: "left",
//     minWidth: 170,
//   },
//   pSize: {
//     fontSize: "12px",
//     display: "inline-block",
//     margin: 0,
//     marginLeft: 15,
//   },
// };

class LogCertifyDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder: "certify",
      saving: false,
      signOpen: false,
    };
  }

  render() {
    const { classes } = this.props;
    // const { loading } = this.state;
    // const { saving, imageUrl, errorMessage, signOpen, download } = this.state;
    return (
      <Dialog
        fullScreen
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ visibility: this.props.isAuto ? "hidden" : "visible" }}
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              {"Log Book"}
            </Grid>
            <Grid item xs={6} md={6}>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.props.handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <div style={{ width: 800, margin: "auto" }}>
            <LogCertify hideResetLines={true} webview={true} {...this.props} />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logs: _get(state, "logData.logs", []),
    driverRealTime: _get(state, "driverData.driverRealTime", []),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    bucket: _get(state, "authData.profile.accountProperties.bucket", ""),
    signature: _get(state, "authData.profile.driver.signature.data"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getLogPostUrl,
      // formatTime,
      // getEndOfDay,
      // getStartOfDay,
      // formatRawDate,
      // postSignImage,
      // getProfile,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LogCertifyDownload))
);
