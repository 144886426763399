import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import {
    getTerminals,
    selectedTerminal
} from "../../../actions/terminalActions";
import { formatDate } from "../../../actions/momentActions";
import CircularProgress from '@mui/material/CircularProgress';
import TerminalTable from "./TerminalTable";
import AddTerminalForm from "./AddTerminalForm";
import ReduxButton from "../../../components/ReduxButton";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;


const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Terminal extends React.Component {
    state = {
        open: false,
        carrier: null,
        searching: false
    };

    async componentDidMount() {
        await this.getTerminals();
    }

    getTerminals = async () => {
        this.setState({ searching: true });
        await this.props.getTerminals();
        this.setState({ searching: false });

    };

    handleOpen = () => {
        this.setState({ open: true, isEdit: false });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.selectedTerminal(null);
        this.props.getTerminals();
    };

    handleEdit = terminal => {
        this.props.selectedTerminal(terminal);
        this.setState({ open: true, isEdit: true });
    };

    render() {
        const { classes, terminals, terminalBusy, isViewOnly } = this.props;
        const { open, isEdit } = this.state;
        return (
            <div>
                {!isViewOnly && <Grid container spacing={0} className={classes.addWrapper}>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <ReduxButton
                            width
                            onClick={this.handleOpen}
                            label={`${LABELS.add}`}
                        />
                    </Grid>
                </Grid>}
                {terminalBusy ?
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div> : <>
                        <TerminalTable
                            handleEdit={this.handleEdit}
                            items={terminals}
                            formatDate={this.props.formatDate}
                            isViewOnly={isViewOnly}
                        />
                        {open && (
                            <AddTerminalForm
                                open={open}
                                isEdit={isEdit}
                                handleClose={this.handleClose}
                            />
                        )}
                    </>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        terminalBusy: _get(state, "terminalData.terminalBusy", false),
        terminals: _get(state, "terminalData.terminals", []),
        isViewOnly: _get(state, "authData.isViewOnly", false),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTerminals,
            selectedTerminal,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Terminal)
);
