import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./dvirActionTypes";

export const setDvirTemplates = (value) => ({
  type: TYPES.DVIR_TEMPLATES,
  value,
});

export const setDvirAll = (value) => ({
  type: TYPES.DVIR_ALL,
  value,
});

export const setDvirCount = (value) => ({
  type: TYPES.DVIR_COUNT,
  value,
});

export function getAllDvirCount(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/dvirs/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data.count", 0);
        dispatch(setDvirCount(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getDvirTemplates(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/dvirTemplates`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setDvirTemplates(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAllDvir(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/dvirs`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setDvirAll(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getDriverDvir(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = _get(getState(), "authData.profile.user.driverId");

    return axios({
      url: `${BASE_URL}/${accountId}/drivers/${driverId}/dvirs`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        dispatch(setDvirAll(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createDriverDvir(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = _get(getState(), "authData.profile.user.driverId");

    return axios({
      url: `${BASE_URL}/${accountId}/drivers/${driverId}/dvirs`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateDriverDvir(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    // const driverId = _get(getState(), "authData.profile.user.driverId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/dvirs/${params.id}`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getDriverDvirTemplates(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    // const driverId = _get(getState(), "authData.profile.user.driverId");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/${accountId}/drivers/{driverId}/dvirTemplates`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setDvirTemplates(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getDriverSignById(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const driverId = _get(getState(), "authData.profile.user.driverId");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/${accountId}/drivers/${driverId}/sign`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function findDvirById(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/dvirs/${params.id}`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function deleteDvir(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/dvirs/${params.id}`,
      method: "delete",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
