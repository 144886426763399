export const LABELS = {
  map: "Mapa",
  table: "Mesa",
  search: "Buscar",
  name: "Nombre",
  status: "Estado",
  location: "Ubicación",
  vehicle: "Vehículo",
  break: "Romper",
  drive: "Conducir",
  shift: "Cambio",
  cycle: "Ciclo",
  violations: "Violaciones",
  eld: "Vejez",
  update: "Actualizar",
  app: "Aplicación",
};
