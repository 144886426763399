import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import DutyStatus from "../../../components/DutyStatus";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.dutyPage;

function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableHeaderCell: {
    textAlign: "left",
    padding: 10,
    minWidth: 55,
  },
  tableRowCell: {
    fontSize: "12px !important",
    padding: 10,
    font: "message-box !important",
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textInactive: {
    textDecoration: "line-through",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  font: {
    fontSize: "14px",
  },
  select: {
    minWidth: "70px",
  },
  error: {
    marginTop: "2px",
    color: "red",
  },
  odoError: {
    color: "red",
    fontSize: "12px",
  },
  dateHeaderCell: {
    textAlign: "left",
    padding: 12,
    minWidth: 50,
  },
});

class ReduxTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      // driverLogs: props.driverLogs
    };
  }

  render() {
    const { classes, item = {}, isAllLog = false } = this.props;
    const eventTypeItems = isAllLog
      ? [
          "CHANGE_DUTY_STATUS",
          "INTERMEDIATE_LOG",
          "YARD_MOVES",
          "PERSONAL_USE",
          "CERTIFICATION",
          "LOGIN_LOGOUT",
          "POWERUP_SHUTDOWN",
          "MALFUNCTION_DIAGNOSTIC",
          ,
        ]
      : ["CHANGE_DUTY_STATUS", "YARD_MOVES", "PERSONAL_USE"];

    const recordStatusItems = isAllLog
      ? [
          "ACTIVE",
          "INACTIVE_CHANGED",
          "INACTIVE_DEACTIVATE",
          "INACTIVE_CHANGE_REQUEST",
          "INACTIVE_CHANGE_REJECTED",
        ]
      : ["ACTIVE"];

    const isAllowed =
      recordStatusItems.includes(item.recordStatus) &&
      eventTypeItems.includes(item.eventType);

    if (
      !isAllowed ||
      item.isLast ||
      item.eventTime === this.props.startOfDay(item.eventTime).toISOString()
    ) {
      return null;
    }

    return (
      <TableRow hover className={`${classes.table}`}>
        <TableCell className={`${classes.tableRowCell}`}>
          <Checkbox
            defaultChecked={item.isEdited}
            color={"primary"}
            checked={item.isEdited || false}
            onChange={(e) => {
              item.isEdited = e.target.checked;
              this.props.multi(item);
            }}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>
            <strong>{this.props.formatTime(item.eventTime)}</strong>
            <div>{this.props.formatRawDate(item.eventTime)}</div>
          </div>
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <DutyStatus
            status={item}
            eventType={item.eventType}
            eventCode={item.eventCode}
            annotation={item.annotation}
            eventSubType={item.eventSubType}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>{item.location}</div>
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {item.odometer && <div>{pF(item.odometer).toFixed(2)} Miles</div>}
        </TableCell>

        <TableCell className={`${classes.tableRowCell}`}>
          <div>
            <TextField
              size="small"
              variant="standard"
              style={{ borderBottom: "1px solid #7F7F7F" }}
              fullWidth
              placeholder="Notes"
              onChange={(e) => {
                item.annotation = e.target.value;
                this.setState({ annotation: e.target.value });
              }}
              onBlur={() => {
                this.props.multi(item);
              }}
              value={this.state.annotation || item.annotation}
            />
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export class MultiStatusDetailTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      allLogs: props.allLogs,
    };
  }

  render() {
    const { classes, onDriverSelect, isAllLog = false } = this.props;
    const { allLogs } = this.state;
    // const isCoDriver = this.isCoDriver();

    // const eventTypeItems = ["CHANGE_DUTY_STATUS", "YARD_MOVES", "PERSONAL_USE"];
    // const recordStatusItems = ["ACTIVE"];
    const eventTypeItems = isAllLog
      ? [
          "CHANGE_DUTY_STATUS",
          "INTERMEDIATE_LOG",
          "YARD_MOVES",
          "PERSONAL_USE",
          "CERTIFICATION",
          "LOGIN_LOGOUT",
          "POWERUP_SHUTDOWN",
          "MALFUNCTION_DIAGNOSTIC",
        ]
      : ["CHANGE_DUTY_STATUS", "YARD_MOVES", "PERSONAL_USE"];

    const recordStatusItems = isAllLog
      ? [
          "ACTIVE",
          "INACTIVE_CHANGED",
          "INACTIVE_DEACTIVATE",
          "INACTIVE_CHANGE_REQUEST",
          "INACTIVE_CHANGE_REJECTED",
        ]
      : ["ACTIVE"];

    return (
      <div>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            size="small"
            variant="standard"
            className={classes.formControl}
            style={{ minWidth: 100 }}
          >
            <Select
              className={classes.font}
              defaultValue="-"
              onChange={(e) => {
                onDriverSelect(e.target.value);
                this.props.multi();
              }}
              inputProps={{
                name: "driver",
                id: "driver",
              }}
            >
              <MenuItem value="-">{LABELS.selectDriver}</MenuItem>
              {this.props.drivers.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item.id}
                  style={{ textTransform: "capitalize" }}
                >
                  {`${item.firstName} ${item.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {Array.isArray(allLogs) && allLogs.length > 0 ? (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  <Checkbox
                    defaultChecked={false}
                    color={"primary"}
                    onChange={(e) => {
                      allLogs.forEach((item) => {
                        const isAllowed =
                          recordStatusItems.includes(item.recordStatus) &&
                          eventTypeItems.includes(item.eventType);
                        if (
                          !isAllowed ||
                          item.isLast ||
                          item.eventTime ===
                            this.props.startOfDay(item.eventTime).toISOString()
                        ) {
                          return;
                        }
                        item.isEdited = e.target.checked;
                        this.props.multi(item);
                      });
                    }}
                  />
                </TableCell>
                <TableCell className={classes.dateHeaderCell}>
                  {LABELS.time}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.status}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Location}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.odometer}
                </TableCell>
                {this.props.adminKey && (
                  <TableCell className={classes.tableHeaderCell}>
                    {LABELS.notes}{" "}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {allLogs.map((item, i) => (
                <ReduxTableRow
                  getDate={this.props.getDate}
                  item={item}
                  key={1000000 + i}
                  index={i}
                  classes={classes}
                  isAllLog={this.props.isAllLog}
                  formatTime={this.props.formatTime}
                  searchLogStatus={this.props.searchLogStatus}
                  setSelectedLog={this.props.setSelectedLog}
                  adminKey={this.props.adminKey}
                  formatRawDate={this.props.formatRawDate}
                  selectedDate={this.props.selectedDate}
                  formatDateTimeUtc={this.props.formatDateTimeUtc}
                  multi={this.props.multi}
                  formatDateTimeToUtc={this.props.formatDateTimeToUtc}
                  startOfDay={this.props.startOfDay}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Grid item xs={12}>
            {" "}
            <Alert severity={"error"}>{"No logs to reassign!"}</Alert>
          </Grid>
        )}
      </div>
    );
  }
}

MultiStatusDetailTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultiStatusDetailTable);
