const defaultState = {
  waittime: {},
};

export default function support(state = defaultState, action) {
  switch (action.type) {
    case "WAITTIME":
      return {
        ...state,
        waittime: action.value,
      };

    default:
      return state;
  }
}
