export const LABELS = {
  viewLogs: "Ver los registros",
  date: "Fecha",
  detailsMulti: "Multi",
  certified: "Certificado",
  uncertified: "No certificado",
  detail: "Detalle",
  violation: "Violación",
  driving: "Conduciendo",
  offDuty: "Fuera de servicio",
  onDutyND: "En servicioND",
  sleeper: "Durmiente",

  deleteLabel: "Eliminar infracciones",
  deleteMsg: "¿Está seguro de que desea eliminar todas las infracciones?",
};
