import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./violationActionTypes";

export const setViolations = value => ({
    type: TYPES.VIOLATIONS,
    value
});

export const setViolationsCount = value => ({
    type: TYPES.VIOLATIONS_COUNT,
    value
});

export const selectedViolation = value => ({
    type: TYPES.SELECTED_VIOLATION,
    value
});

export const appViolationBusy = value => ({
    type: TYPES.APP_VIOLATION_BUSY,
    value
});

export function getViolationsCount(params = {}) {
    return async (dispatch, getState) => {
        const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        try {
            const response = await axios({
                url: `${STATUS_BASE_URL}/manager/${accountId}/violations/count`,
                method: "get",
                params
            });
            const _response = _get(response, "data.count", 0);

            dispatch(setViolationsCount(_response));
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function getViolations(params = {}) {
    return async (dispatch, getState) => {
        const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(appViolationBusy(true));

        try {
            const response = await axios({
                url: `${STATUS_BASE_URL}/manager/${accountId}/violations`,
                method: "get",
                params
            });
            const _response = _get(response, "data", []);

            dispatch(setViolations(_response));
            dispatch(appViolationBusy(false));

            return response;
        } catch (error) {
            dispatch(appViolationBusy(false));
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function createViolation(params = {}) {
    return async (dispatch, getState) => {
        const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        try {
            dispatch(appViolationBusy(true));

            const response = await axios({
                url: `${STATUS_BASE_URL}/manager/${accountId}/violations`,
                method: "post",
                data: params
            });

            const _response = _get(response, "data", {});
            dispatch(appViolationBusy(false));
            return _response;
        } catch (error) {
            dispatch(appViolationBusy(false));
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}

export function updateViolation(params = {}) {
    return async (dispatch, getState) => {
        const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        dispatch(appViolationBusy(true));

        try {
            const response = await axios({
                url: `${STATUS_BASE_URL}/manager/${accountId}/violations/${
                    params._id
                }`,
                method: "put",
                data: params
            });
            dispatch(appViolationBusy(false));
            const _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            dispatch(appViolationBusy(false));
            const message = _get(error, "response.data.errors[0].message");
            return message;
        }
    };
}
