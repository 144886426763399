import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Formik, Form } from "formik";
import {
  getAccountProperties,
  updateAccountProperties,
} from "../../../actions/account/accountAdminActions";
import { validateProperties } from "./validate";
import Grid from '@mui/material/Grid';
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import CheckBox from "../../../components/CheckBox";
import Error from "../../Error";
import Success from "../../Success";
import { THEME } from "../../../constants";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 25,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class EditAccountForm extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.getAccountProperties();
  }

  getAccountProperties = async () => {
    this.setState({ loading: true });
    const accountId = _get(this, "props.selected._id");
    const accountProperties = await this.props.getAccountProperties({
      accountId,
    });
    this.setState({ loading: false, accountProperties });
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    if (values && values.kpin) {
      values.kioskpin = window.btoa(unescape(encodeURIComponent(values.kpin)));
    }

    let response = await this.props.updateAccountProperties(values);

    if (response && response._id) {
      this.setState({
        successMessage: LABELS.updateSuccessfully,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = LABELS.response;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const accountProperties = this.state.accountProperties;

    if (loading || !accountProperties) {
      return null;
    }
    if (accountProperties && accountProperties.kioskpin) {
      accountProperties.kpin = decodeURIComponent(
        escape(window.atob(accountProperties.kioskpin))
      );
    }

    return (
      <Formik
        initialValues={accountProperties}
        validate={validateProperties}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => {
          return (
            <Form style={{ padding: "20px 10px" }}>
              <Grid container spacing={3}>
                <SelectBox
                  md={3}
                  name="updateFrequency"
                  label={LABELS.updateFrequency}
                  items={constants.UPDATE_FREQUENCY}
                />


                <InputBox md={3} name="bucket" label={LABELS.bucket} />
                <SelectBox md={2} name="theme" label={LABELS.theme} items={THEME} />
                <InputBox md={2} name="kpin" label={LABELS.kioskpin}/>
                <SelectBox
                  md={2}
                  name="ispdf"
                  label="Pdf"
                  items={constants.ALL_OPTIONS}
                />
                <InputBox md={6} name="apiKey.hereAppId" label={LABELS.hereAppId}/>
                <InputBox
                  md={6}
                  name="apiKey.hereApiKey"
                  label={LABELS.hereApiKey}
                />

                <Grid item xs={12} md={4}>
                  <CheckBox name="updateLocation" label={LABELS.updateLocation} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.debug" label={LABELS.debug} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.showAllLogs" label={LABELS.allLogs} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.editLogs" label={LABELS.editLogs} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.isIfta" label={LABELS.ifta} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.isTracking" label={LABELS.tracking} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.isDoc" label={LABELS.documents} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.isNotif" label={LABELS.notification} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.isShare" label={LABELS.liveshare} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CheckBox name="settings.isDvir" label={LABELS.dvir} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <CheckBox name="settings.addDvir" label={LABELS.addDvir} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h4>{LABELS.appSettings}</h4>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CheckBox name="settings.appAllLogs" label={LABELS.appAll_Log} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CheckBox name="settings.appAllLogsFmcsa" label={LABELS.appAllLogsFmcsa} />
                </Grid>


              </Grid>
              {successMessage && <Success message={successMessage} />}
              {errorMessage && <Error message={errorMessage} />}
              <div className={classes.DialogActions}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? LABELS.saving : LABELS.save}
                </Button>{" "}
                <Button variant="contained" onClick={this.props.handleClose}>
                  {LABELS.close}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccountProperties,
      updateAccountProperties,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAccountForm))
);
