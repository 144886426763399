import React from "react";
import { withStyles } from '@mui/styles';
import { EVENT_CODES } from "../../constantsStatus";

const styles = (theme) => ({
  wrapper: {
    display: "inline-block",
    // width: 125,
    textAlign: "left",
  },
  status: {
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 3,
    backgroundColor: "white",
    textAlign: "center",
    color: "#aeaeae",
    display: "inline-block",
    minWidth: 85,
    borderRadius: 5,
    fontWeight: 500,
    border: "1px solid #aeaeae",
    fontSize: 13,
    height:"25px"
  },
  small: {
    padding: 0,
    fontSize: 10,
    width: 60,
  },
  labelStatusdefault: {
    color: "#777",
    border: "1px solid #777",
  },
  labelStatusbreak: {
    color: "#1e88e5",
    border: "1px solid #1e88e5",
  },
  labelStatusremark: {
    color: "grey",
    border: "1px solid grey",
  },
  labelStatus_OFFDUTY: {
    color: "#424242",
    border: "1px solid #424242",
  },
  labelStatus_SLEEPER: {
    color: "#c3834c",
    border: "1px solid #c3834c",
  },
  labelStatus_DRIVING: {
    color: "#5cb85c",
    border: "1px solid #5cb85c",
  },
  labelStatus_ONDUTY_ND: {
    color: "#1e88e5",
    border: "1px solid #1e88e5",
  },
  labelStatusRemark: {
    color: "#29b6f6",
    border: "1px solid #29b6f6",
  },
  labelStatuswaitingAtSite: {
    color: "#ff7043",
    border: "1px solid #ff7043",
  },
  labelError: {
    color: "red",
    border: "1px solid red",
  },
});

function DutyStatus(props) {
  const classes = props.classes;
  const isError = props.isError;
  const customeStyles = props.styles || {};
  const status = props.status || {};
  const size = props.size || "medium";
  const tableClass = props.tableClass;
  const eventCode = props.eventCode || "";
  const classKey = `labelStatus_${eventCode}`;
  let classNamesStatus = `${classes.status} ${classes[classKey]} ${tableClass} ${classes[size]}`;
  let eventLabel = EVENT_CODES[props.eventCode];

  if (props.eventType === "LOGIN_LOGOUT") {
    eventLabel = props.eventSubType;
    classNamesStatus = `${classes.status}`;
  } else if (props.eventType === "INTERMEDIATE_LOG") {
    eventLabel = props.eventSubType;
    classNamesStatus = `${classes.status}`;
  } else if (props.eventType === "POWERUP_SHUTDOWN") {
    eventLabel = props.eventSubType;
    classNamesStatus = `${classes.status}`;
  } else if (props.eventType === "CERTIFICATION") {
    eventLabel = props.eventSubType;
    classNamesStatus = `${classes.status}`;
  } else if (props.eventType === "MALFUNCTION_DIAGNOSTIC") {
    eventLabel = status.diagnostic ? "Diagnostic" : "Malfunction";

    if (!status.diagnostic && status.diagnosticCode) {
      eventLabel = "Clr Diag.";
    }
    if (!status.malfunction && status.malfunctionCode) {
      eventLabel = "Clr Malf.";
    }

    classNamesStatus = `${classes.status}`;
  }

  if (isError) {
    classNamesStatus = `${classNamesStatus} ${classes.labelError}`;
  }

  const key =
    props.eventSubType === "EXP:AGIS"
      ? "AS"
      : props.eventSubType === "EXP:AGIE"
      ? "AE"
      : props.eventType === "YARD_MOVES"
      ? " YM"
      : props.eventType === "PERSONAL_USE"
      ? " PC"
      : "";

  return (
    <span className={`${classes.wrapper} ${classes[size]}`}>
      <span
        className={`${classNamesStatus}`}
        style={customeStyles}
      >{`${eventLabel}`}</span>
      <strong>{`${key}`}</strong>
    </span>
  );
}
export default withStyles(styles)(DutyStatus);
