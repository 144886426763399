import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./maintenanceActionTypes";
import { MAINTENANCE_TYPE } from "../constants";

function compare(a, b) {
  if ((a.lastContact || a.eventTime) > (b.lastContact || b.eventTime)) {
    return -1;
  }
  if ((a.lastContact || a.eventTime) < (b.lastConstact || b.eventTime)) {
    return 1;
  }
  return 0;
}

export const setMaintenanceCount = (value) => ({
  type: TYPES.MAINTENANCE_COUNT,
  value,
});

export const setMaintenance = (value) => ({
  type: TYPES.MAINTENANCES,
  value,
});

export const setMaintenanceWarning = (value) => ({
  type: TYPES.MAINTENANCE_WARNING,
  value,
});

export function getMaintenanceCount(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/maintenance/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data.count", 0);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getMaintenance(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    const accountId = _get(getState(), "authData.profile.user.accountId");
    params.accountId = accountId;

    dispatch(setMaintenance([]));

    return axios({
      url: `${BASE_URL}/manager/${accountId}/maintenance`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        dispatch(setMaintenance(_response));
        setTimeout(() => {
          dispatch(getMaintenanceWarning([]));
        }, 5000);

        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createMaintenance(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    params.accountId = accountId;

    return axios({
      url: `${BASE_URL}/manager/${accountId}/maintenance`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateMaintenance(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    params.accountId = accountId;

    return axios({
      url: `${BASE_URL}/manager/${accountId}/maintenance/${
        params.id || params._id
      }`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getMaintenanceWarning() {
  return (dispatch, getState) => {
    dispatch(setMaintenanceWarning([]));
    const maintenances = _get(getState(), "maintenanceData.maintenance");
    const __driverRt = _get(getState(), "driverData.driverRealTime");

    let drObj = {};


    const _driverRt = __driverRt && __driverRt.sort(compare);


    for (let i = _driverRt.length - 1; i >= 0; i--) {
      const drRt = _driverRt[i] || {};
      let eqp = (drRt.vehicle && drRt.vehicle.id) || "";
      drObj[eqp] = drObj[eqp] || {};
      drObj[eqp] = drRt;
    }

    const driverRt = Object.values(drObj);

    if (
      !maintenances ||
      !Array.isArray(maintenances) ||
      maintenances.length < 1
    ) {
      return;
    }
    if (!driverRt || !Array.isArray(driverRt) || driverRt.length < 1) {
      return;
    }

    function getMessage(type, diff) {
      const _label = MAINTENANCE_TYPE.find((item) => item.id === type);
      const label = _label && _label.name;
      let _message = `is due in ${diff} M(s)`;
      if (diff < 0) {
        _message = `is past due by ${diff} M(s)`;
      }
      return ` ${label} ${_message}`;
    }

    let warnings = [];

    for (let j = 0; j < driverRt.length; j++) {
      const rt = driverRt[j] || {};
      const veh = rt.vehicle || {};
      const assetIdCur = veh.id;
      const tel = rt.telemetrics || {};
      const odoKmCur = tel.odometerKm || 0;

      if (odoKmCur) {
        for (let i = 0; i < maintenances.length; i++) {
          const maint = maintenances[i] || [];
          const odometer = parseInt(maint.odometer) || 0;
          const assetId = maint.assetId;

          if (assetIdCur === assetId) {
            const tel = rt.telemetrics || {};
            const odoKmCur = tel.odometerKm || 0;
            const odoCur = parseFloat((odoKmCur * 0.621371).toFixed(2));
            const diff = odometer - odoCur;
            const message = getMessage(maint.type, diff.toFixed());

            if (diff < 0) {
              warnings.push({
                code: "ERROR",
                type: maint.type,
                message,
                label: "Maintenance",
                miles: diff,
                vehicle: veh,
                maintenance: maint,
              });
            } else if (diff < 500) {
              warnings.push({
                code: "WARNING",
                type: maint.type,
                message,
                label: "Maintenance",
                miles: diff,
                vehicle: veh,
                maintenance: maint,
              });
            } else {
              warnings.push({
                code: "INFO",
                type: maint.type,
                message,
                label: "Maintenance",
                miles: diff,
                vehicle: veh,
                maintenance: maint,
              });
            }
          }
        }
      }
    }
    dispatch(setMaintenanceWarning(warnings));
  };
}
