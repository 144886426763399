export const LABELS = {
  DASHCAMERA: {
    header: "La vidéo intégrée est ici.",
    header2:
      "Osrit Dashcam vous aide à améliorer le comportement des conducteurs et à atténuer les risques pour votre entreprise en fournissant un contexte visuel des événements de conduite difficiles.",
    header3: "Pour en savoir plus, veuillez visiter à",
    header3Link: "www.truckdashcamera.com",
  },
  OSRIT: {
    header: "Logiciel de camionnage cloud | TMS.",
    header2:
      "Le logiciel Osrit Cloud vous aide à répartir facilement les charges FTL ou LTL, les clients, les courtiers, les chauffeurs, la paie, la facturation, etc. à partir d'un seul endroit !",
    header3: "Pour en savoir plus, veuillez visiter à",
    header3Link: "www.osrit.com",
  },
  SIGN_IN_FORM: {
    email: "E-mail",
    sendEmail:"Envoyer un e-mail",
    password: "Mot de passe",
    logInBtn: "Se connecter",
    logInLoading: "Chargement...",
    forgetPwd: "Mot de passe oublié",
    required: "Requis",
    inValidEmail: "Adresse e-mail invalide",
    invalidPassword: 'Mot de passe incorrect',
    passwordNoMatch: "Les mots de passe ne correspondent pas",
    invalidEmailPwd: "email ou mot de passe invalide"
  },

  close:"Fermer",
  please_check_yourEmail_for_password_reset_instruction:"Veuillez vérifier votre courrier électronique pour les instructions de réinitialisation du mot de passe.",
  please_try_after_sometime:"S'il vous plaît, essayez après un certain temps.",
};
