export const OVERVIEW_ALL = 'OVERVIEW_ALL';
export const OVERVIEW_COUNT = 'OVERVIEW_COUNT';

export const FIREBASE_ERRORS = 'FIREBASE_ERRORS';
export const GET_USER = 'GET_USER';
export const GET_AUDIT_EVENTS = 'GET_AUDIT_EVENTS';
export const APP_USER_BUSY = 'APP_USER_BUSY';
export const APP_PLAN_BUSY = 'APP_PLAN_BUSY';
export const GET_PLAN = 'GET_PLAN';
export const GET_REALTIME = 'GET_REALTIME';





