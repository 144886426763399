export const LABELS = {
  map: "ਨਕਸ਼ਾ",
  table: "ਟੇਬਲ",
  search: "ਖੋਜ",
  name: "ਨਾਮ",
  status: "ਸਥਿਤੀ",
  location: "ਟਿਕਾਣਾ",
  vehicle: "ਵਾਹਨ",
  break: "ਤੋੜ",
  drive: "ਚਲਾਉਣਾ",
  shift: "ਸ਼ਿਫਟ",
  cycle: "ਚੱਕਰ",
  violations: "ਉਲੰਘਣਾਵਾਂ",
  eld: "ELD",
  update: "ਅੱਪਡੇਟ ਕਰੋ",
  app: "ਐਪ",
};
