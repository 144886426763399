import React from "react";
import _get from "lodash/get";
import { Alert } from "@mui/lab";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { VIOLATIONS, VIOLATIONS_Calforina } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "10px auto 15px",
  },
}));

export default function Violations(props) {
  const classes = useStyles();
  const dailyDiary = _get(props, "dailyDiary[0]", {});
  const violations = _get(props, "dailyDiary[0].violations", []);
  const exception = _get(props, "dailyDiary[0].exceptions[0].id");
  const driver = props.driverCalcus;
  // console.log("driverCalculs", driver);

  if (!violations || !Array.isArray(violations) || violations.length === 0) {
    return null;
  }


  return (
    <Grid container spacing={2} className={classes.root}>
      {violations.map((item, idx) => {
        

        return (
          <Grid item key={`${item.id}_${idx}`} xs={4} md={4}>
             <Alert severity="error" style={{ width: "100%", marginTop: 3 }}>
              {Array.isArray(driver) && driver.find((driver) => {
                return driver?.cycleUsa === "California80hr8days"

              })
                ? `Violation: ${VIOLATIONS_Calforina[item.id]}  (${props.formatTime(item.time)})`
                : `Violation: ${VIOLATIONS[item.id]}  (${props.formatTime(item.time)})`}
            </Alert>
          </Grid>
        );
      })}
    </Grid>
  );
}
