export const DRIVER_LOGS = "DRIVER_LOGS";
export const DRIVER_CALCUS = "DRIVER_CALCUS";

export const DRIVER_DAILY_DIARY = "DRIVER_DAILY_DIARY";
export const DRIVER_DAILY_DIARY_COUNT = "DRIVER_DAILY_DIARY_COUNT";
export const DRIVER_LAST_LOGS = "DRIVER_LAST_LOGS";
export const DRIVER_NEXT_LOGS = "DRIVER_NEXT_LOGS";
export const SELECTED_LOG = "SELECTED_LOG";
export const LOGS_BUSY = "LOGS_BUSY";
export const LOGS_ADD_BUSY = "LOGS_ADD_BUSY";
export const LOGS_EDIT_BUSY = "LOGS_EDIT_BUSY";
export const LOGS_DAILY_BUSY = "LOGS_DAILY_BUSY";
export const LOGS_DELETE_BUSY = "LOGS_DELETE_BUSY";
export const LOG_UNIDENTIFIED = "LOG_UNIDENTIFIED";
export const LOGS_UNIDENTIFIED = "LOGS_UNIDENTIFIED";
export const LOGS_UNIDENTIFIED_COUNT = "LOGS_UNIDENTIFIED_COUNT";
export const LOGS_MALFUNCTION = "LOGS_MALFUNCTION";
export const LOGS_MALFUNCTION_COUNT = "LOGS_MALFUNCTION_COUNT";

export const LOG_EDITS = "LOG_EDITS";
export const LOG_EDITS_COUNT = "LOG_EDITS_COUNT";
