import React from "react";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Table, TableBody, TableRow, TableCell, Link } from "@mui/material";
import DtcCodeDetailModal from "./DtcCodeDetailModal";
import { J1939_parse_string } from "../../../utils/dtcDecode";
import axios from "axios";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

class VehicleDetailModal extends React.Component {
  state = {
    message: "",
    isDtcModal: false,
    dtcMessage: null,
    dtc: null,
  };

  onDtcModalClose = () => {
    this.setState({ isDtcModal: false, dtcSpn: null, spn: null });
  };

  dtcCodeDetails = async (dtc) => {
    this.setState({ isDtcModal: true, dtc });

    const dtcsResponse = await this.props.getDtcs({ name: `SPN ${dtc.spn}` });
    this.setState({
      isDtcModal: true,
      dtcMessage: dtcsResponse,
    });
  };

  render() {
    const { classes } = this.props;
    const { dtcMessage, dtc, isDtcModal } = this.state;
    const telemetrics = this.props.data || {};
    const isSmallScreen = window.innerWidth < 500;
    const coDriver = _get(this, "props.data.item.coDriver", {});
    const { handleClose } = this.props;
    let errorCodes = [];
    const faultCodes = telemetrics.faultCodes;

    if (faultCodes) {
      errorCodes = J1939_parse_string(faultCodes);
    }

    return (
      <>
        {isDtcModal && (
          <DtcCodeDetailModal
            open={isDtcModal}
            onClose={this.onDtcModalClose}
            dtcMessage={dtcMessage}
            dtc={dtc}
          />
        )}
        <Dialog
          fullScreen={isSmallScreen}
          fullWidth={true}
          maxWidth={"sm"}
          className={classes.wrapper}
          open={!!this.props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
            <Typography>{"Vehicle Info"}</Typography>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Table>
              <TableBody>
                {coDriver && coDriver.firstName && (
                  <TableRow>
                    <TableCell className={classes.rTitle}>Codriver :</TableCell>
                    <TableCell>
                      {coDriver.firstName} {coDriver.lastName}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow style={{ width: "100%" }}>
                  <TableCell className={classes.rTitle}>Speed :</TableCell>
                  <TableCell>{telemetrics.speed} M/hr</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rTitle}>Rpm :</TableCell>
                  <TableCell>{telemetrics.rpm}</TableCell>
                </TableRow>
                {telemetrics.fuel ? (
                  <TableRow>
                    <TableCell className={classes.rTitle}>Fuel :</TableCell>
                    <TableCell>{telemetrics.fuel} %</TableCell>
                  </TableRow>
                ) : null}
                {errorCodes &&
                Array.isArray(errorCodes) &&
                errorCodes.length > 0 ? (
                  <TableRow>
                    <TableCell className={classes.rTitle}>
                      Fault Codes :
                    </TableCell>

                    <TableCell>
                      {errorCodes.map((item, idx) => {
                        if (item.spn) {
                          return (
                            <div key={idx}>
                              <span
                                style={{
                                  minWidth: 125,
                                  display: "inline-block",
                                }}
                              >
                                {`SPN ${item.spn} ${item.fmi} ${item.cm} ${item.oc}`}
                              </span>
                              <Button
                                size="small"
                                style={{ marginLeft: 10, fontSize: 10 }}
                                onClick={() => this.dtcCodeDetails(item)}
                              >
                                {"More info"}
                              </Button>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </TableCell>
                  </TableRow>
                ) : null}
                {telemetrics.gear > 0 && (
                  <TableRow>
                    <TableCell className={classes.rTitle}>Gear :</TableCell>
                    <TableCell>{telemetrics.gear}</TableCell>
                  </TableRow>
                )}
                {telemetrics.coolant > 0 && (
                  <TableRow>
                    <TableCell className={classes.rTitle}>Coolant :</TableCell>
                    <TableCell>{telemetrics.coolant}</TableCell>
                  </TableRow>
                )}
                {telemetrics.odometerMi > 0 && (
                  <TableRow>
                    <TableCell className={classes.rTitle}>Odometer :</TableCell>
                    <TableCell>{telemetrics.odometerMi.toFixed(2)}</TableCell>
                  </TableRow>
                )}
                {telemetrics.engineHours > 0 && (
                  <TableRow>
                    <TableCell className={classes.rTitle}>
                      EngineHours :
                    </TableCell>
                    <TableCell>{telemetrics.engineHours}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginTop: "5px" }}
              >
                <Button size="small" onClick={handleClose} color="primary">
                  CLOSE
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(VehicleDetailModal));
