import _get from "lodash/get";
import _set from "lodash/set";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.settingPage;

const validate = values => {
    const errors = {};
    let requiredFields = ["firstName", "lastName", "email"];

    if (values.role === "DRIVER") {
        requiredFields = [
            // "role",
            "alias",
            "firstName",
            "lastName",
            "email",
            "terminalId",
            "carrierId",
            "country",
            "state",
            "regulation",
            "number",
            "exemptDriver",
            "equipments",
            "vehicleId"
        ];
    }
    // Helper function to remove non-ASCII characters
    const removeNonASCII = (str) => str.replace(/[^\x00-\x7F]/g, "");
    
     // Apply non-ASCII removal helper
    ["firstName", "lastName", "name", "line1", "line2", "city"].forEach(field => {
        if (values[field]) {
            values[field] = removeNonASCII(values[field]);
        }
    });

    if (values.firstName && values.firstName.trim().length < 2) {
        errors.firstName = "First Name must be at least 2 characters";
    } else if (values.firstName && values.firstName.trim().length > 30) {
        errors.firstName = "First Name cannot exceed 30 characters";
    }
    if (values.lastName && values.lastName.trim().length < 2) {
        errors.lastName = "Last Name must be at least 2 characters";
    } else if (values.lastName && values.lastName.trim().length > 30) {
        errors.lastName = "Last Name cannot exceed 30 characters";
    }
    if (values.name && values.name.trim().length < 4) {
        errors.name = "Name must be at least 4 characters long";
    } else if (values.name && values.name.trim().length > 120) {
        errors.name = "Name cannot exceed 120 characters";
    }
    if (values.dot && values.dot.trim().length < 1) {
        errors.dot = "DoT number must be at least 1 charecter long"
    } else if (values.dot && values.dot.trim().length > 9) {
        errors.dot = "Name cannot exceed 9 characters";
    }

    if (!values.isEdit) {
        requiredFields.push("password");
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = LABELS.required;
        }
    });
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    } else if (values.exemptDriver === "ON" && !values.exemptAnnotation) {
        errors.exemptAnnotation = `${LABELS.required}`;
    }
    if (values.password && /\s/.test(values.password)) {
        errors.password = "Password must not contain spaces";
    }

    return errors;

};

const validateEdit = values => {
    const errors = {};
    const requiredFields = [
        "name",
        "status",
        "phone",
        "email",
        "contact.name",
        "contact.email",
        "contact.phone",
        "contact.role"
    ];
    requiredFields.forEach(field => {
        let _value = _get(values, `${field}`);
        if (!_value) {
            _set(errors, `${field}`, LABELS.required);
        }
    });

    const contactEmail = values.contact && values.contact.email;
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    } else if (
        contactEmail &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail)
    ) {
        _set(errors, `contact.email`, "Invalid email address");
    }
    return errors;
};

const validateProperties = values => {
    const errors = {};

    if (!values.bucket) {
        errors.bucket = LABELS.required;
    }

    return errors;
};

export { validate, validateEdit, validateProperties };
export default validate;
