export const LABELS = {
  saveMessage: "Сохранено успешно.",
  errorMessage: "Пожалуйста, попробуйте через некоторое время.",
  deleteLabel: "Удалить нарушения",
  deleteMsg: "Вы уверены, что хотите удалить все нарушения?",
  language: "Язык",
  noDriverFound:'Драйвер не найден',


  selectLanguage:"Выберите язык",
  saving:"Сохранение...",
  save:"Сохранять",
  close:"Закрывать",
  
};
