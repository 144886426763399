// import { settings } from "firebase/analytics";

export const LABELS = {
  accounts: "Comptes",
  drivers: "Conductrices",
  equipments: "Équipements",

  allCompany: "Toute la société",
  company: "Entreprise",
  dutyStatus: "Statut de service",
  connection: "Connexion",
  connected: "Connecté",
  disconnected: "Disconnected",
  offDuty: "En congé",
  onDuty: "En service",
  driving: "Conduite",
  sleeping: "Dormir",
  driverInfo: "Informations sur le conducteur",
  name: "Nom",
  status: "Statut",
  violations: "Violations",
  date: "Date",
  eLDConnection: "Connexion ELD",
  total: "Totale",
  formsSignatures: "Formulaires et signatures",
  time_period:"Période de temps",
  today:"Aujourd'hui",
  yesterday:"Hier",
  thisWeek:"Cette semaine",
  thisMonth:"Ce mois-ci",
  sleeper:"Dormeuse",
  remark:"Remarque",
  break:"Casser",
  OnDutyND:"En serviceND",
  callback:"Llamar de vuelta",








  count: "Compter",
  search: "Recherche",
  add: "Ajouter",
  contact: "Contact",
  payment: "Paiement",
  created: "Créé",
  actions: "Actions",
  edit: "Modifier",
  edited:"Édité",
  details: "Détails",
  leads: "Conduite",
  added:"Ajouté",
  saving:"Économie...",
  settings:"Paramètres",

  account: "Compte",
  driver: "Conducteur",
  clear: "Clair",
  new: "Nouveau",
  info: "Info",
  caller: "Votre interlocuteur",
  detail: "Détail",
  other: "Autre",
  clean:"FAIRE LE MÉNAGE",
  delete:"Supprimer",
  support:"Soutien",
  assigned:"Attribué",

  provider: "Fournisseur",
  carrier: "Transporteur",
  shipdate: "Date d'expédition",
  tracking: "Suivi",
  addedby: "Ajouté par",
  role:"Rôle",
  terminal:"Terminal",
  dispatch:"Expédition",
  address:"Adresse",
  link:"Lien",
  expiry:"Expiration",
  start_expiry_date:"Date d'expiration de début",
  end_expiry_date:"Date d'expiration de fin",

  commingSoon: "À venir",

  startDate: "Date de début",
  endDate: "Date de fin",
  logs: "Journaux",
  viewLogs: "Regardes les connexions",
  rowsperpage: "Lignes par page",
  logIn: "Se connecter",

  email: "E-mail",
  error: "Erreur",
  time: "Temps",
  phone:"Téléphone",
  reason:"Raison",
  App:"Application",
  Device:"Appareil",


  searchbyemailorname: "Rechercher par e-mail ou par nom",
  supportUsers: "Utilisateurs d'assistance",
  norecordsarefound: "Aucun enregistrement n'a été trouvé",
  selectVehicle:"Sélectionnez un véhicule",

  syncRealTime: "SYNCHRONISATION EN TEMPS RÉEL",
  syncingRealTime:"SYNCHRONISATION EN TEMPS RÉEL",

  reseller: "Revendeur",
  billing: "Facturation",
  overdues: "Au-dessus des cotisations",
  missingstripe: "Bande manquante",
  notes: "Remarques",

  annual: "Annuellement",
  monthly: "Mensuel",
  enabled: "Activé",
  disabled: "Désactivé",
  sync: "Synchroniser",
  syncing:"Synchronisation...",
  activate: "Activer",
  cancel: "Annuler",
  confirm: "Confirmer",
  pleaseConfirm: "Veuillez confirmer, si vous souhaitez",

  deactivate: "Désactiver",
  activeManagers: "Gestionnaires actifs",
  inactiveManagers: "Gestionnaires inactifs",
  activeDrivers: "Pilotes actifs",
  inactiveDrivers: "Pilotes inactifs",
  allowedVehicles: "Véhicules autorisés",
  activeVehicles: "Véhicules actifs",
  inactiveVehicles: "Véhicules inactifs",
  plan: "Plan",
  planInfo: "Informations sur le régime",
  apiAccess: "Accès à l'API :",
  dues: "Droits",
  noduesfound: "Aucune cotisation trouvée",
  iftaAccess: "Accès Ifta",
  addUser: "Gestionnaires",



 standard:"Standard",
 premium:"Prime",
 enterprise:"Enterprise",


  editplan:"MODIFIER LE PLAN",
  allowedVehicle:"Véhicule autorisé",
  currentVehicle:"Véhicule actuel",
  Name:"Nom",
  ifta:"IFTA",
  api:"API",
  truck:"Camion",
  note:'Note',
  addDue:"AJOUTER DÛ",
  save:"SAUVEGARDER",
  close:"FERMER",
  addUsers:"AJOUTER UN UTILISATEUR",
  editUser:"MODIFIER L'UTILISATEUR",
  edit_User:"Modifier l'utilisateur",
  managers:"Gestionnaires",

  callqueues:"Files d'attente d'appels",
  callInQueue:"appels en file d'attente.",
  loading:"Chargement...",
  refresh:"Rafraîchir",
  view:"Voir",
  signOut:"Se déconnecter",
  AllAccounts:"Tous les comptes",
  notification:"Notifications",
  CustomerSupportPackage:"Forfait de support client",
  queues:"Files d'attente",
  all:"Tout",

  invoices:"Factures",

  allEquipments:"Tous les équipements",
  allStates:"Tous les États",

  chat:"Chat",
  unread:"Non lu",
  nounreadchats:"Pas de discussions non lues",
  view_all_chats:"Afficher toutes les discussions",


  shippingSaveSuccessfully:"L'expédition est enregistrée avec succès",
  planIs_updated_successfully:"Le plan est mis à jour avec succès",
  errorMasage_tryafterSometime:"Quelque chose s'est mal passé, veuillez réessayer après un certain temps.",


};
