import _get from "lodash/get";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.settingPage;

function RenderItem(props) {
  const item = props.item || {};
  const vehicleName = _get(props, "item.vehicle.name");
  const label = _get(props, "item.label");
  const message = _get(props, "item.message");
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemIcon>
          {item.code === "ERROR" && (
            <ErrorIcon fontSize="large" color="error" />
          )}
          {item.code === "WARNING" && (
            <WarningIcon fontSize="large" color="warning" />
          )}
          {item.code === "INFO" && <ErrorIcon fontSize="large" color="info" />}
        </ListItemIcon>
        <ListItemText
          primary={vehicleName}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {`${label}: - `}
              </Typography>
              {message}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export default function WarningMessages(props) {
  const items = props.items || [];
  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="support-dialog"
      aria-describedby="support-dialog"
    >
      <DialogTitle id="support-dialog" onClose={props.handleClose}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>{"Notifications"}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <IconButton
              aria-label="close"
              onClick={props.onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {items.map((item, i) => (
            <RenderItem key={`id-${i}`} item={item} />
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
