import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import NotFoundTableRow from "../NotFoundTableRow";
import Button from '@mui/material/Button';
import Chip from "@mui/material/Chip";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.adminPage;
const LABEL_SUPPORT=_LABELS.supportForm;

const styles = (theme) => ({
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, item = {} } = this.props;
    const assignedTo = item.assignedTo || {};
    // const cbInfo = item.cbInfo || {};
    const createdBy = item.createdBy || {};
    const dueDate = this.props.formatDateTime(item.dueDate);
    const createdDate = this.props.formatDateTime(item.created);

    return (
      <TableRow hover>
        <TableCell>
          <Chip size="small" variant="outlined" label={item.status} />
          <br />
          <Chip size="small" variant="outlined" label={item.priority} />
        </TableCell>
        <TableCell component="th" scope="row">
          <div className={classes.dataLargeRow}>
            <div className={classes.capitalize}>{item.accountName}</div>
            {assignedTo.name && (
              <div className={classes.capitalize}>{assignedTo.name}</div>
            )}

            {assignedTo.email && <div>{assignedTo.email}</div>}
            <div>{`${dueDate}`}</div>
          </div>
        </TableCell>
        <TableCell>
          <div>{item.description}</div>
        </TableCell>
        <TableCell>
          <div>{`${createdBy.name}`}</div>
          <div>{`${createdBy.email}`}</div>
          <div>{`${createdDate}`}</div>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={(e) => this.props.handleEdit(item)}
          >
            {LABELS.edit}
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export class AccountTable extends React.Component {
  state = { open: false, selectedAccount: null, order: "asc" };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleDetails = async (selectedAccount) => {
    this.setState({ loading: true });
    this.setState({ open: true });
    this.props.selectedAccount(selectedAccount);
    const accountId = selectedAccount._id;
    await this.props.getAccountDetails({ accountId });
    this.setState({ loading: false });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.selectedAccount(null);
  };

  render() {
    const { eld, classes, items = [], count = 1000 } = this.props;
    const { page, limit } = this.props;

    // const { open, loading } = this.state;
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table
            size="small"
            aria-label="Support Call Table"
          >
            <TableHead>
              <TableRow>
                <TableCell>{LABELS.status}</TableCell>
                <TableCell>{LABEL_SUPPORT.assignedto}</TableCell>
                <TableCell>{LABELS.details}</TableCell>
                <TableCell>{LABELS.created}</TableCell>
                <TableCell>{LABELS.actions}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(items) &&
                items.map((item, i) => (
                  <ReduxTableRow
                    item={item}
                    key={i}
                    eld={eld}
                    classes={classes}
                    handleDetails={this.handleDetails}
                    handleEdit={this.props.handleEdit}
                    formatDate={this.props.formatDate}
                    formatDateTime={this.props.formatDateTime}
                    handleAccountDetails={this.props.handleAccountDetails}
                  />
                ))}
            </TableBody>
          </Table>
          <NotFoundTableRow items={items} />
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            labelRowsPerPage={LABELS.rowsperpage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

AccountTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountTable);
