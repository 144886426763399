export const LABELS={
    addAccount:"AÑADIR CUENTA",

    name:"Nombre",
    status:"Estado",
    ein:"Ein",
    type:"Tipo",
    email:"Correo electrónico",
    phone:"Teléfono",
    role:"Role",
    billing_name:"Nombre de facturación",
    billing_email:"Correo Electrónico de Facturas",
    billing_phone:"Teléfono de facturación",
    billing_role:"Función de facturación",
    stripe:"Raya",
    note:"Nota",
    detail:"Detalle",
    contact:"Contacto",
    billing:"Facturación",
    loging_users:"Usuarios de inicio de sesión",


    saving:"Ahorro...",
    save:"AHORRAR",
    close:"CERCA",


    cleanDriver:"¿Quieres limpiar el controlador de la lista?",
    cleanNow:"LIMPIO AHORA",
    close:"CERCA",
    cleaning:"Limpieza...",




      // <-------for Support user----->

      addSupportUser:"Agregar usuario de soporte",
    note: "Nota: - Esto es solo para agregar un nuevo usuario de soporte. El usuario de soporte tendrá acceso a todos los detalles de su cuenta y operador.",
   firstName:"Nombre de pila",
   lastName:"Apellido",
   mobile:"Móvil",
   password:"Contraseña",
   reseller:"Revendedoras",
   superAdmin:"Súper administrador",
}