import { React, useEffect, useState } from "react";
import _get from "lodash/get";
import { Alert } from "@mui/lab";
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        margin: "10px auto 8px"
    }
}));

export default function Violations(props) {
    const classes = useStyles();

    const [error, setError] = useState();
    const { selectedDriver } = props;
    const driverId = _get(selectedDriver, "driver.id", "");

    useEffect(() => {
        getErrors();
        // eslint-disable-next-line
    }, [driverId]);

    const getErrors = async () => {
        const errors = await props.getFireBaseErrors({ driverId });
        setError(errors);
    };

    if (!error || !Array.isArray(error) || error.length === 0) {
        return null;
    }
    let message = "";
    return (
        <Grid container spacing={2} className={classes.root}>
            {error.map((item, idx) => {
                if (item.code === 401) {
                    message = `Driver's token is expired.`;
                } else {
                    message = item.error;
                }
                return (
                    <Grid item key={`${item.time}_${idx}`} xs={4} md={4}>
                        <Alert
                            severity="error"
                            style={{ width: "100%", marginTop: 2 }}
                        >
                            {message}
                        </Alert>
                    </Grid>
                );
            })}
        </Grid>
    );
}
