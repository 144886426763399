export const LABELS = {
    leads: "LEAD",
  
    channel: "Canal",
    status: "Statut",
    dot: "DOT",
    name: "Nom",
    adress_line: "Ligne d'adressee",
    address_city: "L'addresse de la ville",
    address_state: "État de l'adresse",
    address_zip:"Adresse postale",
    contact_name:"Nom du contact",
    contact_email:"Email du contact",
    contact_phone:"Numéro du contact",
    callback:"Rappeler",
    description:"Description",

    assignedto:"Assigné à",
    assignee_email:"E-mail du destinataire",

    saving:"Économie",
    save:"SAUVEGARDER",
    close:"FERMER",



    lead_is_saved_successfully:"Le prospect a été enregistré avec succès",
    something_went_wrong_please_try_after_some_time:"Quelque chose s'est mal passé, veuillez réessayer après un certain temps.",

  };
 



  